export const scrollElementIntoView = (htmlId: string) => {
  const element = document.getElementById(htmlId);
  if (!element) return;
  const headerOffset = 180;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.scrollY - headerOffset;
  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
};
