import { gql } from '../__generated__';

export const GET_FEEDBACK = gql(/* GraphQL */ `
  query GetFeedbacksForGuide($id: UUID!, $limit: Int) {
    guide: guide(id: $id) {
      id
      feedbacks(first: $limit) {
        totalCount
        nodes {
          id
          like
          title
          text
          canContact
          timestamp
          userEmail
          comment
          commentedAt
          commentedBy {
            subjectId
            name
          }
          deleted
          deletedAt
          deletedBy {
            subjectId
            name
          }
        }
      }
    }
  }
`);

export const COMMENT_FEEDBACK = gql(/* GraphQL */ `
  mutation GuideCommentUserFeedback($input: GuideCommentUserFeedbackInput!) {
    guideCommentUserFeedback(input: $input) {
      guideModel {
        id
        feedbacks(first: 20) {
          totalCount
          nodes {
            id
            comment
          }
        }
      }
    }
  }
`);

export const DELETE_FEEDBACK = gql(/* GraphQL */ `
  mutation GuideDeleteUserFeedback($input: GuideDeleteUserFeedbackInput!) {
    guideDeleteUserFeedback(input: $input) {
      guideModel {
        id
        feedbacks(first: 20) {
          totalCount
          nodes {
            id
            comment
            deleted
            deletedAt
            deletedBy {
              subjectId
              name
            }
          }
        }
      }
    }
  }
`);
