import { Alert, Button, Grid2 as Grid, Skeleton, Stack, TextField } from '@mui/material';
import React, { ChangeEvent, FC, useState } from 'react';
import { AnalysisProps } from '../../types';
import { CreateAbstractResponse, useAi } from '../../useAi';
import { AutoFixHigh } from '@mui/icons-material';

export const Abstract: FC<AnalysisProps> = ({ initialText, isOpen }) => {
  const api = useAi();
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState(initialText);
  const [response, setResponse] = useState<CreateAbstractResponse>();
  const [isError, setIsError] = useState(false);

  const handleOnTextChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleOnCreateAbstractClick = async () => {
    setResponse(undefined);
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await api.createAbstract({ text });
      if (response.isError) {
        setIsError(true);
        return;
      }
      setResponse(response);
    } catch (error) {
      console.error('Failed to create abstract', error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };
  if (!isOpen) return null;
  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <TextField fullWidth rows={15} multiline value={text} onChange={handleOnTextChange}></TextField>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Button
          disabled={isLoading}
          onClick={handleOnCreateAbstractClick}
          size="large"
          variant="contained"
          color="primary"
          startIcon={<AutoFixHigh />}>
          Lag en oppsummering
        </Button>
      </Grid>
      <Grid size={{ xs: 12, lg: 8 }}>
        {!isLoading && response?.abstract}
        {isLoading && <LoadingAnimation />}
        {!isLoading && response && !response.abstract && <Alert severity="info">Det var ikke mulig å lage en oppsummering av denne teksten.</Alert>}
        {isError && <Alert severity="error">Klarte ikke å lage en oppsummering. Vennligst prøv igjen.</Alert>}
      </Grid>
    </Grid>
  );
};

const LoadingAnimation = () => {
  return (
    <Stack>
      <Skeleton animation="wave" width="95%" />
      <Skeleton animation="wave" width="100%" />
      <Skeleton animation="wave" width="97%" />
      <Skeleton animation="wave" width="94%" />
      <Skeleton animation="wave" width="98%" />
      <Skeleton animation="wave" width="95%" />
      <Skeleton animation="wave" width="94%" />
      <Skeleton animation="wave" width="98%" />
      <Skeleton animation="wave" width="95%" />
    </Stack>
  );
};
