import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Typography, Button, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import LoadingSpinner from '../LoadingSpinner';
import { All_DEPARTMENTS } from '../organization/organization.graphql';
import { Save } from '@mui/icons-material';

interface Props {
  unit: string;
  onChanged(unitId: string, departmentId: string | undefined): void;
  changing: boolean;
}

export function OrganizationSelect({ unit, onChanged, changing }: Props) {
  const { loading, error, data } = useQuery(All_DEPARTMENTS);
  const unitAsString = unit ? unit : '';
  const [currentUnit, setCurrentUnit] = useState(unitAsString);
  const [currentDepartment, setCurrentDepartment] = useState<string | undefined>('');
  if (error) return <Typography>{error.message}</Typography>;
  if (loading || !data?.departments) return <LoadingSpinner />;

  const getDepartmentFromUnit = (): string | undefined => {
    if (!unit) return '';
    let depId = undefined;
    data?.departments?.forEach((item) => {
      if (item?.units?.some((x) => x?.id === unit)) {
        depId = item?.id;
      }
    });
    return depId;
  };
  if (currentUnit !== '' && currentDepartment === '') {
    const dep = getDepartmentFromUnit();
    if (dep) setCurrentDepartment(getDepartmentFromUnit());
  }
  const handleDepartmentChange = (value: string) => {
    setCurrentUnit('');
    setCurrentDepartment(value);
  };
  const reset = () => {
    setCurrentUnit(unitAsString);
    setCurrentDepartment(getDepartmentFromUnit());
  };
  const submit = () => {
    onChanged(currentUnit, currentDepartment);
  };
  const department = data.departments.find((x) => x?.id === currentDepartment);
  return (
    <div>
      <div>
        <FormControl sx={{ width: '50%', marginBottom: 2 }}>
          <InputLabel id="department-label">Avdeling</InputLabel>
          <Select
            labelId="department-label"
            sx={{ marginTop: 1 }}
            value={currentDepartment}
            onChange={(ev) => handleDepartmentChange(ev.target.value as string)}
            fullWidth
          >
            <MenuItem value="">
              <em>Ingen valgt</em>
            </MenuItem>
            {data.departments
              .filter((x) => !x?.isDeleted)
              .map((item) => {
                if (!item) return null;
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              }
              )}
          </Select>
        </FormControl>
      </div>
      <div>
        <FormControl sx={{ width: '50%', marginBottom: 2 }}>
          <InputLabel id="unit-label">Enhet</InputLabel>
          <Select
            labelId="unit-label"
            sx={{ marginTop: 1 }}
            value={currentUnit}
            onChange={(ev) => setCurrentUnit(ev.target.value as string)}
            fullWidth
          >
            <MenuItem value="">
              <em>Ingen valgt</em>
            </MenuItem>
            {department?.units &&
              department.units.map((item) => {
                if (!item) return null;
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              }
              )}
          </Select>
        </FormControl>
      </div>
      {unitAsString !== currentUnit && (
        <div style={{ marginTop: 20 }}>
          <Button onClick={reset}>Avbryt</Button>
          <Button
            onClick={submit}
            disabled={changing}
            variant="contained"
            color="primary"
            startIcon={changing ? <CircularProgress size={15} color="secondary" /> : <Save />}
          >
            Bytt enhet
          </Button>
        </div>
      )}
    </div>
  );
}
