import React, { FC } from 'react';
import { Badge, IconButton, LinearProgress, Link, Stack, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { Delete, Image, Reorder } from '@mui/icons-material';
import Dropzone, { Accept } from 'react-dropzone';
import { DELETE_FIGURE_FILE, GET_GUIDE_FIGURES, UPLOAD_FIGURE } from '../content.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { useGuideCmsContext } from '../GuideCmsContext';
import { FigureFileType } from '../../../__generated__/graphql';
import { ImageMetadataDialog, ImageMetadataDialogProps } from './ImageMetadataDialog';
import { useModal } from '../../../dialogs/useModal';

const getFigureFileTypeDescription = (fileType: FigureFileType) => {
  switch (fileType) {
    case FigureFileType.Large:
      return 'Stor';
    case FigureFileType.Small:
      return 'Liten';
    case FigureFileType.Original:
      return 'Dwg';
    case FigureFileType.Print:
      return 'Utskrift';
  }
};

export const UploadGuideFigureFiles: FC<{
  figureId?: string;
  onFigureCreated: (figureId: string) => void;
}> = ({ figureId, onFigureCreated }) => {
  return (
    <Stack spacing={2}>
      <UploadGuideFigure figureId={figureId} fileType={FigureFileType.Small} onFigureCreated={onFigureCreated} />
      <UploadGuideFigure figureId={figureId} fileType={FigureFileType.Large} onFigureCreated={onFigureCreated} />
      <UploadGuideFigure figureId={figureId} fileType={FigureFileType.Original} onFigureCreated={onFigureCreated} />
      <UploadGuideFigure figureId={figureId} fileType={FigureFileType.Print} onFigureCreated={onFigureCreated} />
    </Stack>
  );
};

const UploadGuideFigure: FC<{
  figureId?: string;
  fileType: FigureFileType;
  onFigureCreated: (figureId: string) => void;
}> = ({ figureId, fileType, onFigureCreated }) => {
  const { guideId } = useGuideCmsContext();
  const figuresResponse = useQuery(GET_GUIDE_FIGURES, { variables: { guideId } });
  const figure = figuresResponse.data?.guide?.figures?.find((f) => f?.id === figureId);
  const file = figure?.files?.find((f) => f?.type === fileType);
  const metadataModal = useModal<ImageMetadataDialogProps, undefined>({ data: file });
  const [deleteFileMutation, { loading: isDeleting }] = useMutation(DELETE_FIGURE_FILE);
  const [uploadFigureMutation, { loading: isUploading }] = useMutation(UPLOAD_FIGURE);
  const handleOnDrop = async (files: File[]) => {
    const file = files[0];
    const result = await uploadFigureMutation({ variables: { input: { file, figureId, fileType, guideId } } });
    const newFigureId = result.data?.guideUploadFigureFile?.figure?.id;
    if (!figureId && newFigureId) {
      onFigureCreated(newFigureId);
    }
  };

  const handleOnDelete = (fileId: string) => {
    deleteFileMutation({ variables: { input: { figureId, fileId } } });
  };

  const handleShowMetadata = () => {
    metadataModal.open(file);
  };

  return (
    <div>
      <Typography variant="h6">{getFigureFileTypeDescription(fileType)}</Typography>
      {!file && <FileChooser disabled={isUploading} onDrop={handleOnDrop} />}
      <div>
        {file && !isDeleting && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Image color="action" />
            <div>
              <Link title="Åpne fil" href={file.url ?? '#'} target="_blank">
                {file.fileName}
              </Link>
            </div>
            <IconButton title={file.metadata?.length ? 'Se metadata' : 'Mangler metadata'} onClick={handleShowMetadata}>
              {file.metadata?.length ? (
                <Reorder />
              ) : (
                <Badge badgeContent="!" color="error">
                  <Reorder />
                </Badge>
              )}
            </IconButton>
            <IconButton onClick={() => handleOnDelete(file.id)}>
              <Delete />
            </IconButton>
          </Stack>
        )}

        {(isUploading || isDeleting) && <LinearProgress sx={{ mt: 2 }} variant="indeterminate" />}
      </div>
      {metadataModal.isOpen && <ImageMetadataDialog modal={metadataModal} />}
    </div>
  );
};

const StyledDropzone = styled.div`
  padding: 0 20px;
  border-width: 2px;
  border-radius: 8px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
`;

const FileChooser: FC<{ disabled?: boolean; accept?: Accept; onDrop: (newFiles: File[]) => void }> = ({ disabled, onDrop, accept }) => {
  return (
    <Dropzone disabled={disabled} onDrop={onDrop} accept={accept}>
      {({ getRootProps, getInputProps }) => (
        <StyledDropzone {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Dra og slipp fil her, eller klikk for å velge</p>
        </StyledDropzone>
      )}
    </Dropzone>
  );
};
