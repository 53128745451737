import React, { useState } from 'react';
import { Typography, TableHead, TableCell, TableBody, Table, TableRow, Button } from '@mui/material';
import { ColWidths, TableCellHead, TableCellEllipsis } from '../components/TableComponents';
import Moment from 'moment';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { GET_SEARCH_TERMS } from './dashboard.graphql';
import { GetSearchTermsQuery } from '../__generated__/graphql';
import { StyledPaper } from '../theme';

type Stats = GetSearchTermsQuery['searchTermStat'];

export function SearchTermStats() {
  const [take, setTake] = useState(20);
  const { data, loading } = useQuery(GET_SEARCH_TERMS, { variables: { take } });

  var date = Moment(Date.now()).add(-1, 'months');
  var month = date.format('MMMM');

  return (
    <StyledPaper>
      <Typography style={{ fontSize: 18 }}>
        {take} mest brukte søketermer i {month}
      </Typography>
      {loading && <LoadingSpinner />}
      {data?.searchTermStat && data.searchTermStat.length === 0 && (
        <Typography style={{ fontSize: 14, marginTop: 10, marginLeft: 16 }}>Ingen søketermer funnet</Typography>
      )}
      {data?.searchTermStat && data.searchTermStat.length > 0 && <SearchTermTable stats={data.searchTermStat} />}
      {data?.searchTermStat && data.searchTermStat.length > 0 && (
        <Button style={{ marginTop: 5, marginLeft: 16 }} onClick={() => setTake(take + 20)}>
          Vis flere...
        </Button>
      )}
    </StyledPaper>
  );
}

function SearchTermTable({ stats }: { stats: Stats }) {
  return (
    <Table size="small">
      <ColWidths widths={[60, null, 100]} />
      <TableHead>
        <TableRow>
          <TableCellHead>Nr.</TableCellHead>
          <TableCellHead>Søketerm</TableCellHead>
          <TableCellHead align="right">Antall</TableCellHead>
        </TableRow>
      </TableHead>
      <TableBody>
        {stats &&
          stats.map((x, i) => {
            return (
              <TableRow key={i}>
                <TableCell>{i + 1}</TableCell>
                <TableCellEllipsis>{x?.searchTerm}</TableCellEllipsis>
                <TableCell align="right">{x?.searchCount}</TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
}
