import { useMutation } from '@apollo/client';
import { useGuideEditor } from '../tiptap/useGuideEditor';
import { RESTORE_GUIDE_CONTENT_VERSION } from '../content.graphql';
import { getTipTapJsonContent } from '../tiptap/tiptapHelper';
import { getAwarenessState } from '../hocuspocus/hocuspocusHelper';
import { useAuth } from 'oidc-react';

export const useRestoreContentVersion = (guideId: string) => {
  const [restoreContentVersion, { loading: isRestoringContentVersion }] = useMutation(RESTORE_GUIDE_CONTENT_VERSION);
  const auth = useAuth();
  const { editor, hocuspocus } = useGuideEditor({
    guideId,
  });
  const { isConnected } = hocuspocus.connectionStatus;
  const restoreVersion = async (versionToRestore: number) => {
    const profile = auth.userData?.profile;
    if (hocuspocus.provider && profile) {
      const awareness = getAwarenessState(hocuspocus.provider);
      const otherUsers = awareness.getOtherUsers(profile);
      const confirmMessage = `
        Når du gjenoppretter denne versjonen, vil ulagret arbeid gå tapt.
        ${
          otherUsers.length > 0
            ? `Akkurat nå er det flere personer som jobber på denne anvisnigen: ${otherUsers.map((user) => user.name).join(', ')}.\n`
            : ''
        }
        Er du sikker på at du vil fortsette? 
      `;
      const shouldContinue = awareness.numberOfConnections === 1 || confirm(confirmMessage);
      if (shouldContinue) {
        const result = await restoreContentVersion({ variables: { input: { id: guideId, version: versionToRestore } } });
        const guideContent = result?.data?.guideRestoreContentVersion?.guideModel?.content;
        if (!guideContent) return;
        const { version, content } = guideContent;
        const jsonContent = getTipTapJsonContent(content ?? '');
        editor?.commands.setContent(jsonContent);
        return version;
      }
    }
  };
  return { restoreVersion, isReady: isConnected && !isRestoringContentVersion, isRestoring: isRestoringContentVersion };
};
