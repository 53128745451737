import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Modal } from '../dialogs/useModal';

export interface AddTaskDialogProps {
  isOnGuide?: boolean;
}

export interface AddTaskDialogResult {
  title: string;
  text: string;
}

export function AddTaskDialog({ modal }: { modal: Modal<AddTaskDialogProps, AddTaskDialogResult> }) {
  const [title, setTitle] = useState('');
  const [comment, setComment] = useState('');
  const { isOnGuide } = modal.data;

  const handleClose = () => modal.close();

  const handleOk = async () => {
    modal.close({ title, text: comment });
  };

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{isOnGuide ? `Registrer ny oppgave` : `Varsle endring`}</DialogTitle>
      <DialogContent>
        <TextField margin="dense" label="Tittel" type="text" autoFocus onChange={(e) => setTitle(e.target.value)} value={title} fullWidth />
        <TextField margin="dense" label="Tekst" type="text" multiline onChange={(e) => setComment(e.target.value)} value={comment} fullWidth />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Avbryt</Button>
        <Button onClick={handleOk} variant="contained" color="primary">
          Legg til
        </Button>
      </DialogActions>
    </Dialog>
  );
}
