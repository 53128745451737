import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { GET_COMPLETED_REVISIONS_PER_MONTH } from './dashboard.graphql';
import LoadingSpinner from '../LoadingSpinner';
import { GetCompletedRevisionsPerMonthQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';

export function CompletedRevisions() {
  const { data, loading } = useQuery(GET_COMPLETED_REVISIONS_PER_MONTH, { fetchPolicy: 'cache-and-network' });

  return (
    <StyledPaper>
      <Typography style={{ fontSize: 18 }}>Reviderte anvisninger pr måned</Typography>
      {loading && <LoadingSpinner />}
      {data?.revisions && <Chart revisions={data.revisions} />}
    </StyledPaper>
  );
}

type Revision = Unpacked<GetCompletedRevisionsPerMonthQuery['revisions']>;
function Chart({ revisions }: { revisions: Revision[] }) {
  const theme = useTheme();
  const completedRevisionsPerMonth = revisions
    .map((x) => moment(x!.completedAt))
    .filter((x) => x.isAfter(moment().subtract(6, 'month')))
    .reduce((acc, date) => {
      const month = date.format('MMM');
      if (!acc[month]) {
        acc[month] = { month, completedRevisionsCount: 0 };
      }
      acc[month].completedRevisionsCount++;
      return acc;
    }, {});
  const data = Object.keys(completedRevisionsPerMonth).map((key) => completedRevisionsPerMonth[key]);

  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart data={data}>
        <Bar dataKey="completedRevisionsCount" fill={theme.palette.primary.main} />
        <XAxis dataKey="month" tick={true} />
        <YAxis />
      </BarChart>
    </ResponsiveContainer>
  );
}
