import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Checkbox } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import { Table, TableCell, TableHead, TableRow } from '@mui/material';
import { useMutation } from '@apollo/client';
import { GUIDE_ADD_STANDARD, GUIDE_REMOVE_STANDARD } from './standards.graphql';
import { ColWidths, TableCellEllipsis, TableCellHead, TableCellIcon } from '../components/TableComponents';
import { Unpacked } from '../graphQLTypes/types';
import { GetStandardsToGuideQuery } from '../__generated__/graphql';

type Standard = Unpacked<GetStandardsToGuideQuery['standards']>;
type GuideModel = GetStandardsToGuideQuery['guide'];

export function EditGuideStandardTable({ standards, guide }: { standards: Standard[] | null | undefined; guide: GuideModel }) {
  const [addStandard] = useMutation(GUIDE_ADD_STANDARD);
  const [removeStandard] = useMutation(GUIDE_REMOVE_STANDARD);
  const [numberOfItems, setNumberOfItems] = useState(50);
  const guideStandards = guide?.standards?.map((x) => x?.id);
  const isStandardSelected = (s: Standard): boolean => guideStandards?.includes(s?.id) ?? false;

  const handleClick = async (standard: Standard): Promise<any> => {
    if (isStandardSelected(standard)) {
      await removeStandard({ variables: { input: { id: guide?.id, standardId: standard?.id } } });
    } else {
      await addStandard({ variables: { input: { id: guide?.id, standardId: standard?.id } } });
    }
  };

  if (!standards) return null;

  return (
    <Table size="small" sx={{ marginTop: 1 }}>
      <ColWidths widths={[60, 60, 250, null, 200, 600]}></ColWidths>
      <TableHead>
        <TableRow>
          <TableCellHead>Valgt</TableCellHead>
          <TableCellHead>Gyldig</TableCellHead>
          <TableCellHead>ID</TableCellHead>
          <TableCellHead>Tittel</TableCellHead>
          <TableCellHead>Utgave</TableCellHead>
          <TableCellHead>ErstattetAv(EA)/Erstatter(E)</TableCellHead>
        </TableRow>
      </TableHead>
      <InfiniteScroll element="tbody" loadMore={() => setNumberOfItems(numberOfItems + 50)} hasMore={standards.length > numberOfItems}>
        {standards.slice(0, numberOfItems - 1).map((x) => {
          if (!x) return null;
          const isSelected = isStandardSelected(x);
          return (
            <TableRow hover role="checkbox" aria-checked={isSelected} tabIndex={-1} key={x.id} selected={isSelected} onClick={() => handleClick(x)} sx={{ cursor: 'pointer' }}>
              <TableCellIcon padding="checkbox">
                <Checkbox sx={{ padding: '5px' }} color="primary" checked={isSelected} />
              </TableCellIcon>
              <TableCell>{x.isValid && <CheckIcon fontSize='small' />}</TableCell>
              <TableCell>{x.name}</TableCell>
              <TableCell>{x.title}</TableCell>
              <TableCell>{x.version}</TableCell>
              <TableCellEllipsis>{x.link}</TableCellEllipsis>
            </TableRow>
          );
        })}
      </InfiniteScroll>
    </Table>
  );
}
