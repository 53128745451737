import { GridColDef, GridColumnTypes, GridValidRowModel } from '@mui/x-data-grid-pro';
import { Cell, Column, DataType } from './useDataSourceApi';

export function toGridColType(dataType: DataType): keyof GridColumnTypes {
  switch (dataType) {
    case DataType.Number:
      return 'number';
    case DataType.Boolean:
      return 'boolean';
    default:
      return 'string';
  }
}

export function toGridColDefs(columns: Column[]): GridColDef[] {
  return columns.map(toGridColDef);
}

export function toGridColDef(column: Column): GridColDef {
  return {
    field: column.id.toString(),
    headerName: column.title,
    description: column.title,
    flex: 1,
    type: toGridColType(column.dataType),
  };
}

export function toGridRows(columns: Column[], rows: Cell[][]): GridValidRowModel[] {
  return rows.map((row, index) => toGridRow(columns, row, index));
}

export function toGridRow(columns: Column[], row: Cell[], index: number): GridValidRowModel {
  const mappedRow: Record<string, string> = {
    id: index.toString(),
  };
  columns.forEach((column) => {
    const value = row.find((cell) => cell.columnId === column.id)?.value;
    if (column.dataType === DataType.Boolean) {
      mappedRow[column.id] = value === 'true' ? value : '';
    } else {
      mappedRow[column.id] = value ?? '';
    }
  });
  return mappedRow;
}
