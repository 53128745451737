import React, { FC, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Stepper, Step, StepLabel, Typography, Box, Stack } from '@mui/material';
import { Modal } from './../dialogs/useModal';
import { Cell, Column, DataSourceDetails, DataSourceInfo, useDataSourceApi } from './useDataSourceApi';
import {
  DataGridPro,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  gridExpandedSortedRowEntriesSelector,
  GridRowSelectionModel,
  GridToolbarQuickFilter,
  useGridApiRef,
  GridApiPro,
  gridFilterModelSelector,
  gridVisibleColumnFieldsSelector,
  GridFilterModel,
} from '@mui/x-data-grid-pro';
import { nbNO } from '@mui/x-data-grid-pro/locales';
import { toGridColDef, toGridRows } from './dataGridHelper';
import LoadingSpinner from '../LoadingSpinner';
import { ArrowBack, ArrowForward, ArrowLeft, ArrowRight } from '@mui/icons-material';

export interface SearchDialogProps {
  dataSourceId?: string;
  columns?: number[];
  filter?: GridFilterModel;
}
export interface SearchDialogResult {
  dataSourceId: string;
  dataSourceCorrelationId: string;
  version: number;
  columns: Column[];
  values: Cell[][];
  filter: GridFilterModel;
}
export function SearchDialog({ modal }: { modal: Modal<SearchDialogProps, SearchDialogResult> }) {
  const apiRef = useGridApiRef();

  const handleCancel = () => modal.close();
  const api = useDataSourceApi();
  const [isLoadingDataSources, setIsLoadingDataSources] = useState(true);
  const [dataSources, setDataSources] = useState<DataSourceInfo[]>([]);
  const [dataSourceDetails, setDataSourceDetails] = useState<DataSourceDetails>();
  const [selectedRow, setSeletedRow] = useState<GridRowSelectionModel>([]);
  const [isLoadingDataSourceDetails, setIsLoadingDataSourceDetails] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(modal.data.dataSourceId ? 1 : 0);
  const [selectedDataSourceId, setSelectedDataSourceId] = useState<string | undefined>(modal.data.dataSourceId);
  const [selectedColumns, setSelectedColumns] = useState<number[] | undefined>(modal.data.columns);
  const [selectedFilter, setSelectedFilter] = useState<GridFilterModel | undefined>(modal.data.filter);

  const fetchDataSources = async () => {
    try {
      const result = await api.getLatestVersions();
      setDataSources(result);
      if (modal.data.dataSourceId && result.some((ds) => ds.id === modal.data.dataSourceId)) {
        setSeletedRow([modal.data.dataSourceId]);
      }
    } finally {
      setIsLoadingDataSources(false);
    }
  };

  useEffect(() => {
    fetchDataSources();
  }, []);

  const fetchDataSourceDetails = async (id: string) => {
    setIsLoadingDataSourceDetails(true);
    setDataSourceDetails(undefined);

    try {
      const details = await api.getDetails(id);
      setDataSourceDetails(details);
    } finally {
      setIsLoadingDataSourceDetails(false);
    }
  };

  useEffect(() => {
    if (currentStep === 0 || !selectedDataSourceId) return;
    fetchDataSourceDetails(selectedDataSourceId);
  }, [currentStep]);

  const handleOnRowSelected = (selectedRow: GridRowSelectionModel) => {
    const dataSourceId = selectedRow?.[0]?.toString();
    setSeletedRow(selectedRow);
    setSelectedDataSourceId(dataSourceId);
    if (dataSourceId !== modal.data.dataSourceId) {
      setSelectedColumns(undefined);
      setSelectedFilter(undefined);
    }
    setCurrentStep(1);
  };

  const handleOnGetDataClick = () => {
    if (!dataSourceDetails) return;
    const data = gridExpandedSortedRowEntriesSelector(apiRef);
    const filterModel = gridFilterModelSelector(apiRef);
    const columns = gridVisibleColumnFieldsSelector(apiRef);
    const visibleColumns = dataSourceDetails.columns.filter((c) => columns.includes(c.id.toString()));

    modal.close({
      dataSourceId: dataSourceDetails.id,
      dataSourceCorrelationId: dataSourceDetails.correlationId,
      version: dataSourceDetails.version,
      columns: visibleColumns,
      values: data.map((row) => {
        return visibleColumns.map((c) => {
          return { columnId: c.id, value: row.model[c.id.toString()] };
        });
      }),
      filter: filterModel,
    });
  };

  return (
    <Dialog
      onClose={handleCancel}
      fullWidth
      maxWidth="xl"
      open={true}
      PaperProps={{
        sx: {
          position: 'absolute',
          top: 0,
          minHeight: '600px',
        },
      }}>
      <DialogTitle>
        {currentStep === 0 && 'Velg datakilde'}
        {currentStep === 1 && 'Filtrer data'}
      </DialogTitle>
      <DialogContent>
        <Stepper sx={{ marginBottom: 2 }} activeStep={currentStep}>
          <Step key={0}>
            <StepLabel>Velg datakilde</StepLabel>
          </Step>
          <Step key={1}>
            <StepLabel>Filtrer data</StepLabel>
          </Step>
        </Stepper>
        {(isLoadingDataSources || isLoadingDataSourceDetails) && <LoadingSpinner />}
        {currentStep === 0 && !isLoadingDataSources && (
          <DataSourceList dataSources={dataSources} selectedRow={selectedRow} onRowSelected={handleOnRowSelected} />
        )}
        {currentStep === 1 && !isLoadingDataSourceDetails && dataSourceDetails && (
          <DataSourceFilter
            apiRef={apiRef}
            dataSource={dataSourceDetails}
            searchDialogProps={{ columns: selectedColumns, filter: selectedFilter, dataSourceId: selectedDataSourceId }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }}>
          <Stack direction="row" gap={2}>
            <Button startIcon={<ArrowBack />} disabled={currentStep === 0} onClick={() => setCurrentStep(currentStep - 1)}>
              Tilbake
            </Button>
            <Button endIcon={<ArrowForward />} disabled={currentStep === 1 || !selectedDataSourceId} onClick={() => setCurrentStep(currentStep + 1)}>
              Neste
            </Button>
          </Stack>
          <Stack direction="row" gap={2}>
            <Button onClick={handleCancel}>Avbryt</Button>
            {currentStep == 1 && (
              <Button variant="contained" color="primary" onClick={handleOnGetDataClick} autoFocus>
                Hent data
              </Button>
            )}
          </Stack>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

const DataSourceList: FC<{
  dataSources: DataSourceInfo[];
  selectedRow?: GridRowSelectionModel;
  onRowSelected: (selectedRow: GridRowSelectionModel) => void;
}> = ({ dataSources, selectedRow, onRowSelected }) => {
  return (
    <DataGridPro
      rowSelectionModel={selectedRow}
      density="compact"
      localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
      pagination
      sx={{
        // disable cell selection style
        '.MuiDataGrid-cell:focus': {
          outline: 'none',
        },
        // pointer cursor on ALL rows
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer',
        },
      }}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 25,
          },
        },
      }}
      rows={dataSources}
      columns={[{ headerName: 'Navn', field: 'name', flex: 1 }]}
      onRowSelectionModelChange={(selectionModel) => {
        onRowSelected(selectionModel);
      }}
      slots={{
        toolbar: () => (
          <GridToolbarContainer>
            <GridToolbarQuickFilter />
          </GridToolbarContainer>
        ),
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
    />
  );
};

const DataSourceFilter: FC<{
  apiRef: React.MutableRefObject<GridApiPro>;
  dataSource: DataSourceDetails;
  searchDialogProps: SearchDialogProps;
}> = ({ apiRef, dataSource, searchDialogProps }) => {
  const columns = dataSource.columns.map((c) => ({ ...toGridColDef(c), disableColumnMenu: true }));
  const rows = toGridRows(dataSource.columns, dataSource.values);
  return (
    <>
      <DataGridPro
        apiRef={apiRef}
        density="compact"
        autoHeight
        localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
        pagination
        columns={columns}
        rows={rows}
        rowSelection={false}
        onColumnVisibilityModelChange={(model) => console.log('ColumnVisibilityModel', model)}
        slots={{
          toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
            </GridToolbarContainer>
          ),
        }}
        initialState={{
          columns: searchDialogProps.columns
            ? {
                columnVisibilityModel: dataSource.columns.reduce(
                  (acc: { [key: number]: boolean }, curr) => ((acc[curr.id] = searchDialogProps!.columns!.includes(curr.id)), acc),
                  {},
                ),
              }
            : undefined,
          filter: searchDialogProps.filter ? { filterModel: searchDialogProps.filter } : undefined,
          pagination: {
            paginationModel: {
              pageSize: 25,
              page: 0,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50]}></DataGridPro>
    </>
  );
};
