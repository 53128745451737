import React, { FC } from 'react';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { useModal } from '../../../../../dialogs/useModal';
import { MathTypeDialog, MathTypeDialogProps, MathTypeDialogResult } from '../../../dialogs/mathType/MathTypeDialog';
import styled from '@emotion/styled';
import { Tooltip } from '@mui/material';

const StyledMathType = styled.div`
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

export const MathTypeView: FC<NodeViewProps> = (props) => {
  const { html } = props.node.attrs;
  const modal = useModal<MathTypeDialogProps, MathTypeDialogResult>({ data: { html } });
  const handleOnClick = async () => {
    const payload = await modal.open({ html });
    if (payload) {
      if (payload.html) {
        props.updateAttributes({ html: payload.html, latexParts: payload.latexParts });
      } else {
        props.deleteNode();
      }
    }
  };

  return (
    <NodeViewWrapper className="react-component" style={{ display: 'inline-block' }}>
      {modal.isOpen && <MathTypeDialog {...modal} />}
      {!props.editor.isEditable && <div dangerouslySetInnerHTML={{ __html: html }} />}
      {props.editor.isEditable && (
        <UnstyledButton onClick={handleOnClick}>
          <Tooltip title="Klikk for å redigere formel">
            <StyledMathType dangerouslySetInnerHTML={{ __html: html }}></StyledMathType>
          </Tooltip>
        </UnstyledButton>
      )}
    </NodeViewWrapper>
  );
};

const UnstyledButton = styled.button`
  all: unset;
`;
