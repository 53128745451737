import React, { FC } from 'react';
import { StyledPaper, theme } from '../theme';
import { Stack, Typography } from '@mui/material';
import { useFeatureFlags } from '../unleash';
import { StyledLink } from '../Components';
import { Guide } from './hearing.graphql';
import { HearingInfo } from './HearingInfo';

interface HearingProps {
  guide: Guide;
}

export const GuideHearing: FC<HearingProps> = ({ guide }) => {
  const { guideCms } = useFeatureFlags();

  const hearing = guide.hearing;
  if (!guideCms || !guide.contentId) {
    return null;
  }
  return (
    <StyledPaper>
      <Typography variant="h5" component="h3">
        Høring
      </Typography>
      <Stack gap={2} sx={{ marginTop: theme.spacing(2) }}>
        <HearingInfo hearing={hearing ?? undefined} guideId={guide.id} />

        <div>
          <StyledLink style={{ marginLeft: '10px' }} to={`/guide/hearings/${guide.id}`}>
            Se status på høringer
          </StyledLink>
        </div>
      </Stack>
    </StyledPaper>
  );
};
