import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

import { UPDATE } from './task.graphql';
import { useMutation } from '@apollo/client';
import { Modal } from '../dialogs/useModal';

export interface EditTaskDialogProps {
  id: string;
  title?: string | null | undefined;
  text?: string | null | undefined;
}

export function EditTaskDialog({ modal }: { modal: Modal<EditTaskDialogProps, unknown> }) {
  const task = modal.data;
  const [title, setTitle] = useState(task.title);
  const [text, setText] = useState(task.text);
  const [updateTask, { loading }] = useMutation(UPDATE, { variables: { input: { id: task.id, title, text } } });

  const handleClose = () => modal.close();

  const handleOk = async () => {
    await updateTask();
    handleClose();
  };

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Endre tekst</DialogTitle>
      <DialogContent>
        <TextField margin="dense" label="Tittel" type="text" autoFocus value={title} onChange={(e) => setTitle(e.target.value)} fullWidth required />
        <TextField margin="dense" label="Beskrivelse" type="text" multiline value={text} onChange={(e) => setText(e.target.value)} fullWidth />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Avbryt</Button>
        <Button disabled={loading} onClick={handleOk} variant="contained" color="primary">
          Endre
        </Button>
      </DialogActions>
    </Dialog>
  );
}
