import React, { FC } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { BacklogItem, CriteriaType } from './types';
import { addRank } from './BacklogModel';

interface ChartProps {
  items: BacklogItem[];
  currentItem: BacklogItem;
  ignoreCriteria: CriteriaType[];
}

export const Chart: FC<ChartProps> = ({ items, currentItem, ignoreCriteria }) => {
  const rankItems = addRank(items, ignoreCriteria);
  const activeIndex = rankItems.findIndex((x) => x?.id === currentItem?.id);

  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart data={rankItems.map((x) => ({ ...x, rank: x.rank.toFixed(2) }))} margin={{ top: 0, right: 0, bottom: 0, left: 0 }}>
        <Bar dataKey="rank">
          {rankItems.map((_, index) => (
            <Cell stroke={index === activeIndex ? '#ff8888' : '#287891'} strokeWidth={index === activeIndex ? 3 : 1} key={`cell-${index}`} />
          ))}
        </Bar>
        <XAxis dataKey="docName" tick={false} />
        <YAxis type="number" domain={[0, 300]} />
        <Tooltip />
      </BarChart>
    </ResponsiveContainer>
  );
};
