const validChartTypes = ['bar', 'line', 'scatter', 'bubble', 'pie', 'doughnut', 'polarArea', 'radar'];

export interface ChartParseResult {
  isValid: boolean;
  error?: string;
  configJson: string;
  config?: any;
}

export const parseChartConfig = (config: string): ChartParseResult => {
  let parsedJson;
  try {
    parsedJson = JSON.parse(config);
  } catch (e: any) {
    return { isValid: false, error: 'Invalid JSON: ' + e.message, configJson: config };
  }
  if (!validChartTypes.includes(parsedJson.type)) {
    return { isValid: false, error: `Invalid chart type: ${parsedJson.type}`, configJson: config };
  }
  return { isValid: true, config: parsedJson, configJson: config };
};
