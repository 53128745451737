import { useEffect } from 'react';

const useKeyDown = (keys: string | string[], callback: () => void) => {
  const onKeyDown = (event: globalThis.KeyboardEvent) => {
    const wasAnyKeyPressed = Array.isArray(keys) ? keys.some((key) => event.key === key) : event.key === keys;
    if (wasAnyKeyPressed) {
      event.preventDefault();
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);
};

export default useKeyDown;
