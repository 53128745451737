import React, { FC } from 'react';
import { CircularProgress, css } from '@mui/material';
import styled from '@emotion/styled';

const StyledLoadingWrapper = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  margin: 24px 0;
  height: ${({ size }) => size}px;
`;

const Loading: FC<{ size?: number }> = ({ size = 80 }) => {
  return (
    <StyledLoadingWrapper size={size}>
      <CircularProgress size={size} />
    </StyledLoadingWrapper>
  );
};

export default Loading;
