import {
  Box,
  Button,
  FormControlLabel,
  Grid2 as Grid,
  IconButton,
  Switch,
  SxProps,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Theme,
  Typography,
} from '@mui/material';
import { ExpandLess, ExpandMore, KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { StyledLink } from '../Components';
import { ColWidths, TableCellEllipsis, TableCellHead, TableCellIcon } from '../components/TableComponents';
import { formatDateOnly } from '../Formatters';
import { GuideStatusIcon } from '../guides/GuideStatusIcon';
import LoadingSpinner from '../LoadingSpinner';
import { GET_CLASSIFICATIONS } from './classifications.graphql';
import { utils, writeFile } from 'xlsx';
import { ClassificationCode, ClassificationItem, GetClassificationsQuery, GuideStatus } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';
export function Overview() {
  const [tabIndex, setTabIndex] = useState(ClassificationCode.Bfs);
  const { loading, error, data } = useQuery(GET_CLASSIFICATIONS);
  return (
    <StyledPaper>
      <Typography variant="h5" gutterBottom>
        Klassifikasjonssystem
      </Typography>
      <Tabs value={tabIndex} onChange={(_, newValue) => setTabIndex(newValue)} aria-label="classification tabs" style={{ marginBottom: 15 }}>
        <Tab label="Byggforskserien" value={ClassificationCode.Bfs} />
        <Tab label="Våtromsnormen" value={ClassificationCode.Bvn} />
      </Tabs>
      {loading && <LoadingSpinner />}
      {error && <Typography>{error.message}</Typography>}
      {!loading && data?.classifications && (
        <Classification classification={data.classifications.find((x) => x?.code === tabIndex)!} guides={data.guides!} />
      )}
    </StyledPaper>
  );
}

type Classification = Unpacked<GetClassificationsQuery['classifications']>;

type Guide = Unpacked<GetClassificationsQuery['guides']>;

function Classification({ classification, guides }: { classification: Classification; guides: Guide[] }) {
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [showWithdrawn, setShowWithdrawn] = useState(false);
  const [showEmpty, setShowEmpty] = useState(false);
  if (!classification) return null;

  const isBvn = classification.code === ClassificationCode.Bvn;
  const showSeriesNumber = isBvn;
  const guideName = isBvn ? 'blader' : 'anvisninger';
  const allIds = getAllIdsInHeadings(classification);
  if (expandedIds.length > 0 && !allIds.includes(expandedIds[0])) setExpandedIds([]);

  const style: SxProps<Theme> = {
    marginTop: 1,
    marginBottom: 2,
    marginLeft: 1,
  };

  return (
    <>
      <Box sx={{ position: 'absolute', right: (theme) => theme.spacing(11), top: (theme) => theme.spacing(24) }}>
        <ExcelExport classification={classification} guides={guides} />
      </Box>
      {expandedIds.length < allIds.length && (
        <Button sx={style} variant="outlined" startIcon={<ExpandMore />} onClick={() => setExpandedIds(allIds)}>
          Utvid alle
        </Button>
      )}
      {expandedIds.length === allIds.length && (
        <Button sx={style} variant="outlined" startIcon={<ExpandLess />} onClick={() => setExpandedIds([])}>
          Kollaps alle
        </Button>
      )}
      <FormControlLabel
        sx={style}
        title={`Vis tilbaketrukkete ${guideName}`}
        control={<Switch onChange={() => setShowWithdrawn(!showWithdrawn)} checked={showWithdrawn} />}
        label={`Vis tilbaketrukkete ${guideName}`}
      />
      <FormControlLabel
        sx={style}
        title="Vis tomme inndelinger"
        control={<Switch onChange={() => setShowEmpty(!showEmpty)} checked={showEmpty} />}
        label="Vis tomme inndelinger"
      />
      <Grid container>
        {classification?.children?.map((x) => {
          return (
            <Grid size={{ xs: 12 }} key={x?.id}>
              <SeriesComponent
                series={x!}
                expandedIds={expandedIds}
                setExpandedIds={setExpandedIds}
                guides={guides}
                showWithdrawn={showWithdrawn}
                showEmpty={showEmpty}
                classificationId={classification.id}
                showSeriesNumber={showSeriesNumber}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}

function SeriesComponent({
  series,
  expandedIds,
  setExpandedIds,
  guides,
  showWithdrawn,
  showEmpty,
  classificationId,
  showSeriesNumber,
}: {
  series: ClassificationItem;
  expandedIds: string[];
  setExpandedIds(ids: string[]): void;
  guides: Guide[];
  showWithdrawn: boolean;
  showEmpty: boolean;
  classificationId: string;
  showSeriesNumber: boolean;
}) {
  return (
    <>
      <Typography>{showSeriesNumber ? `${series.number}. ${series.heading}` : series.heading}</Typography>
      <Grid container>
        {series?.children?.map((x) => (
          <HeadingRow
            key={x!.id}
            heading={x!}
            expandedIds={expandedIds}
            setExpandedIds={setExpandedIds}
            guides={guides}
            showWithdrawn={showWithdrawn}
            showEmpty={showEmpty}
            indentation={0}
            classificationId={classificationId}
          />
        ))}
      </Grid>
    </>
  );
}

interface HeadingRowProps {
  heading: ClassificationItem;
  indentation: number;
  expandedIds: string[];
  setExpandedIds(ids: string[]): void;
  showWithdrawn: boolean;
  showEmpty: boolean;
  guides: Guide[];
  classificationId: string;
}

function HeadingRow({ heading, indentation, expandedIds, setExpandedIds, guides, showWithdrawn, showEmpty, classificationId }: HeadingRowProps) {
  const hasChildren = heading.children;
  const expanded = expandedIds.includes(heading.id);
  const idToGuide = (id: string): Guide => guides.find((x) => x!.id === id);
  const withdrawnFilter = (x: Guide): boolean => x !== undefined && (showWithdrawn || x!.status !== GuideStatus.Expired);

  if (!showEmpty) {
    const guidesUnderHeading = getAllGuidesUnderHeading(heading);
    const noGuidesToShow = guidesUnderHeading.map(idToGuide).filter(withdrawnFilter).length === 0;
    if (!showEmpty && noGuidesToShow) return null;
  }

  const guidesOnItem = heading
    .guides!.map(idToGuide)
    .filter(withdrawnFilter)
    .sort((a, b) => a!.docName!.localeCompare(b!.docName!));

  const onExpand = () => {
    if (expanded) {
      setExpandedIds(expandedIds.filter((x) => x !== heading.id));
    } else {
      if (indentation > 0) setExpandedIds([heading.id, ...expandedIds]);
      else {
        setExpandedIds([...getAllIdsInHeading(heading), ...expandedIds].filter(onlyUnique));
      }
    }
  };

  return (
    <>
      <Grid size={{ xs: 12 }}>
        <Typography display="inline" variant="body1" style={{ marginLeft: indentation === 3 ? 93 : 20 * indentation }}>
          {hasChildren && (
            <IconButton sx={{ padding: '0', verticalAlign: 'text-bottom' }} onClick={onExpand} size="large">
              {expanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          )}
          {!hasChildren && <span>.</span>}
          <span>{heading.number}</span>
        </Typography>
        <Typography display="inline" variant="body1" sx={{ marginLeft: '10px' }}>
          {heading.heading}
        </Typography>
      </Grid>
      {(expanded || !hasChildren) && guidesOnItem.length > 0 && (
        <Grid size={{ xs: 12 }}>
          <Grid container>
            <Grid size={{ xs: 4 }} style={{ maxWidth: 85 * 2 + 25 }} />
            <Grid size="grow">
              <GuideTable guides={guidesOnItem} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {expanded &&
        heading.children &&
        heading.children.map((h) => (
          <HeadingRow
            key={h!.id}
            heading={h!}
            indentation={indentation + 1}
            expandedIds={expandedIds}
            setExpandedIds={setExpandedIds}
            guides={guides}
            showWithdrawn={showWithdrawn}
            showEmpty={showEmpty}
            classificationId={classificationId}
          />
        ))}
    </>
  );
}

function GuideTable({ guides }: { guides: Guide[] }) {
  return (
    <Table size="small" sx={{ marginBottom: 10 }}>
      <ColWidths widths={[40, 120, null, 150, 40]} />
      <TableHead>
        <TableRow>
          <TableCellHead>Status</TableCellHead>
          <TableCellHead>Nummer</TableCellHead>
          <TableCellHead>Tittel</TableCellHead>
          <TableCellHead>Fagområde</TableCellHead>
          <TableCellHead>Sist publisert</TableCellHead>
        </TableRow>
      </TableHead>
      <TableBody>
        {guides.map((x) => {
          if (!x) return null;
          const categoryTitle = x.mainCategory ? x.mainCategory.title : null;
          return (
            <TableRow key={x.id}>
              <TableCellIcon>
                <GuideStatusIcon
                  status={x.status!}
                  hasRevision={x.ongoingRevision !== null}
                  hasChangesSinceLastPublish={x.hasChangesSinceLastPublish}
                />
              </TableCellIcon>
              <TableCell>
                <StyledLink to={`/guide/${x.id}`}>{x.docName}</StyledLink>
              </TableCell>
              <TableCellEllipsis title={x.docTitle}>{x.docTitle}</TableCellEllipsis>
              <TableCellEllipsis title={categoryTitle}>{categoryTitle}</TableCellEllipsis>
              <TableCell>{formatDateOnly(x.lastPublishedAt)}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

function getAllIdsInHeadings(classification: Classification): string[] {
  const allIds: string[] = [];
  classification?.children?.forEach((s) => s?.children?.forEach((h) => allIds.push(h!.id, ...getAllIdsInHeading(h!))));
  return allIds;
}
function getAllIdsInHeading(heading: ClassificationItem): string[] {
  const flat: string[] = [];
  flat.push(heading.id);
  if (heading.children) heading.children.forEach((s) => flat.push(...getAllIdsInHeading(s!)));
  return flat;
}

function getAllGuidesUnderHeading(heading: ClassificationItem): string[] {
  const flat: string[] = [];
  if (!heading.guides) return flat;

  flat.push(...heading.guides);
  if (heading.children) heading.children.forEach((s) => flat.push(...getAllGuidesUnderHeading(s!)));
  return flat;
}

function onlyUnique(value: string, index: number, self: string[]): boolean {
  return self.indexOf(value) === index;
}

function ExcelExport({ classification, guides }: { classification: Classification; guides: Guide[] }) {
  const exportFile = () => {
    const workbook = utils.book_new();
    classification?.children?.forEach((x) => utils.book_append_sheet(workbook, createSeriesSheet(x!), x!.heading!));
    writeFile(workbook, 'klassifikasjonssystem.xlsx');
  };

  const createSeriesSheet = (series: ClassificationItem) => {
    const headings: string[] = ['h1', 'h2', 'h3', 'h4', 'Tittel', 'DokumentNummer', 'DokumentTittel', 'DokumentStatus'];

    const rows = [];
    series.children?.forEach((h1) => MapHeading(h1!, 0, rows));
    const input = [headings, ...rows];
    return utils.aoa_to_sheet(input);
  };

  function MapHeading(heading: ClassificationItem, indentation: number, rows: any[]) {
    const columns: (string | number | null)[] = [];
    for (let i = 0; i < indentation; i++) {
      columns.push(null);
    }
    columns.push(heading.number);
    for (let i = indentation; i < 3; i++) {
      columns.push(null);
    }
    columns.push(heading.heading!);
    rows.push(columns);
    addGuides(heading.guides!, rows);
    if (heading.children) {
      heading.children.forEach((s) => MapHeading(s!, indentation + 1, rows));
    }
  }

  function addGuides(guideIds: string[], rows: any[]) {
    guideIds.forEach((x) => {
      const guide = guides.find((g) => g!.id === x);
      rows.push([null, null, null, null, null, guide!.docName, guide!.docTitle, statusToText(guide!.status!)]);
    });
  }

  function statusToText(status: GuideStatus): string {
    switch (status) {
      case GuideStatus.Active:
        return 'Aktiv';
      case GuideStatus.Expired:
        return 'Tilbaketrukket';
      case GuideStatus.Planned:
        return 'Planlagt';
      default:
        return status;
    }
  }

  return (
    <IconButton onClick={exportFile} title="Eksport til Excel" size="large">
      <img src="/icons8-microsoft-excel.svg" style={{ width: 24, height: 24 }} alt="Excel icon" />
    </IconButton>
  );
}
