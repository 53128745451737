import { FC } from 'react';
import { ReactNodeViewRenderer, NodeViewProps, Node, mergeAttributes } from '@tiptap/react';
import { setBlockType } from '@tiptap/pm/commands';
import { getNodeType } from '@tiptap/core';

interface InsertTableFigureOptions {
  rows?: number;
  cols?: number;
  withHeaderRow?: boolean;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    insertTableFigure: {
      insertTableFigure: (options?: InsertTableFigureOptions) => ReturnType;
    };
  }
}

export const createTableFigureExtension = (viewComponent?: FC<NodeViewProps>) => {
  const addNodeView = viewComponent ? () => ReactNodeViewRenderer(viewComponent) : null;

  const tableFigure = Node.create({
    name: 'tableFigure',
    group: 'block',
    content: 'figcaption table figcaption',
    isolating: true,
    parseHTML() {
      return [
        {
          tag: 'react-component',
        },
      ];
    },
    renderHTML({ HTMLAttributes }) {
      return ['react-component', mergeAttributes(HTMLAttributes)];
    },
    addAttributes() {
      return {
        dataSourceId: {
          default: undefined,
        },
        version: {
          default: undefined,
        },
        dataSourceCorrelationId: {
          default: undefined,
        },
        columns: {
          default: undefined,
        },
        filter: {
          default: undefined,
        },
      };
    },
    addNodeView,
    addCommands() {
      return {
        insertTableFigure:
          ({ rows = 3, cols = 3, withHeaderRow = true } = {}) =>
          ({ commands, state }) => {
            const type = getNodeType('tableFigure', state.schema);
            const canSetBlock = setBlockType(type)(state);
            if (!canSetBlock) {
              return false;
            }

            const table = {
              type: 'table',
              content: [] as any[],
            };

            for (let i = 0; i < rows; i++) {
              const row = {
                type: 'tableRow',
                content: [] as any[],
              };

              for (let j = 0; j < cols; j++) {
                const cell = {
                  type: i === 0 && withHeaderRow ? 'tableHeader' : 'tableCell',
                  attrs: { colspan: 1, rowspan: 1, colwidth: [] },
                  content: [{ type: 'paragraph', content: [] }],
                };

                row.content.push(cell);
              }

              table.content.push(row);
            }

            const tableFigure = {
              type: 'tableFigure',
              content: [
                { type: 'figcaption', content: [{ type: 'paragraph', content: [] }] },
                table,
                { type: 'figcaption', content: [{ type: 'paragraph', content: [] }] },
              ],
            };

            commands.insertContent(tableFigure);

            return true;
          },
      };
    },
  });
  return tableFigure;
};
