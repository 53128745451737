import { gql } from "../__generated__";

export const GET_CHANGES_ON_MINE = gql(/* GraphQL */`
  query GetGuideChangesOnMine ($ownerId: UUID) {
    guides(where: { ownerId: { eq: $ownerId } }) {
      id
      docName
      changes(first: 10, order: { createdAt: DESC }) {
        nodes {
          id
          description
          type
          createdAt
          createdByName
        }
      }
    }
  }
`);

export const GET_LAST_CHANGED_GUIDES = gql(/* GraphQL */`
  query GetMyLastChangedGuides ($userId: UUID!) {
    guides(where: { ownerId: { eq: $userId } }) {
      id
      status
      docName
      docTitle
      ongoingRevision {
        id
      }
      lastChangedAt
      lastChangedById
      hasChangesSinceLastPublish
    }
  }
`);

export const GET_OMY_OPEN_TASKS = gql(/* GraphQL */`
  query GetMyOpenTasks($ownerId: UUID!, $lastRead: DateTime) {
    tasks(
      where: { and: [{ createdAt: { gt: $lastRead } }, { guides: { some: { and: [{ completed: { eq: false } }, { ownerId: { eq: $ownerId } }] } } }] }
    ) {
      id
    }
  }
`);