import React, { useState } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from '@mui/material';
import { StyledLink } from '../Components';
import { formatDate } from '../Formatters';
import { getRoles, roles } from '../resources/Overview';
import { Lens } from '@mui/icons-material';
import { ColWidths, TableCellEllipsis, TableCellIcon, TableCellSort, TableCellHead } from '../components/TableComponents';
import { SortDirection } from '@mui/material/TableCell';
import { RevisionColumn, getCompareFn } from '../resources/RoleHeaders';
import { Revision } from './MyParticipationInRevisions';
import { useAuth } from 'oidc-react';
import { getDefaultComparer } from '../resources/ResourceTable';

export function MyParticipationTable({ revisions }: { revisions: Revision[] }) {
  const auth = useAuth();
  const userId = auth.userData?.profile.sub;

  const [orderBy, setOrderBy] = useState<RevisionColumn | false>(false);
  const [direction, setDirection] = useState<SortDirection>(false);

  const handleSortClick = (key: RevisionColumn) => {
    if (orderBy === key) {
      setDirection(direction === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(key);
      setDirection('asc');
    }
  };

  const sortedRevisions = [
    ...revisions.filter((x) => x?.roles?.some((r) => r?.user?.subjectId === userId) || x?.guide?.owner?.subjectId === userId),
  ].sort(orderBy ? getCompareFn(orderBy, direction) : getDefaultComparer([userId!]));

  const ColumnHeader = ({ colKey, inputProps, children }: { colKey: RevisionColumn; inputProps?: any; children?: any }) => (
    <TableCellSort inputProps={{ ...inputProps }} active={orderBy === colKey} sortDirection={direction} onClick={() => handleSortClick(colKey)}>
      {children}
    </TableCellSort>
  );

  return (
    <Table size="small">
      <ColWidths widths={[80, 250, null, null, null, null, null, null, null, null, 80, 80, 130]}></ColWidths>
      <TableHead>
        <TableRow>
          <ColumnHeader colKey="number">Nummer</ColumnHeader>
          <ColumnHeader colKey="title">Tittel</ColumnHeader>
          <RoleHeaders />
          <ColumnHeader colKey="scheduledEndDate">Publisering</ColumnHeader>
          <ColumnHeader colKey="progress">Fremdrift</ColumnHeader>
          <ColumnHeader colKey="currentPhase">Fase</ColumnHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedRevisions.map((r) => (
          <TableRow key={r?.id}>
            <TableCell style={{ paddingRight: 16 }} title={r?.guide?.docName ?? undefined}>
              <StyledLink to={`/revision/${r?.id}`}>
                <span>{r?.guide?.docName}</span>
              </StyledLink>
            </TableCell>
            <TableCellEllipsis title={r?.guide?.docTitle}>{r?.guide?.docTitle}</TableCellEllipsis>
            <Roles revision={r} userId={userId} />
            <TableCell>{formatDate(r?.scheduledEndDate, 'MMM YYYY')} </TableCell>
            <TableCell>{`${r?.progress}%`} </TableCell>
            <TableCellEllipsis>{r?.currentPhase} </TableCellEllipsis>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function RoleHeaders() {
  return (
    <>
      {Object.entries(roles).map(([key, val]) => (
        <TableCellHead key={key} sx={{ width: 100, paddingBottom: 0 }}>
          <Tooltip title={val.title}>
            <span>{val.label}</span>
          </Tooltip>
        </TableCellHead>
      ))}
    </>
  );
}

function Roles({ revision, userId }: { revision: Revision; userId?: string }) {
  return (
    <>
      {getRoles(revision).map((r) => {
        const isSelectedUser = r.userId === userId;
        return (
          <TableCellIcon key={r.role}>
            <div>{isSelectedUser && <Lens style={{ fontSize: 10 }} />}</div>
          </TableCellIcon>
        );
      })}
    </>
  );
}
