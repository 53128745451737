import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from '@mui/material';
import { GET_PARTNERS, CREATE } from './partners.graphql';
import LoadingSpinner from '../LoadingSpinner';
import { ColWidths, TableCellSort, compareFn } from '../components/TableComponents';
import { SearchField } from '../components/SearchField';
import Fuse from 'fuse.js';
import { SortDirection } from '@mui/material/TableCell';
import { StyledLink } from '../Components';
import { useNavigate, useLocation } from 'react-router-dom';
import { parse } from 'qs';
import { Add, Save } from '@mui/icons-material';
import { Partner } from './types';
import { StyledPaper } from '../theme';

export function Overview() {
  const [filter, setFilter] = useState('');
  const { loading, error, data } = useQuery(GET_PARTNERS, { fetchPolicy: 'cache-and-network' });

  return (
    <StyledPaper>
      <Typography variant="h5" gutterBottom>
        Partnere
        <AddButton />
      </Typography>
      <SearchField filter={setFilter} />
      {loading && <LoadingSpinner />}
      {error && <Typography>{error.message}</Typography>}
      {!loading && data && data.partners && <PartnerTable partners={filterPartners(filter, data.partners)} />}
    </StyledPaper>
  );
}

type ResourceColumn = 'name' | 'address' | 'url' | 'guides';
type CompareFn = (a: Partner, b: Partner) => number;

function PartnerTable({ partners }: { partners: Partner[] }) {
  const [orderBy, setOrderBy] = useState<ResourceColumn>('name');
  const [direction, setDirection] = useState<SortDirection>(false);
  const handleSortClick = (key: ResourceColumn) => {
    if (orderBy === key) {
      setDirection(direction === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(key);
      setDirection('asc');
    }
  };

  const getCompareFn = (): CompareFn => {
    const fn = (getter: (Partner) => any) => compareFn<Partner>(getter, direction);
    if (orderBy === 'guides') return fn((x) => x.guides.length);

    return fn((x) => x[orderBy]);
  };

  const ColumnHeader = ({ colKey, inputProps, children }: { colKey: ResourceColumn; inputProps?: any; children?: any }) => (
    <TableCellSort inputProps={{ ...inputProps }} active={orderBy === colKey} sortDirection={direction} onClick={() => handleSortClick(colKey)}>
      {children}
    </TableCellSort>
  );

  return (
    <Table size="small" sx={{ position: 'relative' }}>
      <ColWidths widths={[null, null, null, 100]}></ColWidths>
      <TableHead>
        <TableRow>
          <ColumnHeader colKey="name">Navn</ColumnHeader>
          <ColumnHeader colKey="address">Adresse</ColumnHeader>
          <ColumnHeader colKey="url">Url</ColumnHeader>
          <ColumnHeader colKey="guides" inputProps={{ align: 'right' }}>
            Anvisninger
          </ColumnHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {partners.sort(getCompareFn()).map((partner) => {
          if (!partner) return null;
          return (
            <TableRow key={partner.id}>
              <TableCell>
                <StyledLink to={`/partner/${partner.id}`}>{partner.name}</StyledLink>
              </TableCell>
              <TableCell>{partner.address}</TableCell>
              <TableCell>{partner.url && <a href={partner.url}>{partner.url}</a>}</TableCell>
              <TableCell align="right">{partner.guides?.length}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

function AddButton() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [create, { called, loading }] = useMutation(CREATE, {
    variables: { input: { name } },
  });
  const close = () => {
    setName('');
    setOpen(false);
  };
  const submit = async () => {
    const result = await create();
    close();
    navigate(`${result.data?.partnerAdd.partnerModel?.id}`);
  };
  const params = parse(location.search.substring(1));
  if (params.create && typeof params.create === 'string') {
    if (name !== params.create) setName(params.create);
    if (!open) setOpen(true);
  }

  return (
    <>
      <IconButton onClick={() => setOpen(true)} title="Legg til ny partner" size="large">
        <Add />
      </IconButton>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Legg til ny partner</DialogTitle>
        <DialogContent>
          <TextField margin="dense" label="Navn" type="text" autoFocus value={name} onChange={(ev) => setName(ev.target.value)} fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Avbryt</Button>
          <Button
            disabled={called}
            onClick={submit}
            variant="contained"
            startIcon={loading ? <CircularProgress size={15} color="secondary" /> : <Save />}
            color="primary">
            Legg til
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export function filterPartners(filter, partners: Partner[]): Partner[] {
  if (!filter) return [...partners];

  var options = {
    shouldSort: false,
    tokenize: true,
    matchAllTokens: true,
    threshold: 0,
    keys: ['name'],
  };

  var fuse = new Fuse(partners, options);
  var filtered = fuse.search(filter.trim());
  return filtered.map((x) => x.item);
}
