import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid2 as Grid,
  IconButton,
  ListItemIcon,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { formatDate } from '../Formatters';
import { Delete, EmojiPeople, ExpandMore, Message, ThumbDown, ThumbUp } from '@mui/icons-material';
import { COMMENT_FEEDBACK, DELETE_FEEDBACK, GET_FEEDBACK } from './userFeedback.graphql';
import { GetFeedbacksForGuideQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';
import Edit from '@mui/icons-material/Edit';
import { Modal, useModal } from '../dialogs/useModal';

export function UserFeedback({ guideId }: { guideId: string }) {
  const [limit, setLimit] = useState(20);

  const { loading, error, data, refetch } = useQuery(GET_FEEDBACK, {
    variables: { id: guideId, limit },
  });
  const hasFeedback = data?.guide?.feedbacks?.nodes && data.guide.feedbacks.nodes.length > 0;
  const moreToLoad = data?.guide?.feedbacks?.nodes && data.guide.feedbacks.nodes.length < data.guide.feedbacks.totalCount;

  const doFetchMore = () => {
    if (!data) return;
    setLimit(limit + 5);
    refetch();
  };

  return (
    <StyledPaper>
      <Typography variant="h5" component="h3">
        Tilbakemelding fra brukere
      </Typography>
      {error && <Typography>{error.message}</Typography>}
      {hasFeedback && <FeedbackList feedbacks={data.guide!.feedbacks!.nodes!} guideId={guideId} />}
      {loading && <LoadingSpinner />}
      {moreToLoad && (
        <Button disabled={loading} onClick={doFetchMore}>
          Last flere
        </Button>
      )}
    </StyledPaper>
  );
}

type Feedback = Unpacked<NonNullable<NonNullable<GetFeedbacksForGuideQuery['guide']>['feedbacks']>['nodes']>;

function FeedbackList({ feedbacks, guideId }: { feedbacks: Feedback[]; guideId: string }) {
  const [showDeletedFeedbacks, setShowDeletedFeedbacks] = useState(false);
  const anyDeleted = feedbacks.some((x) => x?.deleted);
  const likes = feedbacks.filter((x) => x?.like).length;
  const dislikes = feedbacks.filter((x) => x?.like === false).length;
  const hasVotes = likes > 0 || dislikes > 0;
  return (
    <>
      {hasVotes && (
        <Box fontSize="large" m={1}>
          <Stack direction="row" gap={4}>
            <Box display="flex">
              <ThumbUp color="primary" />
              <Box display="inline-block" ml={1}>
                {likes}
              </Box>
            </Box>
            <Box display="flex">
              <ThumbDown color="primary" />
              <Box display="inline-block" ml={1}>
                {dislikes}
              </Box>
            </Box>
          </Stack>
        </Box>
      )}
      {anyDeleted && (
        <FormControlLabel
          control={<Switch checked={showDeletedFeedbacks} onChange={(e) => setShowDeletedFeedbacks(e.target.checked)} />}
          label="Vis slettede tilbakemeldinger"
        />
      )}
      {feedbacks
        .filter((x) => x?.title || x?.text)
        .filter((x) => showDeletedFeedbacks || !x?.deleted)
        .map((x) => {
          if (!x) return null;
          return <FeedbackItem key={x.id} feedback={x} guideId={guideId} />;
        })}
    </>
  );
}

function FeedbackItem({ feedback, guideId }: { feedback: NonNullable<Feedback>; guideId: string }) {
  return (
    <Accordion sx={{ margin: 0, padding: 0 }}>
      <AccordionSummary sx={{ backgroundColor: feedback.deleted ? '#cfd4d1' : 'inherit' }} expandIcon={<ExpandMore />}>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box sx={{ verticalAlign: 'middle', display: 'inline-flex', alignItems: 'start', paddingRight: '0.5em', whiteSpace: 'pre' }}>
            <ListItemIcon>
              <FeedbackIcon feedback={feedback} />
            </ListItemIcon>
          </Box>
          <Box sx={{ flexGrow: 1, marginLeft: (theme) => theme.spacing(), marginTop: '2px' }}>
            {feedback.title && feedback.title.length > 0 ? feedback.title : 'Tilbakemelding'}
          </Box>
          <Box sx={{ paddingLeft: '0.5em' }}>
            <Typography noWrap>
              {feedback.canContact && <EmojiPeople fontSize="small" titleAccess="Jeg kan kontaktes" />}
              {[feedback.userEmail, formatDate(feedback.timestamp)].filter(Boolean).join(', ')}
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" spacing={2}>
          <Grid>
            <Typography variant="body1" whiteSpace="pre-wrap">
              {feedback.text}
            </Typography>
          </Grid>
          <Grid>
            <FeedbackActionPanel feedback={feedback} guideId={guideId} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

function FeedbackActionPanel({ feedback, guideId }: { feedback: NonNullable<Feedback>; guideId: string }) {
  const isDeleted = feedback.deleted;
  return (
    <Stack gap={2}>
      {feedback.comment && (
        <Box>
          <Typography>{`Kommentar: ${feedback.comment}`}</Typography>
          <Typography fontSize="0.875rem" color="rgba(0, 0, 0, 0.6)">{`Sist endret av: ${feedback.commentedBy?.name} - ${formatDate(
            feedback.commentedAt,
          )}`}</Typography>
        </Box>
      )}
      {isDeleted && <Typography component="div">{`Slettet av ${feedback.deletedBy?.name}, ${formatDate(feedback.deletedAt)}`}</Typography>}
      {!isDeleted && <TaskActions feedback={feedback} guideId={guideId} />}
    </Stack>
  );
}

function TaskActions({ feedback, guideId }: { feedback: NonNullable<Feedback>; guideId: string }) {
  const deleteModal = useModal<Feedback, undefined>({ data: feedback });
  const commentModal = useModal<Feedback, undefined>({ data: feedback });

  return (
    <div>
      <IconButton title="Slett tilbakemelding" size="large" onClick={() => deleteModal.open(feedback)}>
        <Delete />
      </IconButton>
      <IconButton title="Skriv kommentar" size="large" onClick={() => commentModal.open(feedback)}>
        <Edit />
      </IconButton>
      {deleteModal.isOpen && <DeleteDialog modal={deleteModal} guideId={guideId} />}
      {commentModal.isOpen && <CommentDialog modal={commentModal} guideId={guideId} />}
    </div>
  );
}

function FeedbackIcon({ feedback }: { feedback: NonNullable<Feedback> }) {
  if (feedback.like !== null) {
    return feedback.like ? <ThumbUp /> : <ThumbDown />;
  }

  return <Message />;
}

function DeleteDialog({ modal, guideId }: { modal: Modal<Feedback, undefined>; guideId: string }) {
  const feedback = modal.data;
  const [deleteFeedback, { loading }] = useMutation(DELETE_FEEDBACK, { variables: { input: { guideId: guideId, feedbackId: feedback?.id } } });

  const handleClose = () => modal.close();
  const handleDelete = async () => {
    await deleteFeedback();
    handleClose();
  };

  return (
    <Dialog open={modal.isOpen} onClose={handleClose}>
      <DialogTitle>Slett tilbakemelding</DialogTitle>
      <DialogContent>{feedback && <FeedbackDialogText feedback={feedback} />}</DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose}>
          Avbryt
        </Button>
        <Button disabled={loading} variant="contained" onClick={handleDelete}>
          {loading ? 'Sletter...' : 'Slett'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function FeedbackDialogText({ feedback }: { feedback: NonNullable<Feedback> }) {
  return (
    <DialogContentText>
      <Typography variant="h5" component="div" mb={2}>
        {feedback.title}
      </Typography>
      <Typography component="div" whiteSpace="pre-wrap">
        {feedback.text}
      </Typography>
      <Typography variant="caption" component="div" whiteSpace="pre-wrap" mt={2}>
        Fra: {feedback?.userEmail}
      </Typography>
    </DialogContentText>
  );
}

function CommentDialog({ modal, guideId }: { modal: Modal<Feedback, undefined>; guideId: string }) {
  const feedback = modal.data;
  const [comment, setComment] = useState(feedback?.comment || '');
  const [commentFeedback, { loading }] = useMutation(COMMENT_FEEDBACK, {
    variables: { input: { guideId: guideId, feedbackId: feedback?.id, comment } },
  });

  const handleClose = () => modal.close();
  const handleUpdate = async () => {
    await commentFeedback();
    handleClose();
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>Kommenter tilbakemelding</DialogTitle>
      <DialogContent>
        {feedback && <FeedbackDialogText feedback={feedback} />}
        <TextField
          autoFocus
          margin="dense"
          id="comment"
          label="Kommentar"
          type="text"
          fullWidth
          variant="standard"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose}>
          Avbryt
        </Button>
        <Button disabled={loading} variant="contained" onClick={handleUpdate}>
          {loading ? 'Lagrer...' : 'Lagre'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
