import React, { FC } from 'react';
import { CollaborationUser } from '../../../../shared/guide-cms/types/collaborationUser';
import { Avatar, ListItem, ListItemAvatar, Paper, Stack, Tooltip, styled } from '@mui/material';

const numberOfAvatarsToShow = 3;

const StyledAvatar = styled(Avatar)`
  border: 2px solid #ffffff;
  height: 34px;
  width: 34px;
  font-size: 16px;
  margin-top: -4px;
  margin-bottom: -4px;
`;

const StackedAvatar = styled(StyledAvatar)`
  margin-left: -6px;
`;

const MoreUsersList = styled(Paper)`
  position: absolute;
  right: 5px;
  top: 46px;
  display: none;
`;

const MoreUsersAvatar = styled(StackedAvatar)`
  position: relative;
  font-size: 14px;
  &:hover + .MuiPaper-root {
    display: block;
  }
`;

export const HocuspocusConnectedUsers: FC<{ users: CollaborationUser[] }> = ({ users: usersFromHocuspocus }) => {
  const users = usersFromHocuspocus.filter((user) => user !== undefined);

  const numberOfUsersToShow = users.length <= numberOfAvatarsToShow ? users.length : numberOfAvatarsToShow - 1;
  const firstUsers = users.slice(0, numberOfUsersToShow);
  const lastUsers = users.slice(numberOfUsersToShow, users.length);
  const moreCount = users.length - numberOfUsersToShow;

  return (
    <Stack direction="row">
      {firstUsers.map((user, i) => {
        return (
          <Tooltip key={`connected_user_${i}`} style={{ zIndex: users.length - i }} title={user.name}>
            <StackedAvatar sx={{ bgcolor: user.color }}>{user.initials}</StackedAvatar>
          </Tooltip>
        );
      })}
      {moreCount > 0 && (
        <>
          <MoreUsersAvatar>+{moreCount} </MoreUsersAvatar>
          <MoreUsersList elevation={4}>
            {lastUsers.map((user, i) => {
              return (
                <ListItem key={`connected_user_expanded_${i}`}>
                  <ListItemAvatar>
                    <StyledAvatar sx={{ bgcolor: user.color }}>{user.initials}</StyledAvatar>
                  </ListItemAvatar>
                  {user.name}
                </ListItem>
              );
            })}
          </MoreUsersList>
        </>
      )}
    </Stack>
  );
};
