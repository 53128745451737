import { FC, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../../../LoadingSpinner';
import Fuse from 'fuse.js';
import { SearchField } from '../../../components/SearchField';
import React from 'react';
import { GetAllStandardsQuery } from '../../../__generated__/graphql';
import { Unpacked } from '../../../graphQLTypes/types';
import { Modal } from '../../../dialogs/useModal';
import { ALL_STANDARDS } from '../../../standards/standards.graphql';
import { Check } from '@mui/icons-material';

export interface StandardLinkDialogResult {
  id: string;
  name: string;
}

type Standards = GetAllStandardsQuery['standards'];
type Standard = Unpacked<Standards>;

export interface StandardLinkDialogProps {
  selectedText?: string;
}

export const StandardLinkDialog: FC<Modal<StandardLinkDialogProps, StandardLinkDialogResult>> = (modal) => {
  const { close } = modal;
  const [filter, setFilter] = useState(modal.data.selectedText ?? '');
  const { data, loading } = useQuery(ALL_STANDARDS);
  const standards = data?.standards;

  const handleOnClose = () => {
    close();
  };

  const filterStandards = (standards: Standard[]): Standard[] => {
    let filtered = standards;
    if (!filter) return filtered;

    var options = {
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0,
      keys: ['name', 'title', 'version', 'language'],
    };

    var fuse = new Fuse(filtered, options);
    filtered = fuse.search(filter.trim()).map((x) => x.item);
    return filtered;
  };

  const filteredStandards = standards ? filterStandards(standards) : [];
  return (
    <Dialog open={true} onClose={handleOnClose} maxWidth="lg" fullWidth={true}>
      <DialogTitle>Legg til lenke til standard</DialogTitle>
      <DialogContent>
        <SearchField filter={setFilter} startSearch={filter} />
        {loading && <LoadingSpinner />}
        {!loading && (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Gyldig</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Tittel</TableCell>
                  <TableCell>Utgave</TableCell>
                  <TableCell>Språk</TableCell>
                  <TableCell>ErstattetAv(EA)/ Erstatter(E)</TableCell>
                  <TableCell>Velg</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredStandards.map((standard) => {
                  if (!standard) return null;
                  return (
                    <TableRow key={standard.id}>
                      <TableCell>{standard.isValid && <Check fontSize="small" />}</TableCell>
                      <TableCell>{standard.name}</TableCell>
                      <TableCell>{standard.title}</TableCell>
                      <TableCell sx={{ minWidth: '130px' }}>{standard.version}</TableCell>
                      <TableCell>{standard.language}</TableCell>
                      <TableCell>{standard.link}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            const result: StandardLinkDialogResult = {
                              id: standard.id,
                              name: standard.name ?? '',
                            };
                            close(result);
                          }}>
                          Velg
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Avbryt</Button>
      </DialogActions>
    </Dialog>
  );
};
