export interface AnalysisProps {
  initialText: string;
  isOpen: boolean;
}

export interface AiSearchDialogResult {}
export interface AiSearchDialogProps {
  initialText: string;
}

export enum AnalysisType {
  Guides,
  Abstract,
  Advices,
  TargetAudience,
  LanguageAnalysis,
}

declare global {
  interface Window {
    aiSearchInitialText: string;
  }
}
