import { FC, useCallback, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Slider,
  Grid2 as Grid,
  TextField,
  IconButton,
  CircularProgress,
} from '@mui/material';
import React from 'react';
import { Modal } from '../../../dialogs/useModal';
import { Node } from '@tiptap/pm/model';
import { Stack } from '@mui/system';
import { ChartComponent } from './ChartComponent';
import { ChartExamples } from './ChartExamples';
import { ChartParseResult, parseChartConfig } from './chartConfig';
import { ChartConfigEditor } from './ChartConfigEditor';
import { formatJson } from './jsonHelper';
import { debounce } from 'throttle-debounce';
import { useAi } from '../../../ai/useAi';
import { AutoFixHigh } from '@mui/icons-material';
import { ExcelImport } from './ExcelImport';

export interface ChartDialogResult {
  width: number;
  config: string;
}

export interface ChartDialogProps {
  chartNode: Node | null;
}

export const ChartDialog: FC<Modal<ChartDialogProps, ChartDialogResult>> = (modal) => {
  const { close } = modal;
  const attrs = modal.data.chartNode?.attrs ?? {};
  const [width, setWidth] = useState(attrs.width);
  const [config, setConfig] = useState(attrs.config);
  const [isValid, setIsValid] = useState<boolean>(true);
  const [aiPrompt, setAiPrompt] = useState<string>('');
  const [isGeneratingAiConfig, setIsGeneratingAiConfig] = useState(false);
  const [aiConfigError, setAiConfigError] = useState(false);

  const ai = useAi();

  const handleOnClose = () => {
    close();
  };

  const handleOnSave = () => {
    const formattedJson = formatJson(config);
    close({
      width,
      config: formattedJson,
    });
  };

  const setConfigDebounced = useCallback(
    debounce(400, (config: string) => {
      setConfig(config);
    }),
    [],
  );

  const handleOnExampleSelect = (config: string) => {
    setConfig(config);
    setIsValid(true);
  };

  const handleOnConfigChanged = (result: ChartParseResult) => {
    setIsValid(result.isValid);
    setConfigDebounced(result.configJson);
  };

  const handleOnExcelImport = (config: string) => {
    setConfig(config);
    setIsValid(true);
  };

  const handleOnGenerateAiConfig = async () => {
    setIsGeneratingAiConfig(true);
    setAiConfigError(false);
    try {
      const response = await ai.createChartjsConfig({ text: aiPrompt });
      if (response.isError) {
        setAiConfigError(true);
      } else {
        const parsed = parseChartConfig(response.config);
        if (parsed.isValid) {
          setConfig(formatJson(response.config));
          setIsValid(true);
        } else {
          setAiConfigError(true);
        }
      }
    } catch {
      setAiConfigError(true);
    } finally {
      setIsGeneratingAiConfig(false);
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleOnClose}
      fullWidth={true}
      PaperProps={{
        sx: {
          position: 'absolute',
          top: 0,
          minHeight: '600px',
          height: '100%',
          minWidth: '90%',
        },
      }}>
      <DialogTitle>Rediger graf</DialogTitle>
      <DialogContent>
        <Grid spacing={2} container>
          <Grid size={{ xs: 12, lg: 6, xl: 5 }}>
            <Stack spacing={2}>
              <ChartExamples onSelect={handleOnExampleSelect}></ChartExamples>

              <ChartComponent width={width} config={config} />
              <div>
                <Typography variant="subtitle2" gutterBottom>
                  Bredde
                </Typography>
                <Slider
                  step={20}
                  value={width}
                  onChange={(_, x) => setWidth(x)}
                  valueLabelDisplay="auto"
                  defaultValue={700}
                  min={100}
                  max={1100}
                  style={{ width: '300px' }}
                />
              </div>
            </Stack>
          </Grid>
          <Grid size={{ xs: 12, lg: 6, xl: 7 }}>
            <Typography variant="h6" gutterBottom>
              Konfigurasjon
            </Typography>
            <p>
              Vi bruker Chart.js for å lage grafer. Se{' '}
              <a href="https://www.chartjs.org/docs/latest/samples/information.html" target="_blank">
                dokumentasjonen til Chart.js
              </a>{' '}
              for mer informasjon og eksempler.
              <br />
              Sjekk også ut QuickChart sin{' '}
              <a href="https://quickchart.io/chart-maker/" target="_blank">
                Chart Maker
              </a>{' '}
              for å lage grafer.
            </p>
            <Stack spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <TextField
                  placeholder="En graf som viser befolkningsvekst i verden fra 1900 til i dag"
                  size="small"
                  label="KI-generert graf"
                  fullWidth
                  value={aiPrompt}
                  disabled={isGeneratingAiConfig}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleOnGenerateAiConfig();
                    }
                  }}
                  error={aiConfigError}
                  helperText={aiConfigError ? 'Kunne ikke generere graf' : ''}
                  onChange={(e) => setAiPrompt(e.target.value)}
                />
                <div style={{ width: '50px' }}>
                  {isGeneratingAiConfig ? (
                    <CircularProgress size="2rem" />
                  ) : (
                    <IconButton color="primary" onClick={handleOnGenerateAiConfig}>
                      <AutoFixHigh />
                    </IconButton>
                  )}
                </div>
              </Stack>
              <Typography variant="subtitle2" gutterBottom>
                Importer fra Excel
              </Typography>
              <ExcelImport onImport={handleOnExcelImport} />
              <Typography variant="subtitle2" gutterBottom>
                Konfigurasjon (JSON)
              </Typography>
              <ChartConfigEditor onChange={handleOnConfigChanged} config={config}></ChartConfigEditor>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Avbryt</Button>
        <Button disabled={!isValid} variant="contained" onClick={handleOnSave}>
          Lagre
        </Button>
      </DialogActions>
    </Dialog>
  );
};
