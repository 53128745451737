import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { Modal } from './useModal';

export interface ConfirmDialogProps {
  title: string;
  message: string | React.ReactNode;
  submitText?: string;
  cancelText?: string;
}

export function ConfirmDialog({ modal }: { modal: Modal<ConfirmDialogProps, boolean> }) {
  const handleCancel = () => modal.close(false);
  const { title, message, submitText, cancelText } = modal.data;

  return (
    <Dialog onClose={handleCancel} maxWidth="xs" fullWidth open={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{cancelText || 'Avbryt'}</Button>
        <Button variant="contained" color="primary" onClick={() => modal.close(true)} autoFocus>
          {submitText || 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
