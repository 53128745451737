import { FC } from 'react';
import { Node, mergeAttributes, ReactNodeViewRenderer, NodeViewProps } from '@tiptap/react';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';

export interface GuideLinkExtensionRenderOptions {
  viewComponent?: FC<NodeViewProps>;
  renderText?: (node: ProseMirrorNode) => string;
}

export const createGuideLinkExtension = (renderOptions: GuideLinkExtensionRenderOptions) => {
  const addNodeView = renderOptions.viewComponent ? () => ReactNodeViewRenderer(renderOptions.viewComponent as FC<NodeViewProps>) : null;
  const node = Node.create({
    name: 'guideLink',
    group: 'inline',
    inline: true,
    selectable: false,
    atom: true,
    renderText({ node }) {
      if (renderOptions.renderText) {
        return renderOptions.renderText(node);
      }
      return node.attrs.docId;
    },
    addAttributes() {
      return {
        guideId: {
          default: null,
        },
        docId: {
          default: null,
        },
        docName: {
          default: null,
        },
      };
    },
    parseHTML() {
      return [
        {
          tag: 'react-component',
        },
      ];
    },

    renderHTML({ HTMLAttributes }) {
      return ['react-component', mergeAttributes(HTMLAttributes)];
    },
    addNodeView,
  });

  return node;
};
