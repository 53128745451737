import { useQuery } from '@apollo/client';
import { GET_GUIDE_FIGURES } from '../content.graphql';
import { FigureFileType, GetGuideFiguresQuery } from '../../../__generated__/graphql';
import { Unpacked } from '../../../graphQLTypes/types';

type Guide = NonNullable<Unpacked<GetGuideFiguresQuery>['guide']>;
export type Figure = NonNullable<Unpacked<NonNullable<Unpacked<Guide>['figures']>>>;

export const getThumbnailUrl = (figure: Figure) => {
  const smallUrl = figure.files?.find((file) => file?.type === FigureFileType.Small)?.url;
  if (smallUrl) return smallUrl;

  const largeUrl = figure.files?.find((file) => file?.type === FigureFileType.Large)?.url;
  if (largeUrl) return largeUrl;

  return undefined;
};

export const useFigure = (guideId: string, figureId?: string): { figure: Figure | undefined; isLoading: boolean } => {
  const { figures, isLoading } = useFigures(guideId);
  if (!figureId) return { figure: undefined, isLoading: false };
  return { figure: figures.find((f) => f.id === figureId), isLoading };
};

export const useFigures = (guideId: string): { figures: Figure[]; isLoading: boolean } => {
  const { data, loading: isLoading } = useQuery(GET_GUIDE_FIGURES, { variables: { guideId } });
  return { figures: (data?.guide?.figures ?? []) as Figure[], isLoading };
};
