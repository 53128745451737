import { gql } from "../__generated__";

export const GET_USERS = gql(/* GraphQL */ `
  query GetUsers {
    users: users {
      subjectId
      name
      email
    }
  }
`);