import React, { FC } from 'react';
import { useGuideCmsContext } from '../GuideCmsContext';
import { useFigure } from './figureHelper';
import styled from '@emotion/styled';
import { getInitialsFromFullName } from '../../../../shared/user';
import { getCurrentStatusDetails, steps } from './status';
import { GuideFigureStatusTooltip } from './GuideFigureStatusTooltip';

const StyledInitialsCircle = styled.div<{ backgroundColor: string; color: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  align-items: center;
  border-radius: 50%;
  display: inline-block;
`;

export const GuideFigureStatus: FC<{ figureId: string }> = ({ figureId }) => {
  const { guideId } = useGuideCmsContext();
  const { figure } = useFigure(guideId, figureId);
  if (!figure) {
    return null;
  }
  const statusDetails = getCurrentStatusDetails(figure);
  if (!statusDetails) {
    return null;
  }

  const step = steps.find((s) => s.status === statusDetails.status);
  if (!step) {
    return null;
  }

  return (
    <GuideFigureStatusTooltip figureId={figureId} status={statusDetails.status}>
      <StyledInitialsCircle backgroundColor={step.color.main} color={step.color.contrastText}>
        {getInitialsFromFullName(statusDetails.changedBy)}
      </StyledInitialsCircle>
    </GuideFigureStatusTooltip>
  );
};
