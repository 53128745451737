import React, { FC } from 'react';
import { Editor } from '@tiptap/react';
import { EmojiSymbols } from '@mui/icons-material';
import { MenuButton, useRichTextEditorContext } from 'mui-tiptap';
import { useModal } from '../../../../../dialogs/useModal';
import { SymbolsDialog, SymbolsDialogProps, SymbolsDialogResult } from '../../../dialogs/SymbolsDialog';

export const SymbolsToolbarButton: FC = () => {
  const modal = useModal<SymbolsDialogProps, SymbolsDialogResult>({ data: { html: '' } });
  const editor = useRichTextEditorContext();
  const handleOnClick = async () => {
    if (!editor) return;

    const payload = await modal.open();
    if (payload) {
      insertCharacter(editor, payload);
    }
  };
  if (!editor) return null;
  return (
    <>
      {modal.isOpen && <SymbolsDialog {...modal} />}
      <MenuButton disabled={!editor?.isEditable} IconComponent={EmojiSymbols} tooltipLabel="Symboler" onClick={handleOnClick}></MenuButton>
    </>
  );
};

const insertCharacter = (editor: Editor, payload: SymbolsDialogResult) => {
  editor?.chain().focus().insertContent({ type: 'text', text: payload.selectedCharacter }).run();
};
