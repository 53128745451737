export enum StatelessMessageType {
  SaveStart = 'SaveStart',
  SaveSuccess = 'SaveSuccess',
  SaveError = 'SaveError',
  InvalidSchemaVersion = 'InvalidSchemaVersion',
}

export type StatelessMessage =
  | {
      type: StatelessMessageType.SaveStart;
    }
  | {
      type: StatelessMessageType.SaveSuccess;
      savedAt: string;
    }
  | {
      type: StatelessMessageType.SaveError;
      error: string;
    }
  | {
      type: StatelessMessageType.InvalidSchemaVersion;
    };
