import React from 'react';
import { styled } from '@mui/material/styles';
import { Archive, Done, Loop, Schedule, Warning } from '@mui/icons-material';
import { Grid2 as Grid } from '@mui/material';
import { Notice } from '../notices/NoticeIcon';
import { Guide } from './Details';
import { GuideStatus } from '../__generated__/graphql';

const PREFIX = 'GuideBadges';

const classes = {
  active: `${PREFIX}-active`,
  draft: `${PREFIX}-draft`,
  expired: `${PREFIX}-expired`,
  planned: `${PREFIX}-planned`,
  revision: `${PREFIX}-revision`,
};

const StyledNotice = styled(Notice)(() => ({
  [`& .${classes.active}`]: {
    color: 'green',
  },

  [`& .${classes.draft}`]: {
    color: 'orange',
  },

  [`& .${classes.expired}`]: {
    color: 'gray',
  },

  [`& .${classes.planned}`]: {
    color: 'gray',
  },

  [`&.${classes.revision}`]: {
    color: 'gray',
  },
}));

export function GuideBadges({ guide }: { guide: Guide }) {
  return (
    <Grid container>
      <Grid>
        <StatusBadge guide={guide} />
        <RevisionBadge guide={guide} />
        <NoticeBadge guide={guide} />
      </Grid>
    </Grid>
  );
}

function StatusBadge({ guide }: { guide: Guide }) {
  const fontSize = 'large';

  switch (guide!.status) {
    case GuideStatus.Planned:
      return <Schedule fontSize={fontSize} className={classes.planned} titleAccess="Ny" />;
    case GuideStatus.Expired:
      return <Archive fontSize={fontSize} className={classes.expired} titleAccess="Tilbaktrukket" />;
    default:
      return guide!.hasChangesSinceLastPublish ? (
        <Warning fontSize={fontSize} className={classes.draft} titleAccess="Upubliserte endringer" />
      ) : (
        <Done fontSize={fontSize} className={classes.active} titleAccess="Publisert" />
      );
  }
}

function RevisionBadge({ guide }: { guide: Guide }) {
  if (!guide!.ongoingRevision?.id) return null;
  return <Loop className={classes.revision} titleAccess="Under revisjon" fontSize="large" />;
}

function NoticeBadge({ guide }: { guide: Guide }) {
  if (guide!.notices?.filter((x) => !x!.deletedAt).length === 0) return null;
  return <StyledNotice titleAccess="Har merknader" fontSize="large" className={classes.revision} color />;
}
