import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface RevisionFilter {
  showOnlyMine: boolean;
  showOnlyMe: boolean;
  showCompleted: boolean;
}

const initialState: RevisionFilter = {
  showOnlyMine: false,
  showOnlyMe: false,
  showCompleted: false,
};

export const revisionFilterSlice = createSlice({
  name: 'revisionFilter',
  initialState,
  reducers: {
    showOnlyMine: (state, action: PayloadAction<boolean>) => {
      state.showOnlyMine = action.payload;
    },
    showOnlyMe: (state, action: PayloadAction<boolean>) => {
      state.showOnlyMe = action.payload;
    },
    showCompleted: (state, action: PayloadAction<boolean>) => {
      state.showCompleted = action.payload;
    },
  },
});

export const { showOnlyMine, showOnlyMe, showCompleted } = revisionFilterSlice.actions;

export const selectRevisionFilter = (state: RootState) => state.revisionFilter;

export default revisionFilterSlice.reducer;
