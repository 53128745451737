import React from 'react';
import { StyledLink } from '../Components';
import { formatDate } from '../Formatters';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { ChangeIcon } from '../guides/Changes';
import Moment, { Moment as MomentType } from 'moment';
import { GET_CHANGES_ON_MINE } from './home.graphql';
import { useAuth } from 'oidc-react';
import { StyledPaper } from '../theme';

export function Changes() {
  const auth = useAuth();
  const { loading, error, data } = useQuery(GET_CHANGES_ON_MINE, {
    variables: { ownerId: auth.userData?.profile.sub },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <StyledPaper>
      <Typography variant="h5" component="h3">
        Min endringslogg
      </Typography>
      {error && <Typography>{error.message}</Typography>}
      {loading && <LoadingSpinner />}
      {data?.guides && data.guides?.length > 0 && (
        <List>
          {getChangeList(data.guides).map((e) => (
            <ListItem key={e.id} disableGutters={true} dense={true} alignItems="flex-start">
              <ListItemIcon>
                <ChangeIcon change={e} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <span>
                    <StyledLink to={`/guide/${e.guideId}`}>{e.docName}</StyledLink>
                    <span>{` - ${e.description}`}</span>
                  </span>
                }
                secondary={`${formatDate(e.createdAt)} - ${e.createdByName}`}
              />
            </ListItem>
          ))}
        </List>
      )}
    </StyledPaper>
  );
}

function getChangeList(guides: any[]): any[] {
  return guides
    .flatMap((g) => g.changes.nodes.map((c) => ({ ...c, guideId: g.id, docName: g.docName, id: `${c.id}-${g.id}` })))
    .sort((a, b) => compareMoment(Moment(a.createdAt), Moment(b.createdAt)))
    .slice(0, 10);
}

const compareMoment = (a: MomentType, b: MomentType) => (a.isSame(b) ? 0 : a.isBefore(b) ? -1 : 1);
