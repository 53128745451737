import React, { useState, FC, ChangeEvent, FocusEvent, useEffect } from 'react';
import { IconButton, InputLabel, StandardTextFieldProps, TextField } from '@mui/material';
import { Edit } from '@mui/icons-material';
import styled from '@emotion/styled';
import { theme } from '../theme';

const DisplayContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  justify-items: left;
  align-items: center;
  min-height: 32px;
`;

const StyledLabel = styled(InputLabel)`
  font-size: 12px;
  margin-bottom: 2px;
`;

interface DisplayComponentProps {
  edit: () => void;
}

interface EditableTextFieldProps {
  TextFieldProps?: StandardTextFieldProps;
  DisplayComponent?: (props: DisplayComponentProps) => React.ReactNode;
  value: string;
  label?: string;
  onUpdate: (value: string) => Promise<void>;
  onCancel?: () => void;
  editing?: boolean;
}

export const EditableTextField: FC<EditableTextFieldProps> = ({
  value: originalValue,
  onUpdate,
  onCancel = () => {},
  TextFieldProps: textFieldProps = {},
  label,
  editing = false,
  DisplayComponent,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(editing);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [value, setValue] = useState<string>(originalValue);
  const displayStyle: React.CSSProperties = {
    whiteSpace: textFieldProps.multiline ? 'pre-wrap' : undefined,
    color: isUpdating || (!value && textFieldProps.placeholder) ? theme.palette.text.secondary : undefined,
  };

  useEffect(() => {
    setIsEditing(editing);
  }, [editing]);

  useEffect(() => {
    setValue(originalValue);
  }, [originalValue]);

  const edit = () => {
    if (isUpdating) return;
    setIsEditing(true);
    setValue(originalValue);
  };

  const cancel = () => {
    setIsEditing(false);
    setValue(originalValue);
    onCancel();
  };

  const handleOnBlur = async (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
    textFieldProps.onBlur?.(event);
    await updateValue();
  };

  const handleOnKeyDown = async (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      cancel();
    }
    if (event.key === 'Enter' && !event.shiftKey) {
      await updateValue();
    }
  };

  const updateValue = async () => {
    setIsEditing(false);
    if (value !== originalValue) {
      if (onUpdate) {
        setIsUpdating(true);
        try {
          await onUpdate(value);
        } catch (e) {
          setValue(originalValue);
          throw e;
        } finally {
          setIsUpdating(false);
        }
      }
    } else {
      onCancel();
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    textFieldProps.onChange?.(event);
    setValue(event.target.value);
  };

  if (isEditing) {
    return (
      <TextField
        autoFocus={true}
        fullWidth={true}
        label={label}
        {...textFieldProps}
        value={value}
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
      />
    );
  }
  if (DisplayComponent) {
    return <DisplayComponent edit={edit} />;
  }
  const displayText = value ? value : textFieldProps.placeholder;
  const showLabel = !!label && !!value;
  return (
    <div>
      {showLabel && <StyledLabel>{label}</StyledLabel>}
      <DisplayContainer>
        <div style={displayStyle} onClick={edit}>
          {displayText}
        </div>
        <IconButton disabled={isUpdating} onClick={edit} style={{ padding: 5, marginLeft: displayText ? 8 : 0 }} size="large">
          <Edit fontSize="small" />
        </IconButton>
      </DisplayContainer>
    </div>
  );
};
