import React, { FC, useEffect, useState } from 'react';
import { useGuideCmsContext } from '../../GuideCmsContext';
import { useLazyQuery } from '@apollo/client';
import { Comment, GUIDE_CONTENT_COMMENTS } from './comments.graphql';
import { CommentStatus } from '../../../../__generated__/graphql';
import { useSearchParams } from 'react-router-dom';

interface CommentsFilter {
  internal: boolean;
  external: boolean;
  open: boolean;
  closed: boolean;
}

interface CommentsContext {
  isLoading: boolean;
  isCommentsEnabled: boolean;
  guideNodeId?: string;
  comments: Comment[];
  filter: CommentsFilter;
  filterComments: (filter: CommentsFilter) => void;
  enableComments: () => void;
  disableComments: () => void;
  openComments: (guideNodeId: string) => void;
  closeComments: () => void;
}

const CommentsContextInstance = React.createContext<CommentsContext | undefined>(undefined);

export const useCommentsContext = (): CommentsContext => {
  const context = React.useContext(CommentsContextInstance);
  if (!context) {
    throw new Error('useCommentsContext must be used within a CommentsContextProvider');
  }
  return context;
};

export const CommentsContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const commentsSearchParam = searchParams.get('comments');
  const [filter, setFilter] = useState<CommentsFilter>({ internal: true, external: true, open: true, closed: false });
  const [guideNodeId, setGuideNodeId] = useState<string>();
  const [isCommentsEnabled, setIsCommentsEnabled] = useState<boolean>(commentsSearchParam === 'true');
  const { guideId } = useGuideCmsContext();

  const [getComments, { loading, data }] = useLazyQuery(GUIDE_CONTENT_COMMENTS, { variables: { id: guideId } });
  const comments = (data?.guide?.contentComments ?? []) as Comment[];
  const filteredComments = comments.filter(
    (comment) =>
      ((filter.external && !!comment.hearing) || (filter.internal && !comment.hearing)) &&
      ((filter.closed && comment.status === CommentStatus.Closed) || (filter.open && comment.status === CommentStatus.Open)),
  );
  useEffect(() => {
    if (!isCommentsEnabled) return;
    getComments();
  }, [guideId, isCommentsEnabled]);

  const openComments = (guideNodeId: string) => {
    setGuideNodeId(guideNodeId);
  };

  const closeComments = () => {
    setGuideNodeId(undefined);
  };

  const enableComments = () => {
    setIsCommentsEnabled(true);
  };

  const disableComments = () => {
    setIsCommentsEnabled(false);
  };

  return (
    <CommentsContextInstance.Provider
      value={{
        isLoading: loading,
        isCommentsEnabled,
        filter,
        comments: filteredComments,
        guideNodeId,
        filterComments: setFilter,
        enableComments,
        disableComments,
        openComments,
        closeComments,
      }}>
      {children}
    </CommentsContextInstance.Provider>
  );
};
