import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button } from '@mui/material';

import { useMutation } from '@apollo/client';
import { DELETE } from './guide.graphql';
import { Modal } from '../dialogs/useModal';

export function DeleteGuideDialog({ modal }: { modal: Modal<string, boolean> }) {
  const [deleteGuide] = useMutation(DELETE, { variables: { input: { id: modal.data } } });
  const handleClose = () => {
    modal.close(false);
  };

  const handleOk = async () => {
    await deleteGuide();
    modal.close(true);
  };

  return (
    <div>
      <Dialog fullWidth={true} maxWidth={'sm'} open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Slett anvisning</DialogTitle>
        <DialogContent>
          <DialogContentText>Dette vil slette anvisningen og kan ikke angres.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Avbryt</Button>
          <Button onClick={handleOk} variant="contained" color="primary">
            Slett
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
