import React, { FC } from 'react';
import { Editor } from '@tiptap/react';
import { Calculate } from '@mui/icons-material';
import { MenuButton, useRichTextEditorContext } from 'mui-tiptap';
import { useModal } from '../../../../../dialogs/useModal';
import { MathTypeDialog, MathTypeDialogProps, MathTypeDialogResult } from '../../../dialogs/mathType/MathTypeDialog';

export const MathTypeFormulaToolbarButton: FC = () => {
  const modal = useModal<MathTypeDialogProps, MathTypeDialogResult>({ data: { html: '' } });
  const editor = useRichTextEditorContext();
  const handleOnClick = async () => {
    if (!editor) return;

    const payload = await modal.open();
    if (payload) {
      addFormula(editor, payload);
    }
  };
  if (!editor) return null;
  return (
    <>
      {modal.isOpen && <MathTypeDialog {...modal} />}
      <MenuButton disabled={!editor?.isEditable} IconComponent={Calculate} tooltipLabel="MathType-formel" onClick={handleOnClick}></MenuButton>
    </>
  );
};

const addFormula = (editor: Editor, payload: MathTypeDialogResult) => {
  editor
    ?.chain()
    .focus()
    .insertContent({
      type: 'mathType',
      attrs: {
        html: payload.html,
        latexParts: payload.latexParts,
      },
    })
    .run();
};
