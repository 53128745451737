import { gql } from '../__generated__';

gql(/* GraphQL */`
  fragment NoticeItem on GuideRevisionNotice {
    id
    guide {
      id
      status
      docName
      docTitle
      owner {
        subjectId
        name
      }
    }
    title
    notice
    createdBy {
      subjectId
      name
    }
    createdAt
    deletedAt
    deletedBy {
      subjectId
      name
    }
  }
`);

export const GET_NOTICES = gql(/* GraphQL */`
  query GetNotices {
    notices {
      ...NoticeItem
    }   
  }  
`);

export const ADD_NOTICE = gql(/* GraphQL */`
  mutation AddNotice($input: GuideAddNoticeInput!) {
    guideAddNotice(input: $input) {
      guideModel {
        id
        hasChangesSinceLastPublish
        notices {
          ...NoticeItem
        }
      }
    }
  }
`);

export const REMOVE = gql(/* GraphQL */`
  mutation RemoveNotice($input: GuideRemoveNoticeInput!) {
    guideRemoveNotice(input: $input) {
      guideModel {
        id
        hasChangesSinceLastPublish
        notices {
          id
          deletedBy {
            subjectId
            name
          }
          deletedAt          
        }
      }
    }
  }
`);

export const UPDATE_NOTICE = gql(/* GraphQL */`
  mutation UpdateNotice($input: GuideUpdateNoticeInput!) {
    guideUpdateNotice(input: $input) {
      guideModel {
        id
        hasChangesSinceLastPublish
        notices {
          id
          title
          notice
        }
      }
    }
  }
`);