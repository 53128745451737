import React, { useState } from 'react';

import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { StandardTable } from './StandardTable';
import Fuse from 'fuse.js';
import { Add } from '@mui/icons-material';
import { ALL_STANDARDS } from './standards.graphql';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { SearchField } from '../components/SearchField';
import { useNavigate } from 'react-router-dom';
import { StyledPaper } from '../theme';
import { useModal } from '../dialogs/useModal';
import { AddStandardDialog, StandardAdded } from './AddStandardDialog';

export function StandardOverview() {
  const [searchTerm, setSearchTerm] = useState('');
  const [taskSaved, setTaskSaved] = useState(false);
  const navigate = useNavigate();
  const { data } = useQuery(ALL_STANDARDS, { fetchPolicy: 'cache-and-network' });
  const addModal = useModal<undefined, StandardAdded>({ data: undefined });

  const addStandard = async () => {
    const result = await addModal.open();
    if (result) navigate(`${result.id}`);
  };

  const standards = data?.standards ? filterStandards(data.standards, searchTerm) : undefined;
  return (
    <>
      <StyledPaper>
        <Typography variant="h5">
          Standarder
          <IconButton onClick={addStandard} title="Legg til ny standard" size="large">
            <Add />
          </IconButton>
        </Typography>
        <SearchField filter={setSearchTerm} />
        {!standards && <LoadingSpinner />}
        {standards && <CreateText standards={standards} />}
        {standards && <StandardTable standards={standards} onTaskCreated={() => setTaskSaved(true)} />}
      </StyledPaper>
      {addModal.isOpen && <AddStandardDialog modal={addModal} />}
      <Snackbar
        open={taskSaved}
        onClose={() => setTaskSaved(false)}
        autoHideDuration={3000}
        message={<span id="message-id">Oppgave lagret</span>}
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={() => setTaskSaved(false)} size="large">
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </>
  );
}

export function CreateText({ standards }: { standards: any[] | null | undefined }) {
  if (!standards || standards.length > 0) return null;

  return (
    <Typography variant="caption">
      Finner du ikke standarden i listen? Da er den mest sannsynlig ikke i bruk (ingen anvisninger knyttet til den). Legg til ny under gjeldende
      anvisning.
    </Typography>
  );
}

export function filterStandards<
  T extends undefined | null | { name?: string | null | undefined; title?: string | null | undefined; version?: string | null | undefined },
>(standards: T[] | null | undefined, searchTerm: string): T[] | null | undefined {
  if (!searchTerm) return standards;
  if (!standards) return standards;

  var options = {
    shouldSort: false,
    tokenize: true,
    matchAllTokens: true,
    threshold: 0,
    location: 0,
    distance: 10,
    maxPatternLength: 32,
    minMatchCharLength: 2,
    keys: ['name', 'title', 'version'],
  };
  var fuse = new Fuse(standards, options);
  var all = fuse.search(searchTerm).map((x) => x.item);
  return all;
}
