import { css } from '@emotion/react';

export const MathematicsStyles = css`
  .Tiptap-mathematics-editor {
    background: #e6edf0;
    color: #000;
    font-family: monospace;
    font-size: 0.8rem;
    padding: 0.2rem;
  }

  .Tiptap-mathematics-render {
    padding: 0 0.25rem;

    &--editable {
      cursor: pointer;
      transition: background 0.2s;

      &:hover {
        background: #f5f5f5;
      }
    }
  }

  .Tiptap-mathematics-editor,
  .Tiptap-mathematics-render {
    border-radius: 0.25rem;
    display: inline-block;
  }
`;
