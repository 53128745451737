import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { roles, Option, Revision } from './Overview';
import { RoleHeaders, StickyTableCell } from './RoleHeaders';

interface Props {
  revisions: Revision[];
  selectedUsers: Option[];
}

export function SummaryTable({ revisions, selectedUsers }: Props) {
  const users = selectedUsers.length > 0 ? selectedUsers : getUsersFromRevisions(revisions);
  return (
    <Table padding="none">
      <TableHead>
        <TableRow>
          <StickyTableCell>Ressurs</StickyTableCell>
          <RoleHeaders />
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((u) => (
          <TableRow key={u.value}>
            <TableCell sx={{ width: '15%' }}>{u.label}</TableCell>
            <RoleSums user={u} revisions={revisions} />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function RoleSums({ user, revisions }: { user: Option; revisions: Revision[] }) {
  return (
    <>
      {Object.keys(roles).map((r) => {
        const count = revisions.reduce(
          (result, current) => result + ((r === 'OWNER' && current?.guide?.owner?.subjectId === user.value) || (current?.roles?.some((rr) => rr?.user?.subjectId === user.value && rr.role === r)) ? 1 : 0),
          0,
        );
        return (
          <TableCell key={r} align="center" sx={{ width: '3%' }}>
            {count || ''}
          </TableCell>
        );
      })}
    </>
  );
}

function getUsersFromRevisions(revisions: Revision[]): Option[] {
  const allRoles = revisions.flatMap((rev) =>
    rev?.roles?.map((role) => {
      return { label: role?.user?.name, value: role?.user?.subjectId };
    }),
  );

  const users: Option[] = [];
  allRoles.forEach((role) => {
    if (role?.value && !users.find((u) => u.value === role.value)) users.push(role);
  });
  users.sort((a, b) => {
    if (a.label > b.label) return 1;
    if (a.label < b.label) return -1;
    return 0;
  });
  return users;
}
