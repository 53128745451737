import React, { useState } from 'react';

import {
  List,
  ListItem,
  ListItemText,
  Grid2 as Grid,
  Typography,
  Button,
  ButtonGroup,
  Stack,
  Popper,
  Grow,
  ClickAwayListener,
  MenuItem,
  MenuList,
  ListItemIcon,
  Snackbar,
  IconButton,
  Paper,
} from '@mui/material';
import { Publish, Archive, Undo, DeleteForever, ArrowDropDown, ContentCopy, IosShare, Close, Edit, Visibility } from '@mui/icons-material';

import { ListLabel, StyledLink } from '../Components';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_SHAREABLE_LINK, DELETE_SHAREABLE_LINK, RESTORE_GUIDE, UPDATE_AUTHORS, SET_PROJECT_LEADER, GET_GUIDE } from './guide.graphql';
import { useNavigate } from 'react-router-dom';
import { useConfig } from '../env';
import { EditableTextField } from '../components/EditableTextField';
import { ResourcesSelectDialog2 as ResourcesSelectDialog } from '../subjectResources/ResourceSelect';
import { Guide } from './Details';
import { GuideStatus } from '../__generated__/graphql';
import { StyledPaper } from '../theme';
import { useModal } from '../dialogs/useModal';
import { UndoChangesOnGuideDialog } from './UndoChangesOnGuideDialog';
import { PublishGuideDialog } from './PublishGuideDialog';
import { ArchiveGuideDialog } from './ArchiveGuideDialog';
import { DeleteGuideDialog } from './DeleteGuideDialog';

interface Items {
  [key: string]: React.ReactNode;
}

export function GuideDetails({ guide }: { guide: Guide }) {
  if (!guide) return null;
  const isBvn = guide.docName?.startsWith('BVN');

  let leftItems: Items = {};
  leftItems['Fagområdeansvarlig:'] = guide.owner?.name;
  leftItems['Forfatter:'] = <Authors guide={guide} />;
  leftItems['Prosjektleder:'] = <ProjectLeaderText guide={guide} />;

  const rightItems: Items = {};
  rightItems['Hovedfagområde:'] = guide.mainCategory ? (
    <StyledLink to={`/category/${guide.mainCategory.id}`}>{guide.mainCategory.title}</StyledLink>
  ) : (
    <span></span>
  );
  if (!isBvn) rightItems['Delserie:'] = <span>{guide.series}</span>;

  rightItems['Klassifikasjon:'] = <span>{guide.classificationLabel}</span>;

  return (
    <StyledPaper>
      <Typography variant="h5">Detaljer</Typography>
      <Grid container>
        <Grid size={{ xs: 12, lg: 6 }}>
          <ItemList items={leftItems} width={175} />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <ItemList items={rightItems} />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Actions guide={guide} />
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

function Actions({ guide }: { guide: Guide }) {
  if (!guide) return null;
  const isNew = guide.status === GuideStatus.Planned;
  if (!isNew && !guide.hasChangesSinceLastPublish) return <UpdateStatusButton guide={guide} />;

  return <PublishButtons guide={guide} />;
}

function PublishButtons({ guide }: { guide: NonNullable<Guide> }) {
  const publishModal = useModal<Guide, unknown>({ data: guide });
  const undoModal = useModal<Guide, unknown>({ data: guide });
  const publishClick = () => publishModal.open(guide);
  const undoClick = () => undoModal.open();
  if (!guide) return null;

  const isNew = guide.status! === GuideStatus.Planned;
  const hasContent = guide.docVersion || (guide.useCmsContent && guide.contentId);
  return (
    <>
      <Stack direction="row" gap={2}>
        <Button
          onClick={publishClick}
          aria-label="Publish"
          color="primary"
          variant="contained"
          disabled={isNew && !hasContent}
          startIcon={<Publish />}>
          {isNew ? 'Publisér' : 'Publisér endringer'}
        </Button>
        {!isNew && (
          <Button onClick={undoClick} aria-label="Undo" variant="contained" color="secondary" startIcon={<Undo />}>
            Angre endringer
          </Button>
        )}
        {isNew && <DeleteButton guide={guide} />}
        <PreviewButton guideId={guide.id} />
      </Stack>
      {undoModal.isOpen && <UndoChangesOnGuideDialog modal={undoModal} />}
      {publishModal.isOpen && <PublishGuideDialog modal={publishModal} />}
    </>
  );
}

function UpdateStatusButton({ guide }: { guide: NonNullable<Guide> }) {
  const archiveModal = useModal<string, unknown>({ data: guide.id });
  const [restoreGuide, { loading: restoring }] = useMutation(RESTORE_GUIDE, { variables: { input: { id: guide.id } } });
  const retractClick = () => archiveModal.open();

  switch (guide.status) {
    case GuideStatus.Active:
      return (
        <>
          <Button onClick={retractClick} aria-label="Archive" color="secondary" variant="contained" startIcon={<Archive />}>
            Trekk tilbake
          </Button>
          {archiveModal.isOpen && <ArchiveGuideDialog modal={archiveModal} />}
        </>
      );
    case GuideStatus.Expired:
      return (
        <Button
          onClick={() => restoreGuide()}
          disabled={restoring}
          aria-label="Restore"
          color="primary"
          variant="contained"
          title="Aktivering av anvisningen vil gjøre at den er en del av aktive anvisninger på web"
          startIcon={<Undo />}>
          Aktiver
        </Button>
      );
    default:
      return null;
  }
}

function DeleteButton({ guide }: { guide: NonNullable<Guide> }) {
  const modal = useModal<string, boolean>({ data: guide.id });
  const navigate = useNavigate();

  const deleteClick = async () => {
    const result = await modal.open();
    if (result) navigate('/guide');
  };

  return (
    <>
      <Button onClick={deleteClick} aria-label="Delete" color="secondary" variant="contained" startIcon={<DeleteForever />}>
        Slett
      </Button>
      {modal.isOpen && <DeleteGuideDialog modal={modal} />}
    </>
  );
}

export function PreviewButton({ guideId }: { guideId: string }) {
  const config = useConfig();
  const { data } = useQuery(GET_GUIDE, { variables: { id: guideId } });
  const guide = data?.guide;
  const [open, setOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [createLink, { loading: creating }] = useMutation(CREATE_SHAREABLE_LINK, { variables: { input: { id: guide?.id } } });
  const [deleteLink, { loading: deleting }] = useMutation(DELETE_SHAREABLE_LINK, {
    variables: { input: { id: guide?.id, token: guide?.shareablePreviewLink } },
  });

  if (!guide) return null;

  const hasShareableLink = guide.shareablePreviewLink != undefined;
  const url = `${config?.bksUrl}/dokument/${guide.docId}?version=preview`;
  const shareableUrl = `${url}&token=${guide.shareablePreviewLink}`;

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return;

    setOpen(false);
  };

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(shareableUrl).catch(function (err) {
        throw err !== undefined ? err : new DOMException('The request is not allowed', 'NotAllowedError');
      });
      setOpenSnackbar(true);
    }
  };

  return (
    <React.Fragment>
      <ButtonGroup variant="outlined" ref={anchorRef}>
        <Button
          aria-label="Go to preview"
          role="link"
          color="secondary"
          title={url}
          onClick={() => window.open(url, '_blank')}
          startIcon={<Visibility />}>
          Forhåndsvisning
        </Button>
        <Button color="secondary" size="small" onClick={() => setOpen(true)}>
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper sx={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {!hasShareableLink && (
                    <MenuItem
                      disabled={creating}
                      onClick={() => createLink()}
                      title="Lag en delbar lenke lenke til forhåndsvisningen. Alle med denne lenken vil ha tilgang til forhåndsvisningen. Lenken vil fungere i 14 dager før den slutter å virke.">
                      <ListItemIcon>
                        <IosShare fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Lag delbar lenke</ListItemText>
                    </MenuItem>
                  )}
                  {hasShareableLink && [
                    <MenuItem
                      key="copy"
                      title="Alle med denne lenken vil ha tilgang til forhåndsvisningen så lenge lenken er gyldig. 14 dager fra den ble laget."
                      onClick={() => copyToClipboard()}>
                      <ListItemIcon>
                        <ContentCopy fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Kopier delbar lenke</ListItemText>
                    </MenuItem>,
                    <MenuItem
                      key="delete"
                      disabled={deleting}
                      onClick={() => deleteLink()}
                      title="Når man sletter lenken vil alle som har denne miste sin tilgang til forhåndsvisningen med en gang.">
                      <ListItemIcon>
                        <DeleteForever fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Slett delbar lenke</ListItemText>
                    </MenuItem>,
                  ]}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={() => setOpenSnackbar(false)}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">Delbar lenke er kopiert</span>}
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={() => setOpenSnackbar(false)} size="large">
            <Close />
          </IconButton>,
        ]}
      />
    </React.Fragment>
  );
}

function ItemList({ items, width }: { items: Items; width?: number }) {
  return (
    <List>
      {Object.keys(items)
        .filter((key) => items[key])
        .map((key) => {
          const item = items[key];
          return (
            <ListItem sx={{ paddingTop: 2, paddingBottom: 2, alignItems: 'flex-start' }} key={key}>
              <ListLabel width={width}>{key}</ListLabel>
              <ListItemText>{item}</ListItemText>
            </ListItem>
          );
        })}
    </List>
  );
}

function ProjectLeaderText({ guide }: { guide: Guide }) {
  const [mutate] = useMutation(SET_PROJECT_LEADER);
  return (
    <EditableTextField
      value={guide.projectLeader ?? ''}
      TextFieldProps={{
        placeholder: 'Prosjektleder navn',
        multiline: true,
        variant: 'standard',
      }}
      onUpdate={async (value) => {
        await mutate({ variables: { input: { id: guide.id, projectLeader: value } } });
      }}
    />
  );
}

function Authors({ guide }: { guide: Guide }) {
  const [edit, setEdit] = useState(false);
  const [updateAuthorsOnGuide] = useMutation(UPDATE_AUTHORS);
  if (!guide) return null;

  const updateAuthors = (resources: string[]) => {
    updateAuthorsOnGuide({ variables: { input: { id: guide.id, subjectResourceIds: resources } } });
  };

  const any = guide.authors && guide.authors.length > 0;

  return (
    <Grid container alignItems="center">
      <Stack>
        {guide.authors?.map((author) => (
          <StyledLink key={author?.id} to={`/subjectResource/${author?.id}`}>
            {author?.name}
          </StyledLink>
        ))}
      </Stack>
      <IconButton onClick={() => setEdit(true)} style={{ padding: 5, marginLeft: any ? 16 : 0 }} size="large">
        <Edit fontSize="small" />
      </IconButton>
      {edit && <ResourcesSelectDialog selected={guide.authors?.map((x) => x?.id) ?? []} onClose={() => setEdit(false)} onSubmit={updateAuthors} />}
    </Grid>
  );
}
