import { gql } from "../__generated__";

gql(/* GraphQL */`
  fragment SubjectResourceItem on SubjectResourceModel {
    id
    name
    isInternal
    hasBeenInternal
    unitId
    isDeleted
    authorOfGuides
    authorInRevisions
    qualityAsuranceInRevisions
    notes
  }`);

export const All_SUBJECT_RESOURCES = gql(/* GraphQL */`
  query GetSubjectResources {
    resources: subjectResources {
      ...SubjectResourceItem
      numberOfActiveRevisions
    }
  }`);

export const SUBJECT_RESOURCES_BY_UNIT = gql(/* GraphQL */`
  query GetSubjectResourcesByUnit ($unitId: UUID!) {
    resources: subjectResources(where: { unitId: { eq: $unitId } }) {
      ...SubjectResourceItem
      numberOfActiveRevisions
    }
  }`);

export const SUBJECT_RESOURCE = gql(/* GraphQL */`
  query GetSubjectResource ($id: UUID!) {
    resource: subjectResource(id: $id) {
      ...SubjectResourceItem
      isDeletable
    }
  }`);

export const CREATE = gql(/* GraphQL */`
  mutation SubjectResourceAdd ($input: SubjectResourceAddInput!) {
    subjectResourceAdd(input: $input) {
      subjectResourceModel {
        ...SubjectResourceItem
      }
    }
  }`);

export const UPDATE_NAME = gql(/* GraphQL */`
  mutation SubjectResourceUpdateName ($input: SubjectResourceUpdateNameInput!) {
    subjectResourceUpdateName(input: $input) {
      subjectResourceModel {
        ...SubjectResourceItem
      }
    }
  }`);

export const UPDATE_NOTES = gql(/* GraphQL */`
  mutation SubjectResourceUpdateNotes ($input: SubjectResourceUpdateNotesInput!) {
    subjectResourceUpdateNotes(input: $input) {
      subjectResourceModel {
        ...SubjectResourceItem
      }
    }
  }`);

export const UPDATE_INTERNAL = gql(/* GraphQL */`
  mutation SubjectResourceUpdateInternal ($input: SubjectResourceUpdateInternalInput!) {
    subjectResourceUpdateInternal(input: $input) {
      subjectResourceModel {
        ...SubjectResourceItem
      }
    }
  }`);

export const UPDATE_HAS_BEEN_INTERNAL = gql(/* GraphQL */`
  mutation SubjectResourceUpdateHasBeenInternal ($input: SubjectResourceUpdateHasBeenInternalInput!) {
    subjectResourceUpdateHasBeenInternal(input: $input) {
      subjectResourceModel {
        ...SubjectResourceItem
      }
    }
  }`);

export const UPDATE_UNIT = gql(/* GraphQL */`
  mutation SubjectResourceUpdateUnit ($input: SubjectResourceUpdateUnitInput!) {
    subjectResourceUpdateUnit(input: $input) {
      subjectResourceModel {
        ...SubjectResourceItem
      }
    }
  }`);

export const DELETE = gql(/* GraphQL */`
  mutation SubjectResourceRemove ($input: SubjectResourceRemoveInput!) {
    subjectResourceRemove(input: $input) {
      subjectResourceModel {
        ...SubjectResourceItem
        isDeleted
      }
    }
  }`);

export const GET_CHANGES = gql(/* GraphQL */`
  query SubjectResourceGetChanges($id: UUID!, $first: Int) {
    changeModel: subjectResourceChanges(id: $id, first: $first) {
      totalCount
      nodes {
        id
        description
        createdAt
        createdBy
      }
    }
  }`);

export const GET_REVISIONS = gql(/* GraphQL */`
  query GetRevisionsBySubjectResource ($id: UUID!) {
    revisionModels: revisions(where: { or: [{ authors: { some: { eq: $id } } }, { professionQualityAssurers: { some: { eq: $id } } }] }) {
      id
      type
      progress
      guide {
        docName
        docTitle
      }
      createdAt
      completedAt
      professionQualityAssurers {
        id
        name
      }
      authors {
        id
        name
      }
    }
  }`);

export const GET_GUIDES = gql(/* GraphQL */`
  query GetGuideBySubjectResource ($id: UUID!) {
    guideModels: guides(where: { authors: { some: { eq: $id } } }) {
      id
      docName
      docTitle
      status
      lastPublishedAt
      mainCategory {
        title
      }
      hasChangesSinceLastPublish
      ongoingRevision {
        id
      }
    }
  }`);