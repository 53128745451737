import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useMutation } from '@apollo/client';
import { UPDATE_NOTICE } from './notices.graphql';
import { GetGuideQuery } from '../__generated__/graphql';
import { Modal } from '../dialogs/useModal';
import { Unpacked } from '../graphQLTypes/types';

type Notice = NonNullable<Unpacked<NonNullable<GetGuideQuery['guide']>['notices']>>;

export function UpdateGuideNoticeDialog({ modal }: { modal: Modal<Notice, unknown> }) {
  const notice = modal.data;
  const [title, setTitle] = useState(notice.title);
  const [text, setText] = useState(notice.notice);
  const [updateGuideNotice, { loading }] = useMutation(UPDATE_NOTICE, {
    variables: { input: { noticeId: notice.id, id: notice.guide?.id, title: title, text } },
  });

  const handleClose = () => {
    modal.close();
  };

  const handleOk = async () => {
    await updateGuideNotice();
    modal.close();
  };

  return (
    <div>
      <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Endre merknad</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="title"
            label="Tittel"
            type="text"
            multiline
            onChange={(ev) => setTitle(ev.target.value)}
            value={title}
            autoFocus
            fullWidth
          />

          <TextField
            margin="dense"
            name="notice"
            label="Merknad"
            type="text"
            multiline
            onChange={(ev) => setText(ev.target.value)}
            value={text}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Avbryt</Button>
          <Button disabled={loading} onClick={handleOk} variant="contained" color="primary">
            Endre
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
