import { gql } from '../__generated__';

gql(/* GraphQL */ `
  fragment PartnerItem on PartnerModel {
    id
    name
    address
    url
    guides
    isDeleted
  }
`);

export const GET_PARTNERS = gql(/* GraphQL */ `
  query GetPartners {
    partners(where: { isDeleted: { eq: false } }, order: { name: ASC }) {
      ...PartnerItem
    }
  }
`);

export const GET_PARTNER = gql(/* GraphQL */ `
  query GetPartner($id: UUID!) {
    partner(id: $id) {
      ...PartnerItem
    }
  }
`);

export const UPDATE_NAME = gql(/* GraphQL */ `
  mutation PartnerUpdateName($input: PartnerUpdateNameInput!) {
    partnerUpdateName(input: $input) {
      partnerModel {
        ...PartnerItem
      }
    }
  }
`);

export const UPDATE_ADDRESS = gql(/* GraphQL */ `
  mutation PartnerUpdateAddress($input: PartnerUpdateAddressInput!) {
    partnerUpdateAddress(input: $input) {
      partnerModel {
        ...PartnerItem
      }
    }
  }
`);

export const UPDATE_URL = gql(/* GraphQL */ `
  mutation PartnerUpdateUrl($input: PartnerUpdateUrlInput!) {
    partnerUpdateUrl(input: $input) {
      partnerModel {
        ...PartnerItem
      }
    }
  }
`);

export const DELETE = gql(/* GraphQL */ `
  mutation PartnerRemove($input: PartnerRemoveInput!) {
    partnerRemove(input: $input) {
      partnerModel {
        ...PartnerItem
      }
    }
  }
`);

export const GET_CHANGES = gql(/* GraphQL */ `
  query PartnerGetChanges($id: UUID!, $limit: Int) {
    changeModel: partnerChanges(id: $id, first: $limit) {
      totalCount
      nodes {
        id
        description
        createdAt
        createdBy
      }
    }
  }
`);

export const CREATE = gql(/* GraphQL */ `
  mutation PartnerAdd($input: PartnerAddInput!) {
    partnerAdd(input: $input) {
      partnerModel {
        ...PartnerItem
      }
    }
  }
`);

export const GET_GUIDES = gql(/* GraphQL */ `
  query PartnerGetGuides($id: UUID!) {
    partner(id: $id) {
      ...PartnerItem
    }
    guides: guides {
      id
      docName
      docTitle
      status
      lastPublishedAt
      mainCategory {
        title
      }
      hasChangesSinceLastPublish
      ongoingRevision {
        id
      }
    }
  }
`);
