import { PaletteColor } from '@mui/material';
import { FigureStatus } from '../../../__generated__/graphql';
import { theme } from '../../../theme';
import { formatDate } from '../../../Formatters';
import { Figure } from './figureHelper';

interface StatusStep {
  status: FigureStatus;
  label: string;
  color: PaletteColor;
}

interface StatusDetails {
  status: FigureStatus;
  comment: string;
  changedAt: string;
  changedBy: string;
}

export const steps: StatusStep[] = [
  { status: FigureStatus.Sketch, label: 'Skisse', color: theme.palette.error },
  { status: FigureStatus.ReadyForModelling, label: 'Klar til opptegning', color: theme.palette.warning },
  { status: FigureStatus.ApprovedByAuthor, label: 'Godkjent forfatter', color: theme.palette.info },
  { status: FigureStatus.Completed, label: 'Ferdig', color: theme.palette.success },
];

export const getCurrentStatusDetails = (figure: Figure) => {
  return figure?.status ? getStatusDetails(figure, figure?.status?.status) : undefined;
};

export const getStatusDetails = (figure: Figure, status: FigureStatus): StatusDetails | undefined => {
  const statusDetails = figure?.statuses?.find((s) => s?.status === status);
  if (!statusDetails) {
    return undefined;
  }

  return {
    status: statusDetails?.status,
    comment: statusDetails?.comment ?? '',
    changedAt: statusDetails?.changedAt,
    changedBy: statusDetails?.changedBy?.name ?? '',
  };
};

export const getLastChangedText = (details: StatusDetails) => {
  return `Sist endret ${formatDate(details.changedAt)} av ${details.changedBy}`;
};

export const getStepDetailsText = (step: StatusStep) => {
  return `Status: ${step.label}`;
};
