import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { KeyboardArrowDown, KeyboardArrowUp, Info, Add } from '@mui/icons-material';
import { Table, TableCell, TableHead, TableRow, Paper, Tooltip, Typography, FormControlLabel, Checkbox, Link } from '@mui/material';
import Fuse from 'fuse.js';
import { useDispatch, useSelector } from 'react-redux';
import { StyledLink, LimitedCell, EditedTableHead } from '../Components';
import { GuideStatusIcon } from '../guides/GuideStatusIcon';
import InfiniteScroll from 'react-infinite-scroller';
import { addRank, getNeedForRevisionValue } from './BacklogModel';
import { RevisionCell } from './RevisionCell';
import { Search, FilterProps } from './BacklogSearch';
import { RankCell } from './RankCell';
import { selectBacklogFilter } from './filterSlice';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { formatDateOnly } from '../Formatters';
import { GET_ALL } from './backlog.graphql';
import { ExcelExport } from './ExcelExport';
import { Criterion, selectCriterion, addIgnore, setIgnore, removeIgnore } from './criterionSlice';
import { SxProps, Theme } from '@mui/system';
import { TableCellProps } from '@mui/material/TableCell';
import { BacklogItem, BacklogItemWithRank, CriteriaType } from './types';
import { GuideStatus, NeedForRevision } from '../__generated__/graphql';
import { useAuth } from 'oidc-react';
import { StyledPaper } from '../theme';
import { useModal } from '../dialogs/useModal';
import { NeedForRevisionDialog, NeedForRevisionDialogInput } from './NeedForRevisionDialog';

const headerTextStyle = {
  fontWeight: 500,
  color: 'rgba(0, 0, 0, 0.54)',
};

const stickyHeaderStyle: SxProps<Theme> = {
  position: 'sticky',
  top: (theme) => theme.spacing(8),
  background: 'white',
};

const criterionColumnStyle = {
  display: 'table-cell',
  '@media(max-width: 1440px)': {
    display: 'none',
  },
};

const StyledEditedTableHead = styled(EditedTableHead)<TableCellProps>(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(8),
  background: 'white',
}));

export function Overview() {
  const auth = useAuth();
  const profile = auth.userData?.profile;
  const [sortDirectionNatural, setSortDirectionNatural] = useState(true);
  const filter: FilterProps = useSelector(selectBacklogFilter);
  const { ignoreCriteria }: Criterion = useSelector(selectCriterion);

  const { data, loading } = useQuery(GET_ALL, { fetchPolicy: 'cache-and-network' });

  const toDistictCategories = (items: BacklogItem[]): string[] => {
    const set = new Set<string>();
    items.forEach((x) => {
      if (x?.mainCategory?.title) {
        set.add(x.mainCategory.title);
      }
    });
    return Array.from(set).sort();
  };

  const categories = data?.guides && toDistictCategories(data.guides);
  const myCategories = data?.guides && toDistictCategories(data.guides.filter((x) => x!.owner?.subjectId === profile?.sub));
  const filteredItems = data?.guides && filterItems(data.guides, filter);
  const rankedItems = filteredItems && addRank(filteredItems, ignoreCriteria, !sortDirectionNatural);

  return (
    <StyledPaper>
      <Typography variant="h5">Planlegging</Typography>
      {rankedItems && <ExcelExport items={rankedItems!} />}
      {categories && myCategories && <Search allCategories={categories} myCategories={myCategories} />}
      {loading && <LoadingSpinner />}
      {rankedItems && (
        <BacklogTable items={rankedItems!} sortDirectionNatural={sortDirectionNatural} setSortDirectionNatural={setSortDirectionNatural} />
      )}
    </StyledPaper>
  );
}

const criterias: CriteriaType[] = ['edition', 'pageViews', 'notices', 'needForRevision'];

function BacklogTable({
  items,
  sortDirectionNatural,
  setSortDirectionNatural,
}: {
  items: BacklogItemWithRank[];
  sortDirectionNatural: boolean;
  setSortDirectionNatural(value: boolean): void;
}) {
  const dispatch = useDispatch();

  const { ignoreCriteria }: Criterion = useSelector(selectCriterion);

  const isPriorityParameterActive = (parameterId: CriteriaType) => {
    return ignoreCriteria.indexOf(parameterId) === -1;
  };

  const togglePriorityParameter = (parameterId: CriteriaType) => {
    let indexOf = ignoreCriteria.indexOf(parameterId);
    if (indexOf > -1) {
      dispatch(removeIgnore(parameterId));
    } else {
      dispatch(addIgnore(parameterId));
    }
  };

  const toggleAllCriteria = () => {
    dispatch(setIgnore(ignoreCriteria.length === 0 ? criterias : []));
  };

  return (
    <Table size="small" sx={{ position: 'relative' }}>
      <TableHead>
        <TableRow sx={{ height: '100%', padding: 'none' }}>
          <TableCell padding="none" sx={stickyHeaderStyle}>
            <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', float: 'left', fontSize: 14 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={sortDirectionNatural}
                    onChange={(e) => setSortDirectionNatural(e.target.checked)}
                    icon={<KeyboardArrowUp />}
                    checkedIcon={<KeyboardArrowDown />}
                    style={{ padding: 0 }}
                  />
                }
                label={
                  <Typography sx={headerTextStyle} variant="caption">
                    Score
                  </Typography>
                }
                style={{ margin: 0 }}
              />
            </div>
          </TableCell>
          <StyledEditedTableHead sx={stickyHeaderStyle}>Status</StyledEditedTableHead>
          <StyledEditedTableHead sx={stickyHeaderStyle}>Nummer</StyledEditedTableHead>
          <StyledEditedTableHead sx={stickyHeaderStyle}>Tittel</StyledEditedTableHead>
          <PriorityTableHeader checked={isPriorityParameterActive('edition')} onChange={() => togglePriorityParameter('edition')}>
            Utgave
          </PriorityTableHeader>
          <PriorityTableHeader
            checked={isPriorityParameterActive('pageViews')}
            onChange={() => togglePriorityParameter('pageViews')}
            title="Antall visninger siste året">
            Visninger
          </PriorityTableHeader>
          <PriorityTableHeader checked={isPriorityParameterActive('notices')} onChange={() => togglePriorityParameter('notices')}>
            Merknader
          </PriorityTableHeader>
          <PriorityTableHeader
            checked={isPriorityParameterActive('needForRevision')}
            onChange={() => togglePriorityParameter('needForRevision')}
            title="1. Kritisk 2. Høyt prioritert 3. Viktig 4. Må vurderes 5. Husk til neste gang">
            Prioritert
          </PriorityTableHeader>
          <StyledEditedTableHead sx={stickyHeaderStyle}>Oppgaver/Kommentarer</StyledEditedTableHead>
          <StyledEditedTableHead sx={stickyHeaderStyle} marginLeft={30}>
            Fagområde
          </StyledEditedTableHead>
          <StyledEditedTableHead sx={stickyHeaderStyle}>Sist endret</StyledEditedTableHead>
          <PriorityTableHeader title="Velg alle prioritetskriterier" checked={ignoreCriteria.length === 0} onChange={toggleAllCriteria}>
            Alle
          </PriorityTableHeader>
        </TableRow>
      </TableHead>
      <BacklogTableBody items={items} />
    </Table>
  );
}

function PriorityTableHeader({
  title,
  children,
  checked,
  onChange,
}: {
  title?: string;
  children: any;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}) {
  return (
    <TableCell align="right" padding="none" sx={{ ...stickyHeaderStyle, ...criterionColumnStyle }}>
      <FormControlLabel
        control={<Checkbox color="primary" checked={checked} onChange={onChange} />}
        label={
          <Typography noWrap sx={headerTextStyle} variant="caption">
            <span>{children}</span>&nbsp;
            {title && (
              <span>
                <Tooltip title={<div style={{ whiteSpace: 'pre-wrap' }}>{title}</div>}>
                  <Info style={{ fontSize: 12 }} />
                </Tooltip>
              </span>
            )}
          </Typography>
        }
      />
    </TableCell>
  );
}

function BacklogTableBody({ items }: { items: BacklogItemWithRank[] }) {
  const [numberOfItems, setNumberOfItems] = useState(50);

  return (
    <InfiniteScroll element="tbody" loadMore={() => setNumberOfItems(numberOfItems + 100)} hasMore={items.length > numberOfItems}>
      {items.slice(0, numberOfItems - 1).map((x) => {
        if (!x) return null;
        return (
          <TableRow key={x.id}>
            <TableCell style={{ padding: '0 16px 0 6px' }}>
              <RankCell item={x} />
            </TableCell>
            <LimitedCell>
              <ItemStatus item={x} />
            </LimitedCell>
            <TableCell>
              <StyledLink to={`/guide/${x.id}`}>{x.docName}</StyledLink>
            </TableCell>
            <LimitedCell maxWidth={300}>{x.docTitle}</LimitedCell>

            <TableCell align="right" padding="none" sx={{ ...criterionColumnStyle }}>
              {x.planningScores?.edition?.value}
            </TableCell>
            <TableCell align="right" padding="none" sx={{ ...criterionColumnStyle }}>
              <span
                title={`${x.docName?.startsWith('BVN') ? 'BVN' : 'BFS'}: nr ${(x.planningScores?.pageViews?.placement?.index ?? 0) + 1} av ${
                  x.planningScores?.pageViews?.placement?.total
                }`}>
                {Number(x.planningScores?.pageViews?.value).toLocaleString('no-NO')}
              </span>
            </TableCell>
            <TableCell align="right" padding="none" sx={{ ...criterionColumnStyle }}>
              <span
                title={x.notices
                  ?.filter((x) => !x?.deletedAt)
                  .map((n) => `${n!.notice}\n${formatDateOnly(n!.createdAt)}`)
                  .join('\n')}>
                {x.notices?.filter((x) => !x?.deletedAt).length}
              </span>
            </TableCell>
            <TableCell align="right" padding="none" sx={{ ...criterionColumnStyle }}>
              <span title={x.needForRevisionComment ?? undefined}>
                <MarkForRevision item={x} />
              </span>
            </TableCell>

            <LimitedCell marginLeft={20}>
              <span
                title={x.tasks
                  ?.map((t) => `${t?.sourceDescription}: ${t?.title}\n${t?.text}\n${t?.createdByName} ${formatDateOnly(t?.createdAt)}\n`)
                  .join('\n')}>
                {x.tasks?.length}
              </span>
              <span title="">/</span>
              <span title={x.comments?.map((c) => `${c!.comment}\n${c!.createdBy?.name} ${formatDateOnly(c!.createdAt)}\n`).join('\n')}>
                {x.comments?.length}
              </span>
            </LimitedCell>
            <LimitedCell marginLeft={30} maxWidth={200}>
              {x.mainCategory?.title}
            </LimitedCell>
            <LimitedCell maxWidth={200}>{x.lastPublishedAt && formatDateOnly(x.lastPublishedAt)}</LimitedCell>
            <TableCell>
              <RevisionStatus item={x} />
            </TableCell>
          </TableRow>
        );
      })}
    </InfiniteScroll>
  );
}

function ItemStatus({ item }: { item: BacklogItemWithRank }) {
  return (
    <GuideStatusIcon status={item.status!} hasRevision={item.ongoingRevision !== null} hasChangesSinceLastPublish={item.hasChangesSinceLastPublish} />
  );
}

function RevisionStatus({ item }: { item: NonNullable<BacklogItem> }) {
  return <RevisionCell guideId={item.id} revisionId={item.ongoingRevision?.id} />;
}

function filterItems(
  items: BacklogItem[],
  { searchTerm, showOngoingRevisions, showNewGuides, showPublishedGuides, showBvn, showBfs, categories }: FilterProps,
) {
  const options = {
    shouldSort: false,
    threshold: 0,
    ignoreLocation: true,
    minMatchCharLength: 3,
    keys: ['docName', 'docNumber', 'docTitle', 'mainCategory.title', 'owner.name'],
  };

  const filteredGuides = items.filter(
    (x) =>
      x &&
      (showNewGuides || x.status !== GuideStatus.Planned) &&
      (showOngoingRevisions || !(x.ongoingRevision && x.status === GuideStatus.Active)) &&
      (showPublishedGuides || !(!x.ongoingRevision && x.status === GuideStatus.Active)) &&
      (showBfs || x.docName?.startsWith('BVN')) &&
      (showBvn || !x.docName?.startsWith('BVN')) &&
      (categories.length === 0 || categories.indexOf(x.mainCategory?.title ?? '') > -1),
  );

  if (!searchTerm) {
    return filteredGuides;
  }

  const fuse = new Fuse(filteredGuides, options);
  return fuse.search(searchTerm).map((x) => x.item);
}

export function MarkForRevision({ item }: { item: NonNullable<BacklogItem> }) {
  const modal = useModal<NeedForRevisionDialogInput, unknown>({ data: item });
  const openPriorityDialog = () => {
    modal.open(item);
  };

  return (
    <>
      <Link
        onClick={openPriorityDialog}
        title={item.needForRevision ? (item.needForRevisionComment ?? undefined) : 'Angi behov for revisjon'}
        style={{ padding: 5 }}
        component="button"
        variant="button">
        {item.needForRevision && item.needForRevision !== NeedForRevision.Undefined ? (
          <Typography style={{ width: 20, fontSize: 14 }}>{getNeedForRevisionValue(item.needForRevision)}</Typography>
        ) : (
          <Add fontSize="small" />
        )}
      </Link>
      {modal.isOpen && <NeedForRevisionDialog modal={modal} />}
    </>
  );
}
