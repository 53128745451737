import React, { useState } from 'react';
import { Table, TableHead, TableRow, TableBody, IconButton, TableCell } from '@mui/material';
import { StyledLink, LimitedCellResourceWithFontWeight } from '../Components';
import { formatDate } from '../Formatters';
import { roles, Revision, getRoles } from './Overview';
import { writeFile, utils } from 'xlsx';
import { ColWidths, TableCellEllipsis, TableCellSort } from '../components/TableComponents';
import { RevisionColumn, RoleHeaders, getCompareFn } from './RoleHeaders';
import { SortDirection } from '@mui/material/TableCell';
import { RevisionRoles } from '../__generated__/graphql';

interface Props {
  revisions: Revision[];
  selectedUsers: string[];
}

export function ResourceTable({ revisions, selectedUsers }: Props) {
  const [orderBy, setOrderBy] = useState<RevisionColumn | false>(false);
  const [direction, setDirection] = useState<SortDirection>(false);

  const handleSortClick = (key: RevisionColumn) => {
    if (orderBy === key) {
      setDirection(direction === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(key);
      setDirection('asc');
    }
  };

  const sortedRevisions = [...revisions].sort(orderBy ? getCompareFn(orderBy, direction) : getDefaultComparer(selectedUsers));

  const ColumnHeader = ({ colKey, inputProps, children }: { colKey: RevisionColumn; inputProps?: any; children?: any }) => (
    <TableCellSort inputProps={{ ...inputProps }} active={orderBy === colKey} sortDirection={direction} onClick={() => handleSortClick(colKey)}>
      {children}
    </TableCellSort>
  );

  return (
    <>
      <ExcelExport revisions={sortedRevisions} />
      <Table size="small" sx={{ position: 'relative' }}>
        <ColWidths widths={[60, null, null, null, null, null, null, null, null, null, 50, null, null, 50]}></ColWidths>
        <TableHead>
          <TableRow>
            <ColumnHeader colKey="number" inputProps={{ style: { maxWidth: 85 } }}>
              Nummer
            </ColumnHeader>
            <ColumnHeader colKey="title">Tittel</ColumnHeader>
            <RoleHeaders />
            <ColumnHeader colKey="mainCategory">Fagområde</ColumnHeader>
            <ColumnHeader colKey="scheduledEndDate" inputProps={{ style: { maxWidth: 75 } }}>
              Publisering
            </ColumnHeader>
            <ColumnHeader colKey="progress" inputProps={{ style: { maxWidth: 70 } }}>
              Fremdrift
            </ColumnHeader>
            <ColumnHeader colKey="currentPhase">Fase</ColumnHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {}
          {sortedRevisions.map((r) => (
            <TableRow key={r?.id}>
              <TableCell style={{ paddingRight: 0 }}>
                <StyledLink to={`/revision/${r?.id}`}>{r?.guide?.docName}</StyledLink>
              </TableCell>
              <TableCellEllipsis title={r?.guide?.docTitle} maxWidth={200}>
                {r?.guide?.docTitle}
              </TableCellEllipsis>
              <Roles revision={r} selectedUsers={selectedUsers} />
              <TableCellEllipsis title={r?.guide?.mainCategory?.title}>{r?.guide?.mainCategory?.title}</TableCellEllipsis>
              <TableCell>{formatDate(r?.scheduledEndDate, 'MMM YYYY')}</TableCell>
              <TableCell>{`${r?.progress}`}</TableCell>
              <TableCellEllipsis>{r?.currentPhase}</TableCellEllipsis>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

function Roles({ revision, selectedUsers }: { revision: Revision; selectedUsers: string[] }) {
  return (
    <>
      {getRoles(revision).map((r) => {
        let fontWeight = selectedUsers.some((u) => u === r.userId) ? 'bold' : 'inherit';
        return (
          <LimitedCellResourceWithFontWeight key={r.role} fontWeight={fontWeight} title={r.name} maxWidth={75}>
            {r.shortName}
          </LimitedCellResourceWithFontWeight>
        );
      })}
    </>
  );
}

function ExcelExport({ revisions }: { revisions: Revision[] }) {
  return (
    <IconButton
      sx={{ position: 'absolute', right: (theme) => theme.spacing(4), top: (theme) => theme.spacing(12) }}
      onClick={() => exportFile(revisions)}
      title="Eksport til Excel"
      size="large">
      <img src="/icons8-microsoft-excel.svg" style={{ width: 24, height: 24 }} alt="Excel icon" />
    </IconButton>
  );
}

function exportFile(revisions: Revision[]) {
  const headings: string[] = [
    'Anvisning (under revisjon)',
    'Fagområde',
    ...Object.entries(roles).map(([, value]) => value.label),
    'Publisering',
    'Fremdrift (%)',
    'Fase',
  ];

  const data = revisions.map((rev) => [
    rev?.guide?.docTitle,
    rev?.guide?.mainCategory?.title,
    ...getRoles(rev).map((r) => r.name),
    rev?.scheduledEndDate ? new Date(rev.scheduledEndDate) : null,
    rev?.progress,
    rev?.currentPhase,
  ]);
  const input = [headings, ...data];
  const sheet = utils.aoa_to_sheet(input);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, sheet, 'Ressurser');
  writeFile(workbook, 'ressursoversikt.xlsx');
}

export function getDefaultComparer(selectedUsers: string[]): (a: Revision, b: Revision) => number {
  if (selectedUsers.length === 0) {
    return getCompareFn('number', 'asc');
  }

  return getOrderByRolesCompareFn(selectedUsers);
}

function getOrderByRolesCompareFn(selectedUsers: string[]): (a: Revision, b: Revision) => number {
  return (a: Revision, b: Revision) => {
    if (a === null || b === null) {
      return 0;
    }
    const rankA = getRoleRank(a, selectedUsers);
    const rankB = getRoleRank(b, selectedUsers);

    return rankB - rankA;
  };
}

function getRoleRank(revision: Revision, selectedUsers: string[]): number {
  var roleValues = revision?.roles?.filter((r) => selectedUsers.some((u) => r?.role && u === r?.user?.subjectId)).map((r) => getRoleValue(r!.role));
  if (selectedUsers.some((u) => u === revision?.guide?.owner?.subjectId)) {
    roleValues?.push(getRoleValue(RevisionRoles.Owner));
  }

  return roleValues?.map((value) => Math.pow(2, value)).reduce((partialSum, a) => partialSum + a, 0) ?? 0;
}
function getRoleValue(role: RevisionRoles) {
  switch (role) {
    case RevisionRoles.ProjectLead:
      return 6;
    case RevisionRoles.InteralQualityAssurer:
      return 5;
    case RevisionRoles.PeerReviewer:
      return 4;
    case RevisionRoles.Dac:
      return 3;
    case RevisionRoles.PublishingEditor:
      return 2;
    case RevisionRoles.Owner:
      return 1;
    default:
      return 0;
  }
}
