import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import Alert from '@mui/material/Alert';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { UPDATE } from './categories.graphql';
import { GetCategoryQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { Modal } from '../dialogs/useModal';

type Category = Unpacked<GetCategoryQuery['category']>;

export function EditCategoryDialog({ modal }: { modal: Modal<Category, undefined> }) {
  const category = modal.data;
  const [title, setTitle] = useState(category?.title);
  const [description, setDescription] = useState(category?.description);
  const [updateCategory, { loading }] = useMutation(UPDATE, { variables: { input: { id: category?.id, title, description } } });

  const handleClose = () => modal.close();

  const handleOk = async () => {
    await updateCategory();
    handleClose();
  };

  const canSave = !loading && title && title.length > 0;

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Endre tekst</DialogTitle>
      <DialogContent>
        <Alert severity="warning">Merk: Endringer på denne teksten vil oppdatere teksten på Byggforsk.no umiddelbart.</Alert>
        <TextField
          name="title"
          margin="dense"
          label="Tittel"
          type="text"
          error={title?.length === 0}
          autoFocus
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
        />
        <TextField
          name="description"
          margin="dense"
          label="Beskrivelse"
          type="text"
          multiline
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Avbryt</Button>
        <Button onClick={handleOk} variant="contained" color="primary" disabled={!canSave}>
          Endre
        </Button>
      </DialogActions>
    </Dialog>
  );
}
