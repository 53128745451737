import React, { FC } from 'react';
import { Hearing } from './hearing.graphql';
import { Button, Chip, Stack } from '@mui/material';
import { formatDate, formatDateOnly } from '../Formatters';
import { HearingDialog } from './HearingDialog';
import { useHearingDialog } from './hearingDialogHooks';
import { StyledLink } from '../Components';
import { Campaign, Edit } from '@mui/icons-material';

export const HearingInfo: FC<{ hearing?: Hearing; guideId: string }> = ({ hearing, guideId }) => {
  const { modal, openHearingDialog, isCreatingHearing } = useHearingDialog(guideId);
  return (
    <Stack gap={2}>
      {modal.isOpen && <HearingDialog {...modal} />}

      <div>
        <HearingDescription hearing={hearing} />
      </div>
      <div>
        <Button
          startIcon={hearing?.startedAt ? <Edit /> : <Campaign />}
          disabled={isCreatingHearing}
          variant="outlined"
          onClick={() => openHearingDialog(hearing)}>
          {hearing?.startedAt ? 'Rediger' : 'Ny høring'}
        </Button>
      </div>

      {hearing && hearing.startedAt && (
        <div>
          <StyledLink style={{ marginLeft: '10px' }} to={`/guide-editor/${guideId}?version=${hearing?.guideContent?.version}&comments=true`}>
            Gå til svar
          </StyledLink>
        </div>
      )}
    </Stack>
  );
};

export const HearingDescription: FC<{ hearing?: Hearing }> = ({ hearing }) => {
  if (!hearing || !hearing.startedAt) {
    return <div>Ingen aktiv høring</div>;
  }
  if (!hearing.endedAt) {
    return (
      <Stack gap={1}>
        <HearingTypeChip hearing={hearing} />
        <div>
          Høring på versjon {hearing.guideContent?.version} startet {formatDate(hearing.startedAt)}
        </div>
      </Stack>
    );
  }
  return (
    <div>
      <HearingTypeChip hearing={hearing} /> Høring på versjon {hearing.guideContent?.version} - {formatDateOnly(hearing.startedAt)} -{' '}
      {formatDateOnly(hearing.endedAt)}
    </div>
  );
};

export const HearingTypeChip: FC<{ hearing: Hearing }> = ({ hearing }) => {
  if (hearing.isExternal) {
    return <Chip sx={{ width: '62px', marginRight: '5px' }} size="small" label="Ekstern" color="warning" />;
  }
  return <Chip sx={{ width: '62px', marginRight: '5px' }} size="small" label="Intern" color="info" />;
};
