import React from 'react';
import { Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { GuideStatusIcon } from '../guides/GuideStatusIcon';
import { formatDate } from '../Formatters';
import { StyledLink } from '../Components';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { GET_LAST_CHANGED_GUIDES } from './home.graphql';
import { useAuth } from 'oidc-react';
import { StyledPaper } from '../theme';
import { Unpacked } from '../graphQLTypes/types';
import { GetMyLastChangedGuidesQuery } from '../__generated__/graphql';

type Guide = Unpacked<GetMyLastChangedGuidesQuery['guides']>;

export function MyLastChangedGuides() {
  const auth = useAuth();
  const { loading, data } = useQuery(GET_LAST_CHANGED_GUIDES, {
    variables: { userId: auth.userData?.profile.sub },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <StyledPaper>
      <Typography variant="h5" component="h3">
        Mine sist endrede anvisninger
      </Typography>
      {loading && <LoadingSpinner />}
      {data?.guides && (
        <List>
          {data.guides.slice(0, 5).map((g) => {
            if (g) return <GuideListItem key={g?.id} guide={g} />;
          })}
        </List>
      )}
      <StyledLink to="/guide?mine=1">Se alle mine anvisninger</StyledLink>
    </StyledPaper>
  );
}

function GuideListItem({ guide }: { guide: NonNullable<Guide> }) {
  return (
    <ListItem key={guide.id} disableGutters={true} dense={true} alignItems="flex-start">
      <ListItemIcon>
        <GuideStatusIcon
          status={guide.status!}
          hasChangesSinceLastPublish={guide.hasChangesSinceLastPublish}
          hasRevision={guide.ongoingRevision !== null}
        />
      </ListItemIcon>
      <ListItemText
        primary={
          <StyledLink to={`/guide/${guide.id}`}>
            {guide.docName} - {guide.docTitle}
          </StyledLink>
        }
        secondary={`Sist endret ${formatDate(guide.lastChangedAt)}`}
      />
    </ListItem>
  );
}
