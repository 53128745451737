import React from 'react';
import { BackButton, StyledAnchor, StyledLink } from '../Components';
import TaskTable from '../tasks/TaskTable';

import { AddAlert } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CircularProgress, List, ListItem, ListItemText, Grid2 as Grid } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import { GET_PARAGRAPH } from './paragraphs.graphql';
import { StyledPaper } from '../theme';
import { useModal } from '../dialogs/useModal';
import { AddTaskDialog, AddTaskDialogProps, AddTaskDialogResult } from '../tasks/AddTaskDialog';
import { ADD_PARAGRAPH_TASK } from '../tasks/task.graphql';

export function Details() {
  const { id } = useParams();
  const { data, loading, refetch } = useQuery(GET_PARAGRAPH, { variables: { id } });
  const addTaskModal = useModal<AddTaskDialogProps, AddTaskDialogResult>({ data: {} });
  const [addTask, { loading: addingTask }] = useMutation(ADD_PARAGRAPH_TASK);
  const paragraph = data?.paragraph;

  const addParagraphNotice = async () => {
    const result = await addTaskModal.open();
    if (result) {
      await addTask({ variables: { input: { ...result, paragraphId: paragraph?.id } } });
      refetch();
    }
  };

  if (loading || !data) return <LoadingSpinner />;
  if (!paragraph) return <Typography variant="h5">Fant ikke paragraf</Typography>;

  return (
    <Grid container>
      <Grid size={{ xs: 12 }}>
        <Typography variant="h5">
          <BackButton />
          {paragraph.number} {paragraph.title}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <StyledPaper>
          <List>
            <ListItem>
              <Typography variant="subtitle1">Forskrift</Typography>
              <ListItemText style={{ marginLeft: 20 }} primary={paragraph.regulation} />
            </ListItem>
            <ListItem>
              <Typography variant="subtitle1">Dibk</Typography>
              <ListItemText
                style={{ marginLeft: 20 }}
                primary={
                  <StyledAnchor href={paragraph.link ?? ''} target="blank">
                    {' '}
                    Gå til forskrift
                  </StyledAnchor>
                }
              />
            </ListItem>
          </List>
          {paragraph.guides && paragraph.guides.length > 0 && (
            <Button
              disabled={addingTask}
              onClick={addParagraphNotice}
              aria-label="Add task"
              variant="outlined"
              startIcon={addingTask ? <CircularProgress size={25} /> : null}>
              <AddAlert /> Varsle endring
            </Button>
          )}
        </StyledPaper>
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <StyledPaper>
          <Typography variant="h5" component="h3">
            Anvisninger
          </Typography>
          <List>
            {paragraph.guides &&
              paragraph.guides.map((x) => {
                if (!x) return null;
                return (
                  <ListItem key={x.id}>
                    <ListItemText>
                      <StyledLink to={`/guide/${x.id}`}>
                        {x.docName} - {x.docTitle}
                      </StyledLink>
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
        </StyledPaper>
      </Grid>
      {paragraph.tasks && paragraph.tasks.length > 0 && (
        <Grid size={{ xs: 12 }}>
          <StyledPaper>
            <Typography variant="h5" component="h3">
              Oppgaver
            </Typography>
            <TaskTable tasks={paragraph.tasks} />
          </StyledPaper>
        </Grid>
      )}
      {addTaskModal.isOpen && <AddTaskDialog modal={addTaskModal} />}
    </Grid>
  );
}
