import React, { FC, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Checkbox, Typography } from '@mui/material';
import { Button } from '@mui/material';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { sumScore } from './BacklogModel';
import { Chart } from './Chart';
import { GET_ALL } from './backlog.graphql';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { GetAllBacklogItemsQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { CriteriaType } from './types';
import { Modal } from '../dialogs/useModal';
import { func } from 'prop-types';

export interface RankDetailsDialogProps {
  guideId: string;
  docName: string | null | undefined;
  docTitle: string | null | undefined;
  ignoreCriteria?: CriteriaType[];
}

type BacklogItem = Unpacked<GetAllBacklogItemsQuery['guides']>;

export const RankDetailsDialog: FC<{ modal: Modal<RankDetailsDialogProps, unknown> }> = ({ modal }) => {
  const { guideId, docName, docTitle, ignoreCriteria } = modal.data;
  const { data, loading } = useQuery(GET_ALL);
  return (
    <Dialog open={true} onClose={() => modal.close()} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
      <DialogTitle id="form-dialog-title">
        <Typography variant="h6" component="div" noWrap style={{ maxWidth: 900 }}>{`Prioriteringskriterier for ${docName} - ${docTitle}`}</Typography>
      </DialogTitle>
      <DialogContent>
        {loading && <LoadingSpinner />}
        {data?.guides && <Details itemId={guideId} items={data.guides} ignoreCriteria={ignoreCriteria ?? []} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => modal.close()} variant="contained">
          Lukk
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Details: FC<{ itemId: string; items: BacklogItem[]; ignoreCriteria: CriteriaType[] }> = ({ itemId, items, ignoreCriteria }) => {
  const [localIgnoreCriteria, setLocalIgnoreCriteria] = useState<CriteriaType[]>(ignoreCriteria);
  const item = items.find((x) => x!.id === itemId)!;

  const isPriorityParameterActive = (id: CriteriaType) => !localIgnoreCriteria.includes(id);
  const togglePriorityParameter = (id: CriteriaType) =>
    setLocalIgnoreCriteria(isPriorityParameterActive(id) ? [...localIgnoreCriteria, id] : localIgnoreCriteria.filter((x) => x !== id));

  const planningscore = item?.planningScores;
  if (!planningscore) return null;

  return (
    <>
      <Chart items={items} ignoreCriteria={localIgnoreCriteria} currentItem={item} />
      <Table padding="none">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Navn</TableCell>
            <TableCell align="right">Verdi</TableCell>
            <TableCell align="right">Vekting</TableCell>
            <TableCell align="right">Poengsum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <PlanningscoreRow
            criteria="edition"
            planningscore={planningscore}
            checked={isPriorityParameterActive('edition')}
            onChange={() => togglePriorityParameter('edition')}
          />
          <PlanningscoreRow
            criteria="pageViews"
            planningscore={planningscore}
            checked={isPriorityParameterActive('pageViews')}
            onChange={() => togglePriorityParameter('pageViews')}
          />
          <PlanningscoreRow
            criteria="notices"
            planningscore={planningscore}
            checked={isPriorityParameterActive('notices')}
            onChange={() => togglePriorityParameter('notices')}
          />
          <PlanningscoreRow
            criteria="needForRevision"
            planningscore={planningscore}
            checked={isPriorityParameterActive('needForRevision')}
            onChange={() => togglePriorityParameter('needForRevision')}
          />
          <TableRow>
            <TableCell>
              <b>Totalt</b>
            </TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell align="right" sx={{ fontWeight: 'bold' }}>
              {sumScore(item, localIgnoreCriteria)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

type Planningscore = NonNullable<Unpacked<NonNullable<BacklogItem>['planningScores']>>;
const PlanningscoreRow: FC<{ criteria: CriteriaType; planningscore: Planningscore; checked: boolean; onChange(): void }> = ({
  criteria,
  planningscore,
  checked,
  onChange,
}) => {
  const planningItem = planningscore[criteria];
  if (!planningItem) return null;
  return (
    <TableRow>
      <TableCell>
        <Checkbox color="primary" checked={checked} onChange={onChange} />
      </TableCell>
      <TableCell>{criteriaTypeToLabel(criteria)}</TableCell>
      <TableCell align="right">{planningItem.value}</TableCell>
      <TableCell align="right">{planningItem.boost}</TableCell>
      <TableCell align="right">{planningItem.score}</TableCell>
    </TableRow>
  );
};

function criteriaTypeToLabel(criteria: CriteriaType): string {
  switch (criteria) {
    case 'edition':
      return 'Utgave';
    case 'pageViews':
      return 'Visninger';
    case 'notices':
      return 'Merknader';
    case 'needForRevision':
      return 'Prioritert';
    default:
      return criteria;
  }
}
