import FormatColorFill from '@mui/icons-material/FormatColorFill';
import {
  DeleteColumn,
  DeleteRow,
  InsertColumnLeft,
  InsertColumnRight,
  InsertRowBottom,
  InsertRowTop,
  LayoutColumnFill,
  LayoutRowFill,
  MergeCellsHorizontal,
  SplitCellsHorizontal,
} from 'mui-tiptap/icons';
import { useRichTextEditorContext, MenuDivider, MenuButton, MenuControlsContainer } from 'mui-tiptap';
import React, { useMemo } from 'react';
import { BorderAll, BorderBottom, BorderClear, BorderLeft, BorderRight, BorderTop, WidthFull, WidthNormal } from '@mui/icons-material';
import { getSelectedNodeAttributes } from '../tiptapHelper';

export type TableMenuControlsProps = {
  /** Class applied to the root controls container element. */
  className?: string;
  /**
   * Override the default labels for any of the menu buttons. For any value that
   * is omitted in this object, it falls back to the default content.
   */
  labels?: {
    insertColumnBefore?: string;
    insertColumnAfter?: string;
    deleteColumn?: string;
    insertRowAbove?: string;
    insertRowBelow?: string;
    deleteRow?: string;
    mergeCells?: string;
    splitCell?: string;
    toggleHeaderRow?: string;
    toggleHeaderColumn?: string;
    toggleHeaderCell?: string;
    deleteTable?: string;
  };
};

/**
 * Renders all of the controls for manipulating a table in a Tiptap editor
 * (add or delete columns or rows, merge cells, etc.).
 */
export default function TableMenuControls({ className, labels }: TableMenuControlsProps) {
  const editor = useRichTextEditorContext();
  const attrs = useMemo(() => (editor?.state.selection ? getSelectedNodeAttributes(editor.state.selection) : {}), [editor?.state.selection]);
  return (
    <MenuControlsContainer className={className}>
      <MenuButton
        tooltipLabel={'Sett inn kolonne før'}
        IconComponent={InsertColumnLeft}
        onClick={() => editor?.chain().focus().addColumnBefore().run()}
        disabled={!editor?.can().addColumnBefore()}
      />

      <MenuButton
        tooltipLabel={'Sett inn kolonne etter'}
        IconComponent={InsertColumnRight}
        onClick={() => editor?.chain().focus().addColumnAfter().run()}
        disabled={!editor?.can().addColumnAfter()}
      />

      <MenuButton
        tooltipLabel={'Slett kolonne'}
        IconComponent={DeleteColumn}
        onClick={() => editor?.chain().focus().deleteColumn().run()}
        disabled={!editor?.can().deleteColumn()}
      />

      <MenuDivider />

      <MenuButton
        tooltipLabel={'Sett inn rad over'}
        IconComponent={InsertRowTop}
        onClick={() => editor?.chain().focus().addRowBefore().run()}
        disabled={!editor?.can().addRowBefore()}
      />

      <MenuButton
        tooltipLabel={'Sett inn rad under'}
        IconComponent={InsertRowBottom}
        onClick={() => editor?.chain().focus().addRowAfter().run()}
        disabled={!editor?.can().addRowAfter()}
      />

      <MenuButton
        tooltipLabel={'Slett rad'}
        IconComponent={DeleteRow}
        onClick={() => editor?.chain().focus().deleteRow().run()}
        disabled={!editor?.can().deleteRow()}
      />

      <MenuDivider />

      <MenuButton
        tooltipLabel={'Slå sammen celler'}
        IconComponent={MergeCellsHorizontal}
        onClick={() => editor?.chain().focus().mergeCells().run()}
        disabled={!editor?.can().mergeCells()}
      />

      <MenuButton
        tooltipLabel={'Splitt celler'}
        IconComponent={SplitCellsHorizontal}
        onClick={() => editor?.chain().focus().splitCell().run()}
        disabled={!editor?.can().splitCell()}
      />

      <MenuDivider />

      <MenuButton
        tooltipLabel={'Venstre kantlinje'}
        IconComponent={BorderLeft}
        selected={attrs?.borderLeft}
        onClick={() => editor?.chain().focus().setCellAttribute('borderLeft', !attrs?.borderLeft).run()}
        disabled={!attrs}
      />
      <MenuButton
        tooltipLabel={'Høyre kantlinje'}
        IconComponent={BorderRight}
        selected={attrs?.borderRight}
        onClick={() => editor?.chain().focus().setCellAttribute('borderRight', !attrs?.borderRight).run()}
        disabled={!attrs}
      />
      <MenuButton
        tooltipLabel={'Øvre kantlinje'}
        IconComponent={BorderTop}
        selected={attrs?.borderTop}
        onClick={() => editor?.chain().focus().setCellAttribute('borderTop', !attrs?.borderTop).run()}
        disabled={!attrs}
      />
      <MenuButton
        tooltipLabel={'Nedre kantlinje'}
        IconComponent={BorderBottom}
        selected={attrs?.borderBottom}
        onClick={() => editor?.chain().focus().setCellAttribute('borderBottom', !attrs?.borderBottom).run()}
        disabled={!attrs}
      />

      <MenuButton
        tooltipLabel={'Alle kantlinjer'}
        IconComponent={BorderAll}
        onClick={() =>
          editor
            ?.chain()
            .focus()
            .setCellAttribute('borderLeft', true)
            .setCellAttribute('borderRight', true)
            .setCellAttribute('borderTop', true)
            .setCellAttribute('borderBottom', true)
            .run()
        }
        disabled={!attrs}
      />

      <MenuButton
        tooltipLabel={'Ingen kantlinjer'}
        IconComponent={BorderClear}
        onClick={() =>
          editor
            ?.chain()
            .focus()
            .setCellAttribute('borderLeft', false)
            .setCellAttribute('borderRight', false)
            .setCellAttribute('borderTop', false)
            .setCellAttribute('borderBottom', false)
            .run()
        }
        disabled={!attrs}
      />

      <MenuDivider />

      <MenuButton
        tooltipLabel={'Vis/skjul header-rad'}
        IconComponent={LayoutRowFill}
        onClick={() => editor?.chain().focus().toggleHeaderRow().run()}
        disabled={!editor?.can().toggleHeaderRow()}
      />

      <MenuButton
        tooltipLabel={'Vis/skjul header-kolonne'}
        IconComponent={LayoutColumnFill}
        onClick={() => editor?.chain().focus().toggleHeaderColumn().run()}
        disabled={!editor?.can().toggleHeaderColumn()}
      />

      <MenuButton
        tooltipLabel={'Vis/skjul header-celle'}
        IconComponent={FormatColorFill}
        onClick={() => editor?.chain().focus().toggleHeaderCell().run()}
        disabled={!editor?.can().toggleHeaderCell()}
        selected={editor?.isActive('tableHeader') ?? false}
      />
      <MenuDivider />
      <MenuButton
        tooltipLabel={'Normal bredde'}
        IconComponent={WidthNormal}
        onClick={() => editor?.chain().focus().setTableWidth(false).run()}
        selected={!editor?.getAttributes('table')['fullWidth']}
      />
      <MenuButton
        tooltipLabel={'Full bredde'}
        IconComponent={WidthFull}
        onClick={() => editor?.chain().focus().setTableWidth(true).run()}
        selected={editor?.getAttributes('table')['fullWidth'] ?? false}
      />
    </MenuControlsContainer>
  );
}
