import React from 'react';

export function Notice({
  titleAccess,
  fontSize = 'default',
  style,
  color,
  className,
}: {
  titleAccess?: string;
  fontSize?: FontSize;
  style?: any;
  color?: boolean;
  className?: string;
}) {
  const remSize = getRemSizeFromFontSize(fontSize);
  const colorDark = color ? '#fd5' : '#757575';
  const colorLight = color ? '#fea' : '#e0e0e0';
  const prefix = color ? 'color' : 'gray';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 832.55963 832.55963"
      width="1em"
      height="1em"
      fontSize={remSize}
      style={style}
      className={className}
    >
      <defs>
        <Gradients colorDark={colorDark} colorLight={colorLight} namePrefix={prefix} />
        <filter id="c">
          <feGaussianBlur stdDeviation="1.78854" />
        </filter>
        <filter id="d">
          <feGaussianBlur stdDeviation="3.57708" />
        </filter>
        <filter id="e">
          <feGaussianBlur stdDeviation="15.19269" />
        </filter>
      </defs>
      <g transform="translate(1111.3 -589.51)">
        <path d="m-1074.9 625.98h759.63v759.63h-759.63z" filter="url(#e)" />
        <path d="m-1082.9 615.98h759.63v759.63h-759.63z" fill={`url(#${prefix}-a)`} />
        <path d="m-1082.9 615.98h759.63v125.26h-759.63z" fill={colorLight} />
        <path
          d="m-500.49 1197.6 0.0442 1.1932c17.96 57.277 21.099 118.21 9.3913 176.75l0.0442 0.9281 84.698-84.698 84.654-84.654c-59.071 11.942-120.6 8.7788-178.37-9.5018l-0.46401-0.022z"
          filter="url(#d)"
        />
        <path d="m-1082.9 615.97v759.66h591.31l168.31-168.28v-591.38h-759.62z" fill={`url(#${prefix}-a)`} />
        <path
          transform="matrix(.97306 0 0 .97306 -11.133 34.657)"
          d="m-502.63 1196.9 0.0442 1.1932c17.96 57.277 21.099 118.21 9.3913 176.75l0.0442 0.9281 84.698-84.698 84.654-84.654c-59.071 11.942-120.6 8.7788-178.37-9.5018l-0.46401-0.022z"
          filter="url(#c)"
        />
        <path
          d="m-502.63 1196.9 0.0442 1.1932c17.96 57.277 21.099 118.21 9.3913 176.75l0.0442 0.9281 84.698-84.698 84.654-84.654c-59.071 11.942-120.6 8.7788-178.37-9.5018l-0.46401-0.022z"
          fill={`url(#${prefix}-b)`}
        />
      </g>
      <title>{titleAccess}</title>
    </svg>
  );
}

function Gradients({ colorDark, colorLight, namePrefix }: { colorDark: string; colorLight: string; namePrefix: string }) {
  return (
    <>
      <linearGradient id={`${namePrefix}-a`} x1="-751.55" x2="-747.51" y1="1379.7" y2="401.81" gradientUnits="userSpaceOnUse">
        <stop stopColor={colorDark} offset="0" />
        <stop stopColor={colorLight} offset="1" />
      </linearGradient>
      <linearGradient id={`${namePrefix}-b`} x1="-414.67" x2="-488.91" y1="1294.8" y2="1213.5" gradientUnits="userSpaceOnUse">
        <stop stopColor={colorDark} offset="0" />
        <stop stopColor={colorLight} offset=".5" />
        <stop stopColor={colorDark} offset="1" />
      </linearGradient>
    </>
  );
}

type FontSize = 'default' | 'large' | 'small';
function getRemSizeFromFontSize(fontSize: FontSize): string {
  switch (fontSize) {
    case 'small':
      return '1.25rem';
    case 'large':
      return '2.1875rem';
    default:
      return '1.5rem';
  }
}
