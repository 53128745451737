import React from 'react';

import { ListItemText, ListItem, ListItemProps, Typography, TextField, List, Button, Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import { formatDate } from '../Formatters';
import { StyledLink } from '../Components';
import { EditableTextField } from '../components/EditableTextField';
import { getRevisionStatus } from './RevisionModel';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useMutation } from '@apollo/client';
import {
  CANCEL,
  COMPLETE,
  SET_MACONOMY_PROJECT_NUMBER,
  SET_SCHEDULED_END_DATE,
  UPDATE_COMMENT_TO_ORGANIZATION,
  UPDATE_DESCRIPTION,
} from './revisions.graphql';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { Revision } from './Details';
import { RevisionRoles } from '../__generated__/graphql';
import { StyledPaper } from '../theme';
import { useModal } from '../dialogs/useModal';
import { CancelDialog } from './CancelDialog';

const CompactListItem = styled(ListItem)<ListItemProps>({
  paddingTop: 1,
  paddingBottom: 1,
});

export function RevisionDetails({
  revision,
  readonly,
  validate,
  setValidate,
}: {
  revision: Revision;
  readonly: boolean;
  validate: boolean;
  setValidate: (validate: boolean) => void;
}) {
  return (
    <StyledPaper>
      <Typography variant="h5" component="h3">
        Detaljer
      </Typography>
      <List dense>
        <CompactListItem>
          <ListItemDl label="Opprettet" value={`${formatDate(revision?.createdAt)} av ${revision?.createdByUser?.name}`} />
        </CompactListItem>
        <CompactListItem>
          <ListItemDl label="Fagområde" value={revision?.guide?.mainCategory?.title} />
        </CompactListItem>
        <CompactListItem>
          <ListItemDl label="Status" value={revision ? getRevisionStatus(revision) : null} />
        </CompactListItem>
        {revision?.canceledAt && (
          <CompactListItem>
            <ListItemDl label="Avbrutt">{`${formatDate(revision.canceledAt)} av ${revision.canceledByUser?.name}`}</ListItemDl>
          </CompactListItem>
        )}
        <CompactListItem>
          <ListItemDl label="Planlagt ferdig">
            <ScheduledEndDate revision={revision} readonly={readonly} />
          </ListItemDl>
        </CompactListItem>
        <MaconomyProjectNumber revision={revision} readonly={readonly} />
        <Description revision={revision} readonly={readonly} />
        <CommentToOrganization revision={revision} readonly={readonly} />
        <CompactListItem>
          <ListItemText primary={<StyledLink to={`/guide/${revision?.guideId}`}>Gå til anvisning</StyledLink>} />
        </CompactListItem>
        <br />
        <CancelButton revision={revision} />
        <CompleteButton revision={revision} validate={validate} setValidate={setValidate} />
      </List>
    </StyledPaper>
  );
}

function MaconomyProjectNumber({ revision, readonly }: { revision: Revision; readonly: boolean }) {
  const [revisionSetMaconomyProjectNumber] = useMutation(SET_MACONOMY_PROJECT_NUMBER);

  if (readonly)
    return (
      <CompactListItem>
        <ListItemDl label="Maconomy Pnr" value={revision?.maconomyProjectNumber} />
      </CompactListItem>
    );

  return (
    <CompactListItem>
      <div style={{ flex: 0.35 }}>Maconomy Pnr:</div>
      <div style={{ flex: 0.65 }}>
        <EditableTextField
          value={revision?.maconomyProjectNumber ?? ''}
          TextFieldProps={{
            variant: 'standard',
          }}
          onUpdate={async (value: string) => {
            await revisionSetMaconomyProjectNumber({ variables: { input: { id: revision?.id, projectNumber: value } } });
          }}
        />
      </div>
    </CompactListItem>
  );
}

function Description({ revision, readonly }: { revision: Revision; readonly: boolean }) {
  const [updateDescription] = useMutation(UPDATE_DESCRIPTION);
  if (readonly)
    return (
      <CompactListItem>
        <ListItemDl label="Beskrivelse" value={revision?.description} />
      </CompactListItem>
    );

  return (
    <CompactListItem>
      <div style={{ flex: 0.35 }}>Beskrivelse:</div>
      <div style={{ flex: 0.65 }}>
        <EditableTextField
          value={revision?.description ?? ''}
          TextFieldProps={{
            multiline: true,
            maxRows: 4,
            variant: 'standard',
          }}
          onUpdate={async (value: string) => {
            await updateDescription({ variables: { input: { id: revision?.id, description: value } } });
          }}
        />
      </div>
    </CompactListItem>
  );
}

function ScheduledEndDate({ revision, readonly }: { revision: Revision; readonly: boolean }) {
  const [setScheduledEndDate] = useMutation(SET_SCHEDULED_END_DATE);
  if (readonly) return <span>{revision?.scheduledEndDate}</span>;
  return (
    <DatePicker
      format="DD.MM.YYYY"
      value={revision?.scheduledEndDate ? moment(revision.scheduledEndDate) : null}
      disablePast={false}
      onChange={(date) => setScheduledEndDate({ variables: { input: { id: revision?.id, scheduledEndDate: date } } })}
    />
  );
}

function CompleteButton({ revision, validate, setValidate }: { revision: Revision; validate: boolean; setValidate: (validate: boolean) => void }) {
  const [complete, { loading: completing }] = useMutation(COMPLETE, { variables: { input: { id: revision?.id } } });

  const hasProjectLead = revision?.roles?.some((role) => role?.role === RevisionRoles.ProjectLead && role.user !== undefined);

  if (revision?.canceledAt || revision?.completedAt) {
    return null;
  }

  const completeNow = async () => {
    if (!hasProjectLead) {
      setValidate(true);
    } else {
      await complete();
    }
  };
  const allTasksCompleted = revision?.checklist?.numberOfTasks === revision?.checklist?.completedTasks;

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={completeNow}
        aria-label="Ferdig"
        disabled={!allTasksCompleted || completing}
        sx={{ marginLeft: (theme) => theme.spacing(2) }}>
        <CheckIcon />
        Ferdig
      </Button>
      {validate && !hasProjectLead && (
        <Alert variant="standard" severity="error">
          Fyll ut påkrevde roller før revisjonen kan fullføres.
        </Alert>
      )}
    </>
  );
}

function CancelButton({ revision }: { revision: Revision }) {
  const [revisionCancel, { loading }] = useMutation(CANCEL, { variables: { input: { id: revision?.id } } });
  const modal = useModal<undefined, boolean>({ data: undefined });

  const cancel = async () => {
    const result = await modal.open();
    if (result) {
      await revisionCancel();
    }
  };

  if (revision?.canceledAt || revision?.completedAt) {
    return null;
  }

  return (
    <>
      <Button variant="outlined" color="secondary" onClick={cancel} aria-label="Avbryt" disabled={loading}>
        <DeleteIcon />
        Avbryt revisjon
      </Button>
      {modal.isOpen && <CancelDialog modal={modal} />}
    </>
  );
}

function CommentToOrganization({ revision, readonly }: { revision: Revision; readonly: boolean }) {
  const [updateComment] = useMutation(UPDATE_COMMENT_TO_ORGANIZATION);
  const setValue = async (text: string) => {
    await updateComment({ variables: { input: { id: revision?.id, text } } });
  };

  if (readonly)
    return (
      <CompactListItem>
        <ListItemDl label="Tekst til forskningsleder" value={revision?.commentToOrganization} />
      </CompactListItem>
    );

  return (
    <CompactListItem>
      <div style={{ flex: 0.35 }}>Tekst til forskningsleder:</div>
      <div style={{ flex: 0.65 }}>
        <EditableTextField
          value={revision?.commentToOrganization ?? ''}
          TextFieldProps={{
            multiline: true,
            maxRows: 4,
            variant: 'standard',
          }}
          onUpdate={setValue}
        />
      </div>
    </CompactListItem>
  );
}

function ListItemDl({ label, children, value }: { label: string; value?: any; children?: any }) {
  return (
    <>
      <div style={{ flex: 0.65 }}>
        <Typography variant="subtitle1">{label}:</Typography>
      </div>
      <div style={{ flex: 0.35 }}>
        {children}
        {value}
      </div>
    </>
  );
}
