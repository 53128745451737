import { AutoFixHigh } from '@mui/icons-material';
import { Alert, Button, Card, CardContent, Chip, Grid2 as Grid, Link, Skeleton, Stack, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, FC, useState } from 'react';
import { AnalysisProps } from '../../types';
import { FindSimilarGuidesResponse, useAiSearchApi } from '../../useAiSearchApi';

export const Guides: FC<AnalysisProps> = ({ initialText, isOpen }) => {
  const [text, setText] = useState(initialText);
  const [response, setResponse] = useState<FindSimilarGuidesResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const api = useAiSearchApi();
  const handleOnTextChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleOnFindButtonClick = async () => {
    setResponse(undefined);
    setIsError(false);
    setIsLoading(true);
    try {
      const response = await api.findSimilarGuides({ text });
      setResponse(response);
    } catch (error) {
      console.error('Failed to get similar guides', error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;
  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <TextField fullWidth rows={15} multiline value={text} onChange={handleOnTextChange}></TextField>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Button disabled={isLoading} onClick={handleOnFindButtonClick} size="large" variant="contained" color="primary" startIcon={<AutoFixHigh />}>
          Finn lignende anvisninger
        </Button>
      </Grid>
      <Grid size={{ xs: 12, md: 8, lg: 7, xl: 5 }}>
        {isLoading && <LoadingAnimation />}
        {!isLoading && response && (
          <>
            <Typography variant="h5" component="h2">
              Lignende anvisninger
            </Typography>
            <Stack gap={2}>
              {response.guides.map((guide) => (
                <Card key={`guide_${guide.docId}`}>
                  <CardContent>
                    <>
                      <Typography sx={{ fontSize: 18 }} variant="h5" component="div" gutterBottom>
                        {guide.docName} - {guide.title}
                      </Typography>
                    </>
                    <Stack spacing={1}>
                      {guide.chunks.map((chunk) => (
                        <Chunk key={`chunk_${chunk.link}`} title={chunk.title} link={chunk.link} score={chunk.score} />
                      ))}
                      {guide.chunks.length === 0 && <Chunk title={guide.title} link={guide.link} score={guide.score} />}
                    </Stack>
                  </CardContent>
                </Card>
              ))}
            </Stack>
          </>
        )}
        {isError && <Alert severity="error">Klarte ikke å hente lignende anvisninger. Vennligst prøv igjen.</Alert>}
      </Grid>
    </Grid>
  );
};

const Chunk: FC<{ title: string; link: string; score: number }> = ({ title, link, score }) => {
  return (
    <div>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" component="span">
        <Chip label={getPercentage(score)} size="small"></Chip>
      </Typography>{' '}
      <Link variant="body2" href={link} target="_blank">
        {title}
      </Link>
    </div>
  );
};

const getPercentage = (value: number) => {
  return `${(value * 100).toFixed(2)}%`;
};

const LoadingAnimation = () => {
  return (
    <Stack spacing={2}>
      <Typography variant="h5">
        <Skeleton animation="wave" width={250} />
      </Typography>
      {Array(4)
        .fill('')
        .map((_, index) => (
          <Skeleton key={`skeleton_${index}`} variant="rectangular" animation="wave" height={100} sx={{ borderRadius: 1 }} />
        ))}
    </Stack>
  );
};
