import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Typography, List, ListItem, ListItemText, Button } from '@mui/material';
import LoadingSpinner from '../LoadingSpinner';
import { formatDate } from '../Formatters';
import { useConfig } from '../env';
import { StyledPaper } from '../theme';

interface Change {
  id: number;
  description: string;
  createdBy: string;
  createdAt: string;
}

export function Changes({ id, gqlQuery }: { id: string; gqlQuery: any }) {
  const [limit, setLimit] = useState(5);
  const config = useConfig();
  const pollIntervall = config ? config.defaultPollInterval : 0;

  const { loading, error, data, refetch } = useQuery<{
    changeModel: { totalCount: number; nodes: Change[] };
  }>(gqlQuery, {
    variables: { id, first: limit },
    pollInterval: pollIntervall,
  });
  const moreToLoad = data && data.changeModel.nodes && data.changeModel.nodes.length < data.changeModel.totalCount;

  const doFetchMore = () => {
    if (!data) return;
    setLimit(limit + 5);
    refetch();
  };

  return (
    <StyledPaper>
      <Typography variant="h5">Endringer</Typography>
      {error && <Typography>{error.message}</Typography>}
      {data && data.changeModel && (
        <List>
          {data.changeModel.nodes.map((x) => (
            <ListItem key={x.id}>
              <ListItemText primary={x.description} secondary={`${x.createdBy} ${formatDate(x.createdAt)}`} />
            </ListItem>
          ))}
        </List>
      )}
      {loading && <LoadingSpinner />}
      {moreToLoad && (
        <Button disabled={loading} onClick={doFetchMore}>
          Last flere
        </Button>
      )}
    </StyledPaper>
  );
}
