import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CriteriaType } from './types';

export interface Criterion {
  ignoreCriteria: CriteriaType[];
}

const initialState: Criterion = {
  ignoreCriteria: [],
};

export const criterionSlice = createSlice({
  name: 'criterion',
  initialState,
  reducers: {
    addIgnore: (state, action: PayloadAction<CriteriaType>) => {
      state.ignoreCriteria = [action.payload, ...state.ignoreCriteria];
    },
    removeIgnore: (state, action: PayloadAction<CriteriaType>) => {
      state.ignoreCriteria = state.ignoreCriteria.filter((id) => id !== action.payload);
    },
    setIgnore: (state, action: PayloadAction<CriteriaType[]>) => {
      state.ignoreCriteria = action.payload;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});

export const { addIgnore, removeIgnore, setIgnore, reset } = criterionSlice.actions;

export const selectCriterion = (state: RootState) => state.criterion;

export default criterionSlice.reducer;
