import React, { FC, useEffect, useState } from 'react';
import { useGuideCmsContext } from '../GuideCmsContext';
import { useFigure } from './figureHelper';
import { useMutation } from '@apollo/client';
import { UPDATE_FIGURE_STATUS, UPDATE_FIGURE_STATUS_COMMENT } from '../content.graphql';
import { Button, Stack, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { EditableTextField } from '../../../components/EditableTextField';
import styled from '@emotion/styled';
import { getStatusDetails, getLastChangedText, steps } from './status';
import { GuideFigureStatusTooltip } from './GuideFigureStatusTooltip';

const StyledStepLabel = styled(StepLabel)<{ color: string }>`
  span.Mui-completed {
    & > svg {
      color: ${({ color }) => color};
    }
  }
`;

export const EditGuideFigureStatus: FC<{ figureId: string }> = ({ figureId }) => {
  const { guideId } = useGuideCmsContext();
  const { figure } = useFigure(guideId, figureId);
  const [updateFigureStatus, { loading: isUpdating }] = useMutation(UPDATE_FIGURE_STATUS);
  const [updateFigureStatusComment] = useMutation(UPDATE_FIGURE_STATUS_COMMENT);
  const [activeStep, setActiveStep] = useState<number>();
  useEffect(() => {
    setActiveStep(steps.findIndex((s) => s.status === figure?.status?.status));
  }, [figure?.status?.status]);

  if (activeStep === undefined) {
    return null;
  }

  const handleBack = () => handleUpdateStatus(activeStep - 1);
  const handleNext = () => handleUpdateStatus(activeStep + 1);

  const handleUpdateStatus = async (nextStep: number) => {
    await updateFigureStatus({ variables: { input: { id: figureId, guideId, status: steps[nextStep].status } } });
    setActiveStep(nextStep);
  };

  const handleUpdateComment = async (updateComment: string) => {
    await updateFigureStatusComment({ variables: { input: { id: figureId, guideId, comment: updateComment } } });
  };

  if (!figure) {
    return null;
  }

  return (
    <>
      <strong>Status</strong>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, i) => {
          const statusDetails = getStatusDetails(figure, step.status);
          return (
            <Step key={i} completed={i <= activeStep}>
              <GuideFigureStatusTooltip figureId={figureId} status={step.status} showTooltip={i < activeStep}>
                <StyledStepLabel color={step.color.main}>{step.label}</StyledStepLabel>
              </GuideFigureStatusTooltip>
              <StepContent TransitionProps={{}}>
                <Stack gap={1}>
                  <EditableTextField
                    value={figure.status?.comment ?? ''}
                    onUpdate={handleUpdateComment}
                    TextFieldProps={{
                      placeholder: 'Skriv inn kommentar',
                      variant: 'standard',
                      multiline: true,
                    }}
                  />
                  {statusDetails && <Typography variant="caption">{getLastChangedText(statusDetails)}</Typography>}
                  <Stack gap={2} direction="row">
                    {activeStep > 0 && (
                      <Button size="small" startIcon={<KeyboardArrowLeft />} variant="outlined" disabled={isUpdating} onClick={handleBack}>
                        Forrige
                      </Button>
                    )}
                    {activeStep < steps.length - 1 && (
                      <Button size="small" endIcon={<KeyboardArrowRight />} variant="contained" disabled={isUpdating} onClick={handleNext}>
                        Neste
                      </Button>
                    )}
                  </Stack>
                </Stack>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </>
  );
};
