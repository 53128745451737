import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#287891',
    },
    secondary: {
      main: '#666666',
    },
    error: {
      main: '#CF453A',
    },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        colorSecondary: {
          '&$checked': {
            color: '#287891',
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.7rem',
        },
      },
    },
  },
  spacing: 8,
});

export const StyledPaper = styled(Paper)`
  padding: ${theme.spacing(2)};
  margin-top: ${theme.spacing(1)};
  margin-right: ${theme.spacing(1)};
`;

export { theme };
