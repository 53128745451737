import React, { FC, useEffect, useState } from 'react';
import { useNodeComments } from './useNodeComments';
import { useCommentsContext } from './CommentsContext';
import styled from '@emotion/styled';
import { theme } from '../../../../theme';
import { Avatar, Box, Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { Send, Delete, Reply, Edit, MoreVert, ExpandLess, ExpandMore, CheckCircle, LockOpen, Forum } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import {
  CREATE_COMMENT,
  DELETE_COMMENT,
  UPDATE_COMMENT,
  Comment as CommentType,
  CREATE_REPLY,
  UPDATE_REPLY,
  DELETE_REPLY,
  UPDATE_COMMENT_STATUS,
} from './comments.graphql';
import { useGuideCmsContext } from '../../GuideCmsContext';
import { useAuth } from 'oidc-react';
import { EditableTextField } from '../../../../components/EditableTextField';
import moment from 'moment';
import { formatDate } from '../../../../Formatters';
import { CommentStatus } from '../../../../__generated__/graphql';
import { CommentsFilter } from './CommentsFilter';
import { CommentsAutoUpdater } from './CommentsAutoUpdater';
import { getFullDescription, useNumberedNode } from '../../tiptap/numbering';
import { getText } from '../../tiptap/tiptapHelper';
import { SidebarHeading } from '../SidebarHeading';

const getAuthor = (comment: CommentType) => {
  return comment.hearing?.participantAlias ?? comment.hearing?.participantEmail ?? comment.createdBy?.name ?? 'Ukjent bruker';
};

const DrawerContent = styled.div`
  padding: ${theme.spacing(2)};
`;

const NewCommentButtonContainer = styled.div`
  display: grid;
  justify-items: right;
`;

const StyledRepliesContainer = styled.div`
  margin-left: 11px;
  border-left: 3px solid ${theme.palette.divider};
  padding-left: 16px;
`;

const StyledComment = styled.div`
  margin-top: ${theme.spacing(1)};
`;

const StyledCommentHeading = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const StyledCommentText = styled.div`
  font-size: 14px;
  white-space: pre-line;
`;

const StyledCommentBody = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

export const Comments: FC = () => {
  const { guideId, content } = useGuideCmsContext();
  const { guideNodeId, isCommentsEnabled, enableComments, disableComments } = useCommentsContext();
  const comments = useNodeComments(guideNodeId);
  const [newComment, setNewComment] = useState('');
  const createCommentInput = () => ({
    guideId,
    guideNodeId: guideNodeId ?? '',
    contentVersion: content?.version ?? -1,
    comment: newComment,
  });
  const [createComment, { loading: isCreatingComment }] = useMutation(CREATE_COMMENT, {
    variables: { input: createCommentInput() },
  });

  const onCreateCommentClick = async () => {
    await createComment();
    setNewComment('');
  };

  useEffect(() => {
    if (!isCommentsEnabled) {
      enableComments();
    }
    return () => {
      if (isCommentsEnabled) {
        disableComments();
      }
    };
  }, [isCommentsEnabled]);
  return (
    <DrawerContent>
      <SidebarHeading icon={<Forum />}>{guideNodeId ? <GuideNodeDescription guideNodeId={guideNodeId} /> : 'Kommentarer'}</SidebarHeading>

      <Box>
        <CommentsFilter />
      </Box>

      <CommentsAutoUpdater />
      {guideNodeId && (
        <div>
          <Box sx={{ mt: 4 }}>
            {comments.map((comment) => (
              <Comment key={comment.id} comment={comment} />
            ))}
          </Box>
          <Stack sx={{ mt: 2 }}>
            <TextField
              value={newComment}
              size="small"
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  onCreateCommentClick();
                }
              }}
              onChange={(e) => setNewComment(e.target.value)}
              label="Kommentar"
              placeholder="Legg til kommentar..."
              multiline
            />
            <NewCommentButtonContainer>
              <IconButton disabled={isCreatingComment} color="info" size="small" title="Send kommentar" onClick={onCreateCommentClick}>
                <Send fontSize="small" />
              </IconButton>
            </NewCommentButtonContainer>
          </Stack>
        </div>
      )}
    </DrawerContent>
  );
};

const GuideNodeDescription: FC<{ guideNodeId: string }> = ({ guideNodeId }) => {
  const { editor } = useGuideCmsContext();
  const numberedNode = useNumberedNode(guideNodeId);
  let description = numberedNode ? getFullDescription(numberedNode) : '';

  if (numberedNode?.type === 'heading') {
    const node = editor?.getJSON().content?.find((node) => node.attrs?.id === guideNodeId);
    if (node) {
      description = `${numberedNode.numbering} ${getText(node)}`;
    }
  }
  return <>{description}</>;
};

const Comment: FC<{ comment: CommentType }> = ({ comment }) => {
  const [showReplies, setShowReplies] = useState(false);
  const [newReply, setNewReply] = useState('');
  const { guideId } = useGuideCmsContext();
  const auth = useAuth();
  const userId = auth.userData?.profile?.sub ?? '';
  const replies = comment.replies ?? [];
  const [deleteComment] = useMutation(DELETE_COMMENT, {});
  const [updateComment] = useMutation(UPDATE_COMMENT, {});
  const [deleteReply] = useMutation(DELETE_REPLY, {});
  const [createReply, { loading: isCreatingReply }] = useMutation(CREATE_REPLY, {});
  const [updateReply] = useMutation(UPDATE_REPLY, {});
  const [updateStatus] = useMutation(UPDATE_COMMENT_STATUS, {});
  const isInternal = !comment.hearing;

  const onCommentDeleteClick = async () => {
    await deleteComment({ variables: { input: { commentId: comment.id, guideId } } });
  };

  const onReplyDeleteClick = async (replyId: string) => {
    await deleteReply({ variables: { input: { commentId: comment.id, replyId } } });
  };

  const onReplyClick = async () => {
    setShowReplies(true);
  };

  const onCreateReplyClick = async () => {
    await createReply({ variables: { input: { commentId: comment.id, comment: newReply } } });
    setNewReply('');
  };

  const onCommentUpdate = async (value: string) => {
    await updateComment({ variables: { input: { commentId: comment.id, comment: value } } });
  };

  const onReplyUpdate = async (replyId: string, value: string) => {
    await updateReply({ variables: { input: { replyId, commentId: comment.id, comment: value } } });
  };

  const onStatusUpdate = async (status: CommentStatus) => {
    await updateStatus({ variables: { input: { commentId: comment.id, status } } });
  };

  return (
    <div key={comment.id}>
      <CommentItem
        author={getAuthor(comment)}
        version={comment.contentVersion}
        isEditable={comment.createdBy?.subjectId === userId}
        text={comment.comment ?? ''}
        timestamp={comment.updatedAt}
        isInternal={isInternal}
        onUpdate={async (value) => {
          await onCommentUpdate(value);
        }}
        showReply={true}
        onDelete={onCommentDeleteClick}
        onReply={onReplyClick}
        onStatusChange={onStatusUpdate}
        status={comment.status}
      />
      {comment.status === CommentStatus.Closed && (
        <Stack direction="row" alignItems="center" gap={1}>
          <CheckCircle color="success" fontSize={'small'} />
          <div>
            <Typography variant="caption">
              Lukket av {comment.statusChangedBy?.name} {formatDate(comment.statusChangedAt)}
            </Typography>
          </div>
        </Stack>
      )}
      {(replies.length > 0 || showReplies) && (
        <>
          <Button
            size="small"
            sx={{ textTransform: 'none' }}
            startIcon={
              showReplies ? (
                <Tooltip title="Skjul svar">
                  <ExpandLess />
                </Tooltip>
              ) : (
                <Tooltip title="Vis svar">
                  <ExpandMore />
                </Tooltip>
              )
            }
            onClick={() => setShowReplies(!showReplies)}>
            {replies.length > 0 ? `${replies.length} svar` : 'Skriv svar'}
          </Button>
        </>
      )}
      {showReplies && (
        <StyledRepliesContainer>
          {replies.map(
            (reply) =>
              reply && (
                <CommentItem
                  key={reply.id}
                  author={reply.createdBy?.name ?? 'Ukjent bruker'}
                  isEditable={reply.createdBy?.subjectId === userId}
                  text={reply.comment ?? ''}
                  isInternal={true}
                  timestamp={reply.updatedAt}
                  onUpdate={async (value) => {
                    await onReplyUpdate(reply.id, value);
                  }}
                  showReply={false}
                  onDelete={() => onReplyDeleteClick(reply.id)}
                  onReply={() => {}}
                />
              ),
          )}

          <Stack sx={{ mt: 2 }}>
            <TextField
              value={newReply}
              size="small"
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  onCreateReplyClick();
                }
              }}
              onChange={(e) => setNewReply(e.target.value)}
              label="Svar"
              placeholder="Skriv et svar..."
              multiline
            />
            <NewCommentButtonContainer>
              <IconButton disabled={isCreatingReply} color="info" size="small" title="Send svar" onClick={onCreateReplyClick}>
                <Send fontSize="small" />
              </IconButton>
            </NewCommentButtonContainer>
          </Stack>
        </StyledRepliesContainer>
      )}
    </div>
  );
};

const CommentItem: FC<{
  author: string;
  version?: number;
  timestamp: string;
  isEditable: boolean;
  showReply: boolean;
  isInternal: boolean;
  text: string;
  status?: CommentStatus;
  onUpdate: (value: string) => Promise<void>;
  onDelete: () => void;
  onReply: () => void;
  onStatusChange?: (status: CommentStatus) => void;
}> = ({ author, version, timestamp, isEditable, showReply, isInternal, text, status, onUpdate, onDelete, onReply, onStatusChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMoreMenuOpen = Boolean(moreMenuAnchorEl);
  const openMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreMenuAnchorEl(event.currentTarget);
  };
  const closeMoreMenu = () => {
    setMoreMenuAnchorEl(null);
  };
  return (
    <StyledComment>
      <StyledCommentHeading>
        <Tooltip
          title={
            <>
              <strong>{author}</strong>
              <br />
              {formatDate(timestamp)}{' '}
              {version && (
                <>
                  <br />
                  Innhold på versjon {version}
                </>
              )}
            </>
          }>
          <Stack direction="row" gap={1} alignItems="center">
            <Avatar
              sx={{
                bgcolor: isInternal ? theme.palette.info.main : theme.palette.warning.main,
                width: '20px',
                height: '20px',
                fontSize: '12px',
              }}></Avatar>
            <Stack direction="row" gap={1} alignItems="baseline">
              <strong>{author}</strong>
              <Typography variant="caption">{moment(timestamp).startOf('seconds').fromNow()}</Typography>
            </Stack>
          </Stack>
        </Tooltip>
        <IconButton size="small" onClick={openMoreMenu}>
          <MoreVert fontSize="small" />
        </IconButton>
      </StyledCommentHeading>
      <StyledCommentBody>
        <StyledCommentText>
          {isEditable && (
            <EditableTextField
              value={text}
              TextFieldProps={{ multiline: true, size: 'small' }}
              editing={isEditing}
              onUpdate={async (value) => {
                setIsEditing(false);
                await onUpdate(value);
              }}
              onCancel={() => setIsEditing(false)}
              DisplayComponent={() => <div>{text}</div>}></EditableTextField>
          )}
          {!isEditable && <div>{text}</div>}
        </StyledCommentText>
        <div>
          <Menu open={isMoreMenuOpen} onClose={closeMoreMenu} anchorEl={moreMenuAnchorEl} disableScrollLock>
            {isEditable && (
              <MenuItem
                onClick={() => {
                  setIsEditing(true);
                  closeMoreMenu();
                }}>
                <ListItemIcon>
                  <Edit fontSize="small" />
                </ListItemIcon>
                <ListItemText>Rediger</ListItemText>
              </MenuItem>
            )}
            {isEditable && (
              <MenuItem
                onClick={() => {
                  onDelete();
                  closeMoreMenu();
                }}>
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                <ListItemText>Slett</ListItemText>
              </MenuItem>
            )}
            {showReply && (
              <MenuItem
                onClick={() => {
                  onReply();
                  closeMoreMenu();
                }}>
                <ListItemIcon>
                  <Reply fontSize="small" />
                </ListItemIcon>
                <ListItemText>Skriv svar</ListItemText>
              </MenuItem>
            )}
            {onStatusChange && status === CommentStatus.Open && (
              <MenuItem
                onClick={() => {
                  onStatusChange(CommentStatus.Closed);
                  closeMoreMenu();
                }}>
                <ListItemIcon>
                  <CheckCircle fontSize="small" />
                </ListItemIcon>
                <ListItemText>Lukk</ListItemText>
              </MenuItem>
            )}
            {onStatusChange && status === CommentStatus.Closed && (
              <MenuItem
                onClick={() => {
                  onStatusChange(CommentStatus.Open);
                  closeMoreMenu();
                }}>
                <ListItemIcon>
                  <LockOpen fontSize="small" />
                </ListItemIcon>
                <ListItemText>Gjenåpne</ListItemText>
              </MenuItem>
            )}
          </Menu>
        </div>
      </StyledCommentBody>
    </StyledComment>
  );
};
