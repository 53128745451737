import React, { FC } from 'react';
import LoadingSpinner from '../LoadingSpinner';
import { StyledPaper } from '../theme';
import { formatDate } from '../Formatters';
import { Alert, AlertTitle, Box, Button, FormControl, Grid2 as Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { DataSourceGrid } from './Grid';
import { DataSourceContextProvider, useDataSourceContext } from './DataSourceContext';
import { ExcelImport } from './ExcelImport';
import { Archive, ArrowBack, ArrowForward, Delete, Download, Edit, Publish, Save } from '@mui/icons-material';
import { BackButton } from '../Components';

export const Details: FC = () => {
  return (
    <DataSourceContextProvider>
      <DetailInner />
    </DataSourceContextProvider>
  );
};

const DetailInner: FC = () => {
  const { canBack, canNext, isLoading, details, isDraft, goBack, goNext, archiveDataSource, nameChanged, descriptionChanged } =
    useDataSourceContext();
  if (isLoading) return <LoadingSpinner />;
  if (!details) return null;
  const canArchive = !(isDraft && details.version === 1) && !details.isArchived;

  return (
    <StyledPaper>
      <Grid container gap={2} direction="column">
        <Typography variant="h5">
          <BackButton /> {details.name}
        </Typography>
        <DataSourceAlert />

        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={2}>
            <Button title="Forrige versjon" startIcon={<ArrowBack />} variant="outlined" onClick={goBack} disabled={!canBack}>
              Forrige
            </Button>
            <Button title="Neste versjon" endIcon={<ArrowForward />} variant="outlined" onClick={goNext} disabled={!canNext}>
              Neste
            </Button>
          </Stack>

          {canArchive && (
            <Button startIcon={<Archive />} variant="outlined" onClick={archiveDataSource}>
              Arkiver datakilde
            </Button>
          )}
        </Stack>
        {isDraft && (
          <Box>
            Importer fra Excel
            <ExcelImport />
          </Box>
        )}
        {isDraft && (
          <>
            <FormControl component="div">
              <TextField label="Navn" size="small" disabled={!isDraft} value={details.name ?? ''} onChange={(e) => nameChanged(e.target.value)} />
            </FormControl>
            <FormControl component="div">
              <TextField
                label="Beskrivelse"
                size="small"
                multiline
                InputProps={{ rows: 4 }}
                disabled={!isDraft}
                value={details.description ?? ''}
                onChange={(e) => descriptionChanged(e.target.value)}
              />
            </FormControl>
            <EditMetaData />
          </>
        )}
        {!isDraft && (
          <Stack gap={1}>
            <div>
              <Typography variant="subtitle2">Navn:</Typography> {details.name}
            </div>
            <div>
              <Typography variant="subtitle2">Beskrivelse:</Typography> <div style={{ whiteSpace: 'pre-line' }}>{details.description}</div>
            </div>
            <MetaData />
          </Stack>
        )}
        <DataSourceGrid />
      </Grid>
    </StyledPaper>
  );
};

const EditMetaData: FC = () => {
  const { details, metaChanged, addMeta, removeMeta } = useDataSourceContext();
  if (!details) return null;

  return (
    <div>
      <Typography variant="subtitle2">Metadata</Typography>
      <Button title="Legg til metadata" onClick={addMeta}>
        Legg til
      </Button>
      {details.meta?.map((item, i) => (
        <Stack direction="row" alignItems="center" key={`meta_${i}`}>
          <TextField
            placeholder="Nøkkel"
            variant="standard"
            size="small"
            value={item.key}
            onChange={(e) => {
              metaChanged(i, { ...item, key: e.target.value });
            }}
          />

          <TextField
            placeholder="Verdi"
            variant="standard"
            size="small"
            value={item.value}
            onChange={(e) => {
              metaChanged(i, { ...item, value: e.target.value });
            }}
          />
          <IconButton title="Fjern metadata" onClick={() => removeMeta(i)}>
            <Delete />
          </IconButton>
        </Stack>
      ))}
    </div>
  );
};

const MetaData: FC = () => {
  const { details } = useDataSourceContext();
  if (!details) return null;

  return (
    <div>
      <Typography variant="subtitle2">Meta data</Typography>
      <ul>
        {details.meta?.map(({ key, value }, i) => (
          <li key={`meta_${key}`}>
            {key}: {value}
          </li>
        ))}
      </ul>
    </div>
  );
};

const DataSourceAlert: FC = () => {
  const { details, isDraft, isPublished, isArchived, hasChanges, publish, unpublish, save, newDraft, deleteDraft } = useDataSourceContext();
  if (!details) return null;
  return (
    <Alert icon={isArchived ? <Archive /> : undefined} severity={isArchived || isDraft ? 'info' : 'success'}>
      {isDraft && <AlertTitle>Utkast versjon {details.version}</AlertTitle>}
      {isArchived && <AlertTitle>Arkivert versjon {details.version}</AlertTitle>}
      {isPublished && <AlertTitle>Publisert versjon {details.version}</AlertTitle>}
      {isPublished && (
        <Stack gap={2}>
          Publisert {formatDate(details.publishedAt!)} av {details.publishedBy?.name}
          {details.isLatestVersion && !details.isArchived && (
            <Stack direction="row" gap={2}>
              <Button color="success" startIcon={<Edit />} variant="contained" onClick={newDraft} disabled={isDraft || !details.isLatestVersion}>
                Nytt utkast
              </Button>
              <Button color="success" startIcon={<Download />} variant="outlined" onClick={unpublish} disabled={isDraft || !details.isLatestVersion}>
                Avpubliser
              </Button>
            </Stack>
          )}
        </Stack>
      )}
      {isArchived && <Stack gap={2}>Denne datakilden er arkivert og kan ikke endres</Stack>}
      {isDraft && (
        <Stack gap={2}>
          Sist oppdatert {formatDate(details.updatedAt)} av {details.updatedBy.name}
          <Stack direction="row" gap={2}>
            <Button color="info" startIcon={<Delete />} variant="outlined" onClick={deleteDraft} disabled={!isDraft}>
              Slett utkast
            </Button>
            <Button color="info" startIcon={<Save />} variant="outlined" onClick={save} disabled={!hasChanges || !isDraft}>
              Lagre
            </Button>
            <Button color="info" startIcon={<Publish />} variant="contained" onClick={publish} disabled={!isDraft}>
              Publiser
            </Button>
          </Stack>
        </Stack>
      )}
    </Alert>
  );
};
