import React from 'react';
import { PreWrap } from '../Components';
import { Typography, IconButton, List, ListItem, ListItemText, ListItemAvatar, Avatar, Tooltip } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { formatDate } from '../Formatters';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { REMOVE_COMMENT } from './comment.graphql';
import { GetGuideQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';
import { useModal } from '../dialogs/useModal';
import { GuideCommentDialog, GuideCommentDialogInput } from './CommentDialog';

type Comment = Unpacked<NonNullable<GetGuideQuery['guide']>['comments']>;

export function Comments({ guideId, comments, onUpdate }: { guideId: string; comments: Comment[]; onUpdate: () => void }) {
  const [removeComment] = useMutation(REMOVE_COMMENT);
  const commentDialog = useModal<GuideCommentDialogInput, boolean>({ data: { guideId: guideId } });

  const comment = async (commentId?: string, comment?: string | null) => {
    const result = await commentDialog.open({ guideId, commentId, comment });
    if (result) await onUpdate();
  };

  const remove = async (commentId: string) => {
    await removeComment({ variables: { input: { id: guideId, commentId } } });
    await onUpdate();
  };

  return (
    <StyledPaper>
      <Typography variant="h5" component="h3">
        Kommentarer
        <IconButton onClick={() => comment()} aria-label="Ny kommentar" size="large">
          <Add />
        </IconButton>
      </Typography>
      <Typography variant="caption" color="textSecondary">
        (registreres ikke i endringslogg)
      </Typography>
      <CommentList comments={comments} comment={comment} remove={remove} />
      {commentDialog.isOpen && <GuideCommentDialog modal={commentDialog} />}
    </StyledPaper>
  );
}

function CommentList({
  comments,
  comment,
  remove,
}: {
  comments: Comment[];
  comment: (commentId: string, comment: string | null | undefined) => void;
  remove: (commentId: string) => void;
}) {
  if (comments.length === 0) {
    return (
      <Typography variant="subtitle1" color="textSecondary">
        Ingen kommentarer
      </Typography>
    );
  }

  const editComment = async (commentObject: NonNullable<Comment>) => {
    comment(commentObject.id, commentObject.comment);
  };

  const deleteComment = async (commentId: string) => {
    await remove(commentId);
  };

  const sortComments = (a: Comment, b: Comment) => {
    if (!a || !b) return 0;
    var date1 = a.lastUpdatedAt || a.createdAt;
    var date2 = b.lastUpdatedAt || b.createdAt;
    if (moment(date2).isAfter(date1)) {
      return 1;
    }
    if (moment(date1).isAfter(date2)) {
      return -1;
    }
    return 0;
  };

  const getSecondary = (c: NonNullable<Comment>) => {
    const createdInfo = `${c.createdBy!.name} ${formatDate(c.createdAt)}`;
    return c.lastUpdatedAt ? createdInfo + ` - Sist endret av ${c.lastUpdatedBy!.name} ${formatDate(c.lastUpdatedAt)}` : createdInfo;
  };

  const getInitials = (name: string | null | undefined) => {
    if (!name) return null;
    return name
      .split(' ')
      .map((x) => x[0])
      .join('');
  };

  return (
    <List>
      {[...comments].sort(sortComments).map((c) => {
        if (!c) return null;
        return (
          <ListItem key={c.id} divider>
            <ListItemAvatar>
              <Avatar>{getInitials(c.createdBy?.name)}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={<PreWrap>{c.comment}</PreWrap>} secondary={getSecondary(c)} />
            <Tooltip id="tooltip-icon" title="Endre" placement="top">
              <IconButton onClick={() => editComment(c)} size="large">
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip id="tooltip-icon" title="Fjern" placement="top">
              <IconButton onClick={() => deleteComment(c.id)} size="large">
                <Delete />
              </IconButton>
            </Tooltip>
          </ListItem>
        );
      })}
    </List>
  );
}
