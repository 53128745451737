import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useQuery } from '@apollo/client';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  Grid2 as Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { GET_PARTNERS } from './partners.graphql';
import { TableCellSort, ColWidths, compareFn } from '../components/TableComponents';
import { Add, Edit } from '@mui/icons-material';
import { StyledLink } from '../Components';
import { SearchField } from '../components/SearchField';
import { useNavigate } from 'react-router-dom';
import { SortDirection } from '@mui/material/TableCell';
import { filterPartners } from './Overview';
import { Partner } from './types';

const PREFIX = 'PartnerSelect';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledTable = styled(Table)({
  [`& .${classes.root}`]: {
    height: '50%',
  },
});

interface Props {
  selected: string;
  onUpdated?(id: string | undefined): void;
}

export function PartnerSelect({ selected, onUpdated }: Props) {
  const [open, setOpen] = useState(false);
  const { loading, error, data } = useQuery(GET_PARTNERS);
  const selectedPartner = data && data.partners && selected ? data.partners.find((x) => x?.id === selected) : undefined;
  return (
    <Grid container alignItems="center">
      {error && <Typography>{error.message}</Typography>}
      {selected && loading && <Typography>Henter..</Typography>}
      {selectedPartner && <StyledLink to={`/partner/${selectedPartner.id}`}>{selectedPartner.name}</StyledLink>}
      {onUpdated && (
        <IconButton onClick={() => setOpen(true)} style={{ padding: 5, marginLeft: selected ? 16 : 0 }} title="Legg til partner" size="large">
          <Edit fontSize="small" />
        </IconButton>
      )}
      {data && onUpdated && (
        <PartnerSelectDialog
          open={open}
          onClose={() => setOpen(false)}
          onSubmit={onUpdated}
          partners={data.partners as Partner[]}
          selected={selected}
        />
      )}
    </Grid>
  );
}

interface DialogProps {
  open: boolean;
  selected: string;
  partners: Partner[];
  onSubmit(selected: string | undefined): void;
  onClose(): void;
}

export function PartnerSelectDialog2({
  onClose,
  onSubmit,
  selected,
}: {
  selected: string;
  onSubmit(selected: string | undefined): void;
  onClose(): void;
}) {
  const { loading, data } = useQuery(GET_PARTNERS);
  if (loading || !data) return null;

  return <PartnerSelectDialog open onClose={onClose} onSubmit={onSubmit} selected={selected} partners={data.partners as Partner[]} />;
}

function PartnerSelectDialog({ open, onClose, onSubmit, selected, partners }: DialogProps) {
  const navigate = useNavigate();
  const [workingSet, setWorkingSet] = useState<string | undefined>(selected);
  const [filter, setFilter] = useState('');

  const handleClose = () => {
    setWorkingSet(selected);
    onClose();
    setFilter('');
  };

  const handleSubmit = () => {
    onSubmit(workingSet);
    setWorkingSet(workingSet);
    onClose();
    setFilter('');
  };

  const addPartner = () => {
    navigate(`/partner?create=${filter}`);
  };

  const filteredPartners = filterPartners(filter, partners);
  const showAddButton = filteredPartners.length === 0;

  return (
    <Dialog className={classes.root} open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Velg partner</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid size={{ xs: showAddButton ? 11 : 12 }}>
            <SearchField filter={setFilter} />
          </Grid>
          <Grid size={{ xs: 1 }}>
            {showAddButton && (
              <IconButton onClick={addPartner} title="Legg til som ny partner" size="large">
                <Add />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <PartnerTable partners={filteredPartners} selected={workingSet} setSelected={setWorkingSet} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Avbryt</Button>
        <Button onClick={handleSubmit} color="primary">
          Velg
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type PartnerColumn = 'selected' | 'name' | 'url';

interface TableProps {
  partners: Partner[];
  selected: string | undefined;
  setSelected(arr: string | undefined): void;
}

function PartnerTable({ partners, selected, setSelected }: TableProps) {
  const isSelected = (id: string) => selected === id;
  const toggleSelected = (id: string) => {
    if (isSelected(id)) {
      setSelected(undefined);
    } else {
      setSelected(id);
    }
  };
  const [orderBy, setOrderBy] = useState<PartnerColumn>('name');
  const [direction, setDirection] = useState<SortDirection>(false);
  const handleSortClick = (key: PartnerColumn) => {
    if (orderBy === key) {
      setDirection(direction === 'desc' ? 'asc' : 'desc');
    } else {
      setOrderBy(key);
      setDirection('desc');
    }
  };

  const getSorter = () => {
    const fn = (getter: (Partner) => any) => compareFn<Partner>(getter, direction);
    if (orderBy === 'selected') return fn((x) => isSelected(x.id));
    return fn((x) => x.name);
  };

  const ColumnHeader = ({ colKey, children }: { colKey: PartnerColumn; children?: any }) => (
    <TableCellSort active={orderBy === colKey} sortDirection={direction} onClick={() => handleSortClick(colKey)}>
      {children}
    </TableCellSort>
  );

  return (
    <StyledTable size="small">
      <ColWidths widths={[40, null, 80]}></ColWidths>
      <TableHead>
        <TableRow>
          <ColumnHeader colKey="selected">Valgt</ColumnHeader>
          <ColumnHeader colKey="name">Navn</ColumnHeader>
          <ColumnHeader colKey="url">Url</ColumnHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {partners.sort(getSorter()).map((resource) => {
          if (!resource) return null;
          const selected = isSelected(resource.id);
          return (
            <TableRow
              hover
              onClick={() => toggleSelected(resource.id)}
              role="checkbox"
              aria-checked={selected}
              tabIndex={-1}
              key={resource.id}
              selected={selected}>
              <TableCell padding="checkbox">
                <Checkbox checked={selected} color="primary" />
              </TableCell>
              <TableCell>{resource.name}</TableCell>
              <TableCell>{resource.url}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
}
