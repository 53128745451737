import React, { FC } from 'react';
import { AutoAwesome } from '@mui/icons-material';
import { Node } from '@tiptap/pm/model';
import { MenuButton, useRichTextEditorContext } from 'mui-tiptap';
import { useModal } from '../../../../../dialogs/useModal';
import { AiSearchDialog } from '../../../../../ai/AiSearchDialog';
import { getGuideLinkExtensionRenderOptions } from '../views/GuideLinkView';
import { getInternalLinkExtensionRenderOptions } from '../views/InternalLinkView';
import { AiSearchDialogProps, AiSearchDialogResult } from '../../../../../ai/types';
import { getStandardLinkExtensionRenderOptions } from '../views/StandardLinkView';

export const AiToolbarButton: FC = () => {
  const { renderText: guideLinkRenderText } = getGuideLinkExtensionRenderOptions();
  const { renderText: internalLinkRenderText } = getInternalLinkExtensionRenderOptions();
  const { renderText: standardLinkRenderText } = getStandardLinkExtensionRenderOptions();

  const editor = useRichTextEditorContext();
  const modal = useModal<AiSearchDialogProps, AiSearchDialogResult>({ data: { initialText: '' } });
  if (!editor) return null;

  const renderLeafNodeText = (node: Node) => {
    if (node.type.name === 'guideLink' && guideLinkRenderText) return guideLinkRenderText(node);
    if (node.type.name === 'internalLink' && internalLinkRenderText) return internalLinkRenderText(node);
    if (node.type.name === 'standardLink' && standardLinkRenderText) return standardLinkRenderText(node);
    return node.textContent;
  };

  const handleOnClick = async () => {
    const { state } = editor;
    let { from, to } = state.selection;
    if (from === to) {
      // select the whole document if nothing is selected
      from = 0;
      to = state.doc.nodeSize - 2;
    }
    const selectedText = state.doc.textBetween(from, to, '\n', renderLeafNodeText);
    await modal.open({ initialText: selectedText });
  };

  return (
    <>
      {modal.isOpen && <AiSearchDialog {...modal} />}
      <MenuButton IconComponent={AutoAwesome} tooltipLabel="AI skrivehjelp" onClick={handleOnClick}></MenuButton>
    </>
  );
};
