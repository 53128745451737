import { useMutation } from '@apollo/client';
import { useModal } from '../dialogs/useModal';
import { HearingDialogProps } from './HearingDialog';
import { CREATE_HEARING, Hearing } from './hearing.graphql';

export const useHearingDialog = (guideId: string) => {
  const modal = useModal<HearingDialogProps, void>({ data: { guideId } });

  const [createHearing, { loading: isCreatingHearing }] = useMutation(CREATE_HEARING);

  const openHearingDialog = async (existingHearing: Hearing | null | undefined) => {
    let hearing = existingHearing;
    if (!hearing) {
      const response = await createHearing({ variables: { input: { guideId: guideId } } });
      hearing = response.data?.hearingCreate?.hearing ?? undefined;
    }
    if (hearing) {
      modal.open();
    }
  };

  return {
    modal,
    isCreatingHearing,
    openHearingDialog,
  };
};
