import { FC } from 'react';
import { Node, mergeAttributes, ReactNodeViewRenderer, NodeViewProps } from '@tiptap/react';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';

export interface InternalLinkExtensionRenderOptions {
  viewComponent?: FC<NodeViewProps>;
  renderText?: (node: ProseMirrorNode) => string;
}

export enum InternalLinkFormat {
  Default = 0,
  CitationIEEEStyle = 1,
}

export const createInternalLinkExtension = (renderOptions: InternalLinkExtensionRenderOptions) => {
  const addNodeView = renderOptions.viewComponent ? () => ReactNodeViewRenderer(renderOptions.viewComponent as FC<NodeViewProps>) : null;
  const node = Node.create({
    name: 'internalLink',
    group: 'inline',
    inline: true,
    selectable: false,
    atom: true,
    renderText({ node }) {
      if (renderOptions.renderText) {
        return renderOptions.renderText(node);
      }
      return node.attrs.id;
    },
    addAttributes() {
      return {
        id: {
          default: null,
        },
        type: {
          default: null,
        },
        format: {
          default: InternalLinkFormat.Default,
        },
      };
    },
    parseHTML() {
      return [
        {
          tag: 'react-component',
        },
      ];
    },
    renderHTML({ HTMLAttributes }) {
      return ['react-component', mergeAttributes(HTMLAttributes)];
    },
    addNodeView,
  });

  return node;
};
