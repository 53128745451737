import React from 'react';
import { Alert, AlertTitle, Button, Link as MuiLink } from '@mui/material';
import { FC } from 'react';
import { NavigateBefore, NavigateNext, Restore } from '@mui/icons-material';
import { Stack } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import { useRestoreContentVersion } from './useRestoreContentVersion';
import Loading from '../../../LoadingSpinner';
import { GuideEditor } from '../tiptap/GuideEditor';
import { useReadOnlyGuideEditor } from '../tiptap/useGuideEditor';
import { useGuideCmsContext } from '../GuideCmsContext';
import { useHistoricContentContext } from './HistoricContentContext';
import { formatDate } from '../../../Formatters';

export const HistoricContentReader: FC<{ version: number }> = ({ version }) => {
  const { guideId, isLoading: isLoadingGuideContent, content } = useGuideCmsContext();
  const { isLoading: isLoadingHistoricContent, historicContent, historicEditorContent } = useHistoricContentContext();
  const { restoreVersion, isReady: isReadyToRestore, isRestoring } = useRestoreContentVersion(guideId);
  const navigate = useNavigate();
  const currentVersion = content?.version ?? 0;
  const canGoNext = version < currentVersion;
  const canGoBack = version > 1;
  const isNextVersionCurrent = version + 1 === currentVersion;
  const isCurrentVersion = version === currentVersion;

  const onRestoreClick = async () => {
    const newVersion = await restoreVersion(version);
    if (newVersion) {
      navigate(`/guide-editor/${guideId}`);
    }
  };

  const { editor } = useReadOnlyGuideEditor({
    content: historicEditorContent,
  });
  if (isLoadingGuideContent) return <Loading />;
  return (
    <div>
      {!isCurrentVersion && (
        <Alert severity="info" sx={{ marginBottom: 2 }}>
          <AlertTitle>
            Du ser på en historisk endring. Gå til{' '}
            <MuiLink component={Link} to={`/guide-editor/${guideId}`}>
              gjeldende (v. {currentVersion})
            </MuiLink>
            .
          </AlertTitle>
          {historicContent && (
            <>
              {historicContent?.lastChangedBy?.name} {formatDate(historicContent?.lastChangedAt)}
              {historicContent?.comment && <>: {historicContent?.comment}</>}
            </>
          )}
          <Stack sx={{ marginTop: 2 }} gap={2} alignItems="left">
            <div>
              <Button disabled={!isReadyToRestore || isRestoring} variant="outlined" onClick={onRestoreClick} startIcon={<Restore />}>
                Gjenopprett denne versjonen
              </Button>
            </div>
            <Stack direction="row" gap={2} alignItems="center">
              <Button
                color="info"
                variant="contained"
                disabled={!canGoBack}
                component={Link}
                to={`/guide-editor/${guideId}?version=${version - 1}`}
                startIcon={<NavigateBefore />}>
                Forrige
              </Button>
              <Button
                color="info"
                variant="contained"
                disabled={!canGoNext}
                component={Link}
                to={isNextVersionCurrent ? `/guide-editor/${guideId}` : `/guide-editor/${guideId}?version=${version + 1}`}
                endIcon={<NavigateNext />}>
                {isNextVersionCurrent ? 'Gjeldende' : 'Neste'}
              </Button>
            </Stack>
          </Stack>
        </Alert>
      )}
      {isLoadingHistoricContent && <Loading />}
      {!isLoadingHistoricContent && editor && <GuideEditor guideId={guideId} editor={editor} />}
    </div>
  );
};
