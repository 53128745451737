import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import { FormControl, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { ColWidths, TableCellEllipsis, TableCellHead, TableCellIcon } from '../components/TableComponents';
import { formatDateOnly } from '../Formatters';
import { GuideStatusIcon } from '../guides/GuideStatusIcon';
import { StyledLink } from '../Components';
import { GET_BOOKS } from './book.graphql';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { GuideStatus } from '../__generated__/graphql';
import { StyledPaper } from '../theme';

export function BookDetail() {
  const { bookTitle } = useParams();
  const [filter, setFilter] = useState('all');

  const { data } = useQuery(GET_BOOKS, { fetchPolicy: 'cache-and-network' });

  const book = data?.books?.find((x) => x?.title === bookTitle);
  const guides = book?.guides;

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };
  const filteredReferences =
    filter === 'all'
      ? guides
      : filter === 'under_revision'
      ? guides?.filter((reference) => reference?.ongoingRevision)
      : guides?.filter((reference) => reference?.status === GuideStatus.Expired);

  return (
    <StyledPaper>
      <Typography variant="h5" gutterBottom>
        {bookTitle}
      </Typography>
      <FormControl>
        <Select variant="standard" value={filter} onChange={handleFilterChange}>
          <MenuItem value="all">Alle</MenuItem>
          <MenuItem value="under_revision">Under revisjon</MenuItem>
          <MenuItem value="tilbaketrukket">Tilbaketrukket</MenuItem>
        </Select>
      </FormControl>
      {!book && <LoadingSpinner />}
      {filteredReferences && (
        <Table size="small">
          <ColWidths widths={[40, 40, null, 150, 40]} />
          <TableHead>
            <TableRow>
              <TableCellHead>Status</TableCellHead>
              <TableCellHead>Nummer</TableCellHead>
              <TableCellHead>Tittel</TableCellHead>
              <TableCellHead>Fagområde</TableCellHead>
              <TableCellHead>Sist publisert</TableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredReferences.map((x) => {
              if (!x) return null;
              return (
                <TableRow key={x.id}>
                  <TableCellIcon>
                    <GuideStatusIcon
                      status={x.status ?? GuideStatus.Planned}
                      hasRevision={x.ongoingRevision !== null}
                      hasChangesSinceLastPublish={x.hasChangesSinceLastPublish}
                    />
                  </TableCellIcon>
                  <TableCell>
                    <StyledLink to={`/guide/${x.id}`}>{x.docName}</StyledLink>
                  </TableCell>
                  <TableCellEllipsis title={x.docTitle}>{x.docTitle}</TableCellEllipsis>
                  <TableCellEllipsis title={x.mainCategory?.title}>{x.mainCategory?.title}</TableCellEllipsis>
                  <TableCell>{formatDateOnly(x.lastPublishedAt)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </StyledPaper>
  );
}
