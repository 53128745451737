import {
  DataGridPro,
  GridColDef,
  GridRowModel,
  GridColumnHeaderParams,
  GridColumnMenu,
  GridColumnMenuProps,
  GridColumnMenuItemProps,
  GridActionsCellItem,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import { nbNO } from '@mui/x-data-grid-pro/locales';
import { FC, useCallback, useRef, useState } from 'react';
import React from 'react';
import { Button, ListItemIcon, ListItemSecondaryAction, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { EditableTextField } from '../components/EditableTextField';
import { Abc, Check, DataObject, Delete, NavigateNext, Numbers, TableRows, ViewColumn } from '@mui/icons-material';
import { useDataSourceContext } from './DataSourceContext';
import { DataType } from './useDataSourceApi';
import { toGridColDef, toGridRows } from './dataGridHelper';

export const DataSourceGrid: FC = () => {
  const { details, isDraft, removeRow, addRow, addColumn, rowChanged, columnChanged } = useDataSourceContext();
  const detailColumns = details?.columns ?? [];
  const dataColumns: GridColDef[] = detailColumns.map((column) => ({
    ...toGridColDef(column),
    editable: isDraft,
    renderHeader: (params: GridColumnHeaderParams) => (
      <EditableTextField
        DisplayComponent={(props) => (
          <Typography
            variant="subtitle2"
            title="Klikk for å endre navn"
            noWrap
            onClick={(e) => {
              if (!isDraft) return;
              e.stopPropagation();
              props.edit();
            }}>
            {params.colDef.headerName ?? ''}
          </Typography>
        )}
        TextFieldProps={{ size: 'small', onClick: (e) => e.stopPropagation() }}
        value={column.title}
        onUpdate={async (val: string) => {
          if (!details) return;
          const c = details.columns.find((c) => c.id === column.id);
          if (!c) return;
          columnChanged({ ...c, title: val });
        }}
      />
    ),
  }));

  const actionsColumn: GridColDef = {
    field: 'actions',
    type: 'actions',
    headerName: 'Handlinger',
    width: 100,
    resizable: false,
    getActions: (props) => {
      return [<GridActionsCellItem disabled={!isDraft} icon={<Delete />} label="Delete" onClick={() => removeRow(parseInt(props.id.toString()))} />];
    },
  };
  const columns = [...dataColumns, actionsColumn];
  const rows = toGridRows(detailColumns, details?.values ?? []);

  return (
    <div>
      <Typography variant="h6">Data</Typography>
      {isDraft && (
        <>
          <Button startIcon={<TableRows />} onClick={addRow}>
            Legg til rad
          </Button>
          <Button startIcon={<ViewColumn />} onClick={addColumn}>
            Legg til kolonne
          </Button>
        </>
      )}

      <DataGridPro
        autoHeight
        density="compact"
        pagination
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        rows={rows}
        columns={columns}
        localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
        slots={{
          columnMenu: CustomColumnMenu,
          toolbar: GridToolbar,
        }}
        slotProps={{ toolbar: { showQuickFilter: true } }}
        processRowUpdate={(newRow: GridRowModel) => {
          rowChanged(
            parseInt(newRow.id),
            dataColumns.map((column) => ({ columnId: parseInt(column.field), value: newRow[column.field].toString() })),
          );
          return newRow;
        }}
      />
    </div>
  );
};

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuDeleteItem: CustomDeleteItem,
        columnDataType: CustomChangeDataType,
      }}
      slotProps={{
        columnMenuDeleteItem: {
          displayOrder: 30,
        },
        columnDataType: {
          displayOrder: 20,
        },
      }}
    />
  );
}

function CustomDeleteItem(props: GridColumnMenuItemProps) {
  const { removeColumn, isDraft } = useDataSourceContext();
  return (
    <MenuItem disabled={!isDraft} onClick={() => removeColumn(parseInt(props.colDef.field))}>
      <ListItemIcon>
        <Delete fontSize="small" />
      </ListItemIcon>
      <ListItemText>Fjern kolonne</ListItemText>
    </MenuItem>
  );
}

function CustomChangeDataType(props: GridColumnMenuItemProps) {
  const { isDraft, columnChanged } = useDataSourceContext();
  const [isOpen, setIsOpen] = useState(false);
  const menuItemRef = useRef<HTMLLIElement>(null);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  const { type: currentType, field, headerName } = props.colDef;

  const changeFormat = (dataType: DataType) => {
    columnChanged({ id: parseInt(field), title: headerName ?? '', dataType });
  };

  return (
    <MenuItem disabled={!isDraft} ref={menuItemRef} onMouseEnter={open} onMouseLeave={close} sx={[isOpen && { backgroundColor: 'action.hover' }]}>
      <ListItemIcon>
        <DataObject fontSize="small" />
      </ListItemIcon>
      <ListItemText>Format</ListItemText>
      <ListItemSecondaryAction>
        <NavigateNext fontSize="small" color="secondary" />
      </ListItemSecondaryAction>
      <Menu
        open={isOpen}
        anchorEl={menuItemRef.current}
        anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        TransitionProps={{ onExited: () => menuItemRef.current?.focus() }}
        disableRestoreFocus
        sx={{
          pointerEvents: 'none',
          '& .MuiList-root': {
            pointerEvents: 'auto',
          },
        }}
        onClose={close}>
        <MenuItem disabled={currentType === 'string'} onClick={() => changeFormat(DataType.Text)}>
          <ListItemIcon>
            <Abc fontSize="small" />
          </ListItemIcon>
          <ListItemText>Tekst</ListItemText>
        </MenuItem>
        <MenuItem disabled={currentType === 'number'} onClick={() => changeFormat(DataType.Number)}>
          <ListItemIcon>
            <Numbers fontSize="small" />
          </ListItemIcon>
          <ListItemText>Tall</ListItemText>
        </MenuItem>
        <MenuItem disabled={currentType === 'boolean'} onClick={() => changeFormat(DataType.Boolean)}>
          <ListItemIcon>
            <Check fontSize="small" />
          </ListItemIcon>
          <ListItemText>Flagg</ListItemText>
        </MenuItem>
      </Menu>
    </MenuItem>
  );
}
