import React from 'react';

import Select from '@mui/material/Select';
import { MenuItem } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { BackButton } from '../Components';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { TableCellHead } from '../components/TableComponents';
import { SearchField } from '../components/SearchField';
import { ADD_PARAGRAPH, GET_GUIDE_AND_PARAGRAPHS, REMOVE_PARAGRAPH } from './guide.graphql';

export function EditGuideParagraphs() {
  const { id } = useParams();
  const [regulation, setRegulation] = React.useState('TEK-17');
  const { data, loading } = useQuery(GET_GUIDE_AND_PARAGRAPHS, { variables: { id } });
  const [addParagraph] = useMutation(ADD_PARAGRAPH);
  const [removeParagraph] = useMutation(REMOVE_PARAGRAPH);

  const [searchTerm, setSearchTerm] = React.useState('');
  const [showOnlySelectedParagraphs, setShowOnlySelectedParagraphs] = React.useState(false);

  if (loading) return <LoadingSpinner />;
  if (!data?.guide) return <div>Fant ikke anvisningen</div>;

  const guide = data.guide;

  const toggleParagrah = async (paragraphId) => {
    const variables = {
      input: {
        id: guide.id,
        paragraphId: paragraphId,
      },
    };
    if (isSelected(paragraphId)) {
      await removeParagraph({ variables });
    } else {
      await addParagraph({ variables });
    }
  };

  const isSelected = (id) => {
    return guide.paragraphs!.some((x) => x!.id === id);
  };

  const filterParagraphs = (searchTerm, paragraphs) => {
    return paragraphs.filter((x) => {
      var parts = searchTerm.split(' ');

      if (showOnlySelectedParagraphs && !isSelected(x.id)) {
        return false;
      }

      return parts.some((y) => {
        var part = y.toLowerCase();
        var number = x.number.toLowerCase();
        var title = x.title.toLowerCase();
        return number.indexOf(part) !== -1 || title.indexOf(part) !== -1;
      });
    });
  };

  const filteredParagraphs = filterParagraphs(searchTerm, data.paragraphs);

  return (
    <div>
      <div>
        <Typography variant="h5">
          <BackButton />
          Anvisning: {guide.docTitle}
        </Typography>
      </div>
      <Select name="regulation" value={regulation} onChange={(x) => setRegulation(x.target.value)}>
        <MenuItem value="TEK-17">TEK-17</MenuItem>
        <MenuItem value="TEK-10">TEK-10</MenuItem>
      </Select>
      <SearchField filter={setSearchTerm} />
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={showOnlySelectedParagraphs}
            onChange={() => setShowOnlySelectedParagraphs(!showOnlySelectedParagraphs)}
          />
        }
        label="Vis bare valgte paragrafer"
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHead>Valgt</TableCellHead>
            <TableCellHead>Forskrift</TableCellHead>
            <TableCellHead>Paragraf</TableCellHead>
            <TableCellHead>Tittel</TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredParagraphs.map((x) => {
            const selected = isSelected(x.id);
            return (
              <TableRow
                hover
                onClick={() => toggleParagrah(x.id)}
                role="checkbox"
                aria-checked={selected}
                tabIndex={-1}
                key={x.id}
                selected={selected}
              >
                <TableCell padding="checkbox">
                  <Checkbox checked={selected} color="primary" />
                </TableCell>
                <TableCell>{x.regulation}</TableCell>
                <TableCell>{x.number}</TableCell>
                <TableCell>{x.title}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}
