import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress,
  Checkbox,
} from '@mui/material';
import { All_SUBJECT_RESOURCES, CREATE } from './subjectResources.graphql';
import LoadingSpinner from '../LoadingSpinner';
import { ColWidths, TableCellSort, compareFn, TableCellEllipsis } from '../components/TableComponents';
import { StyledLink } from '../Components';
import { Add, Save } from '@mui/icons-material';
import { SearchField } from '../components/SearchField';
import Fuse from 'fuse.js';
import { SortDirection } from '@mui/material/TableCell';
import { useLocation, useNavigate } from 'react-router-dom';
import { useOrganization } from '../organization/useOrganization';
import { parse } from 'qs';
import { TableCellIcon } from '../components/TableComponents';
import { GetSubjectResourcesQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { utils, writeFile } from 'xlsx';
import { StyledPaper } from '../theme';

type SubjectResource = Unpacked<GetSubjectResourcesQuery['resources']>;

export function Overview() {
  const [filter, setFilter] = useState('');
  const { loading, error, data } = useQuery(All_SUBJECT_RESOURCES, { fetchPolicy: 'cache-and-network' });

  const filterResources = (resources: SubjectResource[]): SubjectResource[] => {
    const activeResources = resources.filter((x) => x && !x.isDeleted);
    if (!filter) return activeResources;

    var options = {
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0,
      keys: ['name'],
    };

    var fuse = new Fuse(activeResources, options);
    var filtered = fuse.search(filter.trim()).map((x) => x.item);
    return filtered;
  };

  return (
    <StyledPaper>
      <Typography variant="h5" gutterBottom>
        Fagressurser
        <AddButton />
      </Typography>
      <SearchField filter={setFilter} />
      {loading && <LoadingSpinner />}
      {error && <Typography>{error.message}</Typography>}
      {!loading && data?.resources && <ResourceTable resources={filterResources(data.resources)} />}
    </StyledPaper>
  );
}

type ResourceColumn = 'name' | 'internal' | 'unit' | 'department' | 'guides' | 'revisions' | 'notes';
type CompareFn = (a: SubjectResource, b: SubjectResource) => number;

function ResourceTable({ resources }: { resources: SubjectResource[] }) {
  const [getUnitName, getDepartmentName] = useOrganization();

  const [orderBy, setOrderBy] = useState<ResourceColumn>('name');
  const [direction, setDirection] = useState<SortDirection>(false);
  const handleSortClick = (key: ResourceColumn) => {
    if (orderBy === key) {
      setDirection(direction === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(key);
      setDirection('asc');
    }
  };

  const getCompareFn = (): CompareFn => {
    const fn = (getter: (SubjectResource) => any) => compareFn<SubjectResource>(getter, direction);

    if (orderBy === 'internal') return fn((x) => x.isInternal);
    if (orderBy === 'unit') return fn((x) => getUnitName(x.unitId));
    if (orderBy === 'department') return fn((x) => getDepartmentName(x.unitId));
    if (orderBy === 'notes') return fn((x) => x.notes);
    if (orderBy === 'guides') return fn((x) => x.authorOfGuides.length);
    if (orderBy === 'revisions') return fn((x) => x.numberOfActiveRevisions);

    return fn((x) => x.name);
  };

  const ColumnHeader = ({ colKey, inputProps, children }: { colKey: ResourceColumn; inputProps?: any; children?: any }) => (
    <TableCellSort inputProps={{ ...inputProps }} active={orderBy === colKey} sortDirection={direction} onClick={() => handleSortClick(colKey)}>
      {children}
    </TableCellSort>
  );

  return (
    <>
      <ExcelExport resources={resources} />
      <Table size="small" sx={{ position: 'relative' }}>
        <ColWidths widths={[null, 80, null, null, 200, 100, 100]}></ColWidths>
        <TableHead>
          <TableRow>
            <ColumnHeader colKey="name">Navn</ColumnHeader>
            <ColumnHeader colKey="internal" inputProps={{ align: 'center' }}>
              Intern
            </ColumnHeader>
            <ColumnHeader colKey="unit">Enhet</ColumnHeader>
            <ColumnHeader colKey="department">Avdeling</ColumnHeader>
            <ColumnHeader colKey="notes">Notat</ColumnHeader>
            <ColumnHeader colKey="revisions" inputProps={{ align: 'right' }}>
              Revisjoner
            </ColumnHeader>
            <ColumnHeader colKey="guides" inputProps={{ align: 'right' }}>
              Anvisninger
            </ColumnHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {resources.sort(getCompareFn()).map((resource) => (
            <TableRow key={resource?.id}>
              <TableCell>
                <StyledLink to={`/subjectResource/${resource?.id}`}>{resource?.name}</StyledLink>
              </TableCell>
              <TableCellIcon align="center">
                <Checkbox sx={{ padding: '5px' }} color="primary" checked={resource?.isInternal} disabled />
              </TableCellIcon>
              <TableCell>{getUnitName(resource?.unitId)}</TableCell>
              <TableCell>{getDepartmentName(resource?.unitId)}</TableCell>
              <TableCellEllipsis title={resource?.notes}>{resource?.notes}</TableCellEllipsis>
              <TableCell align="right">{resource?.numberOfActiveRevisions}</TableCell>
              <TableCell align="right">{resource?.authorOfGuides?.length}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

function AddButton() {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [create, { called, loading }] = useMutation(CREATE, {
    variables: { input: { name } },
  });
  const close = () => {
    setName('');
    setOpen(false);
  };
  const submit = async () => {
    const result = await create();
    close();
    navigate(`/subjectResource/${result.data!.subjectResourceAdd?.subjectResourceModel?.id}`);
  };
  const params = parse(location.search.substring(1));

  if (params.create && typeof params.create === 'string') {
    if (name !== params.create) setName(params.create);
    if (!open) setOpen(true);
  }

  return (
    <>
      <IconButton onClick={() => setOpen(true)} title="Legg til ny fagressurs" size="large">
        <Add />
      </IconButton>
      <Dialog open={open} onClose={close}>
        <DialogTitle>Legg til ny fagressurs</DialogTitle>
        <DialogContent>
          <TextField margin="dense" label="Navn" type="text" autoFocus value={name} onChange={(ev) => setName(ev.target.value)} fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>Avbryt</Button>
          <Button
            disabled={called}
            onClick={submit}
            variant="contained"
            startIcon={loading ? <CircularProgress size={15} color="secondary" /> : <Save />}
            color="primary">
            Legg til
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function ExcelExport({ resources }: { resources: SubjectResource[] }) {
  const [getUnitName, getDepartmentName] = useOrganization();

  return (
    <IconButton
      sx={{ position: 'absolute', right: (theme) => theme.spacing(4), top: (theme) => theme.spacing(12) }}
      onClick={() => exportFile(resources, getUnitName, getDepartmentName)}
      title="Eksport til Excel"
      size="large">
      <img src="/icons8-microsoft-excel.svg" style={{ width: 24, height: 24 }} alt="Excel icon" />
    </IconButton>
  );
}

function exportFile(
  resources: SubjectResource[],
  getUnitName: (unitId: string) => string | null,
  getDepartmentName: (unitId: string) => string | null,
) {
  const headings: string[] = ['Navn', 'Intern', 'Enhet', 'Avdeling', 'Notat', 'Revisjoner', 'Anvisninger'];

  const data = resources.map((resource) => [
    resource?.name,
    resource?.isInternal ? 'Ja' : 'Nei',
    getUnitName(resource?.unitId),
    getDepartmentName(resource?.unitId),
    resource?.notes,
    resource?.numberOfActiveRevisions,
    resource?.authorOfGuides?.length,
  ]);
  const input = [headings, ...data];
  const sheet = utils.aoa_to_sheet(input);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, sheet, 'Fagressurser');
  writeFile(workbook, 'fagressurser.xlsx');
}
