import { gql } from "../__generated__";

gql(/* GraphQL */ `
  fragment GuideCommentItem on GuideComment {
    id
    comment
    createdBy {
      subjectId
      name
    }
    createdAt
    lastUpdatedBy {
      subjectId
      name
    }
    lastUpdatedAt
  }
`);

export const ADD_COMMENT = gql(/* GraphQL */ `
  mutation GuideAddCommentInput ($input: GuideAddCommentInput!) {
    guideAddComment(input: $input) {
      guideModel {
        id
        comments {
          ...GuideCommentItem
        }
      }
    }
  }
`);

export const UPDATE_COMMENT = gql(/* GraphQL */ `
  mutation GuideUpdateComment($input: GuideUpdateCommentInput!) {
    guideUpdateComment(input: $input) {
      guideModel {
        id
        comments {
          ...GuideCommentItem
        }
      }
    }
  }
`);

export const REMOVE_COMMENT = gql(/* GraphQL */ `
  mutation GuideRemoveComment($input: GuideRemoveCommentInput!) {
    guideRemoveComment(input: $input) {
      guideModel {
        id
        comments {
          ...GuideCommentItem
        }
      }
    }
  }
`);
