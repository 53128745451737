import { ChartConfiguration } from 'chart.js/auto';
import { palette } from './ChartPalette';

export const lineChartSingle = () => {
  const config: ChartConfiguration<'line'> = {
    type: 'line',
    data: {
      labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      datasets: [
        {
          label: 'Datasett 1',
          data: randomArray(10),
          backgroundColor: `${palette.blue}`,
          borderColor: palette.blue,
        },
      ],
    },
    options: {},
    plugins: [],
  };
  return config;
};

export const lineChartSmooth = () => {
  const config: ChartConfiguration<'line'> = {
    type: 'line',
    data: {
      labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      datasets: [
        {
          label: 'Datasett 1',
          data: randomArray(10),
          backgroundColor: `${palette.blue}`,
          borderColor: palette.blue,
          tension: 0.3,
        },
      ],
    },
    options: {},
    plugins: [],
  };
  return config;
};

export const lineChartStyled = () => {
  const config: ChartConfiguration<'line'> = {
    type: 'line',
    data: {
      labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      datasets: [
        {
          label: 'Datasett 1',
          data: randomArray(10),
          backgroundColor: `${palette.blue}`,
          borderColor: palette.blue,
          segment: {
            borderColor: palette.lineColor,
            borderDash: [5, 5],
            borderWidth: 1,
          },
        },
      ],
    },
    options: {},
    plugins: [],
  };
  return config;
};

export const lineChartMultiple = () => {
  const config: ChartConfiguration<'line'> = {
    type: 'line',
    data: {
      labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      datasets: [
        {
          label: 'Datasett 1',
          data: randomArray(10),
          backgroundColor: `${palette.blue}`,
          borderColor: palette.blue,
        },
        {
          label: 'Datasett 2',
          data: randomArray(10),
          backgroundColor: `${palette.yellow}`,
          borderColor: palette.yellow,
        },
        {
          label: 'Datasett 3',
          data: randomArray(10),
          backgroundColor: `${palette.red}`,
          borderColor: palette.red,
        },
        {
          label: 'Datasett 4',
          data: randomArray(10),
          backgroundColor: `${palette.green}`,
          borderColor: palette.green,
        },
      ],
    },
    options: {},
    plugins: [],
  };
  return config;
};

export const barChartSingle = () => {
  const config: ChartConfiguration<'bar'> = {
    type: 'bar',
    data: {
      labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      datasets: [
        {
          label: 'Datasett 1',
          data: randomArray(10),
          backgroundColor: `${palette.blue}`,
          borderColor: palette.blue,
        },
      ],
    },
    options: {},
    plugins: [],
  };
  return config;
};

export const barChartStacked = () => {
  const config: ChartConfiguration<'bar'> = {
    type: 'bar',
    data: {
      labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      datasets: [
        {
          label: 'Datasett 1',
          data: randomArray(10),
          backgroundColor: `${palette.blue}`,
          borderColor: palette.blue,
        },
        {
          label: 'Datasett 2',
          data: randomArray(10),
          backgroundColor: `${palette.red}`,
          borderColor: palette.red,
        },
        {
          label: 'Datasett 3',
          data: randomArray(10),
          backgroundColor: `${palette.green}`,
          borderColor: palette.green,
        },
      ],
    },
    options: {
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    },
    plugins: [],
  };
  return config;
};

export const barChartHorizontal = () => {
  const config: ChartConfiguration<'bar'> = {
    type: 'bar',
    data: {
      labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      datasets: [
        {
          label: 'Datasett 1',
          data: randomArray(10),
          backgroundColor: `${palette.blue}`,
          borderColor: palette.blue,
        },
      ],
    },
    options: {
      indexAxis: 'y',
    },
    plugins: [],
  };
  return config;
};

export const barChartGrouped = () => {
  const config: ChartConfiguration<'bar'> = {
    type: 'bar',
    data: {
      labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
      datasets: [
        {
          label: 'Datasett 1',
          data: randomArray(10),
          backgroundColor: `${palette.blue}`,
          borderColor: palette.blue,
        },
        {
          label: 'Datasett 2',
          data: randomArray(10),
          backgroundColor: `${palette.green}`,
          borderColor: palette.green,
        },
      ],
    },
    options: {},
    plugins: [],
  };
  return config;
};

const random = () => Math.floor(Math.random() * 100);
const randomArray = (length: number) => Array.from({ length }, random);
