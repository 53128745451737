import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface ResourceFilter {
  ignoredRoles: string[];
}

const initialState: ResourceFilter = {
  ignoredRoles: [],
};

export const revisionFilterSlice = createSlice({
  name: 'resourcesFilter',
  initialState,
  reducers: {
    setIgnoredRoles: (state, action: PayloadAction<string[]>) => {
      state.ignoredRoles = action.payload;
    },
  },
});

export const { setIgnoredRoles } = revisionFilterSlice.actions;

export const selectResourceFilter = (state: RootState) => state.resourceFilter;

export default revisionFilterSlice.reducer;
