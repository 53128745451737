import React, { FC } from 'react';
import { NodeViewWrapper, NodeViewProps } from '@tiptap/react';
import styled from '@emotion/styled';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { InternalLinkExtensionRenderOptions, InternalLinkFormat } from '../../../../../../shared/guide-cms/extensions/InternalLink';
import { NumberedNode, NumberedNodeType, getTypeDescription, useNumberedNodes } from '../../numbering';
import { Tooltip } from '@mui/material';

const getColorFromNodeType = (type: NumberedNodeType) => {
  switch (type) {
    case 'imageFigure':
    case 'chartFigure':
      return '#c3e2ff';
    case 'heading':
      return '#ffc8c8';
    case 'tableFigure':
      return '#fff7ad';
    case 'app':
      return '#d4ffdb';
  }
};

const StyledLink = styled.span<{ type: NumberedNodeType; isClickable: boolean }>`
  background-color: ${(props) => getColorFromNodeType(props.type)};
  padding: 0px 1px;
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
`;

const renderText = (numberedNodes?: Map<string, NumberedNode>) => (node: ProseMirrorNode) => {
  const numberedNode = numberedNodes?.get(node.attrs.id);
  return `${getTypeDescription(node.attrs.type, node.attrs.format)} ${numberedNode?.numbering}`;
};

const getTooltipText = ({ node, editor }: NodeViewProps) => {
  if (!editor.isEditable) return null;
  if (node.attrs.type === 'heading') {
    const format = node.attrs.format;
    if (format === InternalLinkFormat.CitationIEEEStyle) {
      return 'Klikk for å endre lenke til punkt';
    }
    return 'Klikk for å endre lenke til referanse';
  }
  return null;
};

const InternalLinkView: FC<NodeViewProps> = (props) => {
  const numberedNodes = useNumberedNodes();
  return (
    <NodeViewWrapper className="react-component" as={'span'}>
      <Tooltip title={getTooltipText(props)}>
        <StyledLink
          isClickable={props.editor.isEditable && props.node.attrs.type === 'heading'}
          onClick={() => {
            if (!props.editor.isEditable) return;
            const format = props.node.attrs.format;
            const newFormat = format === InternalLinkFormat.CitationIEEEStyle ? InternalLinkFormat.Default : InternalLinkFormat.CitationIEEEStyle;
            props.updateAttributes({
              ...props.node.attrs,
              format: newFormat,
            });
          }}
          type={props.node.attrs.type}>
          {renderText(numberedNodes)(props.node)}
        </StyledLink>
      </Tooltip>
    </NodeViewWrapper>
  );
};

declare global {
  interface Window {
    guideCmsNumberedNodes: Map<string, NumberedNode>;
  }
}

export const getInternalLinkExtensionRenderOptions = (): InternalLinkExtensionRenderOptions => {
  return {
    viewComponent: InternalLinkView,
    renderText: renderText(),
  };
};
