import React from 'react';
import { GET_BOOKS } from './book.graphql';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import BookList from './BookList';
import { Typography } from '@mui/material';
import { StyledPaper } from '../theme';

export function Overview() {
  const { data } = useQuery(GET_BOOKS, { fetchPolicy: 'cache-and-network' });
  return (
    <StyledPaper>
      <Typography variant="h5" gutterBottom>
        Bøker
      </Typography>
      {!data && <LoadingSpinner />}
      {data && data.books && <BookList books={data.books} />}
    </StyledPaper>
  );
}
