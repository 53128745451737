import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, IconButton } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { writeFile, utils } from 'xlsx';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useLazyQuery, useQuery } from '@apollo/client';
import moment, { Moment } from 'moment';
import LoadingSpinner from '../LoadingSpinner';
import { GET_PAGEVIEWS_PER_LICENSE as GuidePageViews } from '../guides/guide.graphql';
import { GET_PAGEVIEWS_PER_LICENSE as CategoryPageViews } from '../categories/categories.graphql';
import { DocumentViewItemFragment } from '../__generated__/graphql';
import { Modal } from '../dialogs/useModal';

function useStatPeriod() {
  const makePeriodEnd = () => {
    let periodEnd = new Date();
    periodEnd.setDate(periodEnd.getDate() - 1);
    return moment(periodEnd);
  };

  const makePeriodStart = () => {
    let periodStart = new Date();
    periodStart.setDate(periodStart.getDate() - 1);
    periodStart.setFullYear(periodStart.getFullYear() - 1);
    return moment(periodStart);
  };

  const [periodStart, setPeriodStart] = useState<Moment>(makePeriodStart());
  const [periodEnd, setPeriodEnd] = useState<Moment>(makePeriodEnd());

  const setStart = (date: Moment | null) => setPeriodStart(date ?? makePeriodStart());
  const setEnd = (date: Moment | null) => setPeriodEnd(date ?? makePeriodEnd());
  return { periodStart, periodEnd, setPeriodStart: setStart, setPeriodEnd: setEnd, minDate: makePeriodStart() };
}

export interface LicensePageViewStatForGuideDialogProps {
  id: string;
  docName?: string | null | undefined;
}
export function LicensePageViewStatForGuideDialog({ modal }: { modal: Modal<LicensePageViewStatForGuideDialogProps, undefined> }) {
  const guide = modal.data;
  const { periodStart, periodEnd, setPeriodStart, setPeriodEnd, minDate } = useStatPeriod();
  const handleClose = () => modal.close();
  const { data } = useQuery(GuidePageViews, { variables: { id: guide.id, periodStart, periodEnd, count: 20 } });
  const [getExportData] = useLazyQuery(GuidePageViews, {
    variables: { id: guide.id, periodStart, periodEnd, count: 100 },
    fetchPolicy: 'network-only',
  });

  const exportFile = async () => {
    const headings: string[] = ['Kunde', 'Visninger', 'Næringskode', 'Org.nr', 'Antall ansatte'];

    const result = await getExportData();

    let resultData = result.data?.guide?.pageviewsPerLicense?.map((x) => {
      if (!x) return [];
      return [x.customer?.companyName, x.customer?.visits, getCode(x), x.customer?.orgNumber, x.brregEntity?.numberOfEmployees];
    });
    resultData?.unshift(headings);
    const workbook = utils.book_new();
    if (resultData) {
      const sheet = utils.aoa_to_sheet(resultData);
      utils.book_append_sheet(workbook, sheet, 'Sidevisninger ang. tidsrom');
    }
    writeFile(workbook, 'sidevisninger.xlsx');
  };

  const hasData = data?.guide?.pageviewsPerLicense;
  return (
    <div>
      <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Sidevisninger pr. kunde for {guide.docName} for angitt tidsrom </DialogTitle>
        <DialogContent sx={{ minHeight: 400 }}>
          {!hasData && <LoadingSpinner />}
          {hasData && <Details items={data!.guide!.pageviewsPerLicense!} />}
        </DialogContent>
        <DialogActions>
          <DatePicker format="DD.MM.YYYY" label="Start dato" minDate={minDate} value={periodStart} onChange={(date) => setPeriodStart(date)} />
          <DatePicker
            format="DD.MM.YYYY"
            label="Slutt dato"
            minDate={minDate}
            value={periodEnd}
            disablePast={false}
            onChange={(date) => setPeriodEnd(date)}
          />
          <IconButton onClick={() => exportFile()} title="Eksportér til Excel" size="large">
            <img src="/icons8-microsoft-excel.svg" alt="Excel icon" />
          </IconButton>
          <Button onClick={handleClose} color="primary">
            Lukk
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export interface LicensePageViewStatForCategoryDialogProps {
  id: string;
  title?: string | null | undefined;
}
export function LicensePageViewStatForCategoryDialog({ modal }: { modal: Modal<LicensePageViewStatForCategoryDialogProps, undefined> }) {
  const category = modal.data;
  const { periodStart, periodEnd, setPeriodStart, setPeriodEnd, minDate } = useStatPeriod();

  const { data } = useQuery(CategoryPageViews, { variables: { id: category.id, periodStart, periodEnd, count: 20 } });
  const [getexportCatData] = useLazyQuery(CategoryPageViews, {
    variables: { id: category.id, count: 100, periodStart: periodStart, periodEnd: periodEnd },
    fetchPolicy: 'network-only',
  });

  const handleClose = () => modal.close();

  const exportFile = async () => {
    const headings: string[] = ['Kunde', 'Visninger', 'Næringskode', 'Org.nr', 'Antall ansatte'];

    const result = await getexportCatData();
    let data = result.data?.category?.pageviewsPerLicense?.map((x) => {
      if (!x) return [];
      return [x.customer?.companyName, x.customer?.visits, getCode(x), x.customer?.orgNumber, x.brregEntity?.numberOfEmployees];
    });
    data?.unshift(headings);
    const workbook = utils.book_new();
    if (data) {
      const sheet = utils.aoa_to_sheet(data);
      utils.book_append_sheet(workbook, sheet, 'visningsstatistikk');
    }

    writeFile(workbook, 'visningsstatistikk per kunde for fagområde.xlsx');
  };

  const hasData = data?.category?.pageviewsPerLicense;

  return (
    <div>
      <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Sidevisninger pr. kunde for {category.title} for angitt tidsrom </DialogTitle>
        <DialogContent sx={{ minHeight: 400 }}>
          {!hasData && <LoadingSpinner />}
          {hasData && <Details items={data!.category!.pageviewsPerLicense!} />}
        </DialogContent>

        <DialogActions>
          <DatePicker format="DD.MM.YYYY" label="Start dato" minDate={minDate} value={periodStart} onChange={(date) => setPeriodStart(date)} />
          <DatePicker
            format="DD.MM.YYYY"
            label="Slutt dato"
            minDate={minDate}
            value={periodEnd}
            disablePast={false}
            onChange={(date) => setPeriodEnd(date)}
          />
          <IconButton onClick={() => exportFile()} title="Eksporér til Excel" size="large">
            <img src="/icons8-microsoft-excel.svg" alt="Excel icon" />
          </IconButton>
          <Button onClick={handleClose} color="primary">
            Lukk
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

interface StatItem {
  customer?: { companyName?: string | null; visits?: number | null } | null;
}

function Details({ items }: { items: (StatItem | null | undefined)[] }) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Kunde</TableCell>
          <TableCell align="right">Visninger</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((x, i) => {
          if (!x) return null;
          return (
            <TableRow key={i}>
              <TableCell>{x.customer?.companyName}</TableCell>
              <TableCell align="right">{x.customer?.visits}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

function getCode(x: DocumentViewItemFragment): string {
  if (!x.brregEntity) {
    return '';
  }
  if (!x.brregEntity.code) {
    return '';
  }
  return `${x.brregEntity.code.code} ${x.brregEntity.code.description}`;
}
