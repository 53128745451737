import { JSONContent } from '@tiptap/core';
import { useGuideCmsContext } from '../../GuideCmsContext';
import { NumberedNodes, useNumberedNodes } from '../../tiptap/numbering';

const pushIfNotExists = (node: JSONContent, list: JSONContent[], idSelector: (node: JSONContent) => unknown) => {
  if (!list.some((n) => idSelector(n) === idSelector(node))) {
    list.push(node);
  }
};

export const useReferencesContext = (): ReferencesContext => {
  const { editor } = useGuideCmsContext();
  const numberedNodes = useNumberedNodes();
  if (!editor || editor.isDestroyed) {
    return {
      references: {
        guideLinks: [],
        standardLinks: [],
        internalHeadingLinks: [],
        internalTableLinks: [],
        internalFigureLinks: [],
        internalAppLinks: [],
      },
      missingReferences: { tables: [], figures: [], apps: [] },
    };
  }
  const references = getReferences(editor.getJSON());
  return {
    references,
    missingReferences: getMissingReferences(editor.getJSON(), references, numberedNodes),
  };
};

interface ReferencesContext {
  references: References;
  missingReferences: MissingReferences;
}

interface References {
  guideLinks: JSONContent[];
  standardLinks: JSONContent[];
  internalHeadingLinks: JSONContent[];
  internalTableLinks: JSONContent[];
  internalFigureLinks: JSONContent[];
  internalAppLinks: JSONContent[];
}

interface MissingReferences {
  tables: JSONContent[];
  figures: JSONContent[];
  apps: JSONContent[];
}

const getReferences = (root: JSONContent) => {
  const references: References = {
    guideLinks: [],
    standardLinks: [],
    internalHeadingLinks: [],
    internalTableLinks: [],
    internalFigureLinks: [],
    internalAppLinks: [],
  };

  const findReferences = (node: JSONContent) => {
    if (node.type === 'guideLink') {
      pushIfNotExists(node, references.guideLinks, (n) => n.attrs?.guideId);
    }
    if (node.type === 'standardLink') {
      pushIfNotExists(node, references.standardLinks, (n) => n.attrs?.standardId);
    }
    if (node.type === 'internalLink' && node.attrs?.type === 'heading') {
      pushIfNotExists(node, references.internalHeadingLinks, (n) => n.attrs?.id);
    }
    if (node.type === 'internalLink' && node.attrs?.type === 'tableFigure') {
      pushIfNotExists(node, references.internalTableLinks, (n) => n.attrs?.id);
    }
    if (node.type === 'internalLink' && node.attrs?.type === 'imageFigure') {
      pushIfNotExists(node, references.internalFigureLinks, (n) => n.attrs?.id);
    }
    if (node.type === 'internalLink' && node.attrs?.type === 'app') {
      pushIfNotExists(node, references.internalAppLinks, (n) => n.attrs?.id);
    }
    if (node.content) {
      node.content?.forEach(findReferences);
    }
  };
  root.content?.forEach(findReferences);
  return references;
};

const getMissingReferences = (root: JSONContent, references: References, numberedNodes: NumberedNodes): MissingReferences => {
  const missingReferences: MissingReferences = {
    tables: [],
    figures: [],
    apps: [],
  };

  const findMissingReferences = (node: JSONContent) => {
    if (node.attrs?.id) {
      const numbering = numberedNodes.get(node.attrs.id);
      if (numbering?.h1Level === 0) {
        // It is not required to reference nodes on the first level 0
        return;
      }
    }
    if (node.type === 'tableFigure' && references.internalTableLinks.every((n) => n.attrs?.id !== node.attrs?.id)) {
      pushIfNotExists(node, missingReferences.tables, (n) => n.attrs?.id);
    }
    if ((node.type === 'imageFigure' || node.type === 'chartFigure') && references.internalFigureLinks.every((n) => n.attrs?.id !== node.attrs?.id)) {
      pushIfNotExists(node, missingReferences.figures, (n) => n.attrs?.id);
    }
    if (node.type === 'app' && references.internalAppLinks.every((n) => n.attrs?.id !== node.attrs?.id)) {
      pushIfNotExists(node, missingReferences.apps, (n) => n.attrs?.id);
    }
    if (node.content) {
      node.content?.forEach(findMissingReferences);
    }
  };
  root.content?.forEach(findMissingReferences);
  return missingReferences;
};
