import React, { FC } from 'react';
import { NodeViewWrapper, NodeViewProps } from '@tiptap/react';
import styled from '@emotion/styled';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { StandardLinkExtensionRenderOptions } from '../../../../../../shared/guide-cms/extensions/StandardLink';

const StyledStandardLink = styled.span`
  background-color: #3fbd7c;
  color: #ffffff;
  padding: 0px 1px;
`;

const renderText = (node: ProseMirrorNode) => {
  return node.attrs.standardName;
};

export const StandardLinkView: FC<NodeViewProps> = (props) => {
  return (
    <NodeViewWrapper className="react-component" as={'span'}>
      <StyledStandardLink title="Lenke til standard">{props.node.attrs.standardName}</StyledStandardLink>
    </NodeViewWrapper>
  );
};

export const getStandardLinkExtensionRenderOptions = (): StandardLinkExtensionRenderOptions => {
  return {
    viewComponent: StandardLinkView,
    renderText: (node) => renderText(node),
  };
};
