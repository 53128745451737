import React, { useEffect } from 'react';
import { Table, TableHead, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { formatDate } from '../Formatters';
import { StyledLink } from '../Components';
import { useMutation, useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { GET_TASKS_FOR_STANDARD } from './standards.graphql';
import { Unpacked } from '../graphQLTypes/types';
import { GetTasksForStandardQuery } from '../__generated__/graphql';
import { StyledPaper } from '../theme';
import { ADD_STANDARD_TASK } from '../tasks/task.graphql';
import { AddTaskDialog, AddTaskDialogProps, AddTaskDialogResult } from '../tasks/AddTaskDialog';
import { useModal } from '../dialogs/useModal';

export function Tasks({ standardId, addTaskTrigger }: { standardId: string; addTaskTrigger: number }) {
  const { data, loading, refetch } = useQuery(GET_TASKS_FOR_STANDARD, { variables: { id: standardId }, fetchPolicy: 'cache-and-network' });
  const [addTask] = useMutation(ADD_STANDARD_TASK);
  const addTaskModal = useModal<AddTaskDialogProps, AddTaskDialogResult>({ data: {} });

  useEffect(() => {
    if (addTaskTrigger) {
      addTaskModal.open().then((result) => {
        if (result) {
          addTask({ variables: { input: { standardId, title: result.title, text: result.text } } });
          refetch();
        }
      });
    }
  }, [addTaskTrigger]);

  return (
    <StyledPaper>
      <Typography variant="h5" component="h3">
        Oppgaver
      </Typography>
      {loading && <LoadingSpinner />}
      {!data && <div>Ingen oppgaver</div>}
      {data?.tasks && <TaskTable tasks={data.tasks} />}
      {addTaskModal.isOpen && <AddTaskDialog modal={addTaskModal} />}
    </StyledPaper>
  );
}

type Task = Unpacked<GetTasksForStandardQuery['tasks']>;

function TaskTable({ tasks }: { tasks: Task[] }) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Tittel</TableCell>
          <TableCell>Tekst</TableCell>
          <TableCell>Opprettet av</TableCell>
          <TableCell>Dato</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tasks.map((task) => {
          if (!task) return null;
          return (
            <TableRow key={task.id}>
              <TableCell>
                <StyledLink to={`/task/${task.id}`}>{task.title}</StyledLink>
              </TableCell>
              <TableCell>{task.text}</TableCell>
              <TableCell>{task.createdByName}</TableCell>
              <TableCell>{formatDate(task.createdAt)}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
