import React, { FC } from 'react';
import { Modal, useModal } from '../../../dialogs/useModal';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import styled from '@emotion/styled';
import { Delete, Upload, Edit as EditIcon } from '@mui/icons-material';
import { DELETE_FIGURE } from '../content.graphql';
import { useMutation } from '@apollo/client';
import { useGuideCmsContext } from '../GuideCmsContext';
import { Figure, getThumbnailUrl, useFigures } from './figureHelper';
import LoadingSpinner from '../../../LoadingSpinner';
import { ConfirmDialog, ConfirmDialogProps } from '../../../dialogs/ConfirmDialog';
import { GuideFigureStatus } from './GuideFigureStatus';
import { GuideFigureDialogProps, GuideFigureDialogResult } from './GuideFigureDialog';

const StyledFiguresContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
`;

const CardActionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  gap: 4px;
  width: 100%;
  align-items: center;
`;

export const GuideFiguresOverview: FC<{
  onUploadClick: () => void;
  onEditClick: (figureId: string) => void;
  modal: Modal<GuideFigureDialogProps, GuideFigureDialogResult>;
}> = ({ onUploadClick, onEditClick, modal }) => {
  const { guideId } = useGuideCmsContext();
  const { figures } = useFigures(guideId);
  const { close } = modal;

  const handleOnClose = () => {
    close();
  };

  const handleOnInsert = (figureId: string) => {
    close({ insertFigureId: figureId });
  };

  return (
    <>
      <DialogTitle>Figurer</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <Stack direction="row" gap={2}>
            <Button onClick={onUploadClick} variant="contained" endIcon={<Upload />}>
              Last opp figur
            </Button>
          </Stack>

          <Typography variant="h6">Filer i anvisningen</Typography>
          <StyledFiguresContainer>
            {figures.map((figure) => (
              <FigureOverviewCard key={figure.id} figure={figure} onEditClick={onEditClick} onInsertClick={handleOnInsert} />
            ))}
          </StyledFiguresContainer>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleOnClose}>
          Lukk
        </Button>
      </DialogActions>
    </>
  );
};

const FigureOverviewCard: FC<{
  figure: Figure;
  onInsertClick: (figureId: string) => void;
  onEditClick: (figureId: string) => void;
}> = ({ onInsertClick, onEditClick, figure }) => {
  const [deleteFigureMutation, { loading: isDeleting }] = useMutation(DELETE_FIGURE);
  const deleteConfirmModal = useModal<ConfirmDialogProps, boolean>({
    data: { title: 'Slett figur', message: 'Er du sikker på at du vil slette denne figuren?', submitText: 'Slett' },
  });

  const handleOnDelete = async (figureId: string) => {
    var confirmed = await deleteConfirmModal.open();
    if (!confirmed) return;

    deleteFigureMutation({
      variables: {
        input: {
          figureId,
        },
      },
    });
  };
  const thumbnailUrl = getThumbnailUrl(figure) ?? 'https://placehold.co/500x300?text=Mangler+bilde';
  return (
    <Card key={figure.id}>
      {isDeleting && (
        <CardContent>
          <LoadingSpinner />
        </CardContent>
      )}
      {!isDeleting && (
        <>
          <CardMedia sx={{ height: 140 }} image={thumbnailUrl} />
          <CardActions>
            <CardActionsContainer>
              <div>
                <Button size="small" onClick={() => onInsertClick(figure.id)}>
                  Sett inn
                </Button>
              </div>

              <IconButton size="small" onClick={() => onEditClick(figure.id)} title="Rediger">
                <EditIcon />
              </IconButton>
              <IconButton size="small" onClick={() => handleOnDelete(figure.id)} title="Slett">
                <Delete />
              </IconButton>
              <GuideFigureStatus figureId={figure.id} />
            </CardActionsContainer>
          </CardActions>
          {deleteConfirmModal.isOpen && <ConfirmDialog modal={deleteConfirmModal} />}
        </>
      )}
    </Card>
  );
};
