import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { AddAlert, Check } from '@mui/icons-material';
import { IconButton, Table, TableHead, TableRow, TableCell, CircularProgress } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroller';
import { StyledLink } from '../Components';
import { ColWidths, TableCellHead, TableCellEllipsis, TableCellIcon } from '../components/TableComponents';
import { Unpacked } from '../graphQLTypes/types';
import { GetAllStandardsQuery } from '../__generated__/graphql';
import { useMutation } from '@apollo/client';
import { ADD_STANDARD_TASK } from '../tasks/task.graphql';
import { AddTaskDialog, AddTaskDialogProps, AddTaskDialogResult } from '../tasks/AddTaskDialog';
import { useModal } from '../dialogs/useModal';

const StyledCellHead = styled(TableCellHead)(({ theme }) => ({
  position: 'sticky' as 'sticky',
  top: theme.spacing(8),
  background: 'white',
  zIndex: 1,
}));

type Standard = Unpacked<GetAllStandardsQuery['standards']>;

export function StandardTable({ standards, onTaskCreated }: { standards: Standard[]; onTaskCreated(): void }) {
  const [numberOfItems, setNumberOfItems] = useState(50);

  return (
    <Table size="small" sx={{ postions: 'relative' }}>
      <ColWidths widths={[60, 250, null, 200, 120, 300, 60, 60]}></ColWidths>
      <TableHead>
        <TableRow>
          <StyledCellHead>Gyldig</StyledCellHead>
          <StyledCellHead>ID</StyledCellHead>
          <StyledCellHead>Tittel</StyledCellHead>
          <StyledCellHead>Utgave</StyledCellHead>
          <StyledCellHead>Språk</StyledCellHead>
          <StyledCellHead>ErstattetAv(EA)/ Erstatter(E)</StyledCellHead>
          <StyledCellHead>Anvisninger</StyledCellHead>
          <StyledCellHead />
        </TableRow>
      </TableHead>
      <InfiniteScroll element="tbody" loadMore={() => setNumberOfItems(numberOfItems + 50)} hasMore={standards.length > numberOfItems}>
        {standards.map((standard) => {
          if (!standard) return null;
          return (
            <TableRow key={standard.id}>
              <TableCellIcon>{standard.isValid && <Check fontSize="small" />}</TableCellIcon>
              <TableCellEllipsis>
                <StyledLink title={standard.name} to={`${standard.id}`}>
                  {standard.name}
                </StyledLink>
              </TableCellEllipsis>
              <TableCellEllipsis>{standard.title}</TableCellEllipsis>
              <TableCell>{standard.version}</TableCell>
              <TableCell>{standard.language}</TableCell>
              <TableCellEllipsis title={standard.link}>{standard.link}</TableCellEllipsis>
              <TableCell align="center">{standard.guideRevisions?.length}</TableCell>
              <TaskCell standard={standard} onTaskCreated={onTaskCreated} />
            </TableRow>
          );
        })}
      </InfiniteScroll>
    </Table>
  );
}

function TaskCell({ standard, onTaskCreated }: { standard: NonNullable<Standard>; onTaskCreated: () => void }) {
  const [addTask, { loading }] = useMutation(ADD_STANDARD_TASK);
  const addTaskModal = useModal<AddTaskDialogProps, AddTaskDialogResult>({ data: {} });

  const addStandardTask = async (): Promise<any> => {
    const result = await addTaskModal.open();
    if (result) {
      await addTask({ variables: { input: { standardId: standard.id, title: result.title, text: result.text } } });
      onTaskCreated();
    }
  };

  return (
    <>
      <TableCellIcon>
        {standard.guideRevisions && standard.guideRevisions.length > 0 && (
          <IconButton
            style={{ padding: 5 }}
            title="Varsle endring"
            disabled={loading}
            onClick={() => addStandardTask()}
            color="primary"
            aria-label="Add task"
            size="large">
            {!loading && <AddAlert />}
            {loading && <CircularProgress size={25} />}
          </IconButton>
        )}
      </TableCellIcon>
      {addTaskModal.isOpen && <AddTaskDialog modal={addTaskModal} />}
    </>
  );
}
