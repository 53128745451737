import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InfoIcon from '@mui/icons-material/Info';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Typography,
} from '@mui/material';

import { Tooltip } from '@mui/material';
import { ADD, FETCH_STANDARD } from './standards.graphql';
import { Clear, GetApp } from '@mui/icons-material';
import LoadingSpinner from '../LoadingSpinner';
import { GetStandardFromAuthorityQuery, StandardAddMutation } from '../__generated__/graphql';
import { Modal } from '../dialogs/useModal';

type Standard = GetStandardFromAuthorityQuery['standard'];
export type StandardAdded = NonNullable<StandardAddMutation['standardAdd']>['standardModel'];

export function AddStandardDialog({ modal }: { modal: Modal<undefined, StandardAdded> }) {
  const [productId, setProductId] = useState('');
  const [productIdError, setProductIdError] = useState<string | undefined>(undefined);
  const [alreadyExists, setAlreadyExists] = useState<string | undefined>(undefined);
  const [standard, setStandard] = useState<Standard>(getDefaultStandard());
  const [fetchStandard, { data, loading }] = useLazyQuery(FETCH_STANDARD, {
    onCompleted: (d) => {
      if (!d.standard) {
        if (productId !== '') setProductIdError('Klarte ikke hente fra standard.no. Er det riktig produkt id?');
        else setProductIdError(undefined);
        setStandard(getDefaultStandard());
      } else {
        setProductIdError(undefined);
        setStandard(data!.standard);
      }
    },
  });

  const [addStandard] = useMutation(ADD, {
    variables: {
      input: {
        isValid: standard?.isValid === true,
        externalId: standard?.externalId,
        name: standard?.name,
        title: standard?.title,
        version: standard?.version,
        language: standard?.language,
        parent: standard?.parent,
        child: standard?.child,
      },
    },
  });

  const handleClose = async () => {
    modal.close();
  };

  const handleOk = async () => {
    setAlreadyExists(undefined);
    const result = await addStandard();
    const newStandard = result.data?.standardAdd.standardModel;
    if (newStandard) modal.close(result.data?.standardAdd.standardModel);
    else {
      if (result.data?.standardAdd.errors?.some((e) => e.code === 'StandardAlreadyExistsError'))
        setAlreadyExists('Denne standarden er allerede lagt inn');
    }
  };

  const doFetchStandard = () => {
    setProductIdError(undefined);
    fetchStandard({ variables: { id: productId } });
  };

  const clearStandard = () => {
    setProductId('');
    setProductIdError(undefined);
    setAlreadyExists(undefined);
    setStandard(getDefaultStandard());
  };

  const isExternal = standard?.externalId !== '';
  const isValidStandard = standard?.name !== '' && standard?.version !== '' && standard?.title !== '';
  return (
    <div>
      <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Legg til ny standard</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>Hent fra standard.no</DialogContentText>
          <Info />
          <TextField
            margin="dense"
            label="Produkt ID"
            type="text"
            autoFocus
            value={productId}
            onChange={(x) => setProductId(x.target.value)}
            error={productIdError !== undefined}
            helperText={productIdError}
            disabled={isExternal}
          />
          {!isExternal && (
            <IconButton sx={{ marginTop: 0.8 }} disabled={productId === ''} onClick={() => doFetchStandard()} title="Hent" size="large">
              <GetApp />
            </IconButton>
          )}
          {isExternal && (
            <IconButton sx={{ marginTop: 0.8 }} disabled={standard?.externalId === ''} onClick={() => clearStandard()} title="Tøm" size="large">
              <Clear />
            </IconButton>
          )}
          {loading && <LoadingSpinner />}
          <StandardForm standard={standard} setStandard={setStandard} />
          {alreadyExists && <Typography color="error">Denne standarden er allerede lagt inn</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Avbryt</Button>
          <Button onClick={handleOk} variant="contained" color="primary" disabled={!isValidStandard}>
            Legg til
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function Info() {
  const exampleStyle = {
    border: '2px red solid',
    paperWidthSm: 12,
  };
  const example = (
    <div>
      Fra eksempel url:{' '}
      <div>
        https://online.standard.no/<span style={exampleStyle}>ns-3940-2007</span>
      </div>
    </div>
  );
  return (
    <table>
      <tbody>
        <tr>
          <td>
            <a href={`https://online.standard.no/search?q=NS`} target="_blank" rel="noopener noreferrer">
              Finn produktId på standard.no
            </a>
          </td>
          <td>
            <Tooltip title={example}>
              <InfoIcon />
            </Tooltip>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function StandardForm({ standard, setStandard }: { standard: Standard; setStandard(s: Standard): void }) {
  const readonly = standard?.externalId && standard.externalId.length > 0;

  const setFormState = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    const newStandard: Standard = { ...standard, [name]: value } as Standard;
    setStandard(newStandard);
  };

  if (standard == null) return null;
  return (
    <form>
      <fieldset style={{ marginTop: 20 }} disabled={readonly == true}>
        <FormControlLabel control={<Checkbox name="isValid" color="primary" checked={standard?.isValid} onChange={setFormState} />} label="Gyldig" />
        <TextField name="name" margin="dense" label="ID" type="text" required value={standard?.name} onChange={setFormState} fullWidth />
        <TextField name="title" margin="dense" label="Tittel" type="text" required value={standard?.title} onChange={setFormState} fullWidth />
        <TextField name="version" margin="dense" label="Versjon" type="text" required value={standard.version} onChange={setFormState} fullWidth />
        <TextField name="language" margin="dense" label="Språk" type="text" value={standard.language} onChange={setFormState} fullWidth />
        <TextField name="parent" margin="dense" label="Erstatter" type="text" value={standard.parent} onChange={setFormState} fullWidth />
        <TextField name="child" margin="dense" label="Erstattet av" type="text" value={standard.child} onChange={setFormState} fullWidth />
      </fieldset>
    </form>
  );
}

function getDefaultStandard(): Standard {
  return {
    id: 0,
    externalId: '',
    name: '',
    title: '',
    version: '',
    language: '',
    link: '',
    isValid: true,
    parent: '',
    child: '',
  };
}
