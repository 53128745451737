import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface History {
  entries: Location[];
}
const numberOfEntries = 3;

const initialState: History = {
  entries: [],
};

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    push: (state, action: PayloadAction<Location>) => {
      state.entries.push(action.payload);
      if (state.entries.length > numberOfEntries) {
        state.entries.shift();
      }
    },
  },
});

export const { push } = historySlice.actions;

export const selectHistory = (state: RootState) => state.history;

export default historySlice.reducer;
