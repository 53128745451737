import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';

import { Attributes } from '@tiptap/core';

const CustomTableCellAttributes = (): Attributes => {
  return {
    borderTop: {
      default: true,
      keepOnSplit: true,
      renderHTML: (attributes) => {
        return {
          style: `${attributes.borderTop === false ? 'border-top-width: 0;' : ''}`,
        };
      },
    },
    borderLeft: {
      default: true,
      keepOnSplit: true,
      renderHTML: (attributes) => {
        return {
          style: `${attributes.borderLeft === false ? 'border-left-width: 0;' : ''}`,
        };
      },
    },
    borderBottom: {
      default: true,
      keepOnSplit: true,
      renderHTML: (attributes) => {
        return {
          style: `${attributes.borderBottom === false ? 'border-bottom-width: 0;' : ''}`,
        };
      },
    },
    borderRight: {
      default: true,
      keepOnSplit: true,
      renderHTML: (attributes) => {
        return {
          style: `${attributes.borderRight === false ? 'border-right-width: 0;' : ''}`,
        };
      },
    },
  };
};

export const CustomTableCell = TableCell.extend({
  content: '(paragraph|bulletList|orderedList|imageFigure|tableFigure)+',
  addAttributes() {
    return {
      ...this.parent?.(),
      ...CustomTableCellAttributes(),
    };
  },
});

export const CustomTableHeader = TableHeader.extend({
  content: '(paragraph|bulletList|orderedList|imageFigure|tableFigure)+',
  addAttributes() {
    return {
      ...this.parent?.(),
      ...CustomTableCellAttributes(),
    };
  },
});
