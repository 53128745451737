import { TableImproved } from 'mui-tiptap';
import {} from '@tiptap/pm/tables';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    setTableWidth: {
      setTableWidth: (fullWidth: boolean) => ReturnType;
    };
  }
}

export const Table = TableImproved.extend({
  addAttributes() {
    return {
      fullWidth: {
        default: false,
      },
    };
  },
  addCommands() {
    return {
      ...this.parent?.(),
      setTableWidth:
        (fullWidth: boolean) =>
        ({ commands }) => {
          return commands.updateAttributes('table', { fullWidth: fullWidth });
        },
    };
  },
}).configure({ resizable: true });
