import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from '@apollo/client';
import { Unpacked } from '../graphQLTypes/types';
import { GetUsersQuery } from '../__generated__/graphql';
import { GET_USERS } from './users.graphql';

type User = Unpacked<GetUsersQuery['users']>


export function UserSelector({
  userId,
  users,
  onChange,
  allowNull = true,
}: {
  userId?: string;
  onChange(userId: string | undefined): void;
  users?: User[];
  allowNull?: boolean;
}) {
  return users ? (
    <UserSelectorWithoutDataLoading userId={userId} users={users} onChange={onChange} allowNull={allowNull} />
  ) : (
    <UserSelectorWithDataLoading userId={userId} onChange={onChange} allowNull={allowNull} />
  );
}

function UserSelectorWithDataLoading({
  userId,
  onChange,
  allowNull,
}: {
  userId?: string;
  onChange(userId: string | undefined): void;
  allowNull: boolean;
}) {
  const { data } = useQuery(GET_USERS);
  if (data && data.users) return <UserSelectorWithoutDataLoading userId={userId} users={data.users} onChange={onChange} allowNull={allowNull} />;

  return (
    <Select variant="standard" value={userId ?? ''}>
      <MenuItem value={userId ?? ''}>
        <em>Laster..</em>
      </MenuItem>
    </Select>
  );
}

function UserSelectorWithoutDataLoading({
  userId,
  users,
  onChange,
  allowNull,
}: {
  userId?: string;
  onChange(userId: string | undefined): void;
  users: User[];
  allowNull: boolean;
}) {
  return (
    <Select
      value={userId ?? 'null'}
      onChange={(e) => onChange(e.target.value === 'null' ? undefined : (e.target.value as string))}
      variant="standard"
    >
      {allowNull && (
        <MenuItem key={-1} value={'null'}>
          <em>Ingen</em>
        </MenuItem>
      )}
      {users.map((x, i) => {
        if (!x) return null;
        return (
          <MenuItem key={i} value={x.subjectId}>
            {x.name}
          </MenuItem>
        );
      })}
    </Select>
  );
}