import React from 'react';
import { Link } from 'react-router-dom';

import { StyledLink } from '../Components';
import { IconButton, Typography, Grid2 as Grid, Radio, List, ListItem } from '@mui/material';
import { Edit, RadioButtonUnchecked, RadioButtonChecked } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { UPDATE_MAIN_CATEGORY } from './guide.graphql';
import { GetGuideQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';

type Category = Unpacked<NonNullable<GetGuideQuery['guide']>['categories']>;

interface CategoriesProps {
  guideId: string;
  guideMainCategoryId: string;
  categories: Category[];
  elevation?: number;
}

export function Categories({ guideId, guideMainCategoryId, categories, elevation }: CategoriesProps) {
  return (
    <StyledPaper elevation={elevation}>
      <Typography variant="h6">
        Fagområder
        <IconButton component={Link} to={`/guide/categories/${guideId}`} size="large">
          <Edit />
        </IconButton>
      </Typography>
      {categories.length > 0 && <CategoryList categories={categories} guideId={guideId} guideMainCategoryId={guideMainCategoryId} />}
    </StyledPaper>
  );
}

function CategoryList({ categories, guideId, guideMainCategoryId }: { categories: Category[]; guideId: string; guideMainCategoryId: string }) {
  const [setGuideMainCategory] = useMutation(UPDATE_MAIN_CATEGORY);
  if (categories.length === 0)
    return (
      <Typography variant="subtitle1" color="textSecondary">
        Ingen angitt
      </Typography>
    );
  return (
    <List dense>
      <ListItem sx={{ paddingTop: 2, addingBottom: 2, alignItems: 'flex-start' }}>
        <Grid container justifyContent="flex-end">
          <Typography variant="caption">Hovedfagområde</Typography>
        </Grid>
      </ListItem>
      {categories.map((x) => {
        if (!x) return null;
        const isMainCategory = x.id === guideMainCategoryId;
        return (
          <ListItem key={x.id}>
            <Grid container justifyContent="space-between" alignItems="center">
              <StyledLink to={`/category/${x.id}`}>{x.title}</StyledLink>
              <Radio
                checked={isMainCategory}
                onChange={() => setGuideMainCategory({ variables: { input: { id: guideId, categoryId: x.id } } })}
                value={guideMainCategoryId}
                icon={<RadioButtonUnchecked fontSize="small" />}
                checkedIcon={<RadioButtonChecked fontSize="small" />}
                style={{ padding: 5 }}
              />
            </Grid>
          </ListItem>
        );
      })}
    </List>
  );
}
