import React, { FC } from 'react';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { BlockNode } from '../BlockNode';
import { Card, IconButton, Typography } from '@mui/material';
import { Delete, Extension as ExtensionIcon } from '@mui/icons-material';
import styled from '@emotion/styled';
import { theme } from '../../../../../theme';
import { GET_APPS } from '../../../../guide.graphql';
import { useQuery } from '@apollo/client';
import { getHtmlId, getTypeDescription, useNumberedNode } from '../../numbering';
import { capitalizeFirstLetter } from '../../../../../utils/string';

export const AppView: FC<NodeViewProps> = (nodeViewProps) => {
  const { data, loading } = useQuery(GET_APPS);
  const StyledCard = styled(Card)`
    position: relative;
    height: 200px;
    margin: ${theme.spacing(2)} 0;
  `;

  const StyledIconBackground = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: ${theme.palette.action.selected};
  `;

  const StyledCardContainer = styled.div`
    padding: ${theme.spacing(2)};
    display: grid;
    grid-template-columns: 1fr auto;
  `;

  const app = data?.apps?.find((app) => !!app && app.id === nodeViewProps.node.attrs.appId);
  const node = useNumberedNode(nodeViewProps.node.attrs.id);
  return (
    <NodeViewWrapper className="react-component" id={node ? getHtmlId(node) : ''}>
      <BlockNode props={nodeViewProps}>
        <Typography variant="caption">
          {capitalizeFirstLetter(getTypeDescription('app'))} {node?.numbering}
        </Typography>
        <StyledCard sx={{ height: '400px' }}>
          <StyledIconBackground>
            <ExtensionIcon sx={{ fontSize: '100px', color: theme.palette.action.disabled }} />
          </StyledIconBackground>
          <StyledCardContainer>
            {app && (
              <div>
                {app.name} (v. {app.version})
              </div>
            )}
            {!app && !loading && <div>App: {nodeViewProps.node.attrs.appId}</div>}
            <div>
              {nodeViewProps.editor.isEditable && (
                <IconButton title="Fjern app" onClick={() => nodeViewProps.deleteNode()}>
                  <Delete />
                </IconButton>
              )}
            </div>
          </StyledCardContainer>
        </StyledCard>
      </BlockNode>
    </NodeViewWrapper>
  );
};
