import React, { FC, useEffect, useState } from 'react';
import { FormControlLabel, IconButton, Switch, Tooltip, Typography } from '@mui/material';
import { StyledPaper } from '../theme';
import { DataSourceInfo, useDataSourceApi } from './useDataSourceApi';
import LoadingSpinner from '../LoadingSpinner';
import { Add, Archive, CheckCircle, ModeEdit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../Formatters';
import {
  DataGridPro,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { nbNO } from '@mui/x-data-grid-pro/locales';

export const Overview: FC = () => {
  const navigate = useNavigate();

  const api = useDataSourceApi();
  const apiRef = useGridApiRef();
  const [isLoading, setIsLoading] = useState(true);
  const [dataSources, setDataSources] = useState<DataSourceInfo[]>([]);
  const [filteredDataSources, setFilteredDataSources] = useState<DataSourceInfo[]>([]);
  const [showArchived, setShowArchived] = useState(false);

  const handleOnArchiveFilterChange = (isChecked: boolean) => {
    setShowArchived(isChecked);
    filterDataSources(dataSources, isChecked);
  };

  const filterDataSources = (dataSources: DataSourceInfo[], showArchived: boolean) => {
    setFilteredDataSources(dataSources.filter((x) => showArchived || !x.isArchived));
  };

  const filteredDataSourcesWithCategory = filteredDataSources.map((ds) => ({
    ...ds,
    category: ds.meta?.find((x) => x.key === 'Category')?.value ?? '',
  }));

  const fetchDataSources = async () => {
    try {
      const result = await api.getLatestVersions();
      setDataSources(result);
      filterDataSources(result, showArchived);
    } finally {
      setIsLoading(false);
    }
  };

  const addDataSource = async () => {
    const dataSource = await api.createNew();
    navigate(`/datasource/${dataSource.correlationId}`);
  };

  useEffect(() => {
    fetchDataSources();
  }, []);

  return (
    <StyledPaper>
      <Typography variant="h5" gutterBottom>
        Datakilder
        <IconButton sx={{ marginLeft: 2, marginBottom: '4px' }} onClick={addDataSource}>
          <Add />
        </IconButton>
      </Typography>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          <div>
            <FormControlLabel
              control={<Switch />}
              checked={showArchived}
              onChange={(_, checked) => handleOnArchiveFilterChange(checked)}
              label="Vis arkiverte"
            />
          </div>
          <DataGridPro
            density="compact"
            apiRef={apiRef}
            localeText={nbNO.components.MuiDataGrid.defaultProps.localeText}
            pagination
            sx={{
              // disable cell selection style
              '.MuiDataGrid-cell:focus': {
                outline: 'none',
              },
              // pointer cursor on ALL rows
              '& .MuiDataGrid-row:hover': {
                cursor: 'pointer',
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
              sorting: {
                sortModel: [
                  {
                    field: 'updatedAt',
                    sort: 'desc',
                  },
                ],
              },
            }}
            rows={filteredDataSourcesWithCategory}
            columns={[
              {
                headerName: '',
                field: 'Status',
                width: 50,
                filterable: false,
                sortable: false,
                disableColumnMenu: true,
                renderCell(params) {
                  const dataSource = params.row as DataSourceInfo;
                  if (dataSource.isArchived) {
                    return (
                      <Tooltip title="Arkivert">
                        <Archive color="action" />
                      </Tooltip>
                    );
                  } else if (dataSource.publishedAt) {
                    return (
                      <Tooltip title="Publisert">
                        <CheckCircle color="success" />
                      </Tooltip>
                    );
                  } else {
                    return (
                      <Tooltip title="Utkast">
                        <ModeEdit color="info" />
                      </Tooltip>
                    );
                  }
                },
              },
              { headerName: 'Navn', field: 'name', width: 500 },
              { headerName: 'Versjon', field: 'version', width: 100 },
              {
                headerName: 'Kategori',
                field: 'category',
                width: 100,
              },
              {
                headerName: 'Sist oppdatert',
                field: 'updatedAt',
                width: 160,
                renderCell(params) {
                  return <>{formatDate(params.value as string)}</>;
                },
              },
              { headerName: 'Beskrivelse', field: 'description', width: 400 },
            ]}
            onRowSelectionModelChange={(selectionModel, details) => {
              const selectedRows = apiRef.current.getSelectedRows();
              const selectedDataSourceId = selectionModel[0];
              if (!selectedDataSourceId) {
                return;
              }
              const selectedDataSource = selectedRows.get(selectedDataSourceId) as DataSourceInfo;
              navigate(`/datasource/${selectedDataSource.correlationId}`);
            }}
            slots={{
              toolbar: () => (
                <GridToolbarContainer>
                  <GridToolbarQuickFilter />
                  <GridToolbarColumnsButton />
                  <GridToolbarFilterButton />
                </GridToolbarContainer>
              ),
            }}
            autoHeight
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </>
      )}
    </StyledPaper>
  );
};
