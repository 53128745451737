import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { Criterion, selectCriterion } from './criterionSlice';
import { BacklogItemWithRank } from './types';
import { useModal } from '../dialogs/useModal';
import { RankDetailsDialog, RankDetailsDialogProps } from './RankDetailsDialog';

export function RankCell({ item }: { item: BacklogItemWithRank }) {
  const { ignoreCriteria }: Criterion = useSelector(selectCriterion);
  const modalDetails = useModal<RankDetailsDialogProps, unknown>({
    data: { guideId: item.id, docName: item.docName, docTitle: item.docTitle, ignoreCriteria },
  });
  const openRankDetails = () => {
    modalDetails.open();
  };

  return (
    <>
      <Button onClick={() => openRankDetails()} variant="text">
        {Math.round(item.rank)}
      </Button>
      {modalDetails.isOpen && <RankDetailsDialog modal={modalDetails} />}
    </>
  );
}
