import styled from '@emotion/styled';
import React, { FC } from 'react';
import { theme } from '../../../../theme';
import { SidebarHeading } from '../SidebarHeading';
import { Link as LinkIcon, LinkOff as LinkOffIcon } from '@mui/icons-material';
import { getHtmlId, getLongTypeDescription, NumberedNodeType, useNumberedNodes } from '../../tiptap/numbering';
import { capitalizeFirstLetter } from '../../../../utils/string';
import { StyledLink } from '../../../../Components';
import { Alert, AlertTitle } from '@mui/material';
import { scrollElementIntoView } from '../../navigate';
import { useReferencesContext } from './useReferencesContext';

const DrawerContent = styled.div`
  padding: ${theme.spacing(2)};
`;

const Header = styled.div`
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 6px;
`;

export const References: FC = () => {
  const { references, missingReferences } = useReferencesContext();
  const hasMissingReferences = missingReferences.tables.length > 0 || missingReferences.figures.length > 0 || missingReferences.apps.length > 0;
  return (
    <DrawerContent>
      {hasMissingReferences && (
        <>
          <Alert severity="warning" icon={<LinkOffIcon />}>
            <AlertTitle>Manglende referanser</AlertTitle>
            Tabeller, figurer, grafer og apper skal være referert i teksten. Sjekk at alle referanser er lagt til.
          </Alert>
          {missingReferences.tables.length > 0 && (
            <>
              <Header>Tabeller som mangler referanse</Header>
              {missingReferences.tables.map((node, index) => (
                <InternalNode key={index} id={node.attrs?.id} />
              ))}
            </>
          )}
          {missingReferences.figures.length > 0 && (
            <>
              <Header>Figurer som mangler referanse</Header>
              {missingReferences.figures.map((node, index) => (
                <InternalNode key={index} id={node.attrs?.id} />
              ))}
            </>
          )}
          {missingReferences.apps.length > 0 && (
            <>
              <Header>Apper som mangler referanse</Header>
              {missingReferences.apps.map((node, index) => (
                <InternalNode key={index} id={node.attrs?.id} />
              ))}
            </>
          )}
          <br />
        </>
      )}
      <SidebarHeading icon={<LinkIcon />}>Referanser</SidebarHeading>
      {references.guideLinks.length > 0 && (
        <>
          <Header>Anvisninger</Header>
          {references.guideLinks.map((node, index) => (
            <div key={index}>
              <StyledLink to={`/guide/${node.attrs?.guideId}`}>{node.attrs?.docName}</StyledLink>
            </div>
          ))}
        </>
      )}
      {references.standardLinks.length > 0 && (
        <>
          <Header>Standarder</Header>
          {references.standardLinks.map((node, index) => (
            <div key={index}>
              <StyledLink to={`/standard/${node.attrs?.standardId}`}>{node.attrs?.standardName}</StyledLink>
            </div>
          ))}
        </>
      )}
      {references.internalHeadingLinks.length > 0 && (
        <>
          <Header>Punkter</Header>
          {references.internalHeadingLinks.map((node, index) => (
            <InternalNode key={index} id={node.attrs?.id} />
          ))}
        </>
      )}
      {references.internalTableLinks.length > 0 && (
        <>
          <Header>Tabeller</Header>
          {references.internalTableLinks.map((node, index) => (
            <InternalNode key={index} id={node.attrs?.id} />
          ))}
        </>
      )}
      {references.internalFigureLinks.length > 0 && (
        <>
          <Header>Figurer</Header>
          {references.internalFigureLinks.map((node, index) => (
            <InternalNode key={index} id={node.attrs?.id} />
          ))}
        </>
      )}
      {references.internalAppLinks.length > 0 && (
        <>
          <Header>Apper</Header>
          {references.internalAppLinks.map((node, index) => (
            <InternalNode key={index} id={node.attrs?.id} />
          ))}
        </>
      )}
    </DrawerContent>
  );
};

const InternalNode: FC<{ id: string }> = ({ id }) => {
  const numberedNodes = useNumberedNodes();
  const numberedNode = numberedNodes.get(id);
  if (!numberedNode) {
    return null;
  }
  return (
    <div>
      <StyledLink
        to=""
        onClick={() => {
          scrollElementIntoView(getHtmlId(numberedNode));
        }}>
        {capitalizeFirstLetter(getLongTypeDescription(numberedNode?.type as NumberedNodeType))} {numberedNode?.numbering}
      </StyledLink>
    </div>
  );
};
