import React from 'react';

import { Typography, TextField } from '@mui/material';
import { EditableTextField } from '../components/EditableTextField';
import { UPDATE_FREE_TEXT } from './revisions.graphql';
import { useMutation } from '@apollo/client';
import { Revision } from './Details';
import { StyledPaper } from '../theme';

export function RevisionFreeText({ revision, readonly }: { revision: Revision; readonly?: boolean }) {
  const [updateFreeText] = useMutation(UPDATE_FREE_TEXT);
  return (
    <StyledPaper>
      <Typography variant="h5" component="h3">
        Tekst
      </Typography>

      {readonly ? (
        revision?.freeText
      ) : (
        <EditableTextField
          value={revision?.freeText ?? ''}
          TextFieldProps={{
            multiline: true,
          }}
          onUpdate={async (value) => {
            await updateFreeText({ variables: { input: { id: revision?.id, text: value } } });
          }}
        />
      )}
    </StyledPaper>
  );
}
