import { gql } from "../__generated__";

export const USERS = gql(/* GraphQL */ `
  query GetUsersForResources {
    users {
      subjectId
      name
    }
  }
`);

export const REVISIONS = gql(/* GraphQL */ `
  query GetRevisionsForResources {
    revisions(where: { and: [{ completedAt: { eq: null } }, { canceledAt: { eq: null } }] }) {
      id
      scheduledEndDate
      progress
      authors {
        id
        name
      }
      professionQualityAssurers {
        id
        name
      }
      currentPhase
      roles {
        role
        user {
          subjectId
          name
        }
      }
      guide {
        id
        docName
        docTitle
        owner {
          subjectId
          name
        }
        mainCategory {
          title
        }
      }
    }

    users {
      id: subjectId
      name
    }

    resources: subjectResources {
      id
      name
    }
  }
`);
