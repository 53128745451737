import React from 'react';
import { Typography } from '@mui/material';
import { StyledLink } from '../Components';
import { MyParticipationTable } from './MyParticipationTable';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { GET_REVISIONS_WITH_USER } from './MyParticipationInRevisions.graphql';
import { Unpacked } from '../graphQLTypes/types';
import { GetRevisionsWithUserQuery } from '../__generated__/graphql';
import { StyledPaper } from '../theme';

export type Revision = Unpacked<GetRevisionsWithUserQuery['revisions']>;

export function MyParticipationInRevisions() {
  const { data, loading } = useQuery(GET_REVISIONS_WITH_USER);
  return (
    <StyledPaper>
      <Typography variant="h5" component="h3" style={{ paddingBottom: 15 }}>
        Mine roller i pågående revisjoner
      </Typography>
      {loading && <LoadingSpinner />}
      {data?.revisions && <MyParticipationTable revisions={data.revisions} />}
      <div style={{ paddingTop: 10 }}>
        <StyledLink to="/resource?me=1">Gå til ressurser</StyledLink>
      </div>
    </StyledPaper>
  );
}
