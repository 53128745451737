import { useCallback } from 'react';
import { useAuthenticatedApi } from '../api';

export interface CreateAbstractRequest {
  text: string;
}

export interface CreateAbstractResponse {
  isError: boolean;
  abstract: string;
}

export interface FindAdvicesRequest {
  text: string;
}

export interface FindAdvicesResponse {
  isError: boolean;
  advices: string[];
}

export interface FindContradictingAdvicesRequest {
  text: string;
}

export interface FindContradictionAdvicesResponse {
  isError: boolean;
  contradictions: string[];
}

export interface FindTargetAudienceRequest {
  text: string;
}

export interface FindTargetAudienceResponse {
  isError: boolean;
  targetAudiences: TargetAudience[];
  summary: string;
}

export interface TargetAudience {
  name: string;
  score: number;
}

interface AnalyseBfsLanguageRequest {
  text: string;
}

export interface AnalyseBfsLanguageResponse {
  isError: boolean;
  suggestions: BfsLanguageSuggestion[];
}

export interface BfsLanguageSuggestion {
  rule: string;
  originalText: string;
  correctedText: string;
  details: string;
}

export interface CreateChartjsRequest {
  text: string;
}

export interface CreateChartjsResponse {
  isError: boolean;
  config: string;
}

export const useAi = () => {
  const api = useAuthenticatedApi('ai');

  const createAbstract = useCallback(
    async (request: CreateAbstractRequest) => {
      const response = await api.post<CreateAbstractResponse | string>('create-abstract', request);
      if (response.status === 200) return response.data as CreateAbstractResponse;
      throw new Error(response.data as string);
    },
    [api],
  );

  const findAdvices = useCallback(
    async (request: FindAdvicesRequest) => {
      const response = await api.post<FindAdvicesResponse | string>('find-advices', request);
      if (response.status === 200) return response.data as FindAdvicesResponse;
      throw new Error(response.data as string);
    },
    [api],
  );

  const findContradictingAdvices = useCallback(
    async (request: FindContradictingAdvicesRequest) => {
      const response = await api.post<FindContradictionAdvicesResponse | string>('find-contradicting-advices', request);
      if (response.status === 200) return response.data as FindContradictionAdvicesResponse;
      throw new Error(response.data as string);
    },
    [api],
  );

  const findTargetAudience = useCallback(
    async (request: FindTargetAudienceRequest) => {
      const response = await api.post<FindTargetAudienceResponse | string>('find-target-audience', request);
      if (response.status === 200) return response.data as FindTargetAudienceResponse;
      throw new Error(response.data as string);
    },
    [api],
  );

  const analyseBfsLanguage = useCallback(
    async (request: AnalyseBfsLanguageRequest) => {
      const response = await api.post<AnalyseBfsLanguageResponse | string>('analyse-bfs-language', request);
      if (response.status === 200) return response.data as AnalyseBfsLanguageResponse;
      throw new Error(response.data as string);
    },
    [api],
  );

  const createChartjsConfig = useCallback(
    async (request: CreateChartjsRequest) => {
      const response = await api.post<CreateChartjsResponse | string>('create-chartjs-config', request);
      if (response.status === 200) return response.data as CreateChartjsResponse;
      throw new Error(response.data as string);
    },
    [api],
  );
  return {
    createAbstract,
    createChartjsConfig,
    findAdvices,
    findContradictingAdvices,
    findTargetAudience,
    analyseBfsLanguage,
  };
};
