import React, { FC, useEffect, useMemo, useState } from 'react';
import { Cell, Column, DataSourceDetails, DataType, MetaItem, useDataSourceApi } from './useDataSourceApi';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ConfirmDialog, ConfirmDialogProps } from '../dialogs/ConfirmDialog';
import { useModal } from '../dialogs/useModal';

interface DataSourceContext {
  isLoading: boolean;
  correlationId: string;
  hasChanges: boolean;
  isDraft: boolean;
  isArchived: boolean;
  isPublished: boolean;
  details?: DataSourceDetails;
  save: () => Promise<void>;
  newDraft: () => Promise<void>;
  deleteDraft: () => Promise<void>;
  archiveDataSource: () => Promise<void>;
  publish: () => Promise<void>;
  unpublish: () => Promise<void>;
  removeColumn: (columnId: number) => void;
  removeRow: (index: number) => void;
  addRow: () => void;
  addColumn: () => void;
  rowChanged: (index: number, values: Cell[]) => void;
  columnChanged: (column: Column) => void;
  nameChanged: (name: string) => void;
  descriptionChanged: (description: string) => void;
  metaChanged: (index: number, item: MetaItem) => void;
  addMeta: () => void;
  removeMeta: (index: number) => void;
  canBack: boolean;
  canNext: boolean;
  goBack: () => void;
  goNext: () => void;
  objectsToDataSource: (objects: unknown[]) => void;
}

const DataSourceContextInstance = React.createContext<DataSourceContext | undefined>(undefined);

export const useDataSourceContext = (): DataSourceContext => {
  const context = React.useContext(DataSourceContextInstance);
  if (!context) {
    throw new Error('useDataSourceContext must be used within a DataSourceContextProvider');
  }
  return context;
};

const createRow = (columns: DataSourceDetails['columns']): Cell[] => {
  return columns.map((column) => ({ columnId: column.id, value: '' }));
};

export const DataSourceContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { correlationId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const versionSearchParam = searchParams.get('version');
  const version = versionSearchParam ? parseInt(versionSearchParam) : undefined;

  const [originalDetails, setOriginalDetails] = useState<DataSourceDetails>();
  const [details, setDetails] = useState<DataSourceDetails>();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const confirmModal = useModal<ConfirmDialogProps, boolean>({
    data: { title: '', message: '' },
  });
  const api = useDataSourceApi();

  const hasChanges = useMemo(() => {
    return JSON.stringify(details) !== JSON.stringify(originalDetails);
  }, [details, originalDetails]);

  const detailsLoaded = (details?: DataSourceDetails) => {
    setDetails(details);
    setOriginalDetails(details);
  };

  const goBack = () => {
    if (!details) return;
    navigate(`/datasource/${details.correlationId}?version=${details.version - 1}`, { replace: true });
  };

  const goNext = () => {
    if (!details) return;
    navigate(`/datasource/${details.correlationId}?version=${details.version + 1}`, { replace: true });
  };

  const loadDetails = async (correlationId: string) => {
    try {
      const details = version ? await api.getVersionDetails(correlationId, version) : await api.getLatestVersionDetails(correlationId);
      detailsLoaded(details);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!correlationId) return;
    loadDetails(correlationId);
  }, [correlationId, version]);

  const publish = async () => {
    if (!details) return;
    if (hasChanges) {
      await api.update(details.id, details);
    }
    const updatedDetails = await api.publish(details.id);
    detailsLoaded(updatedDetails);
  };

  const unpublish = async () => {
    if (!details) return;
    const updatedDetails = await api.unpublish(details.id);
    detailsLoaded(updatedDetails);
  };

  const save = async () => {
    if (!details) return;
    const updatedDetails = await api.update(details.id, details);
    detailsLoaded(updatedDetails);
  };

  const newDraft = async () => {
    if (!details) return;
    const updatedDetails = await api.createNewVersion(details.correlationId);
    detailsLoaded(updatedDetails);
    setSearchParams(undefined, { replace: true });
  };

  const deleteDraft = async () => {
    if (!details) return;
    const shouldDelete = await confirmModal.open({
      message: 'Er du sikker på at du vil slette utkastet?',
      title: 'Slett utkast',
      submitText: 'Slett utkast',
    });
    if (!shouldDelete) return;
    const updatedDetails = await api.deleteVersion(details.correlationId, details.id);
    if (!updatedDetails) {
      navigate('/datasource', { replace: true });
      return;
    }
    detailsLoaded(updatedDetails);
    setSearchParams(undefined, { replace: true });
  };

  const archiveDataSource = async () => {
    if (!details) return;
    const shouldArchive = await confirmModal.open({
      message: <>Er du sikker på at du vil arkivere hele datakilden?</>,
      title: 'Arkiver datakilde',
      submitText: 'Arkiver',
    });
    if (!shouldArchive) return;
    await api.archiveAllVersionsOfDataSource(details.correlationId);
    navigate('/datasource', { replace: true });
  };

  const removeColumn = (columnId: number) => {
    if (!details) return;
    setDetails({
      ...details,
      columns: details.columns.filter((c) => c.id !== columnId),
      values: details.values.map((row) => row.filter((cell) => cell.columnId !== columnId)),
    });
  };

  const addRow = () => {
    if (!details) return;
    const row = createRow(details.columns);
    setDetails({ ...details, values: [...details.values, row] });
  };

  const removeRow = (index: number) => {
    if (!details) return;
    setDetails({
      ...details,
      values: details.values.filter((_row, i) => i !== index),
    });
  };

  const addColumn = () => {
    if (!details) return;
    const maxColumnId = details.columns.length > 0 ? Math.max(...details.columns.map((column) => column.id)) : -1;
    const newColumnId = maxColumnId + 1;
    setDetails({
      ...details,
      columns: [...details.columns, { id: newColumnId, title: 'Ny kolonne', dataType: DataType.Text }],
      values: details.values.map((row) => [...row, { columnId: newColumnId, value: '' }]),
    });
  };

  const rowChanged = (index: number, values: Cell[]) => {
    if (!details) return;
    setDetails({
      ...details,
      values: details.values.map((row, i) => (i === index ? values : row)),
    });
  };

  const columnChanged = (column: Column) => {
    if (!details) return;
    setDetails({
      ...details,
      columns: details.columns.map((c) => (c.id === column.id ? column : c)),
    });
  };

  const nameChanged = (name: string) => {
    if (!details) return;
    setDetails({ ...details, name });
  };

  const metaChanged = (index: number, item: MetaItem) => {
    if (!details) return;
    const meta = details.meta.map((m, i) => (i === index ? item : m));
    setDetails({ ...details, meta });
  };

  const addMeta = () => {
    if (!details) return;
    const existing = details.meta ?? [];
    setDetails({ ...details, meta: [...existing, { key: '', value: '' }] });
  };
  const removeMeta = (index: number) => {
    if (!details) return;
    const meta = details.meta.filter((_m, i) => i !== index);
    setDetails({ ...details, meta });
  };

  const descriptionChanged = (description: string) => {
    if (!details) return;
    setDetails({ ...details, description });
  };

  const objectsToDataSource = (objects: unknown[]): void => {
    if (!details) return;

    if (objects.length === 0) {
      setDetails({ ...details, columns: [], values: [] });
    }

    const columns: Column[] = [];
    Object.getOwnPropertyNames(objects[0]).forEach((key) => {
      if (!key.startsWith('__')) {
        const valueTypes = objects.map((object) => typeof (object as Record<string, unknown>)[key]);
        if (valueTypes.every((type) => type === 'number' || type === 'undefined')) {
          columns.push({ id: columns.length, title: key, dataType: DataType.Number });
        } else if (valueTypes.every((type) => type === 'boolean' || type === 'undefined')) {
          columns.push({ id: columns.length, title: key, dataType: DataType.Boolean });
        } else {
          columns.push({ id: columns.length, title: key, dataType: DataType.Text });
        }
      }
    });

    const newValues = objects.map((object) => {
      const row: Cell[] = [];
      columns.forEach((column) => {
        const value = (object as Record<string, unknown>)[column.title];
        row.push({ columnId: column.id, value: value === undefined || value === null ? '' : value.toString() });
      });
      return row;
    });

    setDetails({ ...details, columns: columns, values: newValues });
  };

  if (!correlationId) return null;

  return (
    <DataSourceContextInstance.Provider
      value={{
        isLoading,
        correlationId,
        hasChanges,
        isDraft: !details?.publishedAt && !details?.isArchived,
        isArchived: !!details?.isArchived,
        isPublished: !!details?.publishedAt && !details?.isArchived,
        details,
        save,
        newDraft,
        deleteDraft,
        archiveDataSource,
        publish,
        unpublish,
        removeColumn,
        removeRow,
        addRow,
        addColumn,
        rowChanged,
        columnChanged,
        nameChanged,
        descriptionChanged,
        metaChanged,
        addMeta,
        removeMeta,
        canBack: !!details && details.version > 1,
        canNext: !!details && !details.isLatestVersion,
        goBack,
        goNext,
        objectsToDataSource,
      }}>
      {children}
      {confirmModal.isOpen && <ConfirmDialog modal={confirmModal} />}
    </DataSourceContextInstance.Provider>
  );
};
