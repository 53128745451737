import React from 'react';
import { Tooltip } from '@mui/material';
import { FC } from 'react';
import { CloudDoneOutlined, CloudOff, CloudSyncOutlined } from '@mui/icons-material';
import { Stack } from '@mui/system';
import { formatDate } from '../../../Formatters';
import moment from 'moment';
import { ConnectionStatus, SaveStatus } from './useHocuspocus';

export const HocuspocusConnectionStatus: FC<{ connectionStatus: ConnectionStatus; saveStatus: SaveStatus }> = ({ connectionStatus, saveStatus }) => {
  const { isConnected, isConnecting } = connectionStatus;
  const { isSaving, savedAt } = saveStatus;

  return (
    <Stack direction="row" spacing={2}>
      {!isConnected && (
        <Tooltip title={<>{isConnecting && <>Kobler til server...</>}</>}>
          <CloudOff color="error" />
        </Tooltip>
      )}
      {!isConnecting && isConnected && (
        <Tooltip
          title={
            <>
              Automatisk lagring aktivert. {isSaving && 'Lagrer...'}
              {!isSaving && savedAt && `Sist lagret ${moment(savedAt).startOf('seconds').fromNow()} (${formatDate(savedAt, 'HH:mm:ss')})`}
            </>
          }>
          {isSaving ? <CloudSyncOutlined color="success" /> : <CloudDoneOutlined color="success" />}
        </Tooltip>
      )}
    </Stack>
  );
};
