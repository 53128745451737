import React from 'react';
import { List, ListItem, ListItemIcon, ListItemButton, Badge } from '@mui/material';
import { Link } from 'react-router-dom';
import { Book } from '@mui/icons-material';
import { GetBooksQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';

type Book = Unpacked<GetBooksQuery['books']>;
export function BookList({ books }: { books: Book[] }) {
  return (
    <List>
      {books &&
        books.map((book) => {
          if (!book) return null;
          return (
            <ListItem key={book.id}>
              <ListItemButton component={Link} to={`/book/${book.title}`} state={{ data: book }}>
                <ListItemIcon>
                  <BookBadge book={book} />
                </ListItemIcon>
                {book.title}
              </ListItemButton>
            </ListItem>
          );
        }
        )}
    </List>
  );
}

export function BookBadge({ book }: { book: Book }) {
  const revisions = book?.guides?.filter((x) => x?.ongoingRevision).length;

  return (
    <Badge badgeContent={revisions} color="error">
      <Book />
    </Badge>
  );
}

export default BookList;
