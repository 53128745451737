import React from 'react';
import { Typography, TableHead, Table, TableRow, TableCell, TableCellProps, TableBody, FormControlLabel, Switch, IconButton } from '@mui/material';
import { StyledLink, numberColumnWidth } from '../Components';
import { SearchField } from '../components/SearchField';
import { formatDate } from '../Formatters';
import Fuse from 'fuse.js';
import { RevisionFilter, selectRevisionFilter, showCompleted, showOnlyMe, showOnlyMine } from './filterSlice';
import { getShortName } from '../resources/Overview';
import { ColWidths, TableCellHead, TableCellEllipsis } from '../components/TableComponents';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { useSearchParams } from 'react-router-dom';
import { GET_REVISIONS } from './revisions.graphql';
import { styled } from '@mui/material/styles';
import { revisionTypeToDisplayText } from './RevisionModel';
import { utils, writeFile } from 'xlsx';
import { GetAllRevisionsQuery, RevisionRoles } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { useAuth } from 'oidc-react';
import { StyledPaper } from '../theme';

export function Overview() {
  const { data, loading, error } = useQuery(GET_REVISIONS, { fetchPolicy: 'cache-and-network' });
  return (
    <StyledPaper>
      <Typography variant="h5" component="h3">
        Revisjoner
      </Typography>
      {error && <p>Error: {error.message}</p>}
      {loading && <LoadingSpinner />}
      {data?.revisions && <SearchableRevisionTable revisions={data.revisions} />}
    </StyledPaper>
  );
}

type Revision = Unpacked<GetAllRevisionsQuery['revisions']>;
function SearchableRevisionTable({ revisions }: { revisions: Revision[] }) {
  const dispatch = useDispatch();
  const auth = useAuth();
  const [searchParams] = useSearchParams();

  const [searchTerm, setSearchTerm] = React.useState(searchParams.get('searchTerm') ?? undefined);
  const revisionFilter = useSelector(selectRevisionFilter);
  const alsoCompleted = searchParams.get('completed') === '0';
  if (alsoCompleted) dispatch(showCompleted(true));

  const filteredRevisions = filterRevisions(revisionFilter, revisions, auth.userData?.profile.sub, searchTerm);

  return (
    <>
      <SearchField startSearch={searchTerm} filter={setSearchTerm} />
      <FilterSwitch
        label="Vis kun revisjoner for mine fagområder"
        checked={revisionFilter.showOnlyMine}
        set={(value) => dispatch(showOnlyMine(value))}
      />
      <FilterSwitch label="Vis kun revisjoner jeg deltar i" checked={revisionFilter.showOnlyMe} set={(value) => dispatch(showOnlyMe(value))} />
      <FilterSwitch label="Vis ferdige revisjoner" checked={revisionFilter.showCompleted} set={(value) => dispatch(showCompleted(value))} />
      <RevisionTable revisions={filteredRevisions} />
    </>
  );
}

function FilterSwitch({ label, checked, set }: { label: string; checked: boolean; set: (value: boolean) => void }) {
  return (
    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', float: 'left', fontSize: 14 }}>
      <FormControlLabel control={<Switch onChange={(ev) => set(ev.target.checked)} checked={checked} />} label={label} />
    </div>
  );
}

function filterRevisions(filter: RevisionFilter, revisions: Revision[], userId: string | undefined, searchTerm: string | undefined) {
  var options = {
    shouldSort: true,
    tokenize: true,
    matchAllTokens: true,
    threshold: 0,
    location: 0,
    distance: 10,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['guide.docNumber', 'guide.docName', 'guide.docTitle', 'projectLeadName'],
  };

  const { showOnlyMine, showOnlyMe, showCompleted } = filter;
  const filtered = revisions.filter((x) => {
    return (
      x?.guide != null &&
      (showCompleted || !x.completedAt) &&
      (!showOnlyMine || !userId || x.guide.ownerId === userId) &&
      (!showOnlyMe || x.roles?.some((r) => r?.user?.subjectId === userId))
    );
  });

  if (!searchTerm) {
    return filtered;
  }

  const fuse = new Fuse(filtered, options);
  const all = fuse.search(searchTerm).map((x) => x.item);
  return all;
}

const StickyTableCell = styled(TableCellHead)<TableCellProps>(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(8),
  background: 'white',
}));

function RevisionTable({ revisions }: { revisions: Revision[] }) {
  var prevEndDate = new Date();

  return (
    <>
      <ExcelExport revisions={revisions} />
      <Table size="small" sx={{ position: 'relative' }}>
        <ColWidths widths={[80, null, 100, 100, 80, 100, 120, 120]}></ColWidths>
        <TableHead>
          <TableRow>
            <StickyTableCell sx={{ width: numberColumnWidth }}>Nummer</StickyTableCell>
            <StickyTableCell>Tittel</StickyTableCell>
            <StickyTableCell>Revisjonstype</StickyTableCell>
            <StickyTableCell>Prosjektleder</StickyTableCell>
            <StickyTableCell>Opprettet</StickyTableCell>
            <StickyTableCell>Planlagt ferdig</StickyTableCell>
            <StickyTableCell>Framdrift</StickyTableCell>
            <StickyTableCell>Fase</StickyTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {revisions.map((x) => {
            if (!x?.guide) return null;
            const row = (
              <TableRow key={x.id}>
                <TableCell>
                  <StyledLink to={`/revision/${x.id}`}>{x.guide.docName}</StyledLink>
                </TableCell>
                <TableCellEllipsis>{x.guide.docTitle}</TableCellEllipsis>
                <TableCellEllipsis>{revisionTypeToDisplayText(x.type)}</TableCellEllipsis>
                <TableCell>{getShortName(x.roles?.find((r) => r?.role === RevisionRoles.ProjectLead)?.user?.name)}</TableCell>
                <TableCell>{formatDate(x.createdAt, 'DD.MM.YYYY')}</TableCell>
                <TableCell>{formatDate(x.scheduledEndDate, 'DD.MM.YYYY')}</TableCell>
                <TableCell>{`${x.progress}%`}</TableCell>
                <TableCellEllipsis>{x.currentPhase}</TableCellEllipsis>
              </TableRow>
            );
            const displayMonth = x.scheduledEndDate && prevEndDate && formatDate(x.scheduledEndDate, 'MM') !== formatDate(prevEndDate, 'MM');
            prevEndDate = new Date(x.scheduledEndDate);
            if (displayMonth) {
              return [
                <TableRow key={x.id + 'Month'}>
                  <TableCell colSpan={8}>
                    <Typography variant="h2" style={{ fontSize: '1rem', paddingTop: 12 }}>
                      {formatDate(x.scheduledEndDate, 'MMM YYYY')}
                    </Typography>
                  </TableCell>
                </TableRow>,
                row,
              ];
            } else {
              return row;
            }
          })}
        </TableBody>
      </Table>
    </>
  );
}

function ExcelExport({ revisions }: { revisions: Revision[] }) {
  return (
    <IconButton
      sx={{ position: 'absolute', right: (theme) => theme.spacing(4), top: (theme) => theme.spacing(12) }}
      onClick={() => exportFile(revisions)}
      title="Eksport til Excel"
      size="large">
      <img src="/icons8-microsoft-excel.svg" style={{ width: 24, height: 24 }} alt="Excel icon" />
    </IconButton>
  );
}

function exportFile(revisions: Revision[]) {
  const headings: string[] = ['Nummer', 'Tittel', 'Revisionstype', 'Prosjektleder', 'Opprettet', 'Planlagt ferdig', 'Framdrift', 'Fase'];

  const data = revisions.map((revision) => [
    revision?.guide?.docName,
    revision?.guide?.docTitle,
    revision?.type ? revisionTypeToDisplayText(revision?.type) : null,
    getShortName(revision?.roles?.find((r) => r?.role === RevisionRoles.ProjectLead)?.user?.name),
    formatDate(revision?.createdAt, 'DD.MM.YYYY'),
    formatDate(revision?.scheduledEndDate, 'DD.MM.YYYY'),
    `${revision?.progress}%`,
    revision?.currentPhase,
  ]);
  const input = [headings, ...data];
  const sheet = utils.aoa_to_sheet(input);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, sheet, 'Revisioner');
  writeFile(workbook, 'revisjoner.xlsx');
}
