import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText } from '@mui/material';
import React, { FC } from 'react';
import { Modal } from '../../../dialogs/useModal';
import { ListLabel } from '../../../Components';

export type ImageMetadataDialogProps =
  | { metadata?: ({ name?: string | null | undefined; value?: string | null | undefined } | null)[] | null | undefined }
  | null
  | undefined;

export const ImageMetadataDialog: FC<{ modal: Modal<ImageMetadataDialogProps, undefined> }> = ({ modal }) => {
  return (
    <Dialog open={true} onClose={() => modal.close()} aria-labelledby="metadata-title">
      <DialogTitle id="metadata-title">Metadata</DialogTitle>
      <DialogContent>
        {!modal.data?.metadata?.length && <p>Ingen metadata</p>}
        <List dense>
          {modal.data?.metadata?.map((m) => (
            <ListItem key={m?.name}>
              <ListLabel width={200}>{m?.name}:</ListLabel>
              <ListItemText>{m?.value}</ListItemText>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => modal.close()}>Lukk</Button>
      </DialogActions>
    </Dialog>
  );
};
