import { JSONContent } from '@tiptap/react';
import { useGuideCmsContext } from '../../GuideCmsContext';
import { getText } from '../../tiptap/tiptapHelper';

export interface TocItem {
  id: string;
  text: string;
  level: number;
  items?: TocItem[];
}

export const useTableOfContents = (): TocItem[] => {
  const { editor } = useGuideCmsContext();
  if (!editor || editor.isDestroyed) return [];
  const items = getTableOfContents(editor.getJSON());
  return items;
};

const getTableOfContents = (root: JSONContent): TocItem[] => {
  const tocItems: TocItem[] = [];
  let h1Item: TocItem | undefined, h2Item: TocItem | undefined, h3Item: TocItem | undefined;
  for (const node of root.content ?? []) {
    if (node.type === 'heading' && node.attrs?.id) {
      if (node.attrs.level === 1) {
        h1Item = { level: 1, id: node.attrs.id, text: getText(node) ?? '', items: [] };
        h2Item = undefined;
        h3Item = undefined;
        tocItems.push(h1Item);
      } else if (node.attrs.level === 2) {
        h2Item = { level: 2, id: node.attrs.id, text: getText(node) ?? '', items: [] };
        h3Item = undefined;
        if (!h1Item) {
          // First header in document is H2
          tocItems.push(h2Item);
        } else {
          // H2 is a child of H1
          h1Item.items?.push(h2Item);
        }
      } else if (node.attrs.level === 3) {
        h3Item = { level: 3, id: node.attrs.id, text: getText(node) ?? '' };
        if (!h1Item && !h2Item) {
          // First header in document is H3
          tocItems.push(h3Item);
        } else if (h2Item) {
          // H3 is a child of H2
          h2Item.items?.push(h3Item);
        } else if (h1Item) {
          // H3 is a child of H1
          h1Item.items?.push(h3Item);
        }
      }
    }
  }
  return tocItems;
};
