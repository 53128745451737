import React, { FC } from 'react';
import { useCommentsContext } from './CommentsContext';
import { useSubscription } from '@apollo/client';
import { SUBSCRIBE_COMMENTS_UPDATED } from './comments.graphql';
import { useGuideCmsContext } from '../../GuideCmsContext';

export const CommentsAutoUpdater: FC = () => {
  const { isCommentsEnabled } = useCommentsContext();
  if (!isCommentsEnabled) {
    return null;
  }
  return <SubscribeToCommentsUpdates />;
};

const SubscribeToCommentsUpdates: FC = () => {
  const { guideId } = useGuideCmsContext();
  useSubscription(SUBSCRIBE_COMMENTS_UPDATED, { variables: { guideId } });
  return null;
};
