import React from 'react';
import { StyledLink } from '../Components';
import { formatDateOnly } from '../Formatters';
import { Typography, TableHead, TableCell, TableBody, Table, TableRow } from '@mui/material';
import { getNumber, getTitle } from '../resources/separateTitleNumber';
import { ColWidths, TableCellHead, TableCellEllipsis } from '../components/TableComponents';
import { useQuery } from '@apollo/client';
import { GET_ONGOING_REVISIONS } from './dashboard.graphql';
import LoadingSpinner from '../LoadingSpinner';
import { StyledPaper } from '../theme';

const tableStyle = {
  position: 'relative' as 'relative',
};

const stickyHeader = {
  position: 'sticky' as 'sticky',
  top: 0,
  background: 'white',
};

export function OngoingRevisions() {
  const { data, loading } = useQuery(GET_ONGOING_REVISIONS);

  return (
    <StyledPaper>
      <Typography style={{ fontSize: 18 }}>Anvisninger under revisjon</Typography>
      {data?.revisions && (
        <Table size="small" style={tableStyle}>
          <ColWidths widths={[100, null, 120, 100, 100]}></ColWidths>
          <TableHead>
            <TableRow>
              <TableCellHead style={stickyHeader}>Nummer</TableCellHead>
              <TableCellHead style={stickyHeader}>Tittel</TableCellHead>
              <TableCellHead style={stickyHeader}>Planlagt ferdig</TableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.revisions.map((revision) => {
              if (!revision) return null;
              return (
                <TableRow key={revision.id}>
                  <TableCellEllipsis>
                    <StyledLink title={revision.title} to={'/revision/' + revision.id}>
                      {getNumber(revision.title)}
                    </StyledLink>
                  </TableCellEllipsis>
                  <TableCellEllipsis>{getTitle(revision.title)}</TableCellEllipsis>
                  <TableCell>{formatDateOnly(revision.scheduledEndDate)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      {loading && <LoadingSpinner />}
    </StyledPaper>
  );
}
