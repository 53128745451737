import { gql } from '../__generated__';

gql(/* GraphQL */ `
  fragment BookModelItem on BookModel {
    id
    title
    guides {
      id
      docName
      docTitle
      hasChangesSinceLastPublish
      ongoingRevision {
        id
      }
      status
      mainCategory {
        title
      }
      lastPublishedAt
    }
  }
`);

export const GET_BOOKS = gql(/* GraphQL */ `
  query GetBooks {
    books {
      ...BookModelItem
    }
  }
`);
