import { useCallback, useEffect, useState } from 'react';

const appBarHeight = 64;

let didScroll = false;

const onScroll = () => {
  didScroll = true;
};

const isVisibleToUser = (element: Element) => {
  var rect = element.getBoundingClientRect();
  return rect.top > appBarHeight && rect.bottom <= window.innerHeight;
};

export function useActiveHeadingObserver() {
  const [activeNumbering, setActiveNumbering] = useState('');

  const handleScrolling = useCallback(() => {
    requestAnimationFrame(() => {
      const headings = document.querySelectorAll('h1[numbering], h2[numbering], h3[numbering]');
      const visibleHeadings = Array.from(headings).filter(isVisibleToUser);
      if (visibleHeadings.length > 0) {
        setActiveNumbering(visibleHeadings[0].attributes.getNamedItem('numbering')?.value ?? '');
      }
    });
  }, []);

  useEffect(() => {
    handleScrolling();
    document.addEventListener('scroll', onScroll);
    // Handle scroll events on interval to avoid performance issues
    const interval = setInterval(() => {
      if (didScroll) {
        didScroll = false;
        handleScrolling();
      }
    }, 150);
    return () => {
      document.removeEventListener('scroll', onScroll);
      clearInterval(interval);
    };
  }, []);

  return { activeNumbering };
}
