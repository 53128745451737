import React, { FC } from 'react';
import { Editor } from '@tiptap/react';
import { InternalLinksDialog, InternalLinksDialogProps, InternalLinksDialogResult } from '../../../dialogs/InternalLinksDialog';
import { InsertLink } from '@mui/icons-material';
import { MenuButton, useRichTextEditorContext } from 'mui-tiptap';
import { useModal } from '../../../../../dialogs/useModal';
import { getSelectedText } from '../../tiptapHelper';

export const AddInternalLinkToolbarButton: FC = () => {
  const modal = useModal<InternalLinksDialogProps, InternalLinksDialogResult>({ data: {} });
  const editor = useRichTextEditorContext();
  const handleOnClick = async () => {
    if (!editor) return;
    const payload = await modal.open({
      selectedText: getSelectedText(editor),
    });
    if (payload) {
      addInternalLink(editor, payload);
    }
  };
  if (!editor) return null;
  return (
    <>
      {modal.isOpen && <InternalLinksDialog {...modal} />}
      <MenuButton disabled={!editor?.isEditable} IconComponent={InsertLink} tooltipLabel="Intern lenke" onClick={handleOnClick}></MenuButton>
    </>
  );
};

const addInternalLink = (editor: Editor | null, payload: InternalLinksDialogResult) => {
  editor
    ?.chain()
    .focus()
    .insertContent({
      type: 'internalLink',
      attrs: {
        id: payload.id,
        type: payload.type,
        format: payload.format,
      },
    })
    .run();
};
