import styled from '@emotion/styled';
import { Tooltip, TooltipProps } from '@mui/material';
import { FC } from 'react';
import React from 'react';
import { theme } from '../../../theme';

export const palette = {
  green: '#B4BF86',
  yellow: '#F2DC9C',
  blue: '#9EBADA',
  red: '#D18D70',
  backgroundColor: '#FAF9F8',
  lineColor: '#464646',
};

const SyledPalette = styled.div`
  display: grid;
  gap: 3px;
  width: auto;
  grid-template-columns: repeat(3, auto);
  justify-content: left;
  cursor: pointer;
`;

export const ChartPalette: FC = () => {
  return (
    <div>
      <SyledPalette>
        <PaletteColor color={palette.green} tooltipPlacement="top" />
        <PaletteColor color={palette.yellow} tooltipPlacement="top" />
        <PaletteColor color={palette.blue} tooltipPlacement="top" />
        <PaletteColor color={palette.red} tooltipPlacement="bottom" />
        <PaletteColor color={palette.backgroundColor} tooltipPlacement="bottom" />
        <PaletteColor color={palette.lineColor} tooltipPlacement="bottom" />
      </SyledPalette>
    </div>
  );
};

const SyledColor = styled.button<{ color: string }>`
  background-color: ${(props) => props.color};
  color: ${(props) => theme.palette.getContrastText(props.color)};
  margin: 0;
  padding: 0;
  width: 80px;
  height: 80px;
  line-height: 1;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
`;

const defaultTooltipText = 'Klikk for å kopiere';

const PaletteColor: FC<{ color: string; tooltipPlacement?: TooltipProps['placement'] }> = ({ color, tooltipPlacement = 'right' }) => {
  const [tooltipText, setTooltipText] = React.useState<string>(defaultTooltipText);
  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(color)
        .then(() => {
          setTooltipText('Kopiert!');
        })
        .catch(function (err) {
          throw err !== undefined ? err : new DOMException('The request is not allowed', 'NotAllowedError');
        });
    }
  };
  return (
    <Tooltip
      title={tooltipText}
      placement={tooltipPlacement}
      onMouseLeave={() => {
        setTimeout(() => setTooltipText(defaultTooltipText), 150);
      }}>
      <SyledColor color={color} onClick={copyToClipboard}>
        {color}
      </SyledColor>
    </Tooltip>
  );
};
