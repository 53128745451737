import React, { FC } from 'react';
import { Editor } from '@tiptap/react';
import { ShowChart } from '@mui/icons-material';
import { MenuButton, useRichTextEditorContext } from 'mui-tiptap';
import { lineChartSingle } from '../../../charts/examples';
import { stringify } from '../../../charts/jsonHelper';

export const AddChartToolbarButton: FC = () => {
  const editor = useRichTextEditorContext();
  const handleOnClick = async () => {
    addChart(editor);
  };
  if (!editor) return null;
  return (
    <>
      <MenuButton
        disabled={!editor?.isEditable || !editor.can().chain().focus().insertChartFigure().run()}
        IconComponent={ShowChart}
        tooltipLabel="Graf"
        onClick={handleOnClick}></MenuButton>
    </>
  );
};

const addChart = (editor: Editor | null) => {
  editor?.chain().focus().insertChartFigure(stringify(lineChartSingle())).run();
};
