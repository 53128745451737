import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { IconButton, Typography, Checkbox, Table, TableCell, TableHead, TableRow, FormControlLabel, TableBody } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { BackButton, StyledLink } from '../Components';
import { useMutation, useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { GET_GUIDE_AND_LINKS, ADD_LINK, REMOVE_LINK } from './guide.graphql';
import { SearchField } from '../components/SearchField';
import InfiniteScroll from 'react-infinite-scroller';
import Fuse from 'fuse.js';
import { GuideStatusDisplay } from './GuideTable';
import { ColWidths, TableCellHead, TableCellIcon } from '../components/TableComponents';
import { useParams } from 'react-router';
import { GetGuideQuery, GetGuidesAndLinksQuery, GuideStatus } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';

type GuideLinkOnGuide = Unpacked<NonNullable<GetGuideQuery['guide']>['guideLinks']>;
export function GuideLinks({
  guideId,
  guides,
  elevation,
  isEditable,
}: {
  guideId: string;
  guides: GuideLinkOnGuide[];
  elevation?: number;
  isEditable: boolean;
}) {
  return (
    <StyledPaper elevation={elevation}>
      <Typography variant="h6" component="h3">
        Relevante anvisninger
        {isEditable && (
          <IconButton component={Link} to={`/guide/links/${guideId}`} size="large">
            <Edit />
          </IconButton>
        )}
      </Typography>
      {guides.length === 0 && (
        <Typography variant="subtitle1" color="textSecondary">
          Ingen angitt
        </Typography>
      )}
      {guides.length > 0 && <GuideLinksTable guides={guides} />}
    </StyledPaper>
  );
}

function GuideLinksTable({ guides }: { guides: GuideLinkOnGuide[] }) {
  const sortedGuides = [...guides];
  sortedGuides.sort((a, b) => a?.docName?.localeCompare(b?.docName ?? '') ?? 0);
  return (
    <Table size="small">
      <ColWidths widths={[60, 120, null, null]}></ColWidths>
      <TableHead>
        <TableRow>
          <TableCellHead>Status</TableCellHead>
          <TableCellHead>Nummer</TableCellHead>
          <TableCellHead>Tittel</TableCellHead>
          <TableCellHead>Fagområde</TableCellHead>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedGuides.map((x) => {
          if (!x) return null;
          return (
            <TableRow key={x.id}>
              <TableCellIcon>
                <GuideStatusDisplay guide={x} />
              </TableCellIcon>
              <TableCell>
                <StyledLink to={`/guide/${x.id}`}>{x.docName}</StyledLink>
              </TableCell>
              <TableCell>{x.docTitle}</TableCell>
              <TableCell>{x.mainCategory?.title}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

type Guide = NonNullable<GetGuidesAndLinksQuery['guide']>;
type GuideLink = Unpacked<GetGuidesAndLinksQuery['guides']>;

export function GuideLinksEdit() {
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useQuery(GET_GUIDE_AND_LINKS, { variables: { id: id } });
  const [filter, setFilter] = useState('');
  const [onlySelected, setOnlySelected] = useState(false);
  const [allGuides, setAllGuides] = useState(false);

  if (loading || !data) return <LoadingSpinner />;

  const guides = data.guides!;
  const guide = data.guide!;

  const filterGuides = (guides: GuideLink[]): GuideLink[] => {
    let filtered = guides.filter((x) => x!.id !== guide.id && (allGuides || x!.status !== GuideStatus.Expired));
    if (onlySelected) {
      filtered = filtered.filter((x) => guide.guideLinks?.some((l) => l?.id === x!.id));
    }
    if (!filter) return filtered;

    var options = {
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0,
      keys: ['docTitle', 'docName', 'docNumber', 'mainCategory.title'],
    };

    var fuse = new Fuse(filtered, options);
    filtered = fuse.search(filter.trim()).map((x) => x.item);
    return filtered;
  };

  return (
    <div>
      <div>
        <Typography variant="h5">
          <BackButton />
          Anvisning: {guide.docTitle}
        </Typography>
      </div>
      <SearchField filter={setFilter} />
      <OnlySelectedCheckbox checked={onlySelected} onChange={() => setOnlySelected(!onlySelected)} />
      <AllGuidesCheckbox checked={allGuides} onChange={() => setAllGuides(!allGuides)} />
      {guides && <GuideLinksEditTable guide={guide} guides={filterGuides(guides)} />}
    </div>
  );
}

function OnlySelectedCheckbox({ checked, onChange }: { checked: boolean; onChange(): void }) {
  return (
    <FormControlLabel
      control={<Checkbox name="showOnlySelected" color="primary" checked={checked} onChange={onChange} value="showOnlySelected" />}
      label="Vis bare valgte"
    />
  );
}

function AllGuidesCheckbox({ checked, onChange }: { checked: boolean; onChange(): void }) {
  return (
    <FormControlLabel
      control={<Checkbox name="showAllGuide" color="primary" checked={checked} onChange={onChange} value="showAllGuide" />}
      label="Vis tilbaketrukne anvisninger"
    />
  );
}

function GuideLinksEditTable({ guide, guides }: { guide: Guide; guides: GuideLink[] }) {
  const [numberOfItems, setNumberOfItems] = useState(50);
  const [add] = useMutation(ADD_LINK);
  const [remove] = useMutation(REMOVE_LINK);

  const isSelected = (id: string) => guide.guideLinks?.some((x) => x?.id === id);
  const handleClick = (model: GuideLink) => {
    if (isSelected(model?.id)) {
      remove({ variables: { input: { id: guide.id, guideId: model?.id } } });
    } else {
      add({ variables: { input: { id: guide.id, guideId: model?.id, docVersion: model?.docVersion ?? 0 } } });
    }
  };

  return (
    <Table size="small">
      <ColWidths widths={[60, 60, 120, null, null]}></ColWidths>
      <TableHead>
        <TableRow>
          <TableCellHead>Valgt</TableCellHead>
          <TableCellHead>Status</TableCellHead>
          <TableCellHead>Nummer</TableCellHead>
          <TableCellHead>Tittel</TableCellHead>
          <TableCellHead>Fagområde</TableCellHead>
        </TableRow>
      </TableHead>
      <InfiniteScroll element="tbody" loadMore={() => setNumberOfItems(numberOfItems + 50)} hasMore={guides.length > numberOfItems}>
        {guides.slice(0, numberOfItems - 1).map((x) => {
          if (!x) return null;
          const selected = isSelected(x.id);
          return (
            <TableRow hover onClick={() => handleClick(x)} role="checkbox" aria-checked={selected} tabIndex={-1} key={x.id} selected={selected}>
              <TableCell padding="checkbox">
                <Checkbox checked={selected} color="primary" />
              </TableCell>
              <TableCellIcon>
                <GuideStatusDisplay guide={x} />
              </TableCellIcon>
              <TableCell>{x.docName}</TableCell>
              <TableCell>{x.docTitle}</TableCell>
              <TableCell>{x.mainCategory?.title}</TableCell>
            </TableRow>
          );
        })}
      </InfiniteScroll>
    </Table>
  );
}
