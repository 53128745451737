import React, { useState } from 'react';
import { styled } from '@mui/material/styles';

import { Table, TableBody, TableHead, TableRow, IconButton, TableCell, Typography, Grid2 as Grid } from '@mui/material';
import { Add } from '@mui/icons-material';

import { StyledLink } from '../Components';
import { ColWidths, TableCellHead, TableCellEllipsis } from '../components/TableComponents';
import { writeFile, utils } from 'xlsx';
import { useQuery } from '@apollo/client';
import { GET_ALL } from './categories.graphql';
import LoadingSpinner from '../LoadingSpinner';
import { SearchField } from '../components/SearchField';
import { useNavigate } from 'react-router-dom';
import Fuse from 'fuse.js';
import { GetCategoriesQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';
import { useModal } from '../dialogs/useModal';
import { AddCategoryDialog } from './AddCategoryDialog';

const StickyTableCellHead = styled(TableCellHead)(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(8),
  background: 'white',
}));

type Category = Unpacked<GetCategoriesQuery['categories']>;

export function Overview() {
  const [searchTerm, setSearchTerm] = useState('');
  const { data } = useQuery(GET_ALL, { fetchPolicy: 'cache-and-network' });
  const addModal = useModal<undefined, string | null | undefined>({ data: undefined });
  const navigate = useNavigate();

  const filterCategories = (searchTerm: string, categories: Category[]): Category[] => {
    if (!searchTerm) return categories;

    let options = {
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0,
      location: 0,
      distance: 10,
      maxPatternLength: 32,
      minMatchCharLength: 3,
      keys: ['title', 'description', 'owner.name', 'deputyOwner.name'],
    };

    const fuse = new Fuse(categories, options);
    return fuse.search(searchTerm).map((result) => result.item);
  };

  const addCategory = async () => {
    const result = await addModal.open();
    if (result) {
      navigate(`${result}`);
    }
  };

  const filteredCategories = data?.categories ? filterCategories(searchTerm, data.categories) : undefined;

  return (
    <StyledPaper>
      <Grid container direction="row">
        <Grid size={{ xs: 3 }}>
          <Typography variant="h5">
            Fagområder
            <IconButton onClick={addCategory} title="Legg til nytt fagområde" size="large">
              <Add />
            </IconButton>
          </Typography>
        </Grid>
        <Grid size={{ xs: 9 }} style={{ textAlign: 'right' }}>
          {filteredCategories && <ExcelExport items={filteredCategories} />}
        </Grid>
        <Grid size={{ xs: 12 }}>
          <SearchField filter={setSearchTerm} />
          {!filterCategories && <LoadingSpinner />}
          {filteredCategories && <CategoryTable items={filteredCategories} />}
        </Grid>
      </Grid>
      {addModal.isOpen && <AddCategoryDialog modal={addModal} />}
    </StyledPaper>
  );
}

function CategoryTable({ items }: { items: Category[] }) {
  return (
    <Table size="small" sx={{ position: 'relative' }}>
      <ColWidths widths={[null, null, 100, 100, 100]}></ColWidths>
      <TableHead>
        <TableRow>
          <StickyTableCellHead>Tittel</StickyTableCellHead>
          <StickyTableCellHead>Beskrivelse</StickyTableCellHead>
          <StickyTableCellHead>Fagområdeansvarlig</StickyTableCellHead>
          <StickyTableCellHead>Viseansvarlig</StickyTableCellHead>
          <StickyTableCellHead>
            Anvisninger
            <br />
            (hoved-/underfagområde)
          </StickyTableCellHead>
        </TableRow>
      </TableHead>
      <TableBody>{items.map((x) => (x ? <CategoryRow key={x?.id} category={x} /> : null))}</TableBody>
    </Table>
  );
}

function CategoryRow({ category }: { category: Category }) {
  if (!category) return null;
  return (
    <TableRow>
      <TableCellEllipsis>
        <StyledLink to={`${category.id}`}>{category.title}</StyledLink>
      </TableCellEllipsis>
      <TableCellEllipsis>{category.description}</TableCellEllipsis>
      <TableCellEllipsis>{category.owner?.name}</TableCellEllipsis>
      <TableCellEllipsis>{category.deputyOwner?.name}</TableCellEllipsis>
      <TableCell>{`(${category.guideRevisionsWithThisAsMainCategory} / ${category.guideRevisionsWithThisAsSubCategory})`}</TableCell>
    </TableRow>
  );
}

function ExcelExport({ items }: { items: Category[] }) {
  const exportFile = () => {
    const arrHeading: string[] = ['Tittel', 'Beskrivelse', 'Ansvarlig', 'Anvisninger (hoved)'];
    const data = items.map((s) => s);
    const dataSet = data.map((x) => [x?.title, x?.description, x?.owner?.name, x?.guideRevisionsWithThisAsMainCategory]);
    var headings = JSON.stringify(arrHeading);
    var stringDataSet = JSON.stringify(dataSet);

    const workbook = utils.book_new();
    const inputHeading = JSON.parse(headings);
    const finalData = JSON.parse(stringDataSet);
    const input = [inputHeading, ...finalData];
    const sheet = utils.aoa_to_sheet(input);
    utils.book_append_sheet(workbook, sheet, 'FAGOMRÅDER');
    writeFile(workbook, 'fagområder.xlsx');
  };
  return (
    <div style={{ textAlign: 'right' }}>
      <IconButton onClick={exportFile} title="Eksport til Excel" size="large">
        <img src="/icons8-microsoft-excel.svg" style={{ width: 24, height: 24 }} alt="Excel icon" />
      </IconButton>
    </div>
  );
}
