import { IdTokenClaims } from 'oidc-client-ts';

export const getFullname = (profile?: IdTokenClaims) => {
  if (!profile || !profile.given_name?.length || !profile.family_name?.length) return '';
  if (profile.middle_name?.length) {
    return `${profile.given_name} ${profile.middle_name} ${profile.family_name}`;
  }
  return `${profile.given_name} ${profile.family_name}`;
};

export const getInitials = (profile?: IdTokenClaims) => {
  if (!profile || !profile.given_name?.length || !profile.family_name?.length) return '';
  return `${profile.given_name[0].toUpperCase()}${profile.family_name[0].toUpperCase()}`;
};

export const getInitialsFromFullName = (fullName: string) => {
  if (!fullName.length) return '';
  const nameParts = fullName.split(' ');
  if (nameParts.length === 1) return nameParts[0][0].toUpperCase();
  return `${nameParts[0][0].toUpperCase()}${nameParts[nameParts.length - 1][0].toUpperCase()}`;
};
