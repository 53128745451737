import React from 'react';
import { NotificationsActive } from '@mui/icons-material';
import { StyledLink } from '../Components';
import { useQuery } from '@apollo/client';
import { Typography, Grid2 as Grid, CircularProgress, Divider } from '@mui/material';
import { GET_OPEN_TASKS } from './dashboard.graphql';
import { StyledPaper } from '../theme';

export const OpenTasks = () => {
  const { data, loading } = useQuery(GET_OPEN_TASKS);
  return (
    <StyledPaper>
      <Typography variant="h5">Åpne oppgaver</Typography>
      <Grid container>
        <Grid size={{ xs: 1 }} style={{ paddingTop: 10 }}>
          <NotificationsActive fontSize="large" sx={{ color: (theme) => theme.palette.primary.main }} />
        </Grid>
        <Grid size={{ xs: 11 }}>
          <Typography align="right" variant="h4" color="primary">
            {loading && <CircularProgress size={40} />}
            {data?.tasks?.length}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <StyledLink to="/task">Gå til oppgaver</StyledLink>
    </StyledPaper>
  );
};
