import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useMutation } from '@apollo/client';
import { UPDATE_TITLE } from './guide.graphql';
import { Guide } from './Details';
import { Modal } from '../dialogs/useModal';

export function UpdateGuideTitleDialog({ modal }: { modal: Modal<Guide, unknown> }) {
  const guide = modal.data;
  const [title, setTitle] = useState(guide.docTitle);
  const [updateTitle, { loading }] = useMutation(UPDATE_TITLE);

  const handleClose = () => {
    modal.close();
  };

  const handleOk = async () => {
    await updateTitle({ variables: { input: { id: guide.id, title } } });
    handleClose();
  };

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Endre anvisningstittel</DialogTitle>
      <DialogContent>
        <TextField margin="dense" label="Tittel" type="text" rows={10} autoFocus value={title} onChange={(e) => setTitle(e.target.value)} fullWidth />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Avbryt</Button>
        <Button disabled={loading} onClick={handleOk} variant="contained" color="primary">
          Endre
        </Button>
      </DialogActions>
    </Dialog>
  );
}
