import { FC } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { Modal } from '../../../dialogs/useModal';
import { GET_APPS } from '../../guide.graphql';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../../../LoadingSpinner';
import styled from '@emotion/styled';
import { Extension } from '@mui/icons-material';
import { theme } from '../../../theme';

const StyledAppsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
`;

const StyledIconBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${theme.palette.action.selected};
`;

export interface AppsDialogResult {
  id: string;
  name: string;
}

export interface AppsDialogProps {}

export const AppsDialog: FC<Modal<AppsDialogProps, AppsDialogResult>> = (modal) => {
  const { data, loading } = useQuery(GET_APPS);

  const { close } = modal;
  const handleOnClose = () => {
    close();
  };
  const handleOnInsert = (id: string, name: string) => {
    close({ id, name });
  };

  return (
    <Dialog open={true} onClose={handleOnClose} maxWidth="lg" fullWidth={true}>
      <DialogTitle>Legg til app</DialogTitle>
      <DialogContent>
        {loading && <LoadingSpinner />}
        {data?.apps && (
          <StyledAppsContainer>
            {data.apps.map((app) => (
              <Card key={app?.id}>
                <CardActionArea title="Legg til" onClick={() => handleOnInsert(app?.id, app?.name ?? '')}>
                  <CardMedia component="div" style={{ height: '100px', position: 'relative' }}>
                    <StyledIconBackground>
                      <Extension sx={{ fontSize: '80px', color: theme.palette.action.disabled }} />
                    </StyledIconBackground>
                  </CardMedia>
                  <CardContent>
                    <Typography variant="body2" component="div" align="center" fontWeight={500}>
                      {app?.name} (v. {app?.version})
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </StyledAppsContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Avbryt</Button>
      </DialogActions>
    </Dialog>
  );
};
