import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button } from '@mui/material';

import { useMutation } from '@apollo/client';
import { UNDO } from './guide.graphql';
import { Guide } from './Details';
import { Modal } from '../dialogs/useModal';

export function UndoChangesOnGuideDialog({ modal }: { modal: Modal<Guide, unknown> }) {
  const guide = modal.data;
  const [undoChanges, { loading }] = useMutation(UNDO, {
    variables: { input: { id: guide.id } },
  });
  const handleClose = () => {
    modal.close();
  };

  const handleOk = async () => {
    await undoChanges();
    modal.close();
  };

  return (
    <div>
      <Dialog fullWidth={true} maxWidth={'sm'} open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Angre endringer</DialogTitle>
        <DialogContent>
          <DialogContentText>Dette vil fjerne alle endringer siden forrige publisering og de kan ikke fåes tilbake.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Avbryt</Button>
          <Button onClick={handleOk} variant="contained" color="primary" disabled={loading}>
            Bekreft
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
