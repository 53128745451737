import { FC } from 'react';
import { Node, mergeAttributes, ReactNodeViewRenderer, NodeViewProps } from '@tiptap/react';
import { setBlockType } from '@tiptap/pm/commands';
import { getNodeType } from '@tiptap/core';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    insertApp: {
      insertApp: (appId: string, name: string) => ReturnType;
    };
  }
}

export const createAppExtension = (viewComponent?: FC<NodeViewProps>) => {
  const addNodeView = viewComponent ? () => ReactNodeViewRenderer(viewComponent) : null;
  const node = Node.create({
    name: 'app',
    group: 'block',
    inline: false,
    selectable: false,
    atom: true,
    addAttributes() {
      return {
        appId: {
          default: null,
        },
        name: {
          default: null,
        },
      };
    },
    parseHTML() {
      return [
        {
          tag: 'react-component',
        },
      ];
    },

    renderHTML({ HTMLAttributes }) {
      return ['react-component', mergeAttributes(HTMLAttributes)];
    },
    addNodeView,
    addCommands() {
      return {
        insertApp:
          (appId: string, name: string) =>
          ({ commands, state }) => {
            const type = getNodeType('app', state.schema);
            const canSetBlock = setBlockType(type)(state);
            if (!canSetBlock) {
              return false;
            }
            return commands.insertContent({ type: 'app', attrs: { appId, name } });
          },
      };
    },
  });

  return node;
};
