import React, { useState, useCallback } from 'react';
import { TextField } from '@mui/material';
import { debounce } from 'throttle-debounce';

interface SearchProps {
  startSearch?: string;
  filter(searchTerm: string): void;
}

export function SearchField({ filter, startSearch }: SearchProps) {
  const [searchTerm, setSearchTerm] = useState(startSearch ?? '');
  const triggerFilter = useCallback(
    debounce(300, (s) => filter(s)),
    [],
  );

  const handleFilterInput = (event) => {
    const s = event.target.value;
    setSearchTerm(s);
    triggerFilter(s);
  };

  return (
    <TextField
      variant="standard"
      margin="dense"
      name="searchTerm"
      label="Søk"
      type="text"
      onChange={handleFilterInput}
      value={searchTerm}
      fullWidth
    />
  );
}
