import React, { FC } from 'react';
import { Editor } from '@tiptap/react';
import { GuideLinkDialog, GuideLinkDialogProps, GuideLinkDialogResult } from '../../../dialogs/GuideLinkDialog';
import { LibraryBooks } from '@mui/icons-material';
import { MenuButton, useRichTextEditorContext } from 'mui-tiptap';
import { useModal } from '../../../../../dialogs/useModal';
import { getSelectedText } from '../../tiptapHelper';

export const AddGuideLinkToolbarButton: FC<{ guideId: string }> = ({ guideId }) => {
  const modal = useModal<GuideLinkDialogProps, GuideLinkDialogResult>({ data: { guideId } });
  const editor = useRichTextEditorContext();
  const handleOnClick = async () => {
    if (!editor) return;
    const payload = await modal.open({ selectedText: getSelectedText(editor), guideId });
    if (payload) {
      addGuideLink(editor, payload);
    }
  };
  if (!editor) return null;
  return (
    <>
      {modal.isOpen && <GuideLinkDialog {...modal} />}
      <MenuButton disabled={!editor?.isEditable} IconComponent={LibraryBooks} tooltipLabel="Lenke til anvisning" onClick={handleOnClick}></MenuButton>
    </>
  );
};

const addGuideLink = (editor: Editor | null, payload: GuideLinkDialogResult) => {
  editor
    ?.chain()
    .focus()
    .insertContent({
      type: 'guideLink',
      attrs: {
        guideId: payload.guideId,
        docId: payload.docId,
        docName: payload.docName,
      },
    })
    .run();
};
