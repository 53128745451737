import React from 'react';
import { Link } from 'react-router-dom';

import { List, ListItem, IconButton, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { StyledLink } from '../Components';
import { GetGuideQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';

type Paragraph = Unpacked<NonNullable<GetGuideQuery['guide']>['paragraphs']>;

export function Paragraphs({ guideId, paragraphs, elevation }: { guideId: string; paragraphs: Paragraph[]; elevation?: number }) {
  return (
    <StyledPaper elevation={elevation}>
      <Typography variant="h6" component="h3">
        Byggteknisk forskrift (TEK)
        <IconButton component={Link} to={`/guide/paragraphs/${guideId}`} size="large">
          <Edit />
        </IconButton>
      </Typography>
      {paragraphs.length === 0 && (
        <Typography variant="subtitle1" color="textSecondary">
          Ingen angitt
        </Typography>
      )}
      <List dense>
        {paragraphs.map((x) => {
          if (!x) return null;
          let link = (
            <StyledLink to={`/paragraph/${x.id}`}>
              {x.regulation} {x.number} {x.title}
            </StyledLink>
          );
          return (
            <ListItem key={x.id}>
              <StyledLink to={`/paragraph/${x.id}`}>
                <Typography noWrap>
                  {x.regulation} {x.number} {x.title}
                </Typography>
              </StyledLink>
            </ListItem>
          );
        })}
      </List>
    </StyledPaper>
  );
}
