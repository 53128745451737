import React, { FC, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem } from '@mui/material';
import { useMutation } from '@apollo/client';
import { SET_NEED_FOR_REVISION } from './backlog.graphql';
import { NeedForRevision } from '../__generated__/graphql';
import { Modal } from '../dialogs/useModal';

interface Need {
  value: NeedForRevision;
}

export interface NeedForRevisionDialogInput {
  id: string;
  needForRevision?: NeedForRevision | null;
  needForRevisionComment?: string | null;
}

export const NeedForRevisionDialog: FC<{ modal: Modal<NeedForRevisionDialogInput, unknown> }> = ({ modal }) => {
  const guide = modal.data;
  const [comment, setComment] = useState(guide.needForRevisionComment);
  const [needForRevision, setNeedForRevision] = useState(guide.needForRevision);

  const [updateNeedForRevision, { loading }] = useMutation(SET_NEED_FOR_REVISION, {
    variables: { input: { id: guide.id, needForRevision: needForRevision!, comment } },
  });

  const handleClose = () => {
    modal.close();
  };

  const handleOk = async () => {
    await updateNeedForRevision();

    handleClose();
  };

  return (
    <div>
      <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth>
        <DialogTitle id="form-dialog-title">Angi behov for revisjon</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="comment"
            label="Behov"
            select
            onChange={(e) => setNeedForRevision(e.target.value !== '' ? (e.target.value as NeedForRevision) : undefined)}
            value={needForRevision ? needForRevision : ''}
            fullWidth
            autoFocus>
            {Object.keys(NeedForRevision)
              .filter((key) => isNaN(Number(key)))
              .map((x) => NeedForRevision[x as keyof typeof NeedForRevision])
              .map((x) => (
                <MenuItem key={x} value={x}>
                  <NeedForRevisionLabel needForRevision={x} />
                </MenuItem>
              ))}
          </TextField>
          <TextField
            margin="dense"
            name="comment"
            label="Kommentar"
            type="text"
            multiline
            onChange={(e) => setComment(e.target.value)}
            value={comment ? comment : ''}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Avbryt</Button>
          <Button disabled={loading} onClick={handleOk} variant="contained" color="primary">
            Angi behov
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const NeedForRevisionLabel: FC<{ needForRevision: NeedForRevision | undefined }> = ({ needForRevision }) => {
  const index = getEnumIndex(needForRevision);
  return (
    <span>
      {index !== undefined && `${index}. `}
      {getTextForEnum(needForRevision)}
    </span>
  );
};

function getEnumIndex(needForRevision: NeedForRevision | undefined): number | undefined {
  switch (needForRevision) {
    case NeedForRevision.Critical:
      return 1;
    case NeedForRevision.High:
      return 2;
    case NeedForRevision.Medium:
      return 3;
    case NeedForRevision.Low:
      return 4;
    case NeedForRevision.NextTime:
      return 5;
    default:
      return undefined;
  }
}

function getTextForEnum(needForRevision: NeedForRevision | undefined): string {
  switch (needForRevision) {
    case NeedForRevision.Critical:
      return 'Trenger revisjon';
    case NeedForRevision.High:
      return 'Trenger ikke revisjon';
    case NeedForRevision.Medium:
      return 'Under revisjon';
    case NeedForRevision.Low:
      return 'Tas ut';
    case NeedForRevision.NextTime:
      return 'Vurderes';
    default:
      return 'Ikke angitt';
  }
}
