import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CHANGES_SINCE_LAST_PUBLISH, PUBLISH_GUIDE } from './guide.graphql';
import LoadingSpinner from '../LoadingSpinner';
import { PublishType, GuideStatus } from '../__generated__/graphql';
import { Guide } from './Details';
import { useAuth } from 'oidc-react';
import { useConfig } from '../env';
import { Modal } from '../dialogs/useModal';

export function PublishGuideDialog({ modal }: { modal: Modal<Guide, unknown> }) {
  const guide = modal.data;
  const auth = useAuth();
  const user = auth.userData?.profile;
  const config = useConfig();
  const [forceMajor, setForceMajor] = useState(guide.versions!.length === 0);
  const [publishType, setPublishType] = useState<PublishType | undefined>(forceMajor ? PublishType.Major : undefined);
  const [publishGuide, { loading }] = useMutation(PUBLISH_GUIDE, {
    variables: { input: { id: guide.id, releaseNotes: guide.releaseNotes, type: publishType ?? PublishType.Major } },
  });
  const lastRelease = guide.versions!.length > 0 ? [...guide.versions!].reverse()[0] : undefined;
  const canPublish = user && config && isPublisher(config.publishers, user.email) && publishType;

  const handleClose = () => {
    modal.close();
  };

  const handleOk = async () => {
    await publishGuide();
    handleClose();
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newType = event.target.value as PublishType;
    setPublishType(newType);
  };

  const getNextVersionNumber = (): string => {
    if (!lastRelease) return '1.0';

    var major = lastRelease.major;
    var minor = lastRelease.minor;

    if (publishType === PublishType.Major) {
      major++;
      minor = 0;
    } else if (publishType === PublishType.Minor) {
      minor++;
    }

    return `${major}.${minor}`;
  };

  const setMajorChange = () => {
    if (publishType !== PublishType.Major) setPublishType(PublishType.Major);
    if (!forceMajor) setForceMajor(true);
  };

  const versionNumber = getNextVersionNumber();
  const isNew = guide.status === GuideStatus.Planned;

  return (
    <Dialog
      sx={{ minHeight: '25vh', maxHeight: '50vh' }}
      fullWidth={true}
      maxWidth={'md'}
      open={true}
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{isNew ? 'Publisér' : 'Publisér endringer'}</DialogTitle>
      <DialogContent>
        {!isNew && <ListChanges id={guide.id} onMajorChanges={setMajorChange} />}
        <FormControl component="fieldset">
          <FormLabel sx={{ position: 'absolute', float: 'left', left: 0, top: 9 }} component="legend">
            Endringstype:
          </FormLabel>
          <RadioGroup sx={{ marginLeft: 13 }} row aria-label="type" name="type" value={publishType ?? 'Unset'} onChange={handleTypeChange}>
            <FormControlLabel value={PublishType.Major} control={<Radio color="primary" />} label="Fullstendig revisjon" />
            <FormControlLabel value={PublishType.Minor} disabled={forceMajor} control={<Radio color="primary" />} label="Begrenset revisjon" />
            <FormControlLabel
              sx={{
                border: (theme) => theme.palette.text.disabled,
                borderLeftStyle: 'dashed',
                borderWidth: 'thin',
                paddingRight: '10px',
                marginLeft: '25px',
              }}
              value={PublishType.Bugfix}
              disabled={forceMajor}
              control={<Radio color="primary" />}
              label="Utbedring"
            />
          </RadioGroup>
        </FormControl>
        <div>
          <Typography color="textSecondary" display="inline">
            Ny versjon:
          </Typography>{' '}
          <Typography sx={{ fontWeight: 500, color: (theme) => theme.palette.text.primary }}>{canPublish ? versionNumber : ''}</Typography>
        </div>
        <div>
          <Typography color="textSecondary" mt={2} gutterBottom>
            Hva er endret: (teksten under er synlig i endringshistorikk)
          </Typography>
          <Typography whiteSpace="pre-wrap">{guide.releaseNotes}</Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Avbryt</Button>
        <Button disabled={!canPublish || loading} onClick={handleOk} variant="contained" color="primary">
          Publisér
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ListChanges({ id, onMajorChanges }: { id: string; onMajorChanges(): void }) {
  const { data, loading } = useQuery(GET_CHANGES_SINCE_LAST_PUBLISH, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
  const majorChanges = data && data.guide?.trackingChanges?.some((x) => x?.isMajor);

  if (majorChanges) onMajorChanges();

  return (
    <div>
      <Typography color="textSecondary">Følgende endringer vil publiseres:</Typography>
      <List dense>
        {loading && <LoadingSpinner size={50} />}
        {data &&
          data.guide?.trackingChanges?.map((item, index) => (
            <ListItem key={index}>
              <ListItemText>
                <Typography variant="body2">- {item?.description}</Typography>
              </ListItemText>
            </ListItem>
          ))}
      </List>
    </div>
  );
}

function isPublisher(publishers: string, email?: string): boolean {
  if (!publishers || !email) return false;
  if (publishers === '*') return true;
  return publishers.toLowerCase().split(';').includes(email.toLowerCase());
}
