import React, { FC, useEffect, useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { Modal } from '../../../../dialogs/useModal';
import '@wiris/mathtype-generic/wirisplugin-generic';
import styled from '@emotion/styled';
import { theme } from '../../../../theme';

export interface MathTypeDialogResult {
  html: string;
  latexParts: string[];
}

export interface MathTypeDialogProps {
  html: string;
}

declare global {
  interface Window {
    WirisPlugin: any;
    wrs_int_init: any;
    Latex: any;
  }
}

const TOOLBAR_ID = 'mathTypeToolbar';
const EDITOR_ID = 'mathTypeHtmlEditor';

export const MathTypeDialog: FC<Modal<MathTypeDialogProps, MathTypeDialogResult>> = (modal) => {
  const { close } = modal;
  const handleOnClose = () => {
    close();
  };

  const handleOnInsert = () => {
    const innerHtml = document.getElementById(EDITOR_ID)?.innerHTML ?? '';
    if (!innerHtml) {
      close();
      return;
    }
    const parsedHtml = window.WirisPlugin.Parser.endParse(innerHtml);
    const mathMLRegex = /(<math[^>]*>.*?<\/math>)|([^<]+)/g;

    const matches = [...parsedHtml.matchAll(mathMLRegex)];

    const result = matches
      .map((match) => match[0])
      .map((match) => {
        if (match.startsWith('<math')) {
          const latex = window.WirisPlugin.Latex.getLatexFromMathML(match);
          return '$' + latex + '$';
        }
        return match;
      });

    close({ html: parsedHtml, latexParts: result });
  };

  return (
    <Dialog
      disableEnforceFocus // Must be set in order for the MathType editor to work
      open={true}
      onClose={handleOnClose}
      fullWidth={true}>
      <DialogTitle>MathType Formel</DialogTitle>
      <DialogContent>
        <Editor html={modal.data.html ?? ''} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Avbryt</Button>
        <Button variant="contained" onClick={handleOnInsert}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const Editor: FC<{ html: string }> = ({ html: initialHtml }) => {
  const [html, _] = React.useState(initialHtml);

  const parsedHtmlData = useMemo(() => {
    const result = window.WirisPlugin.Parser.initParse(html);
    return result;
  }, [html]);

  useEffect(() => {
    const editor = document.getElementById(EDITOR_ID);
    const toolbar = document.getElementById(TOOLBAR_ID);

    var genericIntegrationProperties = {
      editorParameters: { language: 'nb', configurationService: { wirisChemEditorEnabled: false } },
    };
    window.wrs_int_init(editor, toolbar, genericIntegrationProperties);
    return () => {
      if (toolbar) {
        toolbar.innerHTML = '';
      }
      window.WirisPlugin.currentInstance.destroy();
    };
  }, []);

  return (
    <div>
      <Toolbar />
      <HtmlEditor data={parsedHtmlData} />
    </div>
  );
};

const StyledHtmlEditor = styled.div`
  border: 1px solid ${theme.palette.divider};
  border-radius: ${theme.shape.borderRadius}px;
  padding: 10px;
`;
const StyledToolbar = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 8px;
  grid-auto-flow: column;
  margin-bottom: 10px;
  & > img {
    padding: 6px;
    border: 1px solid ${theme.palette.divider};
    border-radius: ${theme.shape.borderRadius}px;
    &:hover {
      background-color: #f0f0f0;
    }
  }
`;
const Toolbar = () => (
  <div>
    <Typography variant="body2" gutterBottom>
      Sett inn symboler og formler
    </Typography>
    <StyledToolbar id={TOOLBAR_ID}></StyledToolbar>
  </div>
);
const HtmlEditor: FC<{ data: string }> = (props) => (
  <div>
    <Typography variant="body2" gutterBottom>
      Dobbelklikk på teksten for å redigere i MathType editoren
    </Typography>
    <StyledHtmlEditor id={EDITOR_ID} contentEditable="true" dangerouslySetInnerHTML={{ __html: props.data }}></StyledHtmlEditor>
  </div>
);
