import React from 'react';

import { Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import { formatDate } from '../Formatters';

export default ({ tasks }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Tittel</TableCell>
          <TableCell>Tekst</TableCell>
          <TableCell>Opprettet av</TableCell>
          <TableCell>Dato</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tasks.map((task) => {
          return (
            <TableRow key={task.id}>
              <TableCell>{task.title}</TableCell>
              <TableCell>{task.text}</TableCell>
              <TableCell>{task.createdByName}</TableCell>
              <TableCell>{formatDate(task.createdAt)}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
