import Heading from '@tiptap/extension-heading';
import { NodeViewProps, ReactNodeViewRenderer } from '@tiptap/react';
import { FC } from 'react';

export const createHeadingExtension = (viewComponent?: FC<NodeViewProps>) => {
  const addNodeView = viewComponent ? () => ReactNodeViewRenderer(viewComponent) : null;

  return Heading.extend({
    addNodeView,
  }).configure({
    levels: [1, 2, 3],
  });
};
