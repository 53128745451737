import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './../store';

export interface EditProps {
  guidesInEditMode: string[];
}

const initialState: EditProps = {
  guidesInEditMode: [],
};

export const guideEditSlice = createSlice({
  name: 'guideEdit',
  initialState,
  reducers: {
    toggleEditMode: (state, action: PayloadAction<string>) => {
      state.guidesInEditMode = state.guidesInEditMode.includes(action.payload)
        ? state.guidesInEditMode.filter((id) => id !== action.payload)
        : [...state.guidesInEditMode, action.payload];
    },
  },
});

export const { toggleEditMode } = guideEditSlice.actions;

export const selectGuideEdit = (state: RootState) => state.guideEdit;

export default guideEditSlice.reducer;
