import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ADD_COMMENT, UPDATE_COMMENT } from './comment.graphql';
import { useMutation } from '@apollo/client';
import { Modal } from '../dialogs/useModal';

export interface GuideCommentDialogInput {
  guideId: string;
  commentId?: string;
  comment?: string | null;
}

export function GuideCommentDialog({ modal }: { modal: Modal<GuideCommentDialogInput, boolean> }) {
  const { guideId, commentId, comment } = modal.data;
  const [workingComment, setWorkingComment] = useState(comment);
  const [updateComment, { loading: updating }] = useMutation(UPDATE_COMMENT, {
    variables: { input: { id: guideId, commentId, comment: workingComment } },
  });
  const [addComment, { loading: adding }] = useMutation(ADD_COMMENT, { variables: { input: { id: guideId, comment: workingComment } } });

  const handleClose = () => {
    modal.close(false);
  };

  const handleOk = async () => {
    if (commentId) {
      await updateComment();
    } else {
      await addComment();
    }

    modal.close(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWorkingComment(event.target.value);
  };

  const heading = commentId ? 'Endre kommentar' : 'Legg til kommentar';
  const saving = updating || adding;
  return (
    <div>
      <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth>
        <DialogTitle id="form-dialog-title">{heading}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            name="comment"
            label="Kommentar"
            type="text"
            multiline
            onChange={handleInputChange}
            value={workingComment}
            fullWidth
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Avbryt</Button>
          <Button disabled={saving} onClick={handleOk} variant="contained" color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
