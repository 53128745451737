import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { TableCell, Typography, TableSortLabel, css } from '@mui/material';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

export const numberColumnWidth = 100;

export function BackButton() {
  const navigate = useNavigate();
  return (
    <IconButton onClick={() => navigate(-1)} size="large">
      <ArrowBack />
    </IconButton>
  );
}

const linkStyles = css`
  &:link,
  &:visited {
    color: #0a57cd;
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledAnchor = styled.a`
  ${linkStyles}
`;
export const StyledLink = styled(Link)`
  ${linkStyles}
`;

export const LimitedCellResourceWithFontWeight = (props) => {
  const x = props.marginLeft ? props.marginLeft : 6;
  return (
    <TableCell padding="none" style={{ height: '100%' }}>
      <Typography
        noWrap
        title={props.title || props.children}
        style={{
          maxWidth: props.maxWidth,
          fontFamily: 'Arial, Helvetica, sans-serif',
          fontSize: 14,
          alignContent: 'left',
          fontWeight: props.fontWeight,
          height: '100%',
          marginLeft: x,
        }}>
        {props.children}
      </Typography>
    </TableCell>
  );
};

export const LimitedTypography = (props) => {
  const x = props.marginLeft ? props.marginLeft : 6;
  return (
    <Typography
      noWrap
      title={props.title || props.children}
      style={{
        maxWidth: props.maxWidth,
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: 14,
        alignContent: 'left',
        height: '100%',
        marginLeft: x,
      }}>
      {props.children}
    </Typography>
  );
};

export const LimitedCell = (props) => {
  const x = props.marginLeft ? props.marginLeft : 6;
  const title = props.title || props.children;
  return (
    <TableCell padding="none" className={props.className} style={{ height: '100%' }}>
      <Typography
        noWrap
        title={title ? title : undefined}
        style={{
          maxWidth: props.maxWidth,
          fontFamily: 'Arial, Helvetica, sans-serif',
          fontSize: 14,
          alignContent: 'left',
          height: '100%',
          marginLeft: x,
        }}>
        {props.children}
      </Typography>
    </TableCell>
  );
};

export const LimitedCellResource = (props) => {
  const x = props.marginLeft ? props.marginLeft : 6;
  return (
    <TableCell padding="none" align="left" style={{ height: '100%' }}>
      <Typography
        noWrap
        title={props.title || props.children}
        style={{
          maxWidth: props.maxWidth,
          fontFamily: 'Arial, Helvetica, sans-serif',
          fontSize: 14,
          alignContent: 'left',
          marginLeft: x,
          height: '100%',
        }}>
        {props.children}
      </Typography>
    </TableCell>
  );
};
export const LimitedCellResourceNumber = (props) => (
  <TableCell padding="none" align="left">
    <Typography style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 14, alignContent: 'left', height: '100%', width: 80 }}>
      {props.children}
    </Typography>
  </TableCell>
);
export const LimitedCellGuideNumber = (props) => {
  const x = props.marginLeft ? props.marginLeft : 6;
  return (
    <TableCell padding="none" align="left" className={props.className}>
      <Typography
        style={{ fontFamily: 'Arial, Helvetica, sans-serif', marginLeft: x, fontSize: 14, alignContent: 'left', height: '100%', width: 80 }}>
        {props.children}
      </Typography>
    </TableCell>
  );
};

export const ListLabel = (props) => <Typography style={{ minWidth: props.width || 150, marginTop: 4, marginBottom: 4 }}>{props.children}</Typography>;
export const EditedTableHead = (props) => {
  const x = props.marginLeft ? props.marginLeft : 6;
  return (
    <TableCell padding="none" align="left" className={props.className}>
      <Typography style={{ color: '#8b8b8b', fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 12, marginLeft: x }}>{props.children}</Typography>
    </TableCell>
  );
};

const selectStyle = (width) => ({
  control: (base, state) => ({
    ...base,
    width,
    boxShadow: 'none',
    border: 'none',
    borderRadius: 0,
    borderBottomStyle: 'solid',
    borderBottomWidth: state.isFocused ? '2px' : '1px',
    borderBottomColor: state.isFocused ? 'black' : 'gray',
    '&:hover': { borderBottomColor: 'black', borderBottomWidth: '2px' },
  }),
});

export const MultiSelect = (props) => <Select {...props} styles={selectStyle(props.width || 500)} isMulti />;

interface SortableTableHeadCellProps {
  property: string;
  title?: string;
  className?: string;
  order: 'asc' | 'desc' | false;
  orderBy?: string;
  onRequestSort: () => void;
  children: any;
}
export const SortableTableHeadCell = ({ property, title, className, order, orderBy, onRequestSort, children }: SortableTableHeadCellProps) => (
  <TableCell
    style={{ paddingBottom: 0, textDecoration: 'underline' }}
    key={property}
    title={title}
    className={className}
    sortDirection={orderBy === property ? order : false}>
    <TableSortLabel active={orderBy === property} direction={order || undefined} onClick={() => onRequestSort()}>
      {children}
    </TableSortLabel>
  </TableCell>
);

interface SortableTableHeadCellPropsExtra {
  property: string;
  title?: string;
  className?: string;
  order: 'asc' | 'desc' | false;
  orderBy?: string;
  onRequestSort: () => void;
  children: any;
  maxWidth: any;
}
export const SortableTableHeadCellExtra = ({
  property,
  title,
  className,
  order,
  orderBy,
  onRequestSort,
  children,
  maxWidth,
}: SortableTableHeadCellPropsExtra) => (
  <TableCell
    style={{ maxWidth: maxWidth ? maxWidth : 200, paddingBottom: 0, textDecoration: 'underline' }}
    key={property}
    title={title}
    className={className}
    sortDirection={orderBy === property ? order : false}>
    <TableSortLabel active={orderBy === property} direction={order || undefined} onClick={() => onRequestSort()}>
      {children}
    </TableSortLabel>
  </TableCell>
);

export const PreWrap = (props) => <Typography {...props} style={{ whiteSpace: 'pre-wrap' }} />;

export const SortBy = function (field, reverse, primer) {
  var key = function (x) {
    return primer ? primer(x[field]) : x[field];
  };
  return function (a, b) {
    var A = key(a),
      B = key(b);
    return (A < B ? -1 : A > B ? 1 : 0) * [-1, 1][+!!reverse];
  };
};

export const SortByBoolean = function (a, b) {
  return a === b ? 0 : a ? -1 : 1;
};
