import React from 'react';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import { getGuideStatusDescription } from '../guides/GuideModel';
import { writeFile, utils } from 'xlsx';
import { BacklogItem } from './types';

const PREFIX = 'ExcelExport';

const classes = {
  excelButton: `${PREFIX}-excelButton`,
  excelIcon: `${PREFIX}-excelIcon`,
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  [`&.${classes.excelButton}`]: {
    position: 'absolute' as 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(12),
  },

  [`& .${classes.excelIcon}`]: {
    width: 24,
    height: 24,
  },
}));

export function ExcelExport({ items }: { items?: BacklogItem[] }) {
  return (
    <StyledIconButton className={classes.excelButton} onClick={() => exportFile(items)} title="Eksport til Excel" disabled={!items} size="large">
      <img src="/icons8-microsoft-excel.svg" className={classes.excelIcon} alt="Excel icon" />
    </StyledIconButton>
  );
}

function exportFile(items?: BacklogItem[]) {
  if (!items) {
    return;
  }

  const headings: string[] = [
    'Status',
    'Nummer',
    'Tittel',
    'Siste versjon',
    'Visninger',
    'Prioritert',
    'Prioritert begrunnelse',
    'Fagområde',
    'Merknader',
    'Oppgaver',
    'Forfatter',
    'Prosjektleder',
    'Kommentarer',
  ];

  const data = items.map((item) => {
    if (!item) return [];
    return [
      getGuideStatusDescription(item.status!, item.hasChangesSinceLastPublish, item.ongoingRevision !== null),
      item.docName,
      item.docTitle,
      item.planningScores?.edition?.value,
      item.planningScores?.pageViews?.value,
      item.planningScores?.needForRevision?.value,
      item.needForRevisionComment,
      item.mainCategory?.title,
      item.notices
        ?.filter((x) => !x?.deletedAt)
        .map((notice) => notice!.notice)
        .join('\n'),
      item.tasks?.map((task) => task!.title).join('\n'),
      item.authors?.map((author) => author!.name).join(','),
      item.projectLeader,
      item.comments?.map((comment) => comment!.comment).join('\n'),
    ];
  });
  data.unshift(headings);
  const sheet = utils.aoa_to_sheet(data);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, sheet, 'Planlegging');
  writeFile(workbook, 'planlegging.xlsx');
}
