import React, { FC, useMemo } from 'react';
import { Button, Stack, Typography } from '@mui/material';

import {
  barChartGrouped,
  barChartHorizontal,
  barChartSingle,
  barChartStacked,
  lineChartMultiple,
  lineChartStyled,
  lineChartSingle,
  lineChartSmooth,
} from './examples';
import { stringify } from './jsonHelper';

export const ChartExamples: FC<{ onSelect: (config: string) => void }> = ({ onSelect }) => {
  return (
    <Stack spacing={1}>
      <Typography variant="h6">Eksempler</Typography>
      <p>Klikk på et av eksemplene under som inspirasjon til å komme i gang med grafer.</p>
      <Typography variant="subtitle2">Linjer</Typography>
      <Stack direction="row" spacing={2}>
        <ChartExample title="Enkel linje" getConfig={lineChartSingle} onSelect={onSelect} />
        <ChartExample title="Flere linjer" getConfig={lineChartMultiple} onSelect={onSelect} />
        <ChartExample title="Utjevnet linje" getConfig={lineChartSmooth} onSelect={onSelect} />
        <ChartExample title="Stiplet linje" getConfig={lineChartStyled} onSelect={onSelect} />
      </Stack>
      <Typography variant="subtitle2">Søyler</Typography>
      <Stack direction="row" spacing={2}>
        <ChartExample title="Enkel" getConfig={barChartSingle} onSelect={onSelect} />
        <ChartExample title="Gruppert" getConfig={barChartGrouped} onSelect={onSelect} />
        <ChartExample title="Stablet" getConfig={barChartStacked} onSelect={onSelect} />
        <ChartExample title="Horisontal" getConfig={barChartHorizontal} onSelect={onSelect} />
      </Stack>
    </Stack>
  );
};

export const ChartExample: FC<{ title: string; getConfig: () => unknown; onSelect: (config: string) => void }> = ({ title, getConfig, onSelect }) => {
  const config = useMemo(() => getConfig(), []);
  return (
    <Button variant="outlined" onClick={() => onSelect(stringify(config))}>
      {title}
    </Button>
  );
};
