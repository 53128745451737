import React, { FC } from 'react';
import { useGuideCmsContext } from '../GuideCmsContext';
import { Comments } from './comments/Comments';
import { TableOfContents } from './tableOfContents/TableOfContents';
import { Help } from './help/Help';
import { References } from './references/References';

export const Sidebar: FC = () => {
  const { sidebarType, editor, isSidebarOpen } = useGuideCmsContext();
  if (!editor || !isSidebarOpen) {
    return null;
  }

  switch (sidebarType) {
    case 'toc':
      return <TableOfContents editor={editor} />;
    case 'comments':
      return <Comments />;
    case 'references':
      return <References />;
    case 'help':
      return <Help />;
    default:
      return <div>Invalid sidebar type: {sidebarType}</div>;
  }
};
