import React, { FC } from 'react';
import { useGuideCmsContext } from '../GuideCmsContext';
import { useFigure } from './figureHelper';
import { EditableTextField } from '../../../components/EditableTextField';
import styled from '@emotion/styled';
import { useMutation } from '@apollo/client';
import { UPDATE_FIGURE_COPYRIGHT } from '../content.graphql';
import { EditGuideFigureStatus } from './EditGuideFigureStatus';

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  gap: 8px;
`;

export const EditGuideFigureInformation: FC<{
  figureId: string;
}> = ({ figureId }) => {
  const { guideId } = useGuideCmsContext();
  const { figure } = useFigure(guideId, figureId);
  const [updateFigureCopyright] = useMutation(UPDATE_FIGURE_COPYRIGHT);

  return (
    <FormContainer>
      <strong>Opphavsrett</strong>
      <EditableTextField
        TextFieldProps={{
          variant: 'standard',
          placeholder: 'Opphavsrett',
        }}
        value={figure?.copyright ?? ''}
        onUpdate={async (value) => {
          await updateFigureCopyright({ variables: { input: { id: figureId, guideId, copyright: value } } });
        }}
      />
      <EditGuideFigureStatus figureId={figureId} />
    </FormContainer>
  );
};
