import React from 'react';
import { useQuery } from '@apollo/client';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { GET_ALL } from './categories.graphql';
import { GetCategoriesQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';

export type Category = Unpacked<GetCategoriesQuery['categories']>

export function CategorySelect({ value, onChange, isBvn }: { value: any; onChange(v: Category | null): void; isBvn: boolean }) {
  const { data } = useQuery(GET_ALL);

  const defaultProps = {
    options: data?.categories ? data.categories : [{ id: '-1', title: '<Henter..>' }],
    getOptionLabel: (option) => option.title,
  };

  const handleCategoryChange = (e, v: Category | undefined) => {
    onChange(v ?? null);
  };

  const bvnCategory = data?.categories && data.categories.find((x) => x?.id === '30c772a0-0cff-405c-9d40-f30a4cbc3320');
  if (isBvn && bvnCategory && value !== bvnCategory) onChange(bvnCategory);

  return (
    <Autocomplete
      {...defaultProps}
      disabled={isBvn}
      onChange={(e, v: any) => handleCategoryChange(e, v)}
      value={value}
      renderInput={(params) => <TextField {...params} label="Fagområde" margin="normal" />}
    />
  );
}
