import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useMutation } from '@apollo/client';
import { COMPLETE } from './task.graphql';
import { formatDate } from '../Formatters';
import { Modal } from '../dialogs/useModal';
import { Task } from './TaskActions';

export interface CompleteTaskDialogProps {
  task: Task;
  guideId: string;
}

export function CompleteTaskDialog({ modal }: { modal: Modal<CompleteTaskDialogProps, unknown> }) {
  const { task, guideId } = modal.data;
  const [comment, setComment] = useState('');
  const [completeTask, { loading }] = useMutation(COMPLETE, { variables: { input: { id: task.id, guideId, comment } } });

  const handleClose = () => modal.close();

  const handleOk = async () => {
    await completeTask();
    handleClose();
  };

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Lukk oppgave</DialogTitle>
      <DialogContent>
        <TextField margin="dense" label="Opprettet av" type="text" disabled value={task.createdByName} fullWidth />
        <TextField margin="dense" label="Opprettet dato" type="text" disabled value={formatDate(task.createdAt)} fullWidth />
        <TextField margin="dense" label="Tittel" type="text" value={task.title} disabled fullWidth />
        <TextField margin="dense" label="Tekst" type="text" multiline value={task.text} disabled fullWidth />
        <TextField
          margin="dense"
          name="comment"
          label="Kommentar"
          type="text"
          multiline
          onChange={(e) => setComment(e.target.value)}
          value={comment}
          autoFocus
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Avbryt</Button>
        <Button onClick={handleOk} variant="contained" color="primary" disabled={loading}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
