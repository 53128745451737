import React from 'react';
import { PreWrap } from '../Components';
import { Typography, IconButton, List, ListItem, ListItemText, ListItemAvatar, Avatar, Tooltip } from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import { formatDate } from '../Formatters';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { REMOVE_COMMENT } from './categories.graphql';
import { CategoryComment, GetCategoryQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';
import { useModal } from '../dialogs/useModal';
import { CategoryCommentDialog, CategoryCommentDialogInput } from './CategoryCommentDialog';

type Category = Unpacked<GetCategoryQuery['category']>;

export function Comments({ category }: { category: Category }) {
  const [remove] = useMutation(REMOVE_COMMENT);
  const commentModal = useModal<CategoryCommentDialogInput, unknown>({ data: { categoryId: category?.id } });

  const comment = async (commentId?: string, comment?: string | null) => {
    await commentModal.open({ categoryId: category?.id, commentId, comment });
  };

  const removeComment = async (commentId: string) => {
    await remove({ variables: { input: { id: category?.id, commentId } } });
  };

  return (
    <StyledPaper>
      <Typography variant="h5" component="h3">
        Kommentarer
        <IconButton onClick={() => comment()} aria-label="Ny kommentar" size="large">
          <Add />
        </IconButton>
      </Typography>
      <CommentList comments={category?.comments as CategoryComment[]} comment={comment} remove={removeComment} />
      {commentModal.isOpen && <CategoryCommentDialog modal={commentModal} />}
    </StyledPaper>
  );
}

function CommentList({
  comments,
  comment,
  remove,
}: {
  comments?: CategoryComment[];
  comment: (commentId?: string, comment?: string | null) => void;
  remove: (commentId: string) => void;
}) {
  if (comments?.length === 0) {
    return <Typography variant="subtitle1">Ingen kommentarer</Typography>;
  }

  const editComment = async (commentObject: CategoryComment) => {
    comment(commentObject.id, commentObject.comment);
  };

  const deleteComment = async (commentId: string) => {
    await remove(commentId);
  };

  const sortComments = (a: CategoryComment, b: CategoryComment) => {
    var date1 = a.lastUpdatedAt || a.createdAt;
    var date2 = b.lastUpdatedAt || b.createdAt;
    if (moment(date2).isAfter(date1)) {
      return 1;
    }
    if (moment(date1).isAfter(date2)) {
      return -1;
    }
    return 0;
  };

  const getSecondary = (c: CategoryComment) => {
    const createdInfo = `${c.createdBy?.name} ${formatDate(c.createdAt)}`;
    return c.lastUpdatedAt ? createdInfo + ` - Sist endret av ${c.lastUpdatedBy?.name} ${formatDate(c.lastUpdatedAt)}` : createdInfo;
  };

  const getInitials = (name: string | null | undefined) => {
    if (!name) return null;
    return name
      .split(' ')
      .map((x) => x[0])
      .join('');
  };
  return (
    <List>
      {[...(comments as CategoryComment[])].sort(sortComments).map((c) => (
        <ListItem key={c.id} divider>
          <ListItemAvatar>
            <Avatar>{getInitials(c.createdBy?.name)}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={<PreWrap>{c.comment}</PreWrap>} secondary={getSecondary(c)} />
          <Tooltip id="tooltip-icon" title="Endre" placement="top">
            <IconButton onClick={() => editComment(c)} size="large">
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip id="tooltip-icon" title="Fjern" placement="top">
            <IconButton onClick={() => deleteComment(c.id)} size="large">
              <Delete />
            </IconButton>
          </Tooltip>
        </ListItem>
      ))}
    </List>
  );
}
