import React from 'react';
import IconButton from '@mui/material/IconButton';
import UpdateIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/DeleteForever';
import { ConfirmDialog, ConfirmDialogProps } from '../dialogs/ConfirmDialog';
import { useMutation } from '@apollo/client';
import { REMOVE } from './notices.graphql';
import { GetGuideQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { useModal } from '../dialogs/useModal';
import { UpdateGuideNoticeDialog } from './UpdateGuideNoticeDialog';

type Notice = NonNullable<Unpacked<NonNullable<GetGuideQuery['guide']>['notices']>>;

export function NoticeActions({ notice }: { notice: Notice }) {
  const dialogProps: ConfirmDialogProps = {
    title: 'Sletting',
    message:
      'Er du sikker på at du ønsker å slette merknaden? Når du sletter merknaden, og publiserer endringen, slettes merknaden også på anvisningen på nett.',
    submitText: 'Slett',
    cancelText: 'Avbryt',
  };
  const confirmModal = useModal<ConfirmDialogProps, boolean>({ data: dialogProps });
  const updateModal = useModal<Notice, unknown>({ data: notice });
  const [removeGuideNotice] = useMutation(REMOVE, { variables: { input: { noticeId: notice!.id as string, id: notice?.guide?.id as string } } });

  let openUpdateDialog = () => {
    return updateModal.open(notice);
  };

  const handleClick = async () => {
    const confirmed = await confirmModal.open();
    if (confirmed) {
      await removeGuideNotice();
    }
  };
  return (
    <div>
      <IconButton onClick={openUpdateDialog} title="Endre merknad" size="large">
        <UpdateIcon />
      </IconButton>
      <IconButton onClick={handleClick} title="Slett merknad" size="large">
        <RemoveIcon />
      </IconButton>
      {updateModal.isOpen && <UpdateGuideNoticeDialog modal={updateModal} />}
      {confirmModal.isOpen && <ConfirmDialog modal={confirmModal} />}
    </div>
  );
}
