import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';

import { ADD } from './categories.graphql';
import { Modal } from '../dialogs/useModal';

export function AddCategoryDialog({ modal }: { modal: Modal<undefined, string | null | undefined> }) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [addCategory, { loading }] = useMutation(ADD, {
    variables: { input: { title, description } },
  });

  const handleClose = (newId?: string | null) => {
    modal.close(newId);
  };

  const handleOk = async () => {
    const result = await addCategory();
    handleClose(result.data?.categoryAdd?.categoryModel?.id);
  };

  const canAdd = !loading && title.length > 0;
  return (
    <Dialog open={true} onClose={() => handleClose()} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Legg til nytt fagområde</DialogTitle>
      <DialogContent>
        <TextField margin="dense" label="Tittel" type="text" autoFocus value={title} onChange={(e) => setTitle(e.target.value)} fullWidth required />
        <TextField margin="dense" label="Beskrivelse" type="text" value={description} onChange={(e) => setDescription(e.target.value)} fullWidth />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Avbryt</Button>
        <Button onClick={handleOk} variant="contained" color="primary" disabled={!canAdd}>
          Legg til
        </Button>
      </DialogActions>
    </Dialog>
  );
}
