let loadedConfig: Config = undefined;

const getConfig = async (): Promise<Config> => {
  if (loadedConfig) return loadedConfig;

  const response = await fetch('/env');
  const body = await response.json();
  if (response.status !== 200) {
    throw Error(body.message);
  }
  loadedConfig = body;
  return body;
};

export default getConfig;

export function useConfig(): Config | undefined {
  return loadedConfig;
}

export interface Config {
  apiUrl: string;
  authorityUrl: string;
  homeUrl: string;
  bksUrl: string;
  version: string;
  env: string;
  idp: string;
  defaultPollInterval: number;
  publishers: string;
  unleashUrl: string;
  unleashAccessToken: string;
  hocuspocusUrl: string;
}
