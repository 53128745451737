import React from 'react';
import { styled } from '@mui/material/styles';
import { FormControlLabel, Switch, Tooltip, Grid2 as Grid, Button } from '@mui/material';
import { MultiSelect } from '../Components';
import {
  selectBacklogFilter,
  setCategories,
  setSearchTerm,
  setShowBfs,
  setShowNewGuides,
  setShowBvn,
  setShowOngoingRevisions,
  setShowPublishedGuides,
} from './filterSlice';
import { useDispatch, useSelector } from 'react-redux';
import { SearchField } from '../components/SearchField';

const PREFIX = 'BacklogSearch';

const classes = {
  formControl: `${PREFIX}-formControl`,
  selectControl: `${PREFIX}-selectControl`,
  group: `${PREFIX}-group`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
  },

  [`& .${classes.selectControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    verticalAlign: 'baseline',
  },

  [`& .${classes.group}`]: {
    marginRight: theme.spacing(2),
  },
}));

export interface FilterProps {
  searchTerm: string;
  showNewGuides: boolean;
  showOngoingRevisions: boolean;
  showPublishedGuides: boolean;
  showBvn: boolean;
  showBfs: boolean;
  categories: string[];
}

export function Search({ allCategories, myCategories }: { allCategories: string[]; myCategories: string[] }) {
  const inputs: FilterProps = useSelector(selectBacklogFilter);
  const dispatch = useDispatch();

  const handleCategoryChange = (values) => {
    dispatch(setCategories(values ? values.map((v) => v.value) : []));
  };

  const Group = ({ children }) => <span className={classes.group}>{children}</span>;
  const getSelectValues = (strings: string[]) => (strings ? strings.map((s) => ({ value: s, label: s })) : [{ value: 'henter', label: 'henter' }]);
  return (
    <Root>
      <Grid container alignItems="center" wrap="nowrap">
        <Group>
          <Tooltip title="Vis aktive anvisninger som ikke er under revisjon">
            <FormControlLabel
              className={classes.formControl}
              control={<Switch onChange={() => dispatch(setShowPublishedGuides(!inputs.showPublishedGuides))} checked={inputs.showPublishedGuides} />}
              label="Aktive"
            />
          </Tooltip>
          <Tooltip title="Vis aktive anvisninger under revisjon">
            <FormControlLabel
              className={classes.formControl}
              control={
                <Switch onChange={() => dispatch(setShowOngoingRevisions(!inputs.showOngoingRevisions))} checked={inputs.showOngoingRevisions} />
              }
              label="Under revisjon"
            />
          </Tooltip>
        </Group>
        <Group>
          <Tooltip title="Vis nye anvisninger (ikke publiserte)">
            <FormControlLabel
              className={classes.formControl}
              control={<Switch onChange={() => dispatch(setShowNewGuides(!inputs.showNewGuides))} checked={inputs.showNewGuides} />}
              label="Nye"
            />
          </Tooltip>
        </Group>
        <Group>
          <Tooltip title="Vis anvisninger i Byggforskserien">
            <FormControlLabel
              className={classes.formControl}
              control={<Switch onChange={() => dispatch(setShowBfs(!inputs.showBfs))} checked={inputs.showBfs} />}
              label="BFS"
            />
          </Tooltip>
          <Tooltip title="Vis blader i Byggebransjens Våtromsnorm">
            <FormControlLabel
              className={classes.formControl}
              control={<Switch onChange={() => dispatch(setShowBvn(!inputs.showBvn))} checked={inputs.showBvn} />}
              label="BVN"
            />
          </Tooltip>
        </Group>
        <Grid>
          <MultiSelect
            placeholder="Fagområder"
            options={getSelectValues(allCategories)}
            value={getSelectValues(inputs.categories)}
            onChange={handleCategoryChange}
          />
        </Grid>
      </Grid>
      <Button variant="contained" onClick={() => dispatch(setCategories(myCategories))}>
        Mine
      </Button>
      <SearchField startSearch={inputs.searchTerm} filter={(x) => dispatch(setSearchTerm(x))} />
    </Root>
  );
}
