import React, { FC, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Content } from '@tiptap/react';
import { GUIDE_CONTENT_VERSION } from '../content.graphql';
import { getTipTapJsonContent } from '../tiptap/tiptapHelper';
import { GuideContent } from '../types';

interface HistoricContentContext {
  isLoading: boolean;
  historicContent: GuideContent;
  historicEditorContent?: Content;
}

const HistoricContentContextInstance = React.createContext<HistoricContentContext | undefined>(undefined);

export const useHistoricContentContext = (): HistoricContentContext => {
  const context = React.useContext(HistoricContentContextInstance);
  if (!context) {
    throw new Error('useHistoricContentContext must be used within a HistoricContentContextProvider');
  }
  return context;
};

export const HistoricContentContextProvider: FC<{ guideId: string; version?: number; children: React.ReactNode }> = ({
  guideId,
  version,
  children,
}) => {
  const [content, setContent] = useState<GuideContent>();
  const [editorContent, setEditorContent] = useState<Content>();

  useEffect(() => {
    setContent(undefined);
    setEditorContent(undefined);
    if (!version) return;
    guideContentQuery();
  }, [guideId, version]);

  const [guideContentQuery, { loading }] = useLazyQuery(GUIDE_CONTENT_VERSION, {
    variables: { guideId, version: version! },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setContent(data?.guideContentVersion);
      if (!data.guideContentVersion?.content) return;
      const jsonContent = getTipTapJsonContent(data.guideContentVersion?.content ?? '');
      setEditorContent(jsonContent);
    },
  });

  return (
    <HistoricContentContextInstance.Provider
      value={{
        isLoading: loading,
        historicContent: content,
        historicEditorContent: editorContent,
      }}>
      {children}
    </HistoricContentContextInstance.Provider>
  );
};
