import { GridOn } from '@mui/icons-material';
import { MenuButton, MenuButtonAddTableProps, useRichTextEditorContext } from 'mui-tiptap';
import React from 'react';

export default function AddTableToolbarButton(props: MenuButtonAddTableProps) {
  const editor = useRichTextEditorContext();
  return (
    <MenuButton
      tooltipLabel="Tabell"
      IconComponent={GridOn}
      disabled={!editor?.isEditable || !editor.can().chain().focus().insertTableFigure().run()}
      onClick={() => editor?.chain().focus().insertTableFigure({ rows: 3, cols: 3, withHeaderRow: true }).run()}
      {...props}
    />
  );
}
