import { Editor } from '@tiptap/core';
import { Transaction } from '@tiptap/pm/state';
import { v4 as uuidv4 } from 'uuid';

export const handlePasteTransaction = (editor: Editor, transaction: Transaction) => {
  if (transaction.getMeta('paste') === true) {
    return wrapPastedTablesInTableFigure(editor);
  }
  return false;
};

const wrapPastedTablesInTableFigure = (editor: Editor) => {
  const content = editor.getJSON();

  let hasChanges = false;
  const modifiedContent = {
    ...content,
    content: content.content?.map((node) => {
      if (node.type === 'table') {
        hasChanges = true;
        return {
          type: 'tableFigure',
          attrs: {
            id: uuidv4(),
          },
          content: [
            {
              type: 'figcaption',
              content: [
                {
                  type: 'paragraph',
                  attrs: {
                    id: uuidv4(),
                  },
                  content: [],
                },
              ],
            },
            node,
            {
              type: 'figcaption',
              content: [
                {
                  type: 'paragraph',
                  attrs: {
                    id: uuidv4(),
                  },
                  content: [],
                },
              ],
            },
          ],
        };
      }
      return node;
    }),
  };
  if (hasChanges) {
    editor.commands.setContent(modifiedContent);
  }
  return hasChanges;
};
