import React, { FC, useState } from 'react';
import { Modal } from '../../../dialogs/useModal';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Tab, Tabs } from '@mui/material';
import { UploadGuideFigureFiles } from './UploadGuideFigureFiles';
import { EditGuideFigureInformation } from './EditGuideFigureInformation';
import useKeyDown from '../../../utils/useKeyDown';
import { GuideFigureDialogProps, GuideFigureDialogResult } from './GuideFigureDialog';

export const EditGuideFigure: FC<{
  goBackToOverview: boolean;
  editFigureId?: string;
  onBack: () => void;
  modal: Modal<GuideFigureDialogProps, GuideFigureDialogResult>;
}> = ({ editFigureId, goBackToOverview: goBackToOverview, onBack, modal }) => {
  const [tabValue, setTabValue] = useState(editFigureId ? 1 : 0);
  const [figureId, setFigureId] = useState(editFigureId);

  useKeyDown('Escape', async () => {
    modal.close();
  });

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleOnFigureCreated = (newFigureId: string) => {
    setFigureId(newFigureId);
  };

  const handleOnClose = async () => {
    if (goBackToOverview) {
      onBack();
    } else {
      modal.close();
    }
  };

  return (
    <>
      <DialogTitle>{editFigureId ? 'Rediger figur' : 'Last opp figur'}</DialogTitle>
      <DialogContent sx={{ minHeight: 500 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
            <Tab label="Velg filer" />
            <Tab label="Informasjon" disabled={!figureId} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <UploadGuideFigureFiles figureId={figureId} onFigureCreated={handleOnFigureCreated} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          {figureId && <EditGuideFigureInformation figureId={figureId} />}
        </TabPanel>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleOnClose}>
          Lukk
        </Button>
      </DialogActions>
    </>
  );
};

const TabPanel: FC<{
  children?: React.ReactNode;
  value: number;
  index: number;
}> = ({ children, value, index }) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`guide_figure_tabpanel-${index}`}>
      <Box sx={{ p: 2 }}>{children}</Box>
    </div>
  );
};
