import { FC, useEffect, useState } from 'react';
import { Typography, IconButton, Popover } from '@mui/material';
import React from 'react';
import { Node } from '@tiptap/pm/model';
import { ChartParseResult, parseChartConfig } from './chartConfig';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism-coy.css';
import styled from '@emotion/styled';
import { theme } from '../../../theme';
import { CleaningServices, Palette } from '@mui/icons-material';
import { formatJson } from './jsonHelper';
import { ChartPalette } from './ChartPalette';

export interface ChartDialogResult {
  width: number;
  config: string;
}

export interface ChartDialogProps {
  chartNode: Node;
}

const StyledEditorWrapper = styled.div<{ error: boolean }>`
  position: relative;
  * {
    outline: none;
  }
  border-radius: ${theme.shape.borderRadius}px;
  border: 1px solid ${(props) => (props.error ? theme.palette.error.main : theme.palette.divider)};
  &:focus-within {
    border: 1px solid ${(props) => (props.error ? theme.palette.error.main : theme.palette.primary.main)};
  }
  font-family: monospace;
  font-size: 12px;
`;

const StyledToolbar = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`;

export const ChartConfigEditor: FC<{ config: string; onChange: (result: ChartParseResult) => void }> = ({ onChange, config: initialConfig }) => {
  const [config, setConfig] = useState(initialConfig);
  const [configTextAreaValue, setConfigTextAreaValue] = useState(initialConfig);
  const [configError, setConfigError] = useState<string>();
  const isValid = !configError;

  const handleOnFixFormatting = () => {
    const formattedConfig = formatJson(config);
    setConfig(formattedConfig);
    setConfigTextAreaValue(formattedConfig);
  };

  const handleConfigChange = (value: string) => {
    setConfigTextAreaValue(value);
    const parseResult = parseChartConfig(value);
    onChange(parseResult);
    if (parseResult.isValid) {
      setConfigError(undefined);
      setConfig(value);
    } else {
      setConfigError(parseResult.error);
    }
  };

  useEffect(() => {
    setConfig(initialConfig);
    setConfigTextAreaValue(initialConfig);
    setConfigError(undefined);
  }, [initialConfig]);

  const [palettePopoverAnchor, setPalettePopoverAnchor] = React.useState<HTMLButtonElement | null>(null);
  const handlePaletteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPalettePopoverAnchor(event.currentTarget);
  };

  const handlePalettePopoverClose = () => {
    setPalettePopoverAnchor(null);
  };
  const open = Boolean(palettePopoverAnchor);

  return (
    <div>
      <StyledEditorWrapper error={!!configError}>
        <StyledToolbar>
          <IconButton title="Vis fargepalett" onClick={handlePaletteClick}>
            <Palette />
          </IconButton>
          <Popover
            open={open}
            anchorEl={palettePopoverAnchor}
            onClose={handlePalettePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
            <div
              style={{ padding: 20 }}
              onClick={() =>
                setTimeout(() => {
                  handlePalettePopoverClose();
                }, 300)
              }>
              <ChartPalette />
            </div>
          </Popover>
          <IconButton disabled={!isValid} title="Fiks formatering" onClick={handleOnFixFormatting}>
            <CleaningServices />
          </IconButton>
        </StyledToolbar>
        <Editor
          value={configTextAreaValue}
          onValueChange={(code) => handleConfigChange(code)}
          highlight={(code) => highlight(code, languages.json, 'json')}
          padding={6}
          insertSpaces={true}
          tabSize={2}
        />
      </StyledEditorWrapper>
      {configError && (
        <Typography color="error" variant="body2">
          {configError}
        </Typography>
      )}
    </div>
  );
};
