import { useQuery } from '@apollo/client';
import { All_DEPARTMENTS } from './organization.graphql';
import { Unpacked } from '../graphQLTypes/types';
import { GetDepartmentsQuery } from '../__generated__/graphql';

type Department = Unpacked<GetDepartmentsQuery['departments']>;


export function useOrganization(): [(unitId: string) => string | null, (unitId: string) => string | null] {
  const { loading, error, data } = useQuery(All_DEPARTMENTS);

  const getDepartment = (unitId: string) => {
    return data?.departments?.find((x) => x?.units?.some((u) => u?.id === unitId));
  };

  const lookup = (unitId: string, resolve: (d: Department) => string | null): string | null => {
    if (!unitId) return null;
    if (error) return error.message;
    if (loading || !data) return 'Henter..';

    const department = getDepartment(unitId);
    return resolve(department);
  };

  const getDepartmentName = (unitId: string): string | null => {
    return lookup(unitId, (department) => (department?.name ? department.name : null));
  };

  const getUnitName = (unitId: string): string | null => {
    return lookup(unitId, (department) => (department ? (department.units?.find((x) => x?.id === unitId)?.name ?? null) : null));
  };

  return [getUnitName, getDepartmentName];
}
