import React from 'react';
import { styled } from '@mui/material/styles';
import { StyledLink, ListLabel } from '../Components';
import { List, ListItem, ListItemText, Button, Typography } from '@mui/material';
import { v4 as uuid } from 'uuid';
import { formatDateOnly } from '../Formatters';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { revisionTypeToDisplayText } from '../revisions/RevisionModel';
import { GetGuideQuery, GuideStatus } from '../__generated__/graphql';
import { START_REVISION } from './guide.graphql';
import { StyledPaper } from '../theme';

const PREFIX = 'Revision';

const classes = {
  paper: `${PREFIX}-paper`,
  detailItem: `${PREFIX}-detailItem`,
};

const StyledList = styled(List)(() => ({
  [`& .${classes.paper}`]: {
    minHeight: 260,
  },

  [`& .${classes.detailItem}`]: {
    paddingTop: 2,
    paddingBottom: 2,
    alignItems: 'flex-start',
  },
}));

export type Guide = NonNullable<GetGuideQuery['guide']>;

export function GuideRevision({ guide }: { guide: Guide }) {
  const guideId = guide.id;
  const guideName = guide.docName;
  const isNew = guide.status === GuideStatus.Planned;
  return (
    <StyledPaper className={classes.paper}>
      <Typography variant="h5" component="h3">
        {isNew ? 'Utarbeidelse' : 'Revisjon'}
      </Typography>

      {!guide.ongoingRevision && <CreateRevision guideId={guideId} />}
      {guide.ongoingRevision && <Details revision={guide.ongoingRevision} isNew={isNew} />}

      {!isNew && (
        <div style={{ marginLeft: 10 }}>
          <StyledLink to={`/revision?searchTerm=${guideName}&completed=0`}>Historikk</StyledLink>
        </div>
      )}
    </StyledPaper>
  );
}

function CreateRevision({ guideId }) {
  const revisionId = uuid();
  const [startRevision] = useMutation(START_REVISION, { variables: { input: { id: guideId, revisionId } } });
  return (
    <Button onClick={() => startRevision()} variant="outlined" style={{ marginTop: 10, marginBottom: 10 }}>
      Ny revisjon
    </Button>
  );
}

type Revision = NonNullable<Guide['ongoingRevision']>;
function Details({ revision, isNew }: { revision: Revision; isNew: boolean }) {
  if (revision.canceledAt || revision.completedAt) return null;

  return (
    <StyledList>
      <ListItem className={classes.detailItem}>
        <ListLabel>Type:</ListLabel>
        <ListItemText primary={revisionTypeToDisplayText(revision.type)} />
      </ListItem>
      <ListItem className={classes.detailItem}>
        <ListLabel>Planlagt ferdig:</ListLabel>
        <ListItemText primary={formatDateOnly(revision.scheduledEndDate)} />
      </ListItem>
      <ListItem className={classes.detailItem}>
        <ListLabel>Fase:</ListLabel>
        <ListItemText primary={GetMilstone(revision.checklist!)} />
      </ListItem>
      <ListItem className={classes.detailItem}>
        <ListItemText
          primary={<StyledLink to={`/revision/${revision.id}`}>{isNew ? 'Gå til sjekkliste for utarbeidelse' : 'Gå til revisjon'}</StyledLink>}
        />
      </ListItem>
    </StyledList>
  );
}

type Checklist = NonNullable<Revision['checklist']>;
function GetMilstone(list: Checklist): string {
  for (let index = 0; index < list.milestones!.length; index++) {
    const milestone = list.milestones![index];
    for (let i = 0; i < milestone!.tasks!.length; i++) {
      const task = milestone!.tasks![i];
      if (task!.completed) continue;

      return `${index + 1} - ${milestone!.name}`;
    }
  }

  return '';
}
