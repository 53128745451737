import { FC, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableCell,
  TableBody,
} from '@mui/material';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../../../LoadingSpinner';
import { GET_GUIDE_AND_LINKS } from '../../guide.graphql';
import Fuse from 'fuse.js';
import { SearchField } from '../../../components/SearchField';
import { GuideStatusDisplay } from '../../GuideTable';
import React from 'react';
import { GetGuidesAndLinksQuery, GuideStatus } from '../../../__generated__/graphql';
import { Unpacked } from '../../../graphQLTypes/types';
import { Modal } from '../../../dialogs/useModal';

export interface GuideLinkDialogResult {
  guideId: string;
  docId: number;
  docName: string;
}

type Guide = GetGuidesAndLinksQuery['guide'];
type GuideLink = Unpacked<GetGuidesAndLinksQuery['guides']>;

export interface GuideLinkDialogProps {
  guideId: string;
  selectedText?: string;
}

export const GuideLinkDialog: FC<Modal<GuideLinkDialogProps, GuideLinkDialogResult>> = (modal) => {
  const guideId = modal.data.guideId;
  const { close } = modal;
  const [filter, setFilter] = useState(modal.data.selectedText ?? '');
  const { data, loading } = useQuery(GET_GUIDE_AND_LINKS, { variables: { id: guideId } });

  const handleOnClose = () => {
    close();
  };

  const guide = data?.guide;
  const guides = data?.guides;

  const filterGuides = (guide: Guide, guides: GuideLink[]): GuideLink[] => {
    if (!guide || !guides) return [];
    let filtered = guides.filter((x) => x && x.status !== GuideStatus.Expired && x.docName);
    if (!filter) return filtered;

    var options = {
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0,
      keys: ['docTitle', 'docName', 'mainCategory.title'],
    };

    var fuse = new Fuse(filtered, options);
    filtered = fuse.search(filter.trim()).map((x) => x.item);
    return filtered;
  };

  const filteredGuides = guides ? filterGuides(guide, guides) : [];

  return (
    <Dialog open={true} onClose={handleOnClose} maxWidth="lg" fullWidth={true}>
      <DialogTitle>Legg til lenke til anvisning</DialogTitle>
      <DialogContent>
        <SearchField filter={setFilter} startSearch={filter} />
        {loading && <LoadingSpinner />}
        {!loading && (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>Nummer</TableCell>
                  <TableCell>Tittel</TableCell>
                  <TableCell>Fagområde</TableCell>
                  <TableCell>Velg</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredGuides.map((guide) => {
                  if (!guide) return null;
                  return (
                    <TableRow key={guide.docName}>
                      <TableCell>
                        <GuideStatusDisplay guide={guide} />
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>{guide.docName}</TableCell>
                      <TableCell>{guide.docTitle}</TableCell>
                      <TableCell>{guide.mainCategory?.title}</TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            const result: GuideLinkDialogResult = {
                              guideId: guide.id,
                              docId: guide.docId,
                              docName: guide.docName!,
                            };
                            close(result);
                          }}>
                          Velg
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose}>Avbryt</Button>
      </DialogActions>
    </Dialog>
  );
};
