import React, { useEffect, useState } from 'react';
import { Avatar, Badge, Menu, MenuItem, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { useConfig } from '../env';
import { useQuery } from '@apollo/client';
import { GET_OMY_OPEN_TASKS } from './home.graphql';
import { useAuth } from 'oidc-react';
import { getFullname, getInitials } from '../../shared/user';

export function UserAvatar() {
  return (
    <MyTasksDecorator>
      <InitialsAvatar />
    </MyTasksDecorator>
  );
}

function InitialsAvatar() {
  const auth = useAuth();
  const profile = auth.userData?.profile;
  const initials = getInitials(profile);

  return <Avatar>{initials}</Avatar>;
}

function MyTasksDecorator({ children }: { children: any }) {
  const config = useConfig();
  const auth = useAuth();
  const fullname = getFullname(auth.userData?.profile);

  const [lastRead, setLastRead] = useState<Date>();

  useEffect(() => {
    const lastRead = localStorage.getItem('lastRead');
    if (lastRead === null) setLastRead(new Date());
    else setLastRead(new Date(lastRead));
  }, []);

  const pollInterval = config ? config.defaultPollInterval : 0;
  const { data } = useQuery(GET_OMY_OPEN_TASKS, { variables: { ownerId: auth.userData?.profile.sub, lastRead }, pollInterval });

  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);

  if (!data) return children;

  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const myTasks = data?.tasks?.length;

  return (
    <>
      <Badge
        badgeContent={myTasks}
        color="error"
        overlap="rectangular"
        aria-controls="avatar_menu"
        aria-haspopup="true"
        onClick={handleMenu}
        sx={{ cursor: 'pointer' }}>
        {children}
      </Badge>
      <Menu
        id="avatar_menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
        open={open}>
        <MenuItem onClick={() => auth.signOutRedirect()}>Logg ut {fullname}</MenuItem>
        <MenuItem onClick={handleClose}>
          <Link to="/task?mine=1" style={{ textDecoration: 'none', color: 'inherit' }}>
            Gå til mine oppgaver ({myTasks})...
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
}
