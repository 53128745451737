import { gql } from "../__generated__";


gql(/* GraphQL */`
  fragment UnitItem on UnitModel {
    id
    name
    isDeleted
  }
`);

gql(/* GraphQL */`
  fragment DepartmentItem on DepartmentModel {
    id
    name
    isDeleted
    units {
      ...UnitItem
    }
  }
`);

export const All_DEPARTMENTS = gql(/* GraphQL */`
  query GetDepartments {
    departments: departments {
      ...DepartmentItem
    }
  }
`);

export const GET_CHANGES = gql(/* GraphQL */`
  query GetDepartmentChanges($id: UUID!, $limit: Int) {
    changeModel: departmentChanges(id: $id, first: $limit) {
      totalCount
      nodes {
        id
        description
        createdAt
        createdBy
      }
    }
  }
`);

export const CREATE = gql(/* GraphQL */`
  mutation DepartmentAdd ($input: DepartmentAddInput!) {
    departmentAdd(input: $input) {
      departmentModel {
        ...DepartmentItem
      }
    }
  }
`);

export const UPDATE_NAME = gql(/* GraphQL */`
  mutation DepartmentUpdateName ($input: DepartmentUpdateNameInput!) {
    departmentUpdateName(input: $input) {
      departmentModel {
        ...DepartmentItem
      }
    }
  }  
`);

export const ADD_UNIT = gql(/* GraphQL */`
  mutation DepartmentAddUnit ($input: DepartmentAddUnitInput!) {
    departmentAddUnit(input: $input) {
      departmentModel {
        ...DepartmentItem
      }
    }
  }  
`);

export const UPDATE_UNIT_NAME = gql(/* GraphQL */`
  mutation DepartmentUpdateUnitName ($input: DepartmentUpdateUnitNameInput!) {
    departmentUpdateUnitName(input: $input) {
      departmentModel {
        ...DepartmentItem
      }
    }
  }  
`);

export const DELETE_UNIT = gql(/* GraphQL */`
  mutation DepartmentDeleteUnit ($input: DepartmentDeleteUnitInput!) {
    departmentDeleteUnit(input: $input) {
      departmentModel {
        ...DepartmentItem
      }
    }
  }
`);

export const DELETE = gql(/* GraphQL */`
  mutation DepartmentRemove ($input: DepartmentRemoveInput!) {
    departmentRemove(input: $input) {
      departmentModel {
        ...DepartmentItem
      }
    }
  }  
`);
