import { GuideStatus } from "../__generated__/graphql";

export const getGuideStatusDescription = (guideStatus: GuideStatus, hasChangesSinceLastPublish: boolean, hasRevision: boolean) => {
  if (hasChangesSinceLastPublish) {
    return 'Upubliserte endringer';
  }
  switch (guideStatus) {
    case GuideStatus.Active:
      return hasRevision ? 'Under revisjon' : 'Publisert';
    case GuideStatus.Planned:
      return 'Ny';
    case GuideStatus.Expired:
      return 'Tilbaketrukket';
    default:
      return undefined;
  }
};
