import React, { FC } from 'react';
import { StyledLink } from '../Components';
import { Typography, TableHead, TableCell, TableBody, Table, TableRow } from '@mui/material';
import { formatDateOnly } from '../Formatters';
import { ColWidths, TableCellHead, TableCellEllipsis } from '../components/TableComponents';
import { GET_COMPLETED_REVISIONS } from './dashboard.graphql';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { GetCompletedRevisionsQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';

const tableStyle = {
  position: 'relative' as 'relative',
};

const stickyHeader = {
  position: 'sticky' as 'sticky',
  top: 0,
  background: 'white',
};

type Revision = Unpacked<GetCompletedRevisionsQuery['revisions']>;
export function LastRevisedGuides() {
  const { data, loading } = useQuery(GET_COMPLETED_REVISIONS);

  const Guide: FC<{ revision: Revision }> = ({ revision }) => {
    if (!revision?.guide) return null;
    return (
      <TableRow key={revision.id}>
        <TableCellEllipsis>
          <StyledLink title={`${revision.guide.docName} - ${revision.guide.docTitle}`} to={'/guide/' + revision.guide.id}>
            {revision.guide.docName}
          </StyledLink>
        </TableCellEllipsis>
        <TableCellEllipsis>{revision.guide.docTitle}</TableCellEllipsis>
        <TableCell>{formatDateOnly(revision.completedAt)}</TableCell>
      </TableRow>
    );
  };

  return (
    <StyledPaper>
      <Typography style={{ fontSize: 18 }}>5 sist reviderte anvisninger</Typography>
      {data?.revisions && (
        <Table size="small" style={tableStyle}>
          <ColWidths widths={[100, null, 120, 100, 100]}></ColWidths>

          <TableHead>
            <TableRow>
              <TableCellHead style={stickyHeader}>Nummer</TableCellHead>
              <TableCellHead style={stickyHeader}>Tittel</TableCellHead>
              <TableCellHead style={stickyHeader}>Revidert</TableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.revisions.slice(0, 5).map((revision) => (
              <Guide key={revision?.id} revision={revision} />
            ))}
          </TableBody>
        </Table>
      )}
      {loading && <LoadingSpinner />}
    </StyledPaper>
  );
}
