import React, { FC } from 'react';
import { NodeViewWrapper, NodeViewProps } from '@tiptap/react';
import styled from '@emotion/styled';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { GuideLinkExtensionRenderOptions } from '../../../../../../shared/guide-cms/extensions/GuideLink';

const StyledGuideLink = styled.span`
  background-color: #d0ffaf;
  padding: 0px 1px;
`;

const renderText = (node: ProseMirrorNode) => {
  return node.attrs.docName;
};

export const GuideLinkView: FC<NodeViewProps> = (props) => {
  return (
    <NodeViewWrapper className="react-component" as={'span'}>
      <StyledGuideLink title="Lenke til anvisning">{props.node.attrs.docName}</StyledGuideLink>
    </NodeViewWrapper>
  );
};

export const getGuideLinkExtensionRenderOptions = (): GuideLinkExtensionRenderOptions => {
  return {
    viewComponent: GuideLinkView,
    renderText: (node) => renderText(node),
  };
};
