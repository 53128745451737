import { Typography, List, ListItem } from '@mui/material';
import { ListItemIcon } from '@mui/material';
import { Book as BookIcon } from '@mui/icons-material';
import { StyledLink } from '../Components';
import { Guide } from '../guides/Details';
import React from 'react';
import { StyledPaper } from '../theme';

export function BookListForGuide({ guide }: { guide: Guide }) {
  const hasBooks = guide.books && guide.books.length > 0;
  return (
    <StyledPaper>
      <Typography variant="h5" component="h2">
        Bøker
      </Typography>
      <List dense={true}>
        {!hasBooks && (
          <Typography variant="body2" color="textSecondary">
            Ingen bøker funnet
          </Typography>
        )}
        {hasBooks &&
          guide.books!.map((book) => (
            <StyledLink key={book} to={`/book/${book}`}>
              <ListItem dense>
                <ListItemIcon>
                  <BookIcon />
                </ListItemIcon>
                {book}
              </ListItem>
            </StyledLink>
          ))}
      </List>
    </StyledPaper>
  );
}
