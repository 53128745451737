import React, { FC } from 'react';
import { useGuideCmsContext } from '../GuideCmsContext';
import { useFigure } from './figureHelper';
import { Tooltip } from '@mui/material';
import { getLastChangedText, getStatusDetails, getStepDetailsText, steps } from './status';
import { FigureStatus } from '../../../__generated__/graphql';

export const GuideFigureStatusTooltip: FC<{
  figureId: string;
  status: FigureStatus;
  showTooltip?: boolean;
  children: React.ReactElement<any, any>;
}> = ({ figureId, showTooltip = true, status, children }) => {
  const { guideId } = useGuideCmsContext();
  const { figure } = useFigure(guideId, figureId);
  if (!showTooltip) {
    return children;
  }
  if (!figure) {
    return children;
  }
  const statusDetails = getStatusDetails(figure, status);
  const step = steps.find((s) => s.status === status);
  if (!statusDetails || !step) {
    return children;
  }

  return (
    <Tooltip
      title={
        <div>
          {getStepDetailsText(step)}
          <br />
          {getLastChangedText(statusDetails)}
          {statusDetails.comment && (
            <>
              <br />
              Kommentar: {statusDetails.comment}
            </>
          )}
        </div>
      }>
      {children}
    </Tooltip>
  );
};
