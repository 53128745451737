import React, { FC } from 'react';
import { Editor } from '@tiptap/react';
import { Extension as ExtensionIcon } from '@mui/icons-material';
import { MenuButton, useRichTextEditorContext } from 'mui-tiptap';
import { useModal } from '../../../../../dialogs/useModal';
import { AppsDialog, AppsDialogProps, AppsDialogResult } from '../../../dialogs/AppsDialog';

export const AddAppToolbarButton: FC = () => {
  const modal = useModal<AppsDialogProps, AppsDialogResult>({ data: {} });
  const editor = useRichTextEditorContext();
  const handleOnClick = async () => {
    const payload = await modal.open();
    if (payload) {
      addApp(editor, payload);
    }
  };
  if (!editor) return null;
  return (
    <>
      {modal.isOpen && <AppsDialog {...modal} />}
      <MenuButton
        disabled={!editor?.isEditable || !editor.can().chain().focus().insertApp('', '').run()}
        IconComponent={ExtensionIcon}
        tooltipLabel="Applikasjoner"
        onClick={handleOnClick}></MenuButton>
    </>
  );
};

const addApp = (editor: Editor | null, payload: AppsDialogResult) => {
  editor?.chain().focus().insertApp(payload.id, payload.name).run();
};
