import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './../store';

export interface GuideFilterState {
  showOnlyMine: boolean;
  tab: number;
}

const initialState: GuideFilterState = {
  showOnlyMine: false,
  tab: 0,
};

export const guideFilterSlice = createSlice({
  name: 'guideFilter',
  initialState,
  reducers: {
    showOnlyMine: (state, action: PayloadAction<boolean>) => {
      state.showOnlyMine = action.payload;
    },
    showTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
  },
});

export const { showOnlyMine, showTab } = guideFilterSlice.actions;

export const selectGuideFilter = (state: RootState) => state.guideFilter;

export default guideFilterSlice.reducer;
