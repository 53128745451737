import { Alert, Button, Grid2 as Grid, Skeleton, Stack, TextField } from '@mui/material';
import React, { ChangeEvent, FC, useState } from 'react';
import { AnalysisProps } from '../../types';
import { FindTargetAudienceResponse, useAi } from '../../useAi';
import { AutoFixHigh } from '@mui/icons-material';

export const TargetAudience: FC<AnalysisProps> = ({ initialText, isOpen }) => {
  const api = useAi();
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState(initialText);
  const [response, setResponse] = useState<FindTargetAudienceResponse>();
  const [isError, setIsError] = useState(false);

  const handleOnTextChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleOnFindTargetAudienceClick = async () => {
    setResponse(undefined);
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await api.findTargetAudience({ text });
      if (response.isError) {
        setIsError(true);
        return;
      }
      setResponse(response);
    } catch (error) {
      console.error('Failed to find target audience', error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };
  if (!isOpen) return null;
  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <TextField fullWidth rows={15} multiline value={text} onChange={handleOnTextChange}></TextField>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Button
          disabled={isLoading}
          onClick={handleOnFindTargetAudienceClick}
          size="large"
          variant="contained"
          color="primary"
          startIcon={<AutoFixHigh />}>
          Finn målgrupper
        </Button>
      </Grid>
      <Grid size={{ xs: 12, lg: 8 }}>
        {!isLoading && response && <RenderTargetAudience response={response} />}
        {isLoading && <LoadingAnimation />}
        {isError && <Alert severity="error">Klarte ikke å finner målgrupper til den valgte teksten. Vennligst prøv igjen.</Alert>}
      </Grid>
    </Grid>
  );
};

const RenderTargetAudience = ({ response }: { response: FindTargetAudienceResponse }) => {
  return (
    <Stack>
      {!response.summary && response.targetAudiences.length === 0 && <Alert severity="info">Fant ingen målgrupper for teksten.</Alert>}
      {response.summary && <Alert severity="info">{response.summary}</Alert>}
      {response.targetAudiences.length > 0 && (
        <ul>
          {response.targetAudiences.map((ta, i) => (
            <li key={`target_audience_${i}`}>
              {ta.score} - {ta.name}
            </li>
          ))}
        </ul>
      )}
    </Stack>
  );
};

const LoadingAnimation = () => {
  return (
    <Stack>
      <Skeleton animation="wave" width="95%" />
      <Skeleton animation="wave" width="100%" />
      <Skeleton animation="wave" width="97%" />
      <Skeleton animation="wave" width="94%" />
      <Skeleton animation="wave" width="98%" />
      <Skeleton animation="wave" width="95%" />
      <Skeleton animation="wave" width="94%" />
      <Skeleton animation="wave" width="98%" />
      <Skeleton animation="wave" width="95%" />
    </Stack>
  );
};
