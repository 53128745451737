import styled from '@emotion/styled';
import React, { FC } from 'react';
import { ExpandMore, Functions } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import katex from 'katex';

export const LatexHelp: FC = () => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />} id="panel-header" aria-controls="panel-content">
        <span
          dangerouslySetInnerHTML={{
            __html: katex.renderToString('\\LaTeX'),
          }}></span>
        -formler
      </AccordionSummary>
      <AccordionDetails>
        <p style={{ marginTop: 0 }}>
          Formler kan skrives på{' '}
          <a href="https://en.wikipedia.org/wiki/LaTeX" target="_blank">
            LaTeX-format
          </a>
          .
        </p>
        <p>
          For å skrive formler i LaTeX-format kan man trykke på <Functions style={{ verticalAlign: 'text-bottom' }} />
          -ikonet i verktøylinjen og skrive formelen mellom $-tegnene.
        </p>
        <p>
          For hjelp til å skrive mer avanserte formler, gå til denne{' '}
          <a href="https://latexeditor.lagrida.com/" target="_blank">
            online LaTeX editoren
          </a>
          .
        </p>
        <SectionHeader>Enkel formel</SectionHeader>
        <StyledFormula marginRight>$E=mc^2$</StyledFormula>
        <BlockKatex dangerouslySetInnerHTML={{ __html: katex.renderToString('E=mc^2') }}></BlockKatex>
        <SectionHeader>Display style</SectionHeader>
        Når en formel blir høy, vil LaTeX ofte vise veldig små fonter. Dette kan man unngå ved å legge inn{' '}
        <StyledFormula>\displaystyle</StyledFormula> i formelen.
        <ExampleHeader>Eksempel 1 uten display style</ExampleHeader>
        <StyledFormula marginRight>{`$\\frac{x^2}{y}$`}</StyledFormula>
        <BlockKatex dangerouslySetInnerHTML={{ __html: katex.renderToString('\\frac{x^2}{y}') }}></BlockKatex>
        <ExampleHeader>Eksempel 2 med display style</ExampleHeader>
        <StyledFormula marginRight>{`$\\displaystyle \\frac{x^2}{y}$`}</StyledFormula>
        <BlockKatex dangerouslySetInnerHTML={{ __html: katex.renderToString('\\displaystyle \\frac{x^2}{y}') }}></BlockKatex>
        <ExampleHeader>Eksempel 3 uten display style</ExampleHeader>
        <StyledFormula marginRight>{`$\\frac{\\sum_{i=1}^{n} p_i^2}{n \\cdot p_0^2}$`}</StyledFormula>
        <BlockKatex
          dangerouslySetInnerHTML={{
            __html: katex.renderToString('\\frac{\\sum_{i=1}^{n} p_i^2}{n \\cdot p_0^2}'),
          }}></BlockKatex>
        <ExampleHeader>Eksempel 4 med display style</ExampleHeader>
        <StyledFormula>{`$\\frac{\\displaystyle \\sum_{i=1}^{n} p_i^2}{\\displaystyle n \\cdot p_0^2}$`}</StyledFormula>
        <br />
        <BlockKatex
          dangerouslySetInnerHTML={{
            __html: katex.renderToString('\\frac{\\displaystyle \\sum_{i=1}^{n} p_i^2}{\\displaystyle n \\cdot p_0^2}'),
          }}></BlockKatex>
        <SectionHeader>Enheter</SectionHeader>
        For å skrive enheter, f.eks. (m/s) etter en formel, kan man bruke <StyledFormula>\text</StyledFormula> for å skrive tekst og{' '}
        <StyledFormula>\quad</StyledFormula> eller <StyledFormula>\qquad</StyledFormula> for å lage et mellomrom.
        <ExampleHeader>Eksempel 1</ExampleHeader>
        <StyledFormula>{`$C_L = \\displaystyle \\sqrt{\\frac{E}{\\rho}} \\qquad \\text{(m/s)}$`}</StyledFormula>
        <BlockKatex
          dangerouslySetInnerHTML={{
            __html: katex.renderToString('C_L = \\displaystyle \\sqrt{\\frac{E}{\\rho}} \\qquad \\text{(m/s)}'),
          }}></BlockKatex>
        <ExampleHeader>Eksempel 2</ExampleHeader>
        <StyledFormula>{`$C_L = \\displaystyle \\sqrt{\\frac{E}{\\rho}} \\quad \\text{(m/s)}$`}</StyledFormula>
        <BlockKatex
          dangerouslySetInnerHTML={{
            __html: katex.renderToString('C_L = \\displaystyle \\sqrt{\\frac{E}{\\rho}} \\quad \\text{(m/s)}'),
          }}></BlockKatex>
      </AccordionDetails>
    </Accordion>
  );
};

const BlockKatex = styled.div`
  margin-top: 10px;
`;

const SectionHeader: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Typography variant="h6" component="h3" marginTop={2}>
      {children}
    </Typography>
  );
};

const ExampleHeader = styled.div`
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 6px;
`;

const StyledFormula = styled.span<{ marginRight?: boolean }>`
  background: #e6edf0;
  color: #000;
  font-family: monospace;
  font-size: 0.8rem;
  padding: 0.2rem;
  ${({ marginRight }) => marginRight && 'margin-right: 10px;'}
`;
