import { gql } from "../__generated__";

gql(/* GraphQL */ `
  fragment StandardItem on StandardModel {
    id
    externalId
    name
    title
    version
    language
    link
    isValid
    parent
    child
  }
`);

export const ALL_STANDARDS = gql(/* GraphQL */ `
  query GetAllStandards{
    standards {
      ...StandardItem
      guideRevisions
    }
  }
`);

export const GET_STANDARD = gql(/* GraphQL */ `
  query GetStandard($id: UUID!) {
    standard(id: $id) {
      ...StandardItem
      guides {
        id
        docId
        docName
        docTitle
        owner {
          name
        }
      }
    }
  }
`);

export const ADD = gql(/* GraphQL */ `
  mutation StandardAdd($input: StandardAddInput!) {
    standardAdd(input: $input) {
      standardModel {
        ...StandardItem
      }
      errors {
        code: __typename
        ... on StandardAlreadyExistsError {
          message
        }
      }
    }
  }
`);

export const GUIDE = gql(/* GraphQL */ `
  query GetStandardsToGuide($id: UUID!) {
    guide(id: $id) {
      id
      docTitle
      standards {
        id
        name
        title
      }
    }
    standards {
      ...StandardItem
    }
  }
`);

export const GUIDE_ADD_STANDARD = gql(/* GraphQL */ `
  mutation GuideAddStandard ($input: GuideAddStandardInput!) {
    guideAddStandard(input: $input) {
      guideModel {
        id
        standards {
          id
          name
          title
        }
      }
    }
  }
`);

export const GUIDE_REMOVE_STANDARD = gql(/* GraphQL */ `
  mutation GuideRemoveStandard ($input: GuideRemoveStandardInput!) {
    guideRemoveStandard(input: $input) {
      guideModel {
        id
        standards {
          id
          name
          title
        }
      }
    }
  }
`);

export const FETCH_STANDARD = gql(/* GraphQL */ `
  query GetStandardFromAuthority ($id: String!) {
    standard: standardFromAuthority(id: $id) {
      id
      externalId
      name
      title
      version
      language
      link
      isValid
      parent
      child
    }
  }
`);

export const DELETE = gql(/* GraphQL */ `
  mutation StandardDelete($input: StandardDeleteInput!) {
    standardDelete(input: $input) {
      standardModel {
        id
      }
    }
  }
`);

export const GET_TASKS_FOR_STANDARD = gql(/* GraphQL */ `
  query GetTasksForStandard($id: UUID!) {
    tasks(where: { standardId: { eq: $id } }) {
      id
      title
      text
      sourceDescription
      createdByName
      createdAt
    }
  }
`);