import { RevisionRoles, RevisionType } from '../__generated__/graphql';


export const revisionRoleToDisplayText = (role: RevisionRoles) => {
  switch (role) {
    case RevisionRoles.ProjectLead:
      return 'Prosjektleder';
    case RevisionRoles.InteralQualityAssurer:
      return 'IKS';
    case RevisionRoles.PublishingEditor:
      return 'RED';
    case RevisionRoles.Dac:
      return 'DAK';
    case RevisionRoles.PeerReviewer:
      return 'Sidemann';
    default:
      throw Error(`Unknown role: '${role}'`);
  }
};

export const revisionTypeToDisplayText = (type: RevisionType | null | undefined) => {
  if (!type) return null;
  switch (type) {
    case RevisionType.Correction:
      return 'Feilretting (utgår)';
    case RevisionType.Revision:
      return 'Fullstendig revisjon';
    case RevisionType.Simple:
      return 'Begrenset revisjon';
    case RevisionType.Withdrawal:
      return 'Tilbaketrekke';
    default:
      throw Error(`Unknown type: '${type}'`);
  }
};

export const getRevisionStatus = ({ completedAt, canceledAt }: { completedAt?: string | null | undefined, canceledAt?: string | null | undefined }) => {
  if (completedAt) {
    return 'Ferdig';
  }

  if (canceledAt) {
    return 'Avbrutt';
  }

  return 'Opprettet';
};
