import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './../store';
import { FilterProps } from './BacklogSearch';

const initialState: FilterProps = {
  searchTerm: '',
  showOngoingRevisions: true,
  showNewGuides: true,
  showPublishedGuides: true,
  showBvn: true,
  showBfs: true,
  categories: [],
};

export const backlogFilterSlice = createSlice({
  name: 'bscklogFilter',
  initialState,
  reducers: {
    setSearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setShowOngoingRevisions: (state, action: PayloadAction<boolean>) => {
      state.showOngoingRevisions = action.payload;
    },
    setShowNewGuides: (state, action: PayloadAction<boolean>) => {
      state.showNewGuides = action.payload;
    },
    setShowPublishedGuides: (state, action: PayloadAction<boolean>) => {
      state.showPublishedGuides = action.payload;
    },
    setShowBvn: (state, action: PayloadAction<boolean>) => {
      state.showBvn = action.payload;
    },
    setShowBfs: (state, action: PayloadAction<boolean>) => {
      state.showBfs = action.payload;
    },
    setCategories: (state, action: PayloadAction<string[]>) => {
      state.categories = action.payload;
    },
    clearFilter: (state) => {
      state = initialState;
    },
  },
});

export const {
  setSearchTerm,
  setCategories,
  setShowBfs,
  setShowBvn,
  setShowNewGuides,
  setShowOngoingRevisions,
  setShowPublishedGuides,
  clearFilter,
} = backlogFilterSlice.actions;

export const selectBacklogFilter = (state: RootState) => state.backlogFilter;

export default backlogFilterSlice.reducer;
