import React from 'react';
import IconButton from '@mui/material/IconButton';
import CompleteTaskIcon from '@mui/icons-material/AssignmentLate';
import IgnoreIcon from '@mui/icons-material/NotInterested';
import { DeleteForever, Edit } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { DELETE, COMPLETE } from './task.graphql';
import { GuideRevisionNoticeTypes } from '../__generated__/graphql';
import { EditTaskDialog, EditTaskDialogProps } from './EditTaskDialog';
import { useModal } from '../dialogs/useModal';
import { CompleteTaskDialog, CompleteTaskDialogProps } from './CompleteTaskDialog';

export type Task = {
  id: string;
  title?: string | null | undefined;
  text?: string | null | undefined;
  createdAt: string;
  createdByName?: string;
  type: GuideRevisionNoticeTypes;
};

export function TaskActions({ task, guideId, canDelete, onDelete }: { task: Task; guideId: string; canDelete?: boolean; onDelete?: () => void }) {
  const editModal = useModal<EditTaskDialogProps, unknown>({ data: { id: task.id, title: task.title, text: task.text } });
  const completeModal = useModal<CompleteTaskDialogProps, unknown>({ data: { task, guideId } });

  const openClearDialog = () => completeModal.open({ task, guideId });
  const openEditDialog = () => editModal.open({ id: task.id, title: task.title, text: task.text });

  const [deleteTask, { loading: deleting }] = useMutation(DELETE, { variables: { input: { id: task.id } } });
  const [ignoreTask, { loading: ignoring }] = useMutation(COMPLETE, { variables: { input: { id: task.id, guideId, comment: 'Ikke relevant' } } });

  const doDelete = async () => {
    await deleteTask();
    if (onDelete) onDelete();
  };

  return (
    <div>
      <IconButton onClick={openClearDialog} title="Lukk oppgave" size="large">
        <CompleteTaskIcon color="primary" />
      </IconButton>
      <IconButton onClick={() => ignoreTask()} title="Merk som Ikke relevant" disabled={ignoring} size="large">
        <IgnoreIcon color="secondary" />
      </IconButton>
      {task.type === GuideRevisionNoticeTypes.Guide && (
        <IconButton onClick={openEditDialog} title="Rediger" size="large">
          <Edit />
        </IconButton>
      )}
      {task.type === GuideRevisionNoticeTypes.Guide && canDelete && (
        <IconButton title="Slett oppgave (feilregistering)" onClick={doDelete} disabled={deleting} size="large">
          <DeleteForever />
        </IconButton>
      )}
      {editModal.isOpen && <EditTaskDialog modal={editModal} />}
      {completeModal.isOpen && <CompleteTaskDialog modal={completeModal} />}
    </div>
  );
}
