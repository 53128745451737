import React, { FC } from 'react';
import { AddComment, ModeComment } from '@mui/icons-material';
import styled from '@emotion/styled';
import { NodeViewProps } from '@tiptap/react';
import { useNodeComments } from '../../sidebar/comments/useNodeComments';
import { useCommentsContext } from '../../sidebar/comments/CommentsContext';
import { Badge, IconButton } from '@mui/material';
import { getFullDescription, useNumberedNode } from '../numbering';

const StyledBlockNode = styled.div`
  position: relative;

  .block-node-comments:not(.has-comments):not(.is-open) {
    visibility: hidden;
  }
  &:hover {
    .block-node-comments:not(.has-comments) {
      visibility: visible;
    }
  }
`;

const StyledBlockActions = styled.div`
  position: absolute;
  top: 0;
  left: -44px;
`;

export const BlockNode: FC<{ props: NodeViewProps; children: React.ReactNode }> = ({ props, children }) => {
  const guideNodeId: string = props.node.attrs.id ?? '';
  const { isCommentsEnabled } = useCommentsContext();

  return (
    <StyledBlockNode>
      <StyledBlockActions className="block-node-actions">
        {isCommentsEnabled && guideNodeId && <CommentsButton guideNodeId={guideNodeId} />}
      </StyledBlockActions>

      <div className="block-node-content">{children}</div>
    </StyledBlockNode>
  );
};

const CommentsButton: FC<{ guideNodeId: string }> = ({ guideNodeId }) => {
  const comments = useNodeComments(guideNodeId);
  const context = useCommentsContext();
  const openComments = () => {
    if (context.guideNodeId === guideNodeId) {
      return;
    }
    // const description = numberedNode ? getFullDescription(numberedNode) : '';
    context.openComments(guideNodeId);
  };
  return (
    <IconButton
      size="small"
      className={`block-node-comments ${comments.length > 0 ? `has-comments` : ''} ${context.guideNodeId === guideNodeId ? `is-open` : ''}`}
      onClick={openComments}
      title={comments.length === 0 ? 'Legg til kommentar' : 'Vis kommentarer'}
      color={context.guideNodeId === guideNodeId ? 'info' : 'default'}>
      <Badge badgeContent={comments.length} color="info">
        {comments.length === 0 ? <AddComment /> : <ModeComment />}
      </Badge>
    </IconButton>
  );
};
