import React from 'react';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { Config } from './env';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { onError } from '@apollo/client/link/error';
import LoadingSpinner from './LoadingSpinner';
import { Typography } from '@mui/material';
import { AuthProviderProps } from 'oidc-react';

export function CustomApolloProvider({ config, oidcConfig, children }: { config: Config; oidcConfig: AuthProviderProps; children: React.ReactNode }) {
  const getToken = () => {
    const item = sessionStorage.getItem(`oidc.user:${oidcConfig.authority}:${oidcConfig.clientId}`);
    if (item === null) return null;

    return JSON.parse(item).access_token;
  };

  const uploadLink = createUploadLink({ uri: `${config.apiUrl}/GraphQL` });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `${config.apiUrl}/subscription`.replace('https', 'wss').replace('http', 'ws'),
      // connectionParams: () => ({
      //   Authorization: `Bearer ${getToken()}`,
      // }),
    }),
  );

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    uploadLink,
  );

  const authMiddleware = new ApolloLink((operation, forward) => {
    var token = getToken();
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
        'GraphQL-Preflight': 1,
      },
    }));

    return forward(operation);
  });

  const errorLink = onError((error: any) => {
    let { networkError } = error;

    if (networkError && networkError.statusCode === 401) {
      //auth.signIn();
    }
  });

  const client = new ApolloClient({
    connectToDevTools: config.env === 'Development',
    link: ApolloLink.from([errorLink, authMiddleware, splitLink]),
    cache: new InMemoryCache({
      typePolicies: {
        GuideModel: {
          fields: {
            figures: {
              merge(existing = [], incoming: any[]) {
                return incoming;
              },
            },
          },
        },
      },
    }),
  });

  if (!getToken())
    return (
      <>
        <LoadingSpinner />
        <Typography align="center">Logger inn</Typography>
      </>
    );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
