import { Alert, Button, Grid2 as Grid, Skeleton, Stack, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, FC, useState } from 'react';
import { AnalysisProps } from '../../types';
import { FindAdvicesResponse, FindContradictionAdvicesResponse, useAi } from '../../useAi';
import { AutoFixHigh } from '@mui/icons-material';

export const Advices: FC<AnalysisProps> = ({ initialText, isOpen }) => {
  const api = useAi();
  const [isLoadingAdvices, setIsLoadingAdvices] = useState(false);
  const [isLoadingContradictingAdvices, setIsLoadingContradictingAdvices] = useState(false);
  const [text, setText] = useState(initialText);
  const [advicesResponse, setAdvicesResponse] = useState<FindAdvicesResponse>();
  const [contradictingAdvicesResponse, setContradictingAdvicesResponse] = useState<FindContradictionAdvicesResponse>();
  const [errorLoadingAdvices, setErrorLoadingAdvices] = useState(false);
  const [errorLoadingContradictingAdvices, setErrorLoadingContradictingAdvices] = useState(false);

  const handleOnTextChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleOnFindAdvicesClick = async () => {
    findAdvices();
    findContradictions();
  };

  const findAdvices = async () => {
    setAdvicesResponse(undefined);
    setIsLoadingAdvices(true);
    setErrorLoadingAdvices(false);
    try {
      const response = await api.findAdvices({ text });
      if (response.isError) {
        setErrorLoadingAdvices(true);
        return;
      }
      setAdvicesResponse(response);
    } catch (error) {
      console.error('Failed to load advices', error);
      setErrorLoadingAdvices(true);
    } finally {
      setIsLoadingAdvices(false);
    }
  };

  const findContradictions = async () => {
    setContradictingAdvicesResponse(undefined);
    setIsLoadingContradictingAdvices(true);
    setErrorLoadingContradictingAdvices(false);
    try {
      const response = await api.findContradictingAdvices({ text });
      if (response.isError) {
        setErrorLoadingContradictingAdvices(true);
        return;
      }
      setContradictingAdvicesResponse(response);
    } catch (error) {
      console.error('Failed to load contradicting advices', error);
      setErrorLoadingContradictingAdvices(true);
    } finally {
      setIsLoadingContradictingAdvices(false);
    }
  };
  if (!isOpen) return null;
  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <TextField fullWidth rows={15} multiline value={text} onChange={handleOnTextChange}></TextField>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Stack direction="row" spacing={2}>
          <Button
            disabled={isLoadingAdvices || isLoadingContradictingAdvices}
            onClick={handleOnFindAdvicesClick}
            size="large"
            variant="contained"
            color="primary"
            startIcon={<AutoFixHigh />}>
            Finn anbefalinger
          </Button>
        </Stack>
      </Grid>
      <Grid size={{ xs: 12, lg: 6 }}>
        {!isLoadingAdvices && (
          <div>
            {advicesResponse && (
              <Typography variant="h6" component="h3">
                Anbefalinger
              </Typography>
            )}

            {advicesResponse && advicesResponse.advices.length === 0 && <Alert severity="info">Fant ingen anbefalinger i teksten.</Alert>}
            {errorLoadingAdvices && <Alert severity="error">Klarte ikke å lage en oversikt over anbefalinger. Vennligst prøv igjen.</Alert>}
            {advicesResponse && advicesResponse.advices.length > 0 && (
              <ul>
                {advicesResponse.advices.map((a, i) => (
                  <li key={`advice_${i}`}>{a}</li>
                ))}
              </ul>
            )}
          </div>
        )}
        {isLoadingAdvices && <LoadingAnimation />}
      </Grid>
      <Grid size={{ xs: 12, lg: 6 }}>
        {!isLoadingContradictingAdvices && (
          <div>
            {contradictingAdvicesResponse && (
              <Typography variant="h6" component="h3">
                Motstridende anbefalinger
              </Typography>
            )}
            {contradictingAdvicesResponse && contradictingAdvicesResponse.contradictions.length === 0 && (
              <Alert severity="info">Fant ingen motstridende anbefalinger i teksten.</Alert>
            )}
            {errorLoadingContradictingAdvices && (
              <Alert severity="error">Klarte ikke å lage en oversikt over motstridende anbefalinger. Vennligst prøv igjen.</Alert>
            )}
            {contradictingAdvicesResponse && contradictingAdvicesResponse.contradictions.length > 0 && (
              <ul>
                {contradictingAdvicesResponse.contradictions.map((a, i) => (
                  <li key={`contradicting_advice_${i}`}>{a}</li>
                ))}
              </ul>
            )}
          </div>
        )}
        {isLoadingContradictingAdvices && <LoadingAnimation />}
      </Grid>
    </Grid>
  );
};

const LoadingAnimation = () => {
  return (
    <Stack>
      <Skeleton animation="wave" width="95%" />
      <Skeleton animation="wave" width="100%" />
      <Skeleton animation="wave" width="97%" />
      <Skeleton animation="wave" width="94%" />
      <Skeleton animation="wave" width="98%" />
      <Skeleton animation="wave" width="95%" />
      <Skeleton animation="wave" width="94%" />
      <Skeleton animation="wave" width="98%" />
      <Skeleton animation="wave" width="95%" />
    </Stack>
  );
};
