import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { StyledLink, LimitedTypography, PreWrap } from '../Components';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Switch,
  FormControlLabel,
  Typography,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
} from '@mui/material';
import { formatDate } from '../Formatters';
import { Tabs, Tab, TableRow, TableBody, TableHead, TableCell, Table, List, ListSubheader, Divider, Grid2 as Grid } from '@mui/material';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { NoticeActions } from './NoticeActions';
import { GET_NOTICES } from './notices.graphql';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { Notice as NoticeIcon } from './NoticeIcon';
import { Notice } from './types';
import { GuideRevisionNotice } from '../__generated__/graphql';
import { useAuth } from 'oidc-react';
import { StyledPaper } from '../theme';

export function Overview() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [showOnlyMine, setShowOnlyMine] = useState(false);
  const auth = useAuth();
  const userId = auth.userData?.profile.sub;

  const filter = (notices: Notice[]) => notices.filter((x) => !showOnlyMine || x?.guide?.owner?.subjectId === userId);

  return (
    <StyledPaper>
      <Typography variant="h5">Merknader</Typography>
      <FormControlLabel control={<Switch onChange={(e) => setShowOnlyMine(e.target.checked)} checked={showOnlyMine} />} label="Mine" />
      <Tabs value={selectedTab} onChange={(e, v) => setSelectedTab(v)}>
        <Tab label="Åpne" />
        <Tab label="Slettede" />
      </Tabs>
      {selectedTab === 0 && <OpenNoticeList filter={filter} />}
      {selectedTab === 1 && <DeletedNoticeList filter={filter} />}
    </StyledPaper>
  );
}

function OpenNoticeList({ filter }: { filter(notices: Notice[]): Notice[] }) {
  const { data } = useQuery<{ notices: Notice[] }>(GET_NOTICES, { fetchPolicy: 'cache-and-network' });
  if (!data || !data.notices) return <LoadingSpinner />;
  return data && <NoticeList notices={filter(data.notices.filter((x) => x && !x.deletedAt))} />;
}

function DeletedNoticeList({ filter }: { filter(notices: Notice[]): Notice[] }) {
  const { data } = useQuery(GET_NOTICES, { fetchPolicy: 'cache-and-network' });
  if (!data || !data.notices) return <LoadingSpinner />;
  return data && <NoticeList notices={filter(data.notices.filter((x) => x?.deletedAt))} />;
}

function NoticeList({ notices }: { notices: Notice[] }) {
  const { id } = useParams();

  return (
    <div>
      {notices.map((notice) => (
        <NoticeItem key={notice?.id} notice={notice} expanded={notice?.id === id} />
      ))}
    </div>
  );
}

function DeletedItems({ notice }: { notice: Notice }) {
  return (
    <Table padding="none">
      <TableHead>
        <TableRow>
          <TableCell>Anvisning</TableCell>
          <TableCell>Slettet av</TableCell>
          <TableCell>Tidspunkt</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow key={notice?.id}>
          <TableCell>{<GuideLink notice={notice} />}</TableCell>
          <TableCell> {notice?.deletedBy?.name}</TableCell>
          <TableCell> {formatDate(notice?.deletedAt)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

function NoticeItem({ notice, expanded }: { notice: Notice; expanded?: boolean }) {
  const isDeleted = !!notice?.deletedAt;
  const myRef = useRef(null);

  useEffect(() => {
    if (expanded) {
      myRef.current.scrollIntoView();
    }
  }, [expanded]);

  const changer = notice?.deletedBy ? notice.deletedBy.name : notice?.createdBy?.name;
  return (
    <Accordion sx={{ margin: 0, padding: 0 }} key={notice?.id} defaultExpanded={expanded} ref={myRef}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box sx={{ verticalAlign: 'middle', display: 'inline-flex', alignItems: 'start', paddingRight: '0.5em', whiteSpace: 'pre' }}>
            <ListItemIcon>
              <NoticeIcon color />
            </ListItemIcon>
            <span>{notice?.guide?.docName}</span>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <LimitedTypography maxWidth={250}>{notice?.title}</LimitedTypography>
          </Box>
          <Box sx={{ paddingLeft: '0.5em' }}>
            <Typography sx={{ fontSize: (theme) => theme.typography.pxToRem(15), color: (theme) => theme.palette.text.secondary }}>
              <span style={{ whiteSpace: 'nowrap' }}>
                {changer} {formatDate(notice?.createdAt)}
              </span>
            </Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingTop: 0, paddingBottom: 0, background: 'rgba(0,0,0,0.02)' }}>
        <Grid container direction="column">
          <Grid container direction="row" wrap="nowrap">
            <List>
              <ListItem>
                <ListItemText primary={<PreWrap variant="body1">{notice?.notice}</PreWrap>} />
              </ListItem>
            </List>
          </Grid>
          <Divider />
          <Grid>{isDeleted ? <DeletedItems notice={notice} /> : <OpenItems notice={notice} />}</Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

function OpenItems({ notice }: { notice: Notice }) {
  return (
    <List dense={true}>
      <ListSubheader sx={{ backgroundColor: 'inherit' }}>Anvisninger</ListSubheader>
      <ListItem key={`task-${notice?.guide?.id}-${notice?.id}`}>
        <ListItemText
          style={{ paddingRight: 150 }}
          primary={<GuideLink notice={notice} />}
          secondary={`Ansvarlig: ${notice?.guide?.owner?.name ? notice.guide.owner.name : 'Mangler Navn'}`}
        />
        <ListItemSecondaryAction>
          <NoticeActions notice={notice as GuideRevisionNotice} />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
}

function GuideLink({ notice }: { notice: Notice }) {
  return (
    <StyledLink to={`/guide/${notice?.guide?.id}`}>
      {notice?.guide?.docName} {notice?.guide?.docTitle}
    </StyledLink>
  );
}
