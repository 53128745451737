import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CLASSIFICATIONS } from './classifications.graphql';
import { ClassificationCode, ClassificationItem, GetClassificationsQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';

interface ClassificationDisplayItem {
  id: string;
  publicId: number | null | undefined;
  number: string;
  title: string;
  items: ClassificationDisplayItem[];
}

export interface ClassificationIdentifier {
  classificationId: string;
  classificationItemId: string;
}

export function ClassificationLabel({
  value,
  onChange,
  classificationCode,
}: {
  value?: string;
  onChange?(val: ClassificationIdentifier | undefined): void;
  classificationCode: ClassificationCode;
}) {
  const [current, setCurrent] = useState<string | undefined>(value);

  const { data } = useQuery(GET_CLASSIFICATIONS);

  type Classification = NonNullable<Unpacked<GetClassificationsQuery['classifications']>>;


  const seriesToDisplayItem = (model: Classification): ClassificationDisplayItem[] => {
    return model.children!.map((x) => {
      return {
        id: x!.id,
        publicId: x!.publicId,
        number: `${x!.number}`,
        title: `${x!.number} ${x!.heading}`,
        items: x!.children!.map((h) => headingToDisplayItem(h!, x!.number)),
      };
    });
  };

  const headingsToDisplayItem = (model: Classification): ClassificationDisplayItem[] => {
    return model.children!.flatMap((x) => x!.children!.map((h) => headingToDisplayItem(h!, x!.number)));
  };

  const headingToDisplayItem = (heading: ClassificationItem, parentNumber: number): ClassificationDisplayItem => {
    if (heading.children)
      return {
        id: heading.id,
        publicId: heading.publicId,
        number: `${heading.number}`,
        title: `${heading.number} ${heading.heading}`,
        items: heading.children.map((h) => headingToDisplayItem(h!, heading.number)),
      };

    return {
      id: heading.id,
      publicId: heading.publicId,
      number: `${parentNumber}${heading.number}`,
      title: `${parentNumber}.${heading.number} ${heading.heading}`,
      items: [],
    };
  };

  const getClassificationItems = (val: string, array: ClassificationDisplayItem[]): ClassificationDisplayItem[] => {
    var workingSet: ClassificationDisplayItem[] = [];
    var currentArray = array;
    for (let index = 0; index < val.length; index++) {
      if (!currentArray) return workingSet;

      const element = val.substring(0, index + 1);
      const item = currentArray.find((x) => x.number === element);
      if (item === undefined) return workingSet;

      workingSet.push(item);
      currentArray = item.items;
    }

    return workingSet;
  };

  const classification = data?.classifications && data.classifications.find((x) => x!.code === classificationCode);
  const displayItems =
    classification && (classificationCode === ClassificationCode.Bfs ? headingsToDisplayItem(classification) : seriesToDisplayItem(classification));
  const matchedItems = displayItems && getClassificationItems(value?.replace('.', '') ?? '', displayItems);

  if (onChange) {
    if (!matchedItems || matchedItems.length === 0) {
      if (current) {
        setCurrent(undefined);
        onChange(undefined);
      }
    } else {
      const lastIndex = matchedItems.length - 1;
      const last = matchedItems[lastIndex];
      if (current !== last.id) {
        setCurrent(last.id);
        if (matchedItems.some((x) => x.publicId)) onChange({ classificationId: classification.id, classificationItemId: last.id });
      }
    }
  }

  if (!matchedItems) return null;

  return <label>{matchedItems.map((x) => x.title).join('/')}</label>;
}
