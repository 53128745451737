import { gql } from "../__generated__";

export const GET_PARAGRAPHS = gql(/* GraphQL */`
  query GetParagraphs {
    paragraphs {
      id
      regulation
      number
      title
      guideRevisions
    }
  }
`);

export const GET_PARAGRAPH = gql(/* GraphQL */`
  query GetParagraph ($id: UUID!) {
    paragraph(id: $id) {
      id
      regulation
      number
      title
      link
      guides {
        id
        docId
        docName
        docTitle
        owner {
          name
        }
      }
      tasks {
        id
        title
        text
        createdAt
        createdByName
      }
    }
  }
`);

export const GET_CHANGES = gql(/* GraphQL */`
  query GetParagraphChanges {
    changes: paragraphChanges {
      id
      paragraphId
      regulation
      paragraphNumber
      comment
      date
      handled
      isUsed
      createdAt
    }
  }
`);

// create refreshTek17Changes mutation
export const REFRESH_TEK17 = gql(/* GraphQL */`
  mutation ParagraphRefreshTek17Changes {
    paragraphRefreshTek17Changes {
      __typename
    }
  }
`);