import React, { FC, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GUIDE_CONTENT } from './content.graphql';
import { getTipTapJsonContent } from './tiptap/tiptapHelper';
import { Content, Editor } from '@tiptap/react';
import { GuideContent } from './types';
import { useAuthenticatedApi } from '../../api';

type SidebarType = 'toc' | 'comments' | 'help' | 'references';

export interface GuideCmsContext {
  isLoading: boolean;
  isEditing: boolean;
  guideId: string;
  version?: number;
  schemaVersion: number;
  docId: number;
  docName: string;
  docTitle: string;
  content: GuideContent;
  editorContent?: Content;
  editor?: Editor;
  isSidebarOpen: boolean;
  toggleSidebar: () => void;
  sidebarType: SidebarType;
  setSidebarType: (sidebarType: SidebarType) => void;
  setEditor: (editor: Editor) => void;
  setIsEditing: (isEditing: boolean) => void;
  refreshContent: () => Promise<void>;
  forceRefreshEditors: () => void;
}

const GuideCmsContextInstance = React.createContext<GuideCmsContext | undefined>(undefined);

export const useGuideCmsContext = (): GuideCmsContext => {
  const context = React.useContext(GuideCmsContextInstance);
  if (!context) {
    throw new Error('useGuideCmsContext must be used within a GuideCmsContextProvider');
  }
  return context;
};

export const GuideCmsContextProvider: FC<{ guideId: string; version?: number; children: React.ReactNode }> = ({ guideId, version, children }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [content, setContent] = useState<GuideContent>();
  const [editorContent, setEditorContent] = useState<Content>();
  const [editor, setEditor] = useState<Editor>();
  const [sidebarType, setSidebarType] = useState<SidebarType>('toc');
  const [schemaVersion, setSchemaVersion] = useState<number>(-1);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [_, setForceRefreshCount] = useState<number>(0);
  const api = useAuthenticatedApi('cms-collaboration');

  useEffect(() => {
    api.get('schema-version').then((response) => {
      setSchemaVersion(response.data);
    });
  }, []);
  useEffect(() => {
    setEditorContent(undefined);
    setContent(undefined);
    guideContentQuery();
  }, [guideId]);

  useEffect(() => {
    if (!version) {
      setEditorContent(undefined);
      setContent(undefined);
      guideContentQuery();
    }
  }, [version]);

  const [guideContentQuery, guideContentResponse] = useLazyQuery(GUIDE_CONTENT, {
    fetchPolicy: 'cache-and-network',
    variables: { id: guideId },
    onCompleted(data) {
      setContent(data?.guide?.content);
      if (!data.guide?.content) return;
      const jsonContent = getTipTapJsonContent(data.guide.content.content ?? '');
      setEditorContent(jsonContent);
    },
  });

  const refreshContent = async () => {
    await guideContentQuery();
  };

  const forceRefreshEditors = () => {
    setForceRefreshCount((prev) => prev + 1);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <GuideCmsContextInstance.Provider
      value={{
        isLoading: guideContentResponse.loading,
        isEditing,
        guideId,
        version,
        schemaVersion,
        docId: guideContentResponse.data?.guide?.docId ?? -1,
        docName: guideContentResponse.data?.guide?.docName ?? '',
        docTitle: guideContentResponse.data?.guide?.docTitle ?? '',
        content,
        editorContent,
        editor,
        sidebarType,
        isSidebarOpen,
        toggleSidebar,
        setSidebarType,
        setEditor,
        refreshContent,
        setIsEditing,
        forceRefreshEditors,
      }}>
      {children}
    </GuideCmsContextInstance.Provider>
  );
};
