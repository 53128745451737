import { gql } from '../__generated__';

export const GET_REVISIONS_WITH_USER = gql(/* GraphQL */ `
  query GetRevisionsWithUser {
    revisions(where: { and: [{ completedAt: { eq: null } }, { canceledAt: { eq: null } }] }) {
      id
      scheduledEndDate
      progress
      currentPhase
      roles {
        role
        user {
          subjectId
          name
        }
      }
      guide {
        id
        docName
        docTitle
        owner {
          subjectId
          name
        }
      }
    }
  }
`);
