import React from 'react';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { ListItemIcon, ListItemText, MenuItem, MenuList, Box, CssBaseline, AppBarProps as MuiAppBarProps } from '@mui/material';
import { UserAvatar } from './home/UserAvatar';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useLocation, Outlet } from 'react-router-dom';
import { RouteDef } from './App';
import { useConfig } from './env';
import { styled, useTheme, Theme, CSSObject, ThemeProvider } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const drawerWidth = 250;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  test?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'test',
})<AppBarProps>(({ theme, open, test }) => ({
  margin: 0,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(test && {
    backgroundColor: '#15B5A2',
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const EnvironmentInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  marginRight: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export function Shell({ routes }: { routes: RouteDef[] }) {
  const theme = useTheme();
  const config = useConfig();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <CssBaseline />
      <AppBar position="fixed" open={open} test={isTestEnvironment(config?.env)}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" sx={{ flex: 1 }} noWrap>
            Kunnskapsforvaltning
          </Typography>
          <EnvironmentInfo>
            <EnvironmentDisplay environment={config?.env} />
            <Typography variant="caption" color="inherit">
              v{config?.version}
            </Typography>
          </EnvironmentInfo>
          <UserAvatar />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ backgroundColor: '#fafafa' }}>
          <div>
            <Link to={'/'}>
              <img src="/longhouse.svg" style={{ width: 60, height: 60 }} alt=""></img>
            </Link>
          </div>
          <IconButton onClick={handleDrawerClose}>{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
        </DrawerHeader>
        {open && <Divider />}
        <MenuList>
          {routes
            .filter((x) => x.title)
            .map((r) => {
              const { pathname: currentPath } = useLocation();
              const { path, title, icon, badge } = r;

              const Icon = icon;
              const Badge = badge;

              return (
                <Link key={path} to={path} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <MenuItem selected={path === '/' ? currentPath === path : currentPath.startsWith(path)}>
                    <ListItemIcon
                      title={open ? title : undefined}
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}>
                      {badge && icon ? (
                        <Badge>
                          <Icon />
                        </Badge>
                      ) : (
                        <Icon />
                      )}
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: open ? 1 : 0 }}>{title}</ListItemText>
                  </MenuItem>
                </Link>
              );
            })}
        </MenuList>
      </Drawer>
      <Box component="main" sx={{ marginLeft: open ? '250px' : '65px', paddingLeft: 2, paddingTop: 1, paddingBottom: 2 }}>
        <DrawerHeader />
        <ThemeProvider theme={theme}>
          <Outlet />
        </ThemeProvider>
      </Box>
    </Box>
  );
}

function EnvironmentDisplay({ environment }) {
  const EnvTypograpy = ({ children }) => (
    <Typography variant="body1" color="inherit" sx={{ marginRight: (theme) => theme.spacing(1) }}>
      {children}
    </Typography>
  );

  if (environment === 'Dev') return <EnvTypograpy>DEV</EnvTypograpy>;
  if (environment === 'test') return <EnvTypograpy>TEST</EnvTypograpy>;
  if (environment === 'staging') return <EnvTypograpy>BETA</EnvTypograpy>;

  return null;
}

function isTestEnvironment(env: string | undefined): boolean {
  const testEnvs = ['test', 'staging', 'Dev'];
  return testEnvs.includes(env ?? '');
}
