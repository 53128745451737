import React from 'react';
import { Warning, Schedule, Done, Archive, Loop } from '@mui/icons-material';
import { getGuideStatusDescription } from './GuideModel';
import { GuideStatus } from '../__generated__/graphql';

interface GuideStatusProps {
  status: GuideStatus;
  hasChangesSinceLastPublish: boolean;
  hasRevision: boolean;
}

export function GuideStatusIcon({ status, hasChangesSinceLastPublish, hasRevision }: GuideStatusProps) {
  const title = getGuideStatusDescription(status, hasChangesSinceLastPublish, hasRevision);
  if (status === GuideStatus.Planned) {
    return (
      <span title={title}>
        <Schedule sx={{ verticalAlign: 'middle' }} />
      </span>
    );
  }
  if (status === GuideStatus.Expired) {
    return (
      <span title={title}>
        <Archive sx={{ color: 'gray', verticalAlign: 'middle' }} />
      </span>
    );
  }

  if (hasChangesSinceLastPublish) {
    return (
      <span title={title}>
        <Warning sx={{ color: 'orange', verticalAlign: 'middle' }} />
      </span>
    );
  }

  if (hasRevision) {
    return (
      <span title={title}>
        <Loop sx={{ color: 'green', verticalAlign: 'middle' }} />
      </span>
    );
  }

  return (
    <span title={title}>
      <Done sx={{ color: 'green' }} />
    </span>
  );
}
