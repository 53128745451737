import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton, StyledAnchor, StyledLink } from '../Components';
import { Tasks } from './Tasks';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddAlert from '@mui/icons-material/AddAlert';
import { List, ListItem, ListItemText, Grid2 as Grid } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { DELETE, GET_STANDARD } from './standards.graphql';
import LoadingSpinner from '../LoadingSpinner';
import { useParams } from 'react-router-dom';
import { GetStandardQuery } from '../__generated__/graphql';
import { StyledPaper } from '../theme';

export function StandardDetails() {
  const { id } = useParams();

  const { data, loading } = useQuery(GET_STANDARD, { variables: { id: id } });
  if (loading) return <LoadingSpinner />;
  if (!data) return <span>Fant ikke noen standard</span>;

  return <Layout standard={data.standard} />;
}

type Standard = GetStandardQuery['standard'];
function Layout({ standard }: { standard: Standard }) {
  const [addTaskTrigger, raiseAddTaskTrigger] = React.useState(0);

  return (
    <Grid container>
      <Grid size={{ xs: 12 }}>
        <Typography variant="h5">
          <BackButton />
          {standard?.title}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Details standard={standard} addTask={() => raiseAddTaskTrigger(addTaskTrigger + 1)} />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <StyledPaper>
          <Typography variant="h5" component="h3">
            Anvisninger
          </Typography>
          <List>
            {standard?.guides?.map((guide) => {
              return (
                <ListItem key={guide?.id}>
                  <ListItemText>
                    <StyledLink to={`/guide/${guide?.id}`}>
                      {guide?.docName} - {guide?.docTitle}
                    </StyledLink>
                  </ListItemText>
                </ListItem>
              );
            })}
          </List>
        </StyledPaper>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Tasks standardId={standard?.id} addTaskTrigger={addTaskTrigger} />
      </Grid>
    </Grid>
  );
}

function Details({ standard, addTask }: { standard: Standard; addTask?: () => void }) {
  const [deleteStandard] = useMutation(DELETE, { variables: { input: { id: standard?.id } } });
  const navigate = useNavigate();

  let removeStandard = async () => {
    await deleteStandard();
    navigate('/standard');
  };

  if (!standard) return null;
  return (
    <StyledPaper>
      <List>
        <ListItem>
          <Typography variant="subtitle1">Gyldig:</Typography>
          <ListItemText primary={standard.isValid ? 'Ja' : 'Nei'} />
        </ListItem>
        <ListItem>
          <Typography variant="subtitle1">Versjon:</Typography>
          <ListItemText primary={standard.version} />
        </ListItem>
        <ListItem>
          <Typography variant="subtitle1">Språk:</Typography>
          <ListItemText primary={standard.language} />
        </ListItem>
        <ListItem>
          <Typography variant="subtitle1">Link:</Typography>
          <ListItemText primary={standard.link} />
        </ListItem>
        {standard.externalId && (
          <ListItem>
            <Typography variant="subtitle1">Standard.no:</Typography>
            <ListItemText
              primary={
                <StyledAnchor href={`https://online.standard.no/${standard.externalId}`} target="blank">
                  Gå til standard
                </StyledAnchor>
              }
            />
          </ListItem>
        )}
      </List>
      {standard.guides && standard.guides.length > 0 && (
        <Button onClick={addTask} aria-label="Add task" variant="outlined" style={{ marginRight: 16 }}>
          <AddAlert /> Varsle endring
        </Button>
      )}
      {standard.guides && standard.guides.length === 0 && (
        <Button variant="outlined" color="secondary" onClick={removeStandard} aria-label="Remove standard">
          Fjern standard
        </Button>
      )}
    </StyledPaper>
  );
}
