import React, { useState } from 'react';

import { Checkbox, FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { BackButton } from '../Components';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_WITH_GUIDE, ADD_CATEGORY, REMOVE_CATEGORY } from './categories.graphql';
import { SearchField } from '../components/SearchField';
import LoadingSpinner from '../LoadingSpinner';
import { useParams } from 'react-router-dom';
import Fuse from 'fuse.js';
import { TableCellHead } from '../components/TableComponents';
import { Unpacked } from '../graphQLTypes/types';
import { GetCategoriesAndGuideQuery } from '../__generated__/graphql';

type Guide = GetCategoriesAndGuideQuery['guide'];
type Category = Unpacked<GetCategoriesAndGuideQuery['categories']>;

export function EditGuideCategories() {
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [showOnlySelected, setShowOnlySelected] = useState(false);
  const { data, loading } = useQuery(GET_ALL_WITH_GUIDE, { variables: { id } });

  const filterCategories = (searchTerm: string, categories: Category[]) => {
    const filtered = categories.filter((x) => !showOnlySelected || data?.guide?.categories?.some((c) => c?.id === x?.id));
    if (!searchTerm) return filtered;

    const options = {
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0,
      location: 0,
      distance: 10,
      maxPatternLength: 32,
      minMatchCharLength: 3,
      keys: ['title', 'description'],
    };

    const fuse = new Fuse(filtered, options);
    return fuse.search(searchTerm).map((x) => x.item);
  };

  const filteredCategories = data?.categories && filterCategories(searchTerm, data.categories);

  return (
    <div>
      <div>
        <Typography variant="h5">
          <BackButton />
          Anvisning: {data?.guide && data.guide.docTitle}
        </Typography>
      </div>
      <SearchField filter={setSearchTerm} />
      <FormControlLabel
        control={<Checkbox color="primary" checked={showOnlySelected} onChange={(e) => setShowOnlySelected(e.target.checked)} />}
        label="Vis bare valgte fagområder"
      />
      {loading && <LoadingSpinner />}
      {filteredCategories && <EditTable guide={data.guide} categories={filteredCategories} />}
    </div>
  );
}

function EditTable({ guide, categories }: { guide: Guide; categories: Category[] }) {
  const [addCategory] = useMutation(ADD_CATEGORY);
  const [removeCategory] = useMutation(REMOVE_CATEGORY);
  if (!guide) return null;

  const selected = guide.categories?.map((x) => x?.id) ?? [];
  const isSelected = (id) => selected.includes(id);

  const handleRemove = (categoryId: string) => removeCategory({ variables: { input: { id: guide.id, categoryId } } });
  const handleAdd = (categoryId: string) => addCategory({ variables: { input: { id: guide.id, categoryId } } });

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCellHead>Valgt</TableCellHead>
          <TableCellHead>Tittel</TableCellHead>
          <TableCellHead>Beskrivelse</TableCellHead>
        </TableRow>
      </TableHead>
      <TableBody>
        {categories.map((x) => {
          if (!x) return null;
          const itemSelected = isSelected(x.id);
          return (
            <TableRow
              hover
              onClick={() => (itemSelected ? handleRemove(x.id) : handleAdd(x.id))}
              role="checkbox"
              aria-checked={itemSelected}
              tabIndex={-1}
              key={x.id}
              selected={itemSelected}
            >
              <TableCell padding="checkbox">
                <Checkbox checked={itemSelected} color="primary" />
              </TableCell>
              <TableCell>{x.title}</TableCell>
              <TableCell>{x.description}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
