import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Modal } from '../dialogs/useModal';

export function CancelDialog({ modal }: { modal: Modal<undefined, boolean> }) {
  const close = (submit: boolean) => modal.close(submit);
  return (
    <div>
      <Dialog open={true} onClose={() => close(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Avbryte revisjon</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Er du sikker på at du vil avbryte denne revisjonen</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => close(false)} color="secondary" autoFocus>
            Nei
          </Button>
          <Button onClick={() => close(true)} variant="contained" color="primary">
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
