import { configureStore } from '@reduxjs/toolkit';
import historyReducer from './historySlice';
import criterionReducer from './backlog/criterionSlice';
import revisionFilterReducer from './revisions/filterSlice';
import guideFilterReducer from './guides/filterSlice';
import guideEditReducer from './guides/editSlice';
import backlogFilterReducer from './backlog/filterSlice';
import resourceFilterReducer from './resources/filterSlice';

const store = configureStore({
  reducer: {
    history: historyReducer,
    criterion: criterionReducer,
    revisionFilter: revisionFilterReducer,
    guideFilter: guideFilterReducer,
    guideEdit: guideEditReducer,
    backlogFilter: backlogFilterReducer,
    resourceFilter: resourceFilterReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
