import { gql } from "../__generated__";

export const GET_COMPLETED_REVISIONS = gql(/* GraphQL */`
    query GetCompletedRevisions {
        revisions(
        where: {
        completedAt: { neq: null } 
        }
        order: { completedAt: DESC }
        ) {
        id
        completedAt
        guide {
            id
            docName
            docTitle
        }
        }
    }
  `);

export const GET_NOTICES = gql(/* GraphQL */`
    query GetNoticesForCount{ 
        notices {
            id
        }
}
`);

export const GET_UNHANDLED_REGULATION_CHANGES = gql(/* GraphQL */`
    query GetUnhandledRegulationChangesForCount { 
        paragraphChanges(where: { handled: { eq: false} }) { id }
    }
`);

export const GET_OPEN_TASKS = gql(/* GraphQL */`
    query GetOpenTasksForCount { 
        tasks(where: { guides: { some: { completed: { eq: false }} }}) { id }
    }
`);

export const GET_POPULAR_GUIDES_LAST_MONTH = gql(/* GraphQL */`
  query GetPopularGuidesLastMonth{
    pageViews(order: { pageViews: DESC }, first: 10) {
      nodes {
        pageViews
        guide {
          id
          docName
          docTitle
        }
      }
    }
  }
`);

export const GET_SEARCH_TERMS = gql(/* GraphQL */`
  query GetSearchTerms ($take: Int!) {
    searchTermStat: searchTerms(first: $take) {
      searchTerm
      searchCount
    }
  }
`);

export const GET_ONGOING_REVISIONS = gql( /* GraphQL */ `
    query GetOngoingRevisions { 
        revisions(
            where: { and: [{ completedAt: { eq: null } }, { canceledAt: { eq: null} }, { scheduledEndDate: { neq: null } }]} 
            order: { scheduledEndDate: ASC }
        ) { 
            id
            title
            scheduledEndDate
        } 
    }
`);

export const GET_COMPLETED_REVISIONS_PER_MONTH = gql(/* GraphQL */`
    query GetCompletedRevisionsPerMonth {
        revisions(
        where: {
            completedAt: { neq: null } 
        }    
        ) {    
            completedAt    
        }
  }`);