import { useCallback } from 'react';
import { useAuthenticatedApi } from '../api';

export interface FindSimilarGuidesRequest {
  text: string;
}

export interface FindSimilarGuidesResponse {
  guides: Guide[];
}

interface Guide {
  title: string;
  docName: string;
  link: string;
  docId: number;
  score: number;
  chunks: GuideChunk[];
}

interface GuideChunk {
  title: string;
  link: string;
  score: number;
}

export const useAiSearchApi = () => {
  const api = useAuthenticatedApi('ai-search');

  const findSimilarGuides = useCallback(
    async (request: FindSimilarGuidesRequest) => {
      const response = await api.post<FindSimilarGuidesResponse | string>('similar-guides', request);
      if (response.status === 200) return response.data as FindSimilarGuidesResponse;
      throw new Error(response.data as string);
    },
    [api],
  );

  return {
    findSimilarGuides,
  };
};
