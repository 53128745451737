import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';

export const SidebarHeading: FC<{ icon: React.ReactNode; children: React.ReactNode }> = ({ icon, children }) => {
  return (
    <Typography variant="h6">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: '8px',
          mb: 1,
        }}>
        {icon}
        {children}
      </Box>
    </Typography>
  );
};
