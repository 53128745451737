import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useQuery } from '@apollo/client';
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  Grid2 as Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { All_SUBJECT_RESOURCES } from './subjectResources.graphql';
import { TableCellSort, compareFn, ColWidths } from '../components/TableComponents';
import { Add, Edit } from '@mui/icons-material';
import { StyledLink } from '../Components';
import { SearchField } from '../components/SearchField';
import Fuse from 'fuse.js';
import { useNavigate } from 'react-router-dom';
import { SortDirection } from '@mui/material/TableCell';
import { Unpacked } from '../graphQLTypes/types';
import { GetSubjectResourcesQuery } from '../__generated__/graphql';

type SubjectResource = Unpacked<GetSubjectResourcesQuery['resources']>;

const PREFIX = 'ResourceSelect';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledTable = styled(Table)({
  [`& .${classes.root}`]: {
    height: '50%',
  },
});

interface Props {
  selected: string[];
  onUpdated?(id: string[]): void;
}

export function ResourceSelect({ selected, onUpdated }: Props) {
  const [open, setOpen] = useState(false);
  const { loading, error, data } = useQuery(All_SUBJECT_RESOURCES);

  const EditButton = (props) => {
    if (!onUpdated || !data) return null;

    return (
      <IconButton onClick={() => setOpen(true)} style={{ padding: 5, marginLeft: props.marginLeft || 0 }} title="Endre fagressurs" size="large">
        <Edit fontSize="small" />
      </IconButton>
    );
  };

  const Dialog = () => {
    if (!data?.resources) return null;

    return <ResourceSelectDialog open={open} onClose={() => setOpen(false)} onSubmit={onUpdated} resources={data.resources} selected={selected} />;
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid size={{ xs: 11 }}>
          <List dense>
            {error && (
              <ListItem>
                <ListItemText>{error.message}</ListItemText>
              </ListItem>
            )}
            {loading && (
              <ListItem>
                <ListItemText>Henter..</ListItemText>
              </ListItem>
            )}
            {data?.resources &&
              selected &&
              selected.map((s, i) => {
                const resource = data?.resources?.find((r) => r?.id === s)!;
                return (
                  <ListItem key={s} sx={{ paddingLeft: 0 }}>
                    <StyledLink to={`/subjectResource/${resource.id}`}>{resource.name}</StyledLink>
                  </ListItem>
                );
              })}
          </List>
        </Grid>
        <Grid size={{ xs: 1 }}>
          <EditButton />
        </Grid>
      </Grid>
      <Dialog />
    </>
  );
}

interface DialogProps {
  open: boolean;
  selected: string[];
  resources: SubjectResource[];
  onSubmit?(selected: string[]): void;
  onClose(): void;
}

export function ResourcesSelectDialog2({
  onClose,
  onSubmit,
  selected,
}: {
  selected: string[];
  onClose(): void;
  onSubmit?(selected: string[]): void;
}) {
  const { loading, data } = useQuery(All_SUBJECT_RESOURCES);
  if (loading || !data) return null;
  if (!data.resources) return null;
  return <ResourceSelectDialog open selected={selected} onClose={onClose} onSubmit={onSubmit} resources={data.resources} />;
}

function ResourceSelectDialog({ open, onClose, onSubmit, selected, resources }: DialogProps) {
  const [workingSet, setWorkingSet] = useState(selected);
  const [filter, setFilter] = useState('');
  const navigate = useNavigate();

  const handleClose = () => {
    setWorkingSet(selected);
    onClose();
    setFilter('');
  };

  const handleSubmit = () => {
    if (onSubmit) onSubmit(workingSet);
    setWorkingSet(workingSet);
    onClose();
    setFilter('');
  };

  const addSubjectResource = () => {
    navigate(`/subjectResource?create=${filter}`);
  };

  const filterResources = (): SubjectResource[] => {
    const activeResources = resources.filter((x) => x && !x.isDeleted);
    if (!filter) return activeResources;

    var options = {
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0,
      keys: ['name'],
    };

    var fuse = new Fuse(activeResources, options);
    var filtered = fuse.search(filter.trim()).map((x) => x.item);
    return filtered;
  };

  const filteredResources = filterResources();
  const showAddButton = filteredResources.length === 0;

  return (
    <Dialog className={classes.root} open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle id="ResourceSelector">Velg fagressurs</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid size={{ xs: showAddButton ? 11 : 12 }}>
            <SearchField filter={setFilter} />
          </Grid>
          <Grid size={{ xs: 1 }}>
            {showAddButton && (
              <IconButton onClick={addSubjectResource} title="Legg til som ny fagressurs" size="large">
                <Add />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <ResourceTable resources={filteredResources} selected={workingSet} setSelected={setWorkingSet} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Avbryt</Button>
        <Button onClick={handleSubmit} color="primary">
          Velg
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type ResourceColumn = 'selected' | 'name' | 'internal';

interface TableProps {
  resources: SubjectResource[];
  selected: string[];
  setSelected(arr: string[]): void;
}

function ResourceTable({ resources, selected, setSelected }: TableProps) {
  const isSelected = (id: string) => selected.some((x) => x === id);
  const toggleSelected = (id: string) => {
    if (isSelected(id)) {
      setSelected(selected.filter((x) => x !== id));
    } else {
      setSelected(selected.concat(id));
    }
  };
  const [orderBy, setOrderBy] = useState<ResourceColumn>('name');
  const [direction, setDirection] = useState<SortDirection>(false);
  const handleSortClick = (key: ResourceColumn) => {
    if (orderBy === key) {
      setDirection(direction === 'desc' ? 'asc' : 'desc');
    } else {
      setOrderBy(key);
      setDirection('desc');
    }
  };

  const getSorter = () => {
    const fn = (getter: (SubjectResource) => any) => compareFn<SubjectResource>(getter, direction);
    if (orderBy === 'selected') return fn((x) => isSelected(x.id));
    if (orderBy === 'internal') return fn((x) => x.isInternal);

    return fn((x) => x.name);
  };

  const ColumnHeader = ({ colKey, children }: { colKey: ResourceColumn; children?: any }) => (
    <TableCellSort active={orderBy === colKey} sortDirection={direction} onClick={() => handleSortClick(colKey)}>
      {children}
    </TableCellSort>
  );

  return (
    <StyledTable size="small">
      <ColWidths widths={[40, null, 80]}></ColWidths>
      <TableHead>
        <TableRow>
          <ColumnHeader colKey="selected">Valgt</ColumnHeader>
          <ColumnHeader colKey="name">Navn</ColumnHeader>
          <ColumnHeader colKey="internal">Intern</ColumnHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {resources.sort(getSorter()).map((resource) => {
          if (!resource) return null;
          const selected = isSelected(resource.id);
          return (
            <TableRow
              hover
              onClick={() => toggleSelected(resource.id)}
              role="checkbox"
              aria-checked={selected}
              tabIndex={-1}
              key={resource.id}
              selected={selected}>
              <TableCell padding="checkbox">
                <Checkbox checked={selected} color="primary" />
              </TableCell>
              <TableCell>{resource.name}</TableCell>
              <TableCell>
                <Checkbox color="primary" disabled checked={resource.isInternal} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
}
