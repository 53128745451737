import { gql } from '../__generated__';
import { GetGuideQuery, HearingItemFragment, HearingParticipantItemFragment } from '../__generated__/graphql';

export type Guide = NonNullable<GetGuideQuery['guide']>;
export type Hearing = NonNullable<HearingItemFragment>;
export type HearingParticipant = NonNullable<HearingParticipantItemFragment>;

gql(/* GraphQL */ `
  fragment HearingParticipantItem on HearingParticipantModel {
    id
    accessToken
    email
    invitationSentAt
    isFinished
    contentComments {
      id
    }
  }
`);

gql(/* GraphQL */ `
  fragment HearingItem on HearingModel {
    id
    isExternal
    emailMessage
    scheduledEndAt
    startedAt
    startedBy {
      name
    }
    endedAt
    endedBy {
      name
    }
    guideContent {
      id
      version
    }
    participants(where: { isDeleted: { eq: false } }) {
      ...HearingParticipantItem
    }
  }
`);

export const GET_GUIDE_HEARINGS = gql(/* GraphQL */ `
  query GetGuideHearings($guideId: UUID!) {
    guide(id: $guideId) {
      id
      docName
      docTitle
      contentId
      hearings(order: { startedAt: DESC }) {
        ...HearingItem
      }
    }
  }
`);

export const CREATE_HEARING = gql(/* GraphQL */ `
  mutation CreateHearing($input: HearingCreateInput!) {
    hearingCreate(input: $input) {
      hearing {
        ...HearingItem
      }
      guide {
        id
        hearing {
          ...HearingItem
        }
        hearings(order: { startedAt: DESC }) {
          ...HearingItem
        }
      }
    }
  }
`);

export const START_HEARING = gql(/* GraphQL */ `
  mutation StartHearing($input: HearingStartInput!) {
    hearingStart(input: $input) {
      hearingModel {
        ...HearingItem
      }
    }
  }
`);

export const END_HEARING = gql(/* GraphQL */ `
  mutation EndHearing($input: HearingEndInput!) {
    hearingEnd(input: $input) {
      hearing {
        ...HearingItem
      }
      guide {
        id
        hearing {
          ...HearingItem
        }
      }
    }
  }
`);

export const UPDATE_HEARING_IS_EXTERNAL = gql(/* GraphQL */ `
  mutation UpdateHearingIsExternal($input: HearingUpdateIsExternalInput!) {
    hearingUpdateIsExternal(input: $input) {
      hearingModel {
        ...HearingItem
      }
    }
  }
`);

export const UPDATE_HEARING_SCHEDULED_END_AT = gql(/* GraphQL */ `
  mutation UpdateHearingScheduledEndAt($input: HearingUpdateScheduledEndAtInput!) {
    hearingUpdateScheduledEndAt(input: $input) {
      hearingModel {
        ...HearingItem
      }
    }
  }
`);

export const UPDATE_HEARING_EMAIL_MESSAGE = gql(/* GraphQL */ `
  mutation UpdateHearingEmailMessage($input: HearingUpdateEmailMessageInput!) {
    hearingUpdateEmailMessage(input: $input) {
      hearingModel {
        ...HearingItem
      }
    }
  }
`);

export const ADD_HEARING_PARTICIPANTS = gql(/* GraphQL */ `
  mutation AddHearingParticipants($input: HearingAddParticipantsInput!) {
    hearingAddParticipants(input: $input) {
      hearingModel {
        ...HearingItem
      }
    }
  }
`);

export const REMOVE_HEARING_PARTICIPANT = gql(/* GraphQL */ `
  mutation RemoveHearingParticipant($input: HearingRemoveParticipantInput!) {
    hearingRemoveParticipant(input: $input) {
      hearingModel {
        ...HearingItem
      }
    }
  }
`);

export const SEND_INVITE = gql(/* GraphQL */ `
  mutation SendHearingInvite($input: HearingSendInviteInput!) {
    hearingSendInvite(input: $input) {
      hearingId
      participantId
    }
  }
`);

export const SUBSCRIBE_HEARING_UPDATED = gql(/* GraphQL */ `
  subscription HearingUpdated($hearingId: UUID!) {
    hearingUpdated(hearingId: $hearingId) {
      ...HearingItem
    }
  }
`);
