import React, { FC } from 'react';
import { GuideCms } from './GuideCms';
import { Drawer } from '@mui/material';
import styled from '@emotion/styled';
import { theme } from '../../theme';
import { useGuideCmsContext } from './GuideCmsContext';
import { Sidebar } from './sidebar/Sidebar';
import { SidebarButtons } from './sidebar/SidebarButtons';

const sidebarWidth = 440;
const sidebarButtonsWidth = 44;

const StyledDrawer = styled(Drawer)`
  width: ${sidebarWidth}px;
  overflow-y: hidden;
  & .MuiDrawer-paper {
    width: ${sidebarWidth}px;
    margin-top: 64px;
    padding-bottom: 64px;
  }
`;

const StyledSidebarButtonsDrawer = styled.div<{ isSidebarOpen: boolean }>`
  position: fixed;
  top: 80px;
  right: 0;
  ${({ isSidebarOpen }) => (isSidebarOpen ? `margin-right: ${sidebarWidth}px;` : ``)}
  transition: margin-right ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeOut};
`;

const StyledMain = styled.div<{ isSidebarOpen: boolean }>`
  ${({ isSidebarOpen }) => (isSidebarOpen ? `margin-right: ${sidebarWidth + sidebarButtonsWidth}px;` : `margin-right: ${sidebarButtonsWidth}px;`)}
  transition: margin-right ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeOut};
`;

export const Layout: FC = () => {
  const { isSidebarOpen } = useGuideCmsContext();
  return (
    <div>
      <StyledMain isSidebarOpen={isSidebarOpen}>
        <GuideCms />
        <StyledSidebarButtonsDrawer isSidebarOpen={isSidebarOpen}>
          <SidebarButtons />
        </StyledSidebarButtonsDrawer>
      </StyledMain>

      <StyledDrawer open={isSidebarOpen} variant="persistent" anchor="right">
        <Sidebar />
      </StyledDrawer>
    </div>
  );
};
