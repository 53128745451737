import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button, TextField, FormControlLabel } from '@mui/material';

import { useMutation, useQuery } from '@apollo/client';
import { ARCHIVE_GUIDE, GET_GUIDES } from './guide.graphql';
import { MultiSelect } from '../Components';
import { GetGuidesQuery, GuideStatus } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { Modal } from '../dialogs/useModal';

const PREFIX = 'ArchiveGuideDialog';

const classes = {
  root: `${PREFIX}-root`,
  labelPlacementTop: `${PREFIX}-labelPlacementTop`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
    alignItems: 'flex-start',
  },

  [`& .${classes.labelPlacementTop}`]: {
    marginLeft: 0,
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

type Guide = Unpacked<GetGuidesQuery['guides']>;
export function ArchiveGuideDialog({ modal }: { modal: Modal<string, unknown> }) {
  const guideId = modal.data;
  const [releaseNotes, setReleaseNotes] = useState('');
  const [replacementGuides, setReplacementGuides] = useState<Guide[]>([]);
  const { loading, data } = useQuery(GET_GUIDES);
  const [archiveGuide] = useMutation(ARCHIVE_GUIDE, {
    variables: { input: { id: guideId, releaseNotes, replacementGuides: replacementGuides.map((x) => x?.id) } },
  });
  const handleClose = () => {
    modal.close();
  };

  const handleOk = async () => {
    await archiveGuide();
    handleClose();
  };

  const handleReplacementGuidesChange = (values) =>
    values && !loading ? setReplacementGuides(values.map((v) => data?.guides?.find((g) => g!.id === v.value))) : setReplacementGuides([]);

  const getSelectValues = (models: Guide[], addOnEmpty: boolean = false) => {
    if (models.length === 0 && addOnEmpty) return [{ value: 'empty', label: 'Henter..' }];
    return models.map((m) => ({ value: m.id, label: m.docName + ' ' + m.docTitle }));
  };
  const availableGuides = !loading ? data?.guides?.filter((x) => x!.status !== GuideStatus.Expired) : [];

  return (
    <Root>
      <Dialog fullWidth={true} maxWidth={'sm'} open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Trekk tilbake anvisning</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Dette vil merke anvisningen som tilbaketrukket. Anvisningen vil umiddelbart ikke lenger være en del av aktive anvisninger på web
          </DialogContentText>
          <FormControlLabel
            classes={classes}
            labelPlacement="top"
            label="Erstattes av"
            control={
              <StyledMultiSelect
                placeholder="Ingen"
                width={552}
                labelId="replacement-guides-label"
                id="replacement-guides"
                options={getSelectValues(availableGuides, true)}
                value={getSelectValues(replacementGuides)}
                onChange={handleReplacementGuidesChange}
              />
            }
          />
          <TextField
            name="releaseNotes"
            margin="dense"
            label="Kommentar til tilbaketrekking av anvisning"
            type="text"
            multiline={true}
            rows={10}
            autoFocus
            value={releaseNotes}
            onChange={(ev) => setReleaseNotes(ev.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Avbryt</Button>
          <Button onClick={handleOk} variant="contained" color="primary">
            Trekk tilbake
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
}

const StyledMultiSelect = styled(MultiSelect)(({ theme }) => ({
  zIndex: '100 !important',
  [`& .Select-menu-outer`]: {
    background: 'red',
    zIndex: 100,
  },
}));
