import { FC, useEffect, useRef } from 'react';
import React from 'react';
import Chart from 'chart.js/auto';
import { parseChartConfig } from './chartConfig';

export const ChartComponent: FC<{ width: number; config: string }> = ({ width, config }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const renderChart = () => {
    if (!canvasRef.current) return;
    const parseResult = parseChartConfig(config);
    if (!parseResult.isValid) {
      console.error('Failed to create chart', parseResult.error);
      return;
    }
    try {
      return new Chart(canvasRef.current, parseResult.config);
    } catch (e) {
      console.error('Failed to create chart', e);
    }
  };

  useEffect(() => {
    const chart = renderChart();
    return () => {
      chart?.destroy();
    };
  }, [config, width]);

  return (
    <div style={{ overflowX: 'auto' }}>
      <div style={{ width: `${width}px` }}>
        <canvas ref={canvasRef}></canvas>
      </div>
    </div>
  );
};
