import { gql } from "../__generated__";

export const GET_CLASSIFICATIONS = gql(/* GraphQL */`
  query GetClassifications {
    classifications {
      id
      name
      code
      hasChanges
      lastPublished
      children {
        id
        publicId
        number
        heading
        children {
          id
          publicId
          number
          heading
          guides
          children {
            id
            publicId
            number
            heading
            guides
            children {
              id
              publicId
              number
              heading
              guides
              children {
                id
                publicId
                number
                heading
                guides
              }
            }
          }
        }
      }
    }
    guides: guides {
      id
      docName
      docTitle
      status
      lastPublishedAt
      mainCategory {
        title
      }
      hasChangesSinceLastPublish
      ongoingRevision {
        id
      }
    }
  }
`);
