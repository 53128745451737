import { gql } from '../../../../__generated__';
import { GuideContentCommentItemFragment } from '../../../../__generated__/graphql';

export type Comment = NonNullable<GuideContentCommentItemFragment>;

gql(/* GraphQL */ `
  fragment HearingInfoItem on HearingInfo {
    hearingId
    participantAlias
    participantId
    participantEmail
  }
`);

gql(/* GraphQL */ `
  fragment CommentReplyItem on CommentReply {
    id
    createdAt
    updatedAt
    createdBy {
      name
      subjectId
    }
    comment
  }
`);

gql(/* GraphQL */ `
  fragment GuideContentCommentItem on GuideContentCommentModel {
    id
    guideId
    guideNodeId
    contentVersion
    createdAt
    updatedAt
    createdBy {
      name
      subjectId
    }
    comment
    status
    statusChangedAt
    statusChangedBy {
      name
    }
    hearing {
      ...HearingInfoItem
    }
    replies {
      ...CommentReplyItem
    }
  }
`);

export const GUIDE_CONTENT_COMMENTS = gql(/* GraphQL */ `
  query GetGuideContentComments($id: UUID!) {
    guide(id: $id) {
      id
      contentComments {
        ...GuideContentCommentItem
      }
    }
  }
`);

export const CREATE_COMMENT = gql(/* GraphQL */ `
  mutation GuideContentCommentCreate($input: GuideContentCommentCreateInput!) {
    guideContentCommentCreate(input: $input) {
      guideContentCommentModel {
        id
      }
    }
  }
`);

export const UPDATE_COMMENT = gql(/* GraphQL */ `
  mutation GuideContentCommentUpdate($input: GuideContentCommentUpdateInput!) {
    guideContentCommentUpdate(input: $input) {
      guideContentCommentModel {
        id
      }
    }
  }
`);

export const UPDATE_COMMENT_STATUS = gql(/* GraphQL */ `
  mutation GuideContentCommentUpdateStatus($input: GuideContentCommentUpdateStatusInput!) {
    guideContentCommentUpdateStatus(input: $input) {
      guideContentCommentModel {
        id
      }
    }
  }
`);

export const DELETE_COMMENT = gql(/* GraphQL */ `
  mutation GuideContentCommentDelete($input: GuideContentCommentDeleteInput!) {
    guideContentCommentDelete(input: $input) {
      guideContentCommentModel {
        id
      }
    }
  }
`);

export const CREATE_REPLY = gql(/* GraphQL */ `
  mutation GuideContentCommentCreateReply($input: GuideContentCommentCreateReplyInput!) {
    guideContentCommentCreateReply(input: $input) {
      guideContentCommentModel {
        id
      }
    }
  }
`);

export const UPDATE_REPLY = gql(/* GraphQL */ `
  mutation GuideContentCommentUpdateReply($input: GuideContentCommentUpdateReplyInput!) {
    guideContentCommentUpdateReply(input: $input) {
      guideContentCommentModel {
        id
      }
    }
  }
`);

export const DELETE_REPLY = gql(/* GraphQL */ `
  mutation GuideContentCommentDeleteReply($input: GuideContentCommentDeleteReplyInput!) {
    guideContentCommentDeleteReply(input: $input) {
      guideContentCommentModel {
        id
      }
    }
  }
`);

export const SUBSCRIBE_COMMENTS_UPDATED = gql(/* GraphQL */ `
  subscription ContentCommentsUpdated($guideId: UUID!) {
    contentCommentsUpdated(guideId: $guideId) {
      id
      contentComments {
        ...GuideContentCommentItem
      }
    }
  }
`);
