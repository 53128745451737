import React, { FC, useState } from 'react';
import { Modal } from '../../../dialogs/useModal';
import { GuideFiguresOverview } from './GuideFiguresOverview';
import { EditGuideFigure } from './EditGuideFigure';
import { Dialog } from '@mui/material';

export interface GuideFigureDialogResult {
  insertFigureId?: string;
}

export interface GuideFigureDialogProps {
  editFigureId?: string;
}

export const GuideFigureDialog: FC<Modal<GuideFigureDialogProps, GuideFigureDialogResult>> = (modal) => {
  const [editFigureId, setEditFigureId] = useState(modal.data.editFigureId);
  const [isEditing, setIsEditing] = useState(!!modal.data.editFigureId);

  const handleOnUpload = () => {
    setIsEditing(true);
  };

  const handleOnEdit = (figureId: string) => {
    setEditFigureId(figureId);
    setIsEditing(true);
  };

  const handleOnEditBack = () => {
    setEditFigureId(undefined);
    setIsEditing(false);
  };

  const handleOnClose = () => {
    modal.close();
  };

  return (
    <Dialog open={true} maxWidth="md" fullWidth={true} disableEscapeKeyDown={isEditing} onClose={handleOnClose}>
      {isEditing ? (
        <EditGuideFigure modal={modal} goBackToOverview={!modal.data.editFigureId} editFigureId={editFigureId} onBack={handleOnEditBack} />
      ) : (
        <GuideFiguresOverview modal={modal} onUploadClick={handleOnUpload} onEditClick={handleOnEdit} />
      )}
    </Dialog>
  );
};
