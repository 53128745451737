import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import { useMutation } from '@apollo/client';
import { ADD_NOTICE } from './notices.graphql';
import Alert from '@mui/material/Alert';
import { Modal } from '../dialogs/useModal';

export function AddGuideNoticeDialog({ modal }: { modal: Modal<string, unknown> }) {
  const guideId = modal.data;
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [addGuideNotice, { loading }] = useMutation(ADD_NOTICE, { variables: { input: { id: guideId, title, text } } });

  const handleClose = () => {
    modal.close();
  };

  const handleOk = async () => {
    await addGuideNotice();
    modal.close();
  };

  return (
    <div>
      <Dialog open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Legg til merknad</DialogTitle>
        <DialogContent>
          <Alert severity="warning">Etter publisering vil merknaden være synlig på anvisningen på webportalen</Alert>
          <TextField
            margin="dense"
            name="title"
            label="Tittel"
            type="text"
            multiline
            onChange={(ev) => setTitle(ev.target.value)}
            value={title}
            autoFocus
            fullWidth
          />

          <TextField
            margin="dense"
            name="notice"
            label="Merknadstekst"
            type="text"
            multiline
            onChange={(ev) => setText(ev.target.value)}
            value={text}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Avbryt</Button>
          <Button disabled={loading} onClick={handleOk} variant="contained" color="primary">
            Legg til
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
