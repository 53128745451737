import React from 'react';
import { Link } from 'react-router-dom';

import { List, ListItem, IconButton, Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { StyledLink } from '../Components';
import { GetGuideQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';

type Standard = Unpacked<NonNullable<GetGuideQuery['guide']>['standards']>;
export function Standards({
  guideId,
  standards,
  elevation,
  isEditable,
}: {
  guideId: string;
  standards: Standard[];
  elevation?: number;
  isEditable: boolean;
}) {
  return (
    <StyledPaper elevation={elevation}>
      <Typography variant="h6" component="h3">
        Standarder
        {isEditable && (
          <IconButton component={Link} to={`/guide/standards/${guideId}`} size="large">
            <Edit />
          </IconButton>
        )}
      </Typography>
      {standards.length === 0 && (
        <Typography variant="subtitle1" color="textSecondary">
          Ingen angitt
        </Typography>
      )}
      <List dense>
        {standards.map((x) => {
          if (!x) return null;
          return (
            <ListItem key={x.id}>
              <StyledLink to={`/standard/${x.id}`}>
                <Typography noWrap>{x.name}</Typography>
              </StyledLink>
            </ListItem>
          );
        })}
      </List>
    </StyledPaper>
  );
}
