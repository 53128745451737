import React from 'react';
import { Badge } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useConfig } from '../env';
import { useAuth } from 'oidc-react';

const openTasksQuery = gql`
  query ($ownerId: UUID!) {
    tasks(where: { guides: { some: { and: [{ completed: { eq: false } }, { ownerId: { eq: $ownerId } }] } } }) {
      id
    }
  }
`;

export function UnreadTasksBadge({ children }: { children: React.ReactElement<any, any> }) {
  const auth = useAuth();
  const profile = auth.userData?.profile;
  const config = useConfig();
  const pollIntervall = config ? config.defaultPollInterval : 0;
  const { loading, error, data } = useQuery(openTasksQuery, { pollInterval: pollIntervall, variables: { ownerId: profile?.sub } });

  return !loading && !error ? (
    <Badge badgeContent={data.tasks.length} color="error">
      {children}
    </Badge>
  ) : (
    children
  );
}
