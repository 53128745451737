import React, { useState } from 'react';
import { styled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import { Table, TableBody, TableCell, TableHead, TableRow, FormControlLabel, Switch, CircularProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { Close, Refresh, AddAlert } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Grid2 as Grid } from '@mui/material';
import Fuse from 'fuse.js';
import { ColWidths, TableCellHead, TableCellEllipsis, TableCellIcon } from '../components/TableComponents';
import { SearchField } from '../components/SearchField';

import { StyledLink } from '../Components';
import { formatDate } from '../Formatters';
import LoadingSpinner from '../LoadingSpinner';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CHANGES, GET_PARAGRAPHS, REFRESH_TEK17 } from './paragraphs.graphql';
import { GetParagraphChangesQuery, GetParagraphsQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';
import { AddTaskDialog, AddTaskDialogProps, AddTaskDialogResult } from '../tasks/AddTaskDialog';
import { useModal } from '../dialogs/useModal';
import { ADD_PARAGRAPH_TASK } from '../tasks/task.graphql';

const StickyTableCellHead = styled(TableCellHead)(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(8),
  background: 'white',
}));

export function ParagraphOverview() {
  return (
    <Grid container>
      <Grid size={{ xs: 12, sm: 8 }}>
        <Paragraphs />
      </Grid>
      <Grid size={{ xs: 12, sm: 4 }}>
        <Changes />
      </Grid>
    </Grid>
  );
}

type Paragraph = Unpacked<GetParagraphsQuery['paragraphs']>;

function Paragraphs() {
  const regulation = 'TEK-17';
  const [searchTerm, setSearchTerm] = useState('');
  const { data } = useQuery(GET_PARAGRAPHS);

  const paragraphs = data?.paragraphs;
  const filteredParagraphs = paragraphs ? filterParagraphs(searchTerm, paragraphs) : undefined;

  return (
    <StyledPaper>
      <Typography variant="h5">Byggteknisk forskrift (TEK)</Typography>
      <Typography variant="caption">Gjeldende forskrift: {regulation}</Typography>
      <SearchField filter={setSearchTerm} />
      {!filterParagraphs && <LoadingSpinner />}
      {filteredParagraphs && filteredParagraphs.length === 0 && (
        <Typography variant="caption">
          Finner du ikke paragrafen i listen? Da er den mest sannsynlig ikke i bruk (ingen anvisninger knyttet til den).
        </Typography>
      )}
      {filteredParagraphs && <ParagraphTable paragraphs={filteredParagraphs} />}
    </StyledPaper>
  );
}

function ParagraphTable({ paragraphs }: { paragraphs: Paragraph[] }) {
  const [taskSaved, setTaskSaved] = React.useState(false);
  const handleCloseSnackbar = () => setTaskSaved(false);

  return (
    <>
      <Table size="small" sx={{ position: 'relative' }}>
        <ColWidths widths={[80, null, 100, 100, 80]}></ColWidths>
        <TableHead>
          <TableRow>
            <StickyTableCellHead>Paragraf</StickyTableCellHead>
            <StickyTableCellHead>Tittel</StickyTableCellHead>
            <StickyTableCellHead>Anvisninger</StickyTableCellHead>
            <StickyTableCellHead />
          </TableRow>
        </TableHead>
        <TableBody>
          {paragraphs.map((x) => {
            if (!x) return null;
            return (
              <TableRow key={x.id}>
                <TableCell>
                  <StyledLink to={`${x.id}`}>{x.number}</StyledLink>
                </TableCell>
                <TableCellEllipsis>{x.title}</TableCellEllipsis>
                <TableCell align="center">{x.guideRevisions?.length}</TableCell>
                <TaskCell paragraph={x} onTaskCreated={() => setTaskSaved(true)} />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Snackbar
        open={taskSaved}
        onClose={handleCloseSnackbar}
        autoHideDuration={3000}
        message={<span id="message-id">Oppgave lagret</span>}
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={handleCloseSnackbar} size="large">
            <Close />
          </IconButton>,
        ]}
      />
    </>
  );
}

function TaskCell({ paragraph, onTaskCreated }: { paragraph: NonNullable<Paragraph>; onTaskCreated: () => void }) {
  const addTaskModal = useModal<AddTaskDialogProps, AddTaskDialogResult>({ data: {} });
  const [addTask, { loading }] = useMutation(ADD_PARAGRAPH_TASK);

  const addParagraphNotice = async () => {
    const result = await addTaskModal.open();
    if (result) {
      await addTask({ variables: { input: { paragraphId: paragraph.id, title: result.title, text: result.text } } });
      onTaskCreated();
    }
  };

  return (
    <>
      <TableCellIcon>
        {paragraph.guideRevisions && paragraph.guideRevisions.length > 0 && (
          <IconButton
            style={{ padding: 5 }}
            title="Varsle endring"
            disabled={loading}
            onClick={() => addParagraphNotice()}
            color="primary"
            aria-label="Add task"
            size="large">
            {!loading && <AddAlert />}
            {loading && <CircularProgress size={25} />}
          </IconButton>
        )}
      </TableCellIcon>
      {addTaskModal.isOpen && <AddTaskDialog modal={addTaskModal} />}
    </>
  );
}

function Changes() {
  const [showHandled, setShowHandled] = React.useState(false);
  const { data, refetch } = useQuery(GET_CHANGES);
  const [refreshChanges] = useMutation(REFRESH_TEK17);
  const changes = data?.changes;
  return (
    <StyledPaper>
      <Typography variant="h5">
        Endringer
        <IconButton disabled={!changes} onClick={() => refreshChanges()} size="large">
          <Refresh />
        </IconButton>
      </Typography>
      <FormControlLabel
        control={<Switch name="showHandled" onChange={() => setShowHandled(!showHandled)} checked={showHandled} />}
        label="Vis varslede"
      />
      {!changes && <LoadingSpinner />}
      {changes && (
        <List>
          {changes
            .filter((c) => showHandled || !c?.handled)
            .map((c) => {
              if (!c) return null;
              return <Change key={c.id} change={c} refetch={refetch} />;
            })}
        </List>
      )}
    </StyledPaper>
  );
}

type RegulationChange = Unpacked<GetParagraphChangesQuery['changes']>;
function Change({ change, refetch }: { change: NonNullable<RegulationChange>; refetch: () => void }) {
  const addTaskModal = useModal<AddTaskDialogProps, AddTaskDialogResult>({ data: {} });
  const [addTask, { loading: addingTask }] = useMutation(ADD_PARAGRAPH_TASK);

  const addTasks = async () => {
    const result = await addTaskModal.open();
    if (result) {
      addTask({ variables: { input: { paragraphId: change.paragraphId, ...result } } });
      // TODO - update change to handled
      refetch();
    }
  };

  const primaryText = (
    <span style={!change.handled && change.isUsed ? { fontWeight: 'bold' } : {}}>
      <span>{formatDate(change.date, 'DD.MM.YY')}</span> <StyledLink to={`/paragraph/${change.paragraphId}`}>{change.paragraphNumber}</StyledLink>
    </span>
  );
  return (
    <ListItem key={change.id}>
      <ListItemText primary={primaryText} secondary={change.comment} />
      {change.isUsed && (
        <ListItemSecondaryAction>
          <IconButton disabled={addingTask} onClick={addTasks} color="primary" size="large">
            {!addingTask && <AddAlert />}
            {addingTask && <CircularProgress size={25} />}
          </IconButton>
        </ListItemSecondaryAction>
      )}
      {addTaskModal.isOpen && <AddTaskDialog modal={addTaskModal} />}
    </ListItem>
  );
}

function filterParagraphs(searchTerm: string, paragraphs: Paragraph[]): Paragraph[] {
  var options = {
    shouldSort: true,
    tokenize: true,
    matchAllTokens: true,
    threshold: 0,
    location: 0,
    distance: 10,
    maxPatternLength: 32,
    minMatchCharLength: 3,
    keys: ['number', 'title'],
  };

  if (!searchTerm) {
    return paragraphs;
  }

  var fuse = new Fuse(paragraphs, options);
  var all = fuse.search(searchTerm);
  return all.map((x) => x.item);
}
