import React from 'react';
import { roles } from './Overview';
import { styled } from '@mui/material/styles';
import { TableCellProps, Tooltip } from '@mui/material';
import { compareFn, TableCellHead } from '../components/TableComponents';
import { SortDirection } from '@mui/material/TableCell';
import moment from 'moment';

export function RoleHeaders() {
  return (
    <>
      {Object.keys(roles).map((r) => (
        <StickyTableCell key={r} align="center">
          <Tooltip title={roles[r].title}>
            <span>{roles[r].label}</span>
          </Tooltip>
        </StickyTableCell>
      ))}
    </>
  );
}

export const StickyTableCell = styled(TableCellHead)<TableCellProps>(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(8),
  background: 'white',
}));

export type RevisionColumn = 'number' | 'title' | 'mainCategory' | 'scheduledEndDate' | 'progress' | 'currentPhase';
export type SortableRevision = { scheduledEndDate?: string, guide?: { docName?: string | null | undefined, docTitle?: string | null | undefined, mainCategory?: { title?: string | null | undefined } | null | undefined } | null } | null | undefined;
export type CompareFn = (a: SortableRevision, b: SortableRevision) => number;

export const getCompareFn = (orderBy: RevisionColumn, direction: SortDirection): CompareFn => {
  const fn = (getter: (model: SortableRevision) => any) => compareFn<SortableRevision>(getter, direction);
  if (orderBy === 'number') return fn((x) => x?.guide?.docName);
  if (orderBy === 'title') return fn((x) => x?.guide?.docTitle);
  if (orderBy === 'mainCategory') return fn((x) => x?.guide?.mainCategory?.title);
  if (orderBy === 'scheduledEndDate') return fn((x) => (x?.scheduledEndDate ? moment(x.scheduledEndDate) : null));

  return fn((x) => x ? x[orderBy] : null);
};
