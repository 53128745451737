import React, { useState } from 'react';

import { styled } from '@mui/material/styles';

import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { Launch, Note } from '@mui/icons-material';

import { useConfig } from '../env';
import moment from 'moment';
import { Guide } from './Details';
import { PublishType } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';
import { capitalizeFirstLetter } from '../utils/string';

const PREFIX = 'Releases';

const classes = {
  extraDense: `${PREFIX}-extraDense`,
  smallButton: `${PREFIX}-smallButton`,
  retracted: `${PREFIX}-retracted`,
};

const StyledDialog = styled(Dialog)({
  [`& .${classes.extraDense}`]: {
    paddingTop: 1,
    paddingBottom: 1,
  },
  [`& .${classes.smallButton}`]: {
    padding: 5,
  },
  [`& .${classes.retracted}`]: {
    borderBottom: 'none',
  },
});

export default function Releases({ guide }: { guide: Guide }) {
  const hasVersions = guide.versions && guide.versions.length > 0;
  return (
    <StyledPaper>
      <Typography variant="h5" component="h3" gutterBottom>
        Endringshistorikk
      </Typography>
      {hasVersions && <ReleaseList guide={guide} />}
      {!hasVersions && (
        <Typography color="textSecondary" variant="body2">
          Det finnes ingen endringshistorikk til anvisningen
        </Typography>
      )}
    </StyledPaper>
  );
}

type Change = Version & {
  edition: string | undefined;
};

function toPublicId(version: Version): string {
  return `${version.major}.${version.minor}`;
}

function toEdition(version: Version): string {
  return capitalizeFirstLetter(moment(version.createdAt).format('MMMM YYYY'));
}

type Version = NonNullable<Unpacked<Guide['versions']>>;
function ReleaseList({ guide }: { guide: Guide }) {
  var currentEdition: string | undefined = undefined;
  var currentChange: Change | undefined = undefined;
  const arr: Change[] = [];
  for (let index = 0; index < guide.versions!.length; index++) {
    const version = guide.versions![index];
    if (!version) continue;
    if (version.type === PublishType.Major) {
      currentEdition = toEdition(version);
    }
    var change = { edition: currentEdition, ...version };
    if (version.type !== PublishType.Bugfix) arr.push(change);
    else if (currentChange) {
      currentChange.releaseNotes = change.releaseNotes;
      currentChange.retractedAt = change.retractedAt;
      currentChange.retractedReason = change.retractedReason;
    }
    currentChange = change;
  }

  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Dato</TableCell>
            <TableCell>Utgave</TableCell>
            <TableCell>Versjon</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {arr.reverse().map((change) => (
            <VersionRow key={change.versionId} change={change} docId={guide.docId} />
          ))}
        </TableBody>
      </Table>
    </>
  );
}

function VersionRow({ docId, change }: { change: Change; docId: number }) {
  const config = useConfig();

  const [notes, setNotes] = useState<string | null | undefined>(undefined);

  const versionId = toPublicId(change);

  return (
    <>
      {change.retractedAt && (
        <TableRow key={`${change.versionId}-retracted`}>
          <TableCell className={classes.retracted}>{moment(change.retractedAt).format('D.MM.YYYY')}</TableCell>
          <TableCell className={classes.retracted} colSpan={2}>
            Trukket tilbake
          </TableCell>
          <TableCell className={classes.retracted}>
            {change.retractedReason && (
              <IconButton className={classes.smallButton} title="Se endringsmelding" onClick={() => setNotes(change.retractedReason)} size="large">
                <Note fontSize="small" />
              </IconButton>
            )}
          </TableCell>
        </TableRow>
      )}
      <TableRow key={change.versionId}>
        <TableCell>{moment(change.createdAt).format('D.MM.YYYY')}</TableCell>
        <TableCell>{change.edition}</TableCell>
        <TableCell>{versionId}</TableCell>
        <TableCell>
          {change.releaseNotes && (
            <IconButton className={classes.smallButton} title="Se endringsmelding" onClick={() => setNotes(change.releaseNotes)} size="large">
              <Note fontSize="small" />
            </IconButton>
          )}
          <IconButton
            className={classes.smallButton}
            title="Åpne i Byggforsk.no"
            href={config ? `${config.bksUrl}/dokument/${docId}?version=${versionId}` : '#'}
            target="_blank"
            size="large">
            <Launch fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
      {notes && <NotesDialog text={notes} handleClose={() => setNotes(undefined)} />}
    </>
  );
}

function NotesDialog({ text, handleClose }: { text: string; handleClose(): void }) {
  return (
    <StyledDialog maxWidth={'sm'} open={true} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Hva er endret</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Lukk</Button>
      </DialogActions>
    </StyledDialog>
  );
}
