import React from 'react';
import { IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { ConfirmDialog, ConfirmDialogProps } from '../dialogs/ConfirmDialog';
import { useMutation } from '@apollo/client';
import { START_REVISION } from '../guides/guide.graphql';
import { v4 as uuid } from 'uuid';
import { useModal } from '../dialogs/useModal';

export function RevisionCell({ guideId, revisionId }: { guideId: string; revisionId?: string }) {
  const dialogProps: ConfirmDialogProps = {
    title: 'Ny revisjon',
    message: 'Vil du starte ny revisjon?',
    submitText: 'Start',
    cancelText: 'Avbryt',
  };
  const confirmModal = useModal<ConfirmDialogProps, boolean>({ data: dialogProps });
  const [startRevision, { loading }] = useMutation(START_REVISION, { variables: { input: { id: guideId, revisionId: uuid() } } });

  const iconButtonStyle = {
    height: 20,
    width: 20,
    padding: 0,
  };
  if (revisionId) {
    return (
      <IconButton sx={iconButtonStyle} component={Link} to={`/revision/${revisionId}`} title="Gå til revisjon" size="large">
        <EditIcon sx={{ fontSize: 20 }} />
      </IconButton>
    );
  }
  if (loading) {
    return (
      <div style={{ position: 'relative' }}>
        <CircularProgress size={24} />
      </div>
    );
  }
  const handleClick = async () => {
    const confirmed = await confirmModal.open();
    if (confirmed) {
      await startRevision();
    }
  };

  return (
    <>
      <IconButton sx={iconButtonStyle} onClick={handleClick} disabled={loading} title="Start ny revisjon" size="large">
        <AddIcon sx={{ fontSize: 20 }} />
      </IconButton>
      {confirmModal.isOpen && <ConfirmDialog modal={confirmModal} />}
    </>
  );
}
