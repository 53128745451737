import React, { useState } from 'react';
import { Add, ExpandMore } from '@mui/icons-material';
import { GetGuideQuery, GuideRevisionNoticeTypes } from '../__generated__/graphql';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid2 as Grid,
  FormControlLabel,
  Switch,
  ListItemIcon,
  Typography,
  IconButton,
  CircularProgress,
  Box,
} from '@mui/material';
import { TaskActions } from './TaskActions';
import { formatDate } from '../Formatters';
import { PreWrap, StyledLink, SortBy } from '../Components';
import { TaskIcon } from './Overview';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';
import { useModal } from '../dialogs/useModal';
import { AddTaskDialog, AddTaskDialogProps, AddTaskDialogResult } from './AddTaskDialog';
import { useMutation } from '@apollo/client';
import { ADD_GUIDE_TASK } from './task.graphql';

type Task = Unpacked<NonNullable<GetGuideQuery['guide']>['tasks']>;

export function TasksForGuide({ guideId, tasks, onTaskAdded }: { tasks: Task[]; guideId: string; onTaskAdded: () => any }) {
  const [mutation, { loading }] = useMutation(ADD_GUIDE_TASK);
  const addModal = useModal<AddTaskDialogProps, AddTaskDialogResult>({ data: { isOnGuide: true } });
  const addTask = async () => {
    const result = await addModal.open();
    if (result) {
      await mutation({ variables: { input: { guideId, title: result.title, text: result.text } } });
      onTaskAdded();
    }
  };

  return (
    <StyledPaper>
      <Typography variant="h5" component="h3">
        Oppgaver
        <IconButton disabled={loading} onClick={addTask} aria-label="Ny oppgave" size="large">
          {loading && <CircularProgress size={25} />}
          {!loading && <Add />}
        </IconButton>
      </Typography>
      <Typography variant="caption" color="textSecondary">
        (intern bruk)
      </Typography>
      <TaskList guideId={guideId} tasks={tasks} />
      {addModal.isOpen && <AddTaskDialog modal={addModal} />}
    </StyledPaper>
  );
}

export function TaskList({ guideId, tasks }: { guideId: string; tasks: Task[] }) {
  const [showClosedTasks, setShowClosedTasks] = useState(false);

  const allTasks = tasks.map((x) => {
    const completedGuide = x!.guides?.find((g) => g!.guideRevisionId === guideId && g!.completed);
    return { ...x, completedGuide };
  });

  const closedTasks = allTasks
    .filter((x) => x.completedGuide)
    .sort(
      SortBy('createdAt', false, function (a) {
        return a.toUpperCase();
      }),
    );
  let openTasks = allTasks.filter((x) => !x.completedGuide);
  let firstOpenAndClosedTasks = openTasks.concat(closedTasks);
  let allTasksInOpenAndClosedOrder = firstOpenAndClosedTasks.filter((x) => showClosedTasks || !x.completedGuide);
  if (allTasks.length === 0)
    return (
      <Typography variant="subtitle1" color="textSecondary">
        Ingen oppgaver
      </Typography>
    );

  return (
    <div>
      <FormControlLabel
        control={<Switch checked={showClosedTasks} onChange={(e) => setShowClosedTasks(e.target.checked)} />}
        label="Vis lukkede oppgaver"
      />
      {allTasksInOpenAndClosedOrder.map((x) => {
        if (!x) return null;
        return <TaskPanel key={x.id + guideId} task={x} guideId={guideId} />;
      })}
    </div>
  );
}

function TaskPanel({ task, guideId }: { task: Task; guideId: string }) {
  if (!task) return null;
  const isClosed = task!.guides!.find((x) => x!.guideRevisionId === guideId)!.completed;

  return (
    <Accordion sx={{ margin: 0, padding: 0 }}>
      <AccordionSummary sx={{ backgroundColor: isClosed ? '#cfd4d1' : 'inherit' }} expandIcon={<ExpandMore />}>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box sx={{ verticalAlign: 'middle', display: 'inline-flex', alignItems: 'start', paddingRight: '0.5em', whiteSpace: 'pre' }}>
            <ListItemIcon>
              <TaskIcon type={task.type} />
            </ListItemIcon>
          </Box>
          <Box>
            <SourceLink task={task} />
          </Box>
          <Box sx={{ flexGrow: 1, marginLeft: (theme) => theme.spacing(), marginTop: '2px' }}>{task.title}</Box>
          <Box sx={{ paddingLeft: '0.5em' }}>
            <Typography noWrap>{[task.createdByName, formatDate(task.createdAt)].filter(Boolean).join(', ')}</Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          <Grid>
            <PreWrap variant="body1">{task.text}</PreWrap>
          </Grid>
          <Grid>
            <GuideTaskActionPanel guideId={guideId} task={task} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

function GuideTaskActionPanel({ task, guideId }) {
  if (task.completedGuide) {
    const guide = task.completedGuide;
    return (
      <div>
        <Typography>{`Lukket av ${guide.completedByName}, ${formatDate(guide.completedAt)}`}</Typography>
        {guide.completedComment && <Typography>{`Kommentar: ${guide.completedComment}`}</Typography>}
      </div>
    );
  }

  return <TaskActions task={task} guideId={guideId} canDelete />;
}

function SourceLink({ task }: { task: Task }) {
  if (!task) return null;
  switch (task.type) {
    case GuideRevisionNoticeTypes.Paragraph:
      return (
        <StyledLink to={`/paragraph/${task.paragraphId}`}>
          <Typography>{task.sourceDescription}</Typography>
        </StyledLink>
      );
    case GuideRevisionNoticeTypes.Standard:
      return (
        <StyledLink to={`/standard/${task.standardId}`}>
          <Typography>Gå til standard</Typography>
        </StyledLink>
      );
    default:
      return null;
  }
}
