import { useCallback, useMemo, useState } from 'react';

export interface Modal<TData, TResult> {
  isOpen: boolean;
  data: TData;
  open: (data?: TData) => Promise<TResult | undefined>;
  close: (feedback?: TResult) => void;
}

export interface ModalOptions<TData> {
  isOpen?: boolean;
  data: TData;
}

interface ModalState<TResult> {
  resolve?: (value?: TResult) => void;
}

export const useModal = <TData = {}, TResult = {}>(initial: ModalOptions<TData>): Modal<TData, TResult> => {
  const [isOpen, setIsOpen] = useState(initial?.isOpen ?? false);
  const state = useMemo<ModalState<TResult>>(() => ({ resolve: undefined }), []);
  const [modalData, setModalData] = useState<TData>(initial.data);

  const open = useCallback((data?: TData) => {
    setIsOpen(true);
    if (data) {
      setModalData(data);
    }
    return new Promise<TResult | undefined>((resolve) => {
      state.resolve = resolve;
    });
  }, []);

  const close = useCallback((feedback?: TResult) => {
    setIsOpen(false);
    state.resolve?.(feedback);
  }, []);

  return { isOpen, data: modalData, open, close };
};
