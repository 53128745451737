import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Table, TableHead, TableCellProps, TableRow, TableCell, IconButton, FormControlLabel, Switch } from '@mui/material';
import { StyledLink } from '../Components';
import { formatDateOnly, formatDate } from '../Formatters';
import InfiniteScroll from 'react-infinite-scroller';
import { writeFile, utils } from 'xlsx';
import Moment from 'moment';
import { SearchField } from '../components/SearchField';
import Fuse from 'fuse.js';
import { getShortName } from '../resources/Overview';
import { ColWidths, TableCellHead, TableCellEllipsis } from '../components/TableComponents';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { GET_RELEASES } from './guide.graphql';
import { GetReleasesQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';

export const StickyTableCell = styled(TableCellHead)<TableCellProps>(({ theme }) => ({
  position: 'sticky',
  top: theme.spacing(8),
  background: 'white',
}));

export function ReleaseOverview() {
  const { loading, error, data } = useQuery(GET_RELEASES);
  return (
    <StyledPaper>
      <Typography variant="h5">Publiseringer</Typography>
      {loading && <LoadingSpinner />}
      {error && <span>{error.message}</span>}
      {data && data.releases && <ReleaseSearchableTable releases={data.releases} />}
    </StyledPaper>
  );
}

type GuideRelease = Unpacked<GetReleasesQuery['releases']>;
function ReleaseSearchableTable({ releases }: { releases: GuideRelease[] }) {
  const [showBFS, setShowBFS] = useState(true);
  const [showBVN, setShowBVN] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const filter = (releases: GuideRelease[]) => {
    const isBfs = (x: GuideRelease) => !x!.docName || !x!.docName.startsWith('BVN');
    const isBvn = (x: GuideRelease) => !x!.docName || x!.docName.startsWith('BVN');

    const releasesAfterSwitchFilter = releases.filter((x) => (showBFS && isBfs(x)) || (showBVN && isBvn(x)));

    if (!searchTerm) return releasesAfterSwitchFilter;

    let options = {
      shouldSort: false,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0,
      location: 0,
      distance: 10,
      maxPatternLength: 32,
      minMatchCharLength: 3,
      keys: ['docNumber', 'docName', 'title', 'projectLeader'],
    };

    const fuse = new Fuse(releasesAfterSwitchFilter, options);
    let all = fuse.search(searchTerm).map((x) => x.item);
    return all;
  };

  return (
    <>
      <SearchField filter={setSearchTerm} />
      <FormControlLabel
        title="Vis anvisninger under Byggebransjens Våtromsnorm"
        control={<Switch onChange={() => setShowBVN(!showBVN)} checked={showBVN} />}
        label="BVN"
      />
      <FormControlLabel
        title="Vis anvisninger i Byggforskserien"
        control={<Switch onChange={() => setShowBFS(!showBFS)} checked={showBFS} />}
        label="BFS"
      />
      <ReleaseTable releases={filter(releases)} />
    </>
  );
}

function ReleaseTable({ releases }: { releases: GuideRelease[] }) {
  const [numberOfItems, setNumberOfItems] = useState(50);

  return (
    <>
      <ExcelExport releases={releases} />
      <Table size="small" sx={{ position: 'relative' }}>
        <ColWidths widths={[120, null, 160, 80, 120, 120, 100]}></ColWidths>
        <TableHead>
          <TableRow>
            <StickyTableCell>Nummer</StickyTableCell>
            <StickyTableCell>Tittel</StickyTableCell>
            <StickyTableCell>Versjon</StickyTableCell>
            <StickyTableCell>Prosjektleder</StickyTableCell>
            <StickyTableCell>Publisert</StickyTableCell>
            <StickyTableCell>Tilbaketrukket</StickyTableCell>
            <TableCell />
          </TableRow>
        </TableHead>

        <InfiniteScroll element="tbody" loadMore={() => setNumberOfItems(numberOfItems + 50)} hasMore={releases.length > numberOfItems}>
          {releases.slice(0, numberOfItems - 1).map((release, index, array) => {
            if (!release) return null;
            const date = getPublishDate(release);
            const prevDate = index > 0 ? getPublishDate(array[index - 1]!) : undefined;
            return (
              <TableRowWithMonthSeparator key={release.id} currentDate={date} prevDate={prevDate}>
                <TableCell>
                  <StyledLink to={`/guide/${release.guideId}`}>{release.docName}</StyledLink>
                </TableCell>
                <TableCellEllipsis>{release.title}</TableCellEllipsis>
                <TableCell>{release.version}</TableCell>
                <TableCell>{getShortName(release.projectLeader)}</TableCell>
                <TableCell>{!release.archivedAt && formatDateOnly(release.publishedAt)}</TableCell>
                <TableCell>{formatDateOnly(release.archivedAt)}</TableCell>
                <TableCell />
              </TableRowWithMonthSeparator>
            );
          })}
        </InfiniteScroll>
      </Table>
    </>
  );
}

function TableRowWithMonthSeparator({ children, currentDate, prevDate }: { children: any; currentDate: string; prevDate?: string }) {
  const displayMonth = !prevDate || formatDate(currentDate, 'MM') !== formatDate(prevDate, 'MM');

  if (!displayMonth) return <TableRow>{children}</TableRow>;

  return (
    <>
      <TableRow>
        <TableCell colSpan={7}>
          <Typography variant="h2" style={{ fontSize: '1rem', paddingTop: 12 }}>
            {formatDate(currentDate, 'MMM YYYY')}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>{children}</TableRow>
    </>
  );
}

function ExcelExport({ releases }: { releases: GuideRelease[] }) {
  const exportFile = () => {
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, createReleaseSheet(), 'Publiseringer');
    utils.book_append_sheet(workbook, createSummarySheet(), 'Opptelling');
    writeFile(workbook, 'publiseringsoversikt.xlsx');
  };

  const createReleaseSheet = () => {
    const headings: string[] = ['Nummer', 'Tittel', 'Versjon', 'Prosjektleder', 'Publisert', 'Tilbaketrukket'];

    const data = releases.map((rel) => {
      if (!rel) return [];
      return [
        rel.docName,
        rel.title,
        rel.version,
        rel.projectLeader,
        rel.publishedAt ? new Date(rel.publishedAt) : null,
        rel.archivedAt ? new Date(rel.archivedAt) : null,
      ];
    });
    const input = [headings, ...data];
    return utils.aoa_to_sheet(input);
  };

  const createSummarySheet = () => {
    let headings: string[] = ['År'];
    for (var i = 0; i <= 11; i++) {
      headings.push(Moment.monthsShort(i));
    }
    var dates = releases.map((x) => new Date(getPublishDate(x!)));
    var years = dates.map((x) => x.getFullYear()).filter((s, i, a) => a.indexOf(s) === i);
    const data = years.map((year) => {
      let arr = [year];
      for (var i = 0; i <= 11; i++) {
        arr.push(getReleasesInMonth(year, i, dates));
      }
      return arr;
    });
    const input = [headings, ...data];
    return utils.aoa_to_sheet(input);
  };

  const getReleasesInMonth = (year: number, month: number, releases: Date[]) => {
    return releases.filter((x) => {
      return x.getFullYear() === year && x.getMonth() === month;
    }).length;
  };

  return (
    <IconButton
      sx={{ position: 'absolute', right: (theme) => theme.spacing(4), top: (theme) => theme.spacing(12) }}
      onClick={exportFile}
      title="Eksport til Excel"
      size="large">
      <img src="/icons8-microsoft-excel.svg" style={{ width: 24, height: 24 }} alt="Excel icon" />
    </IconButton>
  );
}

function getPublishDate(guide: NonNullable<GuideRelease>): string {
  return guide.archivedAt ? guide.archivedAt : guide.publishedAt;
}
