import { gql } from '../__generated__';

gql(/* GraphQL */ `
  fragment BacklogItem on GuideModel {
    id
    docName
    docNumber
    docId
    docTitle
    hasChangesSinceLastPublish
    lastPublishedAt
    status
    projectLeader
    authors {
      id
      name
    }
    needForRevision
    needForRevisionComment
    planningScores {
      edition {
        value
        score
        boost
      }
      notices {
        value
        score
        boost
      }
      pageViews {
        value
        score
        boost
        placement {
          index
          total
        }
      }
      needForRevision {
        value
        score
        boost
      }
    }
    owner {
      subjectId
      name
    }
    comments {
      id
      comment
      createdAt
      createdBy {
        subjectId
        name
      }
    }
    tasks {
      id
      sourceDescription
      title
      text
      createdAt
      createdByName
    }
    mainCategory {
      id
      title
    }
    notices {
      id
      title
      notice
      createdAt
      deletedAt
    }
    ongoingRevision {
      id
    }
  }
`);

export const GET_ALL = gql(/* GraphQL */ `
  query GetAllBacklogItems {
    guides(where: { status: { neq: EXPIRED } }) {
      ...BacklogItem
    }
  }
`);

export const SET_NEED_FOR_REVISION = gql(/* GraphQL */ `
  mutation GuideSetNeedForRevision($input: GuideSetNeedForRevisionInput!) {
    guideSetNeedForRevision(input: $input) {
      guideModel {
        id
        needForRevision
        needForRevisionComment
      }
    }
  }
`);
