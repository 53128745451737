import { gql } from '../__generated__';

gql(/* GraphQL */ `
  fragment CategoryItem on CategoryModel {
    id
    title
    description
    guideRevisionsWithThisAsMainCategory
    guideRevisionsWithThisAsSubCategory
    owner {
      name
    }
    deputyOwner {
      name
    }
  }
`);

gql(/* GraphQL */ `
  fragment CategoryCommentItem on CategoryComment {
    id
    comment
    createdBy {
      subjectId
      name
    }
    createdAt
    lastUpdatedBy {
      subjectId
      name
    }
    lastUpdatedAt
  }
`);

export const GET_ALL = gql(/* GraphQL */ `
  query GetCategories{
    categories {
      ...CategoryItem
    }
  }
`);

export const GET_ALL_WITH_GUIDE = gql(/* GraphQL */ `
  query GetCategoriesAndGuide($id: UUID!) {
    categories {
      ...CategoryItem
    }

    guide: guide(id: $id) {
      id
      docTitle
      categories {
        id        
      }
    }
  }
`);

export const GET = gql(/* GraphQL */ `
  query GetCategory($id: UUID!) {
    category: category(id: $id) {
      ...CategoryItem
      comments {
        ...CategoryCommentItem
      }
      ownerId
      displayManagerId
      guideRevisions
    }
  }
`);

export const ADD = gql(/* GraphQL */ `
  mutation CategoryAdd($input: CategoryAddInput!) {
    categoryAdd(input: $input) {
      categoryModel {
        id
        title
        description
        owner {
          name
        }
      }
    }
  }
`);

export const UPDATE = gql(/* GraphQL */ `
  mutation CategoryUpdate($input: CategoryUpdateInput!) {
    categoryUpdate(input: $input) {
      categoryModel {
        id
        title
        description
      }
    }
  }
`);

export const DELETE = gql(/* GraphQL */ `
  mutation CategoryDelete($input: CategoryDeleteInput!) {
    categoryDelete(input: $input) {
      categoryModel {
        id
      }
    }
  }
`);

export const REMOVE_CATEGORY = gql(/* GraphQL */ `
  mutation GuideRemoveCategory($input: GuideRemoveCategoryInput!) {
    guideRemoveCategory(input: $input) {
      guideModel {
        id
        mainCategory {
          id
          title
        }        
        categories {
          id
          title
        }
      }
    }
  }
`);

export const ADD_CATEGORY = gql(/* GraphQL */ `
  mutation GuideAddCategory($input: GuideAddCategoryInput!) {
    guideAddCategory(input: $input) {
      guideModel {
        id
        mainCategory {
          id
          title
        }        
        categories {
          id
          title
        }
      }
    }
  }  
`);

export const ADD_COMMENT = gql(/* GraphQL */ `
  mutation CategoryAddComment($input: CategoryAddCommentInput!) {
    categoryAddComment(input: $input) {
      categoryModel {
        id
        comments {
          ...CategoryCommentItem
        }
      }
    }
  }
`);

export const UPDATE_COMMENT = gql(/* GraphQL */ `
  mutation CategoryUpdateComment($input: CategoryUpdateCommentInput!) {
    categoryUpdateComment(input: $input) {
      categoryModel {
        id
        comments {
          ...CategoryCommentItem
        }
      }
    }
  }
`);

export const REMOVE_COMMENT = gql(/* GraphQL */ `
  mutation CategoryRemoveComment($input: CategoryRemoveCommentInput!) {
    categoryRemoveComment(input: $input) {
      categoryModel {
        id
        comments {
          ...CategoryCommentItem
        }
      }
    }
  }
`);

export const SET_OWNER = gql(/* GraphQL */ `
  mutation CategoryUpdateOwner($input: CategoryUpdateOwnerInput!) {
    categoryUpdateOwner(input: $input) {
      categoryModel {
        id
        ownerId
        owner {
          name
        }
      }
    }
  }
`);

export const SET_DEPUTY_OWNER = gql(/* GraphQL */ `
  mutation CategoryUpdateViseOwner($input: CategoryUpdateViseOwnerInput!) {
    categoryUpdateViseOwner(input: $input) {
      categoryModel {
        id
        displayManagerId
        deputyOwner {
          name
        }
      }
    }
  }
`);

gql(/* GraphQL */ `
  fragment DocumentViewItem on DocumentViewModel {
    customer {
          companyName
          visits
          orgNumber
        }
        brregEntity {
          numberOfEmployees
          code {
            code
            description
          }
        }
  }`);

export const GET_PAGEVIEWS_PER_LICENSE = gql(/* GraphQL */ `
  query GetPageViewsPerLicenseForCategory($id: UUID!, $periodStart: DateTime!, $periodEnd: DateTime!, $count: Int!) {
  category(id: $id) {
    id
    pageviewsPerLicense(periodStart: $periodStart, periodEnd: $periodEnd, count: $count) {
       ...DocumentViewItem
    }
  }
}
  `);