import React, { FC } from 'react';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import { ChartComponent } from '../../../charts/ChartComponent';
import { useModal } from '../../../../../dialogs/useModal';
import { ChartDialog, ChartDialogProps, ChartDialogResult } from '../../../charts/ChartDialog';
import { IconButton } from '@mui/material';
import { Edit } from '@mui/icons-material';

export const ChartView: FC<NodeViewProps> = (props) => {
  const { config, width } = props.node.attrs;
  const modal = useModal<ChartDialogProps, ChartDialogResult>({ data: { chartNode: props.node } });
  const openChartDialog = async () => {
    const result = await modal.open({ chartNode: props.node });
    if (result) {
      const { width, config } = result;
      props.updateAttributes({ width, config });
    }
  };
  return (
    <NodeViewWrapper className="react-component">
      {modal.isOpen && <ChartDialog {...modal} />}
      {/* Position the edit button before the Delete button on ChartFigureView */}
      {props.editor.isEditable && (
        <IconButton style={{ position: 'absolute', left: '55px', top: 0 }} title="Rediger" onClick={openChartDialog}>
          <Edit />
        </IconButton>
      )}
      <ChartComponent width={width} config={config} />
    </NodeViewWrapper>
  );
};
