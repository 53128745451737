import { HocuspocusProvider } from '@hocuspocus/provider';
import { CollaborationUser } from '../../../../shared/guide-cms/types/collaborationUser';
import { IdTokenClaims } from 'oidc-client-ts';

export const getAwarenessState = (provider: HocuspocusProvider) => {
  const activeUsers: CollaborationUser[] = [];
  const awareness = provider.awareness?.getStates();
  awareness?.forEach((element) => {
    const user = element.user as CollaborationUser;
    activeUsers.push(user);
  });

  const uniqueActiveUsers = [...new Map(activeUsers.map((item) => [item.id, item])).values()];

  return {
    numberOfConnections: awareness?.size,
    getOtherUsers: (profile: IdTokenClaims) => {
      return uniqueActiveUsers.filter((user) => user.id !== profile.sub);
    },
  };
};
