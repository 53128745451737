import { FC, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Tabs, Tab } from '@mui/material';
import React from 'react';
import { AutoAwesome, OpenInNew } from '@mui/icons-material';
import { Modal } from '../dialogs/useModal';
import { Guides } from './analysis/guides';
import { Abstract } from './analysis/abstract';
import { AiSearchDialogProps, AiSearchDialogResult, AnalysisType } from './types';
import { Advices } from './analysis/advices';
import { TargetAudience } from './analysis/targetAudience';
import { LanguageAnalysis } from './analysis/languageAnalysis';

export const AiSearchDialog: FC<Modal<AiSearchDialogProps, AiSearchDialogResult>> = (modal) => {
  const isRunningInSeparateWindow = window.location.pathname.includes('ai-search-dialog');
  const [currentAnalysis, setCurrentAnalysis] = useState(AnalysisType.Guides);

  const { close } = modal;

  const handleOnClose = () => {
    if (isRunningInSeparateWindow) {
      window.close();
    } else {
      close();
    }
  };

  const handleOnOpenInNewWindow = () => {
    const newUrl = `${window.location.protocol}//${window.location.host}/ai-search-dialog`;
    const newWindow = window.open(newUrl, '_blank', 'popup');
    if (newWindow) {
      newWindow.aiSearchInitialText = modal.data.initialText;
      close();
    }
  };

  const handleTabChange = (_event: React.SyntheticEvent, analysis: AnalysisType) => {
    setCurrentAnalysis(analysis);
  };

  return (
    <Dialog fullScreen={isRunningInSeparateWindow} open={true} onClose={handleOnClose} maxWidth="xl" fullWidth={true}>
      <DialogTitle>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <AutoAwesome /> AI skrivehjelp
          </div>
          {!isRunningInSeparateWindow && (
            <Button variant="outlined" startIcon={<OpenInNew />} onClick={handleOnOpenInNewWindow}>
              Åpne i nytt vindu
            </Button>
          )}
        </div>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={currentAnalysis} onChange={handleTabChange}>
            <Tab label="Anvisninger" value={AnalysisType.Guides} />
            <Tab label="Innholdspunkt" value={AnalysisType.Abstract} />
            <Tab label="Anbefalinger" value={AnalysisType.Advices} />
            <Tab label="Målgrupper" value={AnalysisType.TargetAudience} />
            <Tab label="Språk" value={AnalysisType.LanguageAnalysis} />
          </Tabs>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Guides isOpen={currentAnalysis === AnalysisType.Guides} initialText={modal.data.initialText} />
          <Abstract isOpen={currentAnalysis === AnalysisType.Abstract} initialText={modal.data.initialText} />
          <Advices isOpen={currentAnalysis === AnalysisType.Advices} initialText={modal.data.initialText} />
          <TargetAudience isOpen={currentAnalysis === AnalysisType.TargetAudience} initialText={modal.data.initialText} />
          <LanguageAnalysis isOpen={currentAnalysis === AnalysisType.LanguageAnalysis} initialText={modal.data.initialText} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleOnClose}>
          Lukk
        </Button>
      </DialogActions>
    </Dialog>
  );
};
