import React, { useState } from 'react';

import { styled } from '@mui/material/styles';

import { List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { formatDate } from '../Formatters';
import { Button } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_CHANGES } from './guide.graphql';
import LoadingSpinner from '../LoadingSpinner';
import { useConfig } from '../env';
import { Gavel, GroupWork, LibraryBooks, LocalLibrary } from '@mui/icons-material';
import { Notice } from '../notices/NoticeIcon';
import { ChangeLogTypes, GetGuideChangesQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { StyledPaper } from '../theme';

const PREFIX = 'Changes';

const classes = {
  eventIcon: `${PREFIX}-eventIcon`,
  subHeader: `${PREFIX}-subHeader`,
  root: `${PREFIX}-root`,
  alignText: `${PREFIX}-alignText`,
};

export function Changes({ guideId }: { guideId: string }) {
  const [limit, setLimit] = useState(5);
  const config = useConfig();
  const pollIntervall = config ? config.defaultPollInterval : 0;

  const { loading, error, data, refetch } = useQuery(GET_CHANGES, {
    variables: { id: guideId, first: limit },
    pollInterval: pollIntervall,
  });
  const moreToLoad = data?.changeModel?.nodes && data.changeModel.nodes.length < data.changeModel.totalCount;

  const doFetchMore = () => {
    if (!data) return;
    setLimit(limit + 5);
    refetch();
  };

  return (
    <StyledPaper>
      <Typography variant="h5">Logg</Typography>
      {error && <Typography>{error.message}</Typography>}
      {data && data.changeModel && <ChangeList changes={data.changeModel.nodes} />}
      {loading && <LoadingSpinner />}
      {moreToLoad && (
        <Button disabled={loading} onClick={doFetchMore}>
          Last flere
        </Button>
      )}
    </StyledPaper>
  );
}

type GuideChange = Unpacked<NonNullable<GetGuideChangesQuery['changeModel']>['nodes']>;
function ChangeList({ changes }: { changes: GuideChange[] }) {
  const list = [...changes];
  list.sort((a, b) => (a!.createdAt < b!.createdAt ? 1 : -1));
  return (
    <List>
      {list.map((x) => {
        if (!x) return null;
        return (
          <ListItem key={x.id} disableGutters={true} dense={true}>
            <ListItemIcon>
              <ChangeIcon change={x} className={classes.eventIcon} />
            </ListItemIcon>
            <ListItemText primary={x.description} secondary={`${x.createdByName} ${formatDate(x.createdAt)}`} />
          </ListItem>
        );
      })}
    </List>
  );
}

export function ChangeIcon({ change, className }: { change: NonNullable<GuideChange>; className?: string }) {
  switch (change.type) {
    case ChangeLogTypes.Paragraph:
      return <Gavel className={className} />;
    case ChangeLogTypes.Standard:
      return <LocalLibrary className={className} />;
    case ChangeLogTypes.Guide:
      return <LibraryBooks className={className} />;
    case ChangeLogTypes.Category:
      return <GroupWork className={className} />;
    case ChangeLogTypes.Notice:
      return <Notice className={className} />;

    default:
      return null;
  }
}
