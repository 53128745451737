import { useAuth } from 'oidc-react';
import { useConfig } from './env';
import { useMemo } from 'react';
import axios from 'axios';

export const useAuthenticatedApi = (baseUrl: string) => {
  const config = useConfig();
  const auth = useAuth();

  return useMemo(
    () =>
      axios.create({
        baseURL: `${config?.apiUrl}/${baseUrl}`,
        headers: { Authorization: `Bearer ${auth.userData?.access_token}` },
      }),
    [config?.apiUrl, auth.userData?.access_token, baseUrl],
  );
};
