import { Alert, AlertTitle, Button, Grid2 as Grid, Skeleton, Stack, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, FC, useState } from 'react';
import { AnalysisProps } from '../../types';
import { BfsLanguageSuggestion, useAi } from '../../useAi';
import { AutoFixHigh, Close, Done, DoneAll } from '@mui/icons-material';

export const LanguageAnalysis: FC<AnalysisProps> = ({ initialText, isOpen }) => {
  const api = useAi();
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState(initialText);
  const [suggestions, setSuggestions] = useState<BfsLanguageSuggestion[]>();
  const [isError, setIsError] = useState(false);

  const handleOnTextChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleOnAnalyseLanguageClick = async () => {
    setSuggestions(undefined);
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await api.analyseBfsLanguage({ text });
      if (response.isError) {
        setIsError(true);
        return;
      }
      setSuggestions(response.suggestions);
    } catch (error) {
      console.error('Failed to analyse language', error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const closeSuggestion = (suggestion: BfsLanguageSuggestion) => {
    setSuggestions(suggestions?.filter((s) => s !== suggestion));
  };

  const closeAllSuggestions = () => {
    setSuggestions([]);
  };

  const acceptSuggestion = (suggestion: BfsLanguageSuggestion) => {
    setText(text.replace(suggestion.originalText, suggestion.correctedText));
    closeSuggestion(suggestion);
  };

  const acceptAllSuggestions = () => {
    suggestions?.forEach(acceptSuggestion);
    closeAllSuggestions();
  };

  if (!isOpen) return null;
  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <TextField fullWidth rows={15} multiline value={text} onChange={handleOnTextChange}></TextField>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Button
          disabled={isLoading}
          onClick={handleOnAnalyseLanguageClick}
          size="large"
          variant="contained"
          color="primary"
          startIcon={<AutoFixHigh />}>
          Analyser språk
        </Button>
      </Grid>
      <Grid size={{ xs: 12, lg: 8 }}>
        {!isLoading && suggestions && suggestions.length > 0 && (
          <Stack spacing={2}>
            <Typography variant="h6" component="h3">
              Språkanbefalinger
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button
                startIcon={<DoneAll />}
                variant="contained"
                color="info"
                onClick={acceptAllSuggestions}
                title="Oppdater teksten med alle anbefalinger">
                Aksepter alle anefalinger
              </Button>
              <Button startIcon={<Close />} variant="outlined" onClick={closeAllSuggestions}>
                Lukk alle anefalinger
              </Button>
            </Stack>
            {suggestions.map((suggestion, i) => (
              <Suggestion
                key={`suggestion_${i}`}
                suggestion={suggestion}
                onClose={() => {
                  closeSuggestion(suggestion);
                }}
                onAccept={() => {
                  acceptSuggestion(suggestion);
                }}
              />
            ))}
          </Stack>
        )}

        {isLoading && <LoadingAnimation />}
        {!isLoading && suggestions?.length === 0 && <Alert severity="info">Fant ingen språkanbefalinger fra teksten.</Alert>}
        {isError && <Alert severity="error">Klarte ikke finne språkanbefalinger i teksten. Vennligst prøv igjen.</Alert>}
      </Grid>
    </Grid>
  );
};

const Suggestion: FC<{ suggestion: BfsLanguageSuggestion; onClose: () => void; onAccept: () => void }> = ({ suggestion, onClose, onAccept }) => {
  return (
    <Alert severity="info" onClose={onClose} closeText="Lukk forslag">
      <AlertTitle>{suggestion.rule}</AlertTitle>
      Original tekst: <em>{suggestion.originalText}</em>
      <br />
      Endringsforslag: <em>{suggestion.correctedText}</em>
      <br />
      <br />
      Begrunnelse: {suggestion.details}
      <br />
      <br />
      <Button color="info" startIcon={<Done />} variant="contained" onClick={onAccept} title="Oppdater teksten med anbefalingen">
        Aksepter
      </Button>
    </Alert>
  );
};

const LoadingAnimation = () => {
  return (
    <Stack>
      <Skeleton animation="wave" width="95%" />
      <Skeleton animation="wave" width="100%" />
      <Skeleton animation="wave" width="97%" />
      <Skeleton animation="wave" width="94%" />
      <Skeleton animation="wave" width="98%" />
      <Skeleton animation="wave" width="95%" />
      <Skeleton animation="wave" width="94%" />
      <Skeleton animation="wave" width="98%" />
      <Skeleton animation="wave" width="95%" />
    </Stack>
  );
};
