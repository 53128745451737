import React from 'react';
import { StyledLink } from '../Components';
import { Typography, TableHead, TableCell, TableBody, Table, TableRow } from '@mui/material';
import Moment from 'moment';
import { ColWidths, TableCellHead, TableCellEllipsis } from '../components/TableComponents';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { GET_POPULAR_GUIDES_LAST_MONTH } from './dashboard.graphql';
import { StyledPaper } from '../theme';

const tableStyle = {
  position: 'relative' as 'relative',
};

const stickyHeader = {
  position: 'sticky' as 'sticky',
  top: 0,
  background: 'white',
};

export function PopularGuides() {
  const month = Moment(Date.now()).add(-1, 'months').format('MMMM');
  const { data } = useQuery(GET_POPULAR_GUIDES_LAST_MONTH);
  return (
    <StyledPaper>
      <Typography style={{ fontSize: 18 }}>10 mest leste anvisninger i {month}</Typography>
      {data?.pageViews && <Guides pageViews={data.pageViews.nodes} />}
      {!data && <LoadingSpinner />}
    </StyledPaper>
  );
}

function Guides({ pageViews }) {
  return (
    <Table size="small" style={tableStyle}>
      <ColWidths widths={[100, null, 120]}></ColWidths>
      <TableHead>
        <TableRow>
          <TableCellHead style={stickyHeader}>Nummer</TableCellHead>
          <TableCellHead style={stickyHeader}>Tittel</TableCellHead>
          <TableCellHead align="right" style={stickyHeader}>
            Antall visninger
          </TableCellHead>
        </TableRow>
      </TableHead>
      <TableBody>
        {pageViews.map((p) => {
          return (
            <TableRow key={p.guide.id}>
              <TableCellEllipsis>
                <StyledLink title={`${p.guide.docName} - ${p.guide.docTitle}`} to={'/guide/' + p.guide.id}>
                  {p.guide.docName}
                </StyledLink>
              </TableCellEllipsis>
              <TableCellEllipsis>{p.guide.docTitle}</TableCellEllipsis>
              <TableCell align="right">{Number(p.pageViews).toLocaleString()}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
