import React from 'react';
import { Comment } from '@mui/icons-material';
import { GET_NOTICES } from './dashboard.graphql';
import { CircularProgress, Divider, Grid2 as Grid, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import { StyledLink } from '../Components';
import { StyledPaper } from '../theme';

export function Notices() {
  const { data, loading } = useQuery(GET_NOTICES);
  return (
    <StyledPaper>
      <Typography variant="h5">Antall merknader</Typography>
      <Grid container>
        <Grid size={{ xs: 1 }} style={{ paddingTop: 10 }}>
          <Comment fontSize="large" sx={{ color: (theme) => theme.palette.primary.main }} />
        </Grid>
        <Grid size={{ xs: 11 }}>
          <Typography align="right" variant="h4" color="primary">
            {loading && <CircularProgress size={40} />}
            {data?.notices?.length}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <StyledLink to="/notice">Gå til merknader</StyledLink>
    </StyledPaper>
  );
}
