import React from 'react';
import { FC } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { GuideCmsContextProvider } from './GuideCmsContext';
import { HistoricContentContextProvider } from './historicContent/HistoricContentContext';
import { CommentsContextProvider } from './sidebar/comments/CommentsContext';
import { Layout } from './Layout';

export const GuideCmsPage: FC = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const versionSearchParam = searchParams.get('version');
  const version = versionSearchParam ? parseInt(versionSearchParam) : undefined;

  if (!id) return null;
  return (
    <GuideCmsContextProvider guideId={id} version={version}>
      <HistoricContentContextProvider guideId={id} version={version}>
        <CommentsContextProvider>
          <Layout />
        </CommentsContextProvider>
      </HistoricContentContextProvider>
    </GuideCmsContextProvider>
  );
};
