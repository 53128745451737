import React from 'react';
import { styled } from '@mui/material/styles';
import { useQuery, useMutation } from '@apollo/client';
import {
  Typography,
  List,
  ListItem,
  TextField,
  ListItemText,
  Grid2 as Grid,
  Button,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableCell,
  IconButton,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../LoadingSpinner';
import { UPDATE_NAME, UPDATE_ADDRESS, UPDATE_URL, GET_CHANGES, GET_GUIDES, GET_PARTNER, DELETE } from './partners.graphql';
import { EditableTextField } from '../components/EditableTextField';
import { ListLabel, StyledLink } from '../Components';
import { Partner } from './types';
import { formatDateOnly } from '../Formatters';
import { TableCellHead, TableCellEllipsis, TableCellIcon, ColWidths } from '../components/TableComponents';
import { GuideStatusIcon } from '../guides/GuideStatusIcon';
import { Changes } from '../components/Changes';
import { StyledPaper as ThemedPaper } from '../theme';

const PREFIX = 'Details';

const classes = {
  extraDense: `${PREFIX}-extraDense`,
  detailBox: `${PREFIX}-detailBox`,
  completeRevision: `${PREFIX}-completeRevision`,
  smallButton: `${PREFIX}-smallButton`,
};

const StyledPaper = styled(ThemedPaper)({
  [`& .${classes.extraDense}`]: {
    paddingTop: 1,
    paddingBottom: 1,
  },
  [`& .${classes.detailBox}`]: {
    minHeight: 195,
  },
  [`& .${classes.completeRevision}`]: {
    opacity: 0.6,
  },
  [`& .${classes.smallButton}`]: {
    padding: 6,
    float: 'right',
  },
});

export function Details() {
  const { id } = useParams();
  const partnerId = id as string;

  return (
    <Grid container>
      <Grid size={{ xs: 12 }}>
        <Typography variant="h5" component="h3">
          Partner
        </Typography>
      </Grid>
      <Grid container size={{ xs: 8 }}>
        <Grid size={{ xs: 12 }}>
          <DetailsBox partnerId={partnerId} />
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Guides partnerId={partnerId} />
        </Grid>
      </Grid>
      <Grid size={{ xs: 4 }}>
        <ChangeLog partnerId={partnerId} />
      </Grid>
    </Grid>
  );
}

function DetailsBox({ partnerId }: { partnerId: string }) {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_PARTNER, { variables: { id: partnerId } });
  const [partnerRemove, { called }] = useMutation(DELETE, { variables: { input: { id: partnerId } } });

  const remove = async () => {
    const result = await partnerRemove();
    navigate('/partner');
  };

  if (error) return <Typography>{error.message}</Typography>;
  if (loading || !data) return <LoadingSpinner />;

  const partner = data.partner;
  if (!partner) return <Typography>Fant ikke partner</Typography>;
  return (
    <StyledPaper className={classes.detailBox}>
      <Typography variant="h5">{partner.name + (partner.isDeleted ? ' - Slettet' : '')}</Typography>
      <List dense>
        <ListItem classes={{ dense: classes.extraDense }}>
          <ListLabel>Navn</ListLabel>
          <ListItemText>
            <Name partner={partner} />
          </ListItemText>
        </ListItem>
        <ListItem classes={{ dense: classes.extraDense }}>
          <ListLabel>Adresse</ListLabel>
          <ListItemText>
            <Address partner={partner} />
          </ListItemText>
        </ListItem>
        <ListItem classes={{ dense: classes.extraDense }}>
          <ListLabel>Url</ListLabel>
          <ListItemText>
            <Url partner={partner} />
          </ListItemText>
        </ListItem>
      </List>
      {partner.guides && partner.guides.length === 0 && !partner.isDeleted && (
        <Button disabled={called} onClick={remove} startIcon={<Delete />} variant="contained" color="primary">
          Slett partner
        </Button>
      )}
    </StyledPaper>
  );
}

function Name({ partner }: { partner: NonNullable<Partner> }) {
  const [partnerUpdateName] = useMutation(UPDATE_NAME);

  if (partner.isDeleted) return <span>{partner.name}</span>;
  const value = partner.name ? partner.name : '';
  const setValue = async (input: string) => {
    await partnerUpdateName({ variables: { input: { id: partner.id, name: input } } });
  };
  return <TextEditor placeholder="Navn" value={value} setValue={setValue} />;
}

function Address({ partner }: { partner: NonNullable<Partner> }) {
  const [partnerUpdateAddress] = useMutation(UPDATE_ADDRESS);

  if (partner.isDeleted) return <span>{partner.address}</span>;
  const value = partner.address ? partner.address : '';
  const setValue = async (input: string) => {
    await partnerUpdateAddress({ variables: { input: { id: partner.id, address: input } } });
  };
  return <TextEditor placeholder="Adresse" value={value} setValue={setValue} multiline />;
}

function Url({ partner }: { partner: NonNullable<Partner> }) {
  const [partnerUpdateUrl] = useMutation(UPDATE_URL);

  if (partner.isDeleted) return <a href={partner.url ?? undefined}>{partner.url}</a>;
  const value = partner.url ? partner.url : '';
  const setValue = async (input: string) => {
    await partnerUpdateUrl({ variables: { input: { id: partner.id, url: input } } });
  };

  return (
    <EditableTextField
      value={value}
      DisplayComponent={(props) => <UrlDisplayComponent value={value} edit={props.edit} />}
      TextFieldProps={{
        multiline: true,
      }}
      onUpdate={setValue}
    />
  );
}

function UrlDisplayComponent({ value, edit }: { value: string; edit(): void }) {
  return (
    <Grid container alignItems="center">
      <a href={value}>{value}</a>
      <IconButton onClick={edit} style={{ padding: 5, marginLeft: value ? 16 : 0 }} size="large">
        <Edit fontSize="small" />
      </IconButton>
    </Grid>
  );
}

export function TextEditor({
  placeholder,
  value,
  setValue,
  multiline,
}: {
  placeholder: string;
  multiline?: boolean;
  value: string;
  setValue: (v: string) => Promise<void>;
}) {
  return (
    <EditableTextField
      value={value}
      TextFieldProps={{
        placeholder,
        multiline,
      }}
      onUpdate={setValue}
    />
  );
}

function ChangeLog({ partnerId }: { partnerId: string }) {
  return <Changes id={partnerId} gqlQuery={GET_CHANGES} />;
}

function Guides({ partnerId }: { partnerId: string }) {
  const { loading, error, data } = useQuery(GET_GUIDES, { variables: { id: partnerId } });
  const partnerGuides = data?.guides ? data.guides.filter((x) => data?.partner?.guides?.includes(x!.id)) : undefined;
  const Counter = () => {
    return data ? <span>({partnerGuides?.length})</span> : null;
  };

  return (
    <StyledPaper>
      <Typography variant="h5">
        Anvisninger <Counter />
      </Typography>
      {error && <Typography>{error.message}</Typography>}
      {loading && <LoadingSpinner />}
      {data && (
        <Table size="small">
          <ColWidths widths={[40, 40, null, 150, 40]} />
          <TableHead>
            <TableRow>
              <TableCellHead>Status</TableCellHead>
              <TableCellHead>Nummer</TableCellHead>
              <TableCellHead>Tittel</TableCellHead>
              <TableCellHead>Fagområde</TableCellHead>
              <TableCellHead>Sist publisert</TableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {partnerGuides?.map((x) => {
              if (!x) return null;
              const mainCategoryTitle = x.mainCategory ? x.mainCategory.title : null;
              return (
                <TableRow key={x.id}>
                  <TableCellIcon>
                    <GuideStatusIcon
                      status={x.status!}
                      hasRevision={x.ongoingRevision !== null}
                      hasChangesSinceLastPublish={x.hasChangesSinceLastPublish}
                    />
                  </TableCellIcon>
                  <TableCell>
                    <StyledLink to={`/guide/${x.id}`}>{x.docName}</StyledLink>
                  </TableCell>
                  <TableCellEllipsis title={x.docTitle}>{x.docTitle}</TableCellEllipsis>
                  <TableCellEllipsis title={mainCategoryTitle}>{mainCategoryTitle}</TableCellEllipsis>
                  <TableCell>{formatDateOnly(x.lastPublishedAt)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </StyledPaper>
  );
}
