import { useCallback } from 'react';
import { useAuthenticatedApi } from '../api';

export enum DataType {
  Text = 0,
  Number = 1,
  Boolean = 2,
}

export interface User {
  subjectId: string;
  name: string;
  email: string;
}

export interface Column {
  id: number;
  title: string;
  dataType: DataType;
}

export interface Cell {
  columnId: number;
  value: string;
}

export interface DataSourceInfo {
  id: string;
  correlationId: string;
  name: string;
  description: string;
  version: number;
  updatedAt: string;
  updatedBy: User;
  publishedAt?: string;
  publishedBy?: User;
  isArchived: boolean;
  meta: MetaItem[];
}

export interface MetaItem {
  key: string;
  value: string;
}

export interface DataSourceDetails extends DataSourceInfo {
  isLatestVersion: boolean;
  meta: MetaItem[];
  columns: Column[];
  values: Cell[][];
}

export interface DataSourcePayload {
  name: string;
  description: string;
  meta: MetaItem[];
  columns: Column[];
  values: Cell[][];
}

export const useDataSourceApi = () => {
  const api = useAuthenticatedApi('datasource-admin');

  const getLatestVersions = useCallback(async () => {
    const response = await api.get<DataSourceInfo[] | string>('');
    if (response.status === 200) return response.data as DataSourceInfo[];
    throw new Error(response.data as string);
  }, [api]);

  const getDetails = useCallback(
    async (id: string) => {
      const response = await api.get<DataSourceDetails | string>(id);
      if (response.status === 200) return response.data as DataSourceDetails;
      throw new Error(response.data as string);
    },
    [api],
  );

  const update = useCallback(
    async (id: string, payload: DataSourcePayload) => {
      const response = await api.put<DataSourceDetails | string>(id, payload);
      if (response.status === 200) return response.data as DataSourceDetails;
      throw new Error(response.data as string);
    },
    [api],
  );

  const deleteVersion = useCallback(
    async (correlationId: string, id: string) => {
      const response = await api.delete<DataSourceDetails | undefined | string>(`by-correlation-id/${correlationId}/${id}`);
      if (response.status === 200) return response.data as DataSourceDetails;
      if (response.status === 204) return undefined;
      throw new Error(response.data as string);
    },
    [api],
  );

  const publish = useCallback(
    async (id: string) => {
      const response = await api.post<DataSourceDetails | string>(`${id}/publish`);
      if (response.status === 200) return response.data as DataSourceDetails;
      throw new Error(response.data as string);
    },
    [api],
  );

  const unpublish = useCallback(
    async (id: string) => {
      const response = await api.post<DataSourceDetails | string>(`${id}/unpublish`);
      if (response.status === 200) return response.data as DataSourceDetails;
      throw new Error(response.data as string);
    },
    [api],
  );

  const createNew = useCallback(async () => {
    const response = await api.post<DataSourceDetails | string>(`version`);
    if (response.status === 200) return response.data as DataSourceDetails;
    throw new Error(response.data as string);
  }, [api]);

  const getLatestVersionDetails = useCallback(
    async (correlationId: string) => {
      const response = await api.get<DataSourceDetails | string>(`by-correlation-id/${correlationId}`);
      if (response.status === 200) return response.data as DataSourceDetails;
      throw new Error(response.data as string);
    },
    [api],
  );

  const getVersionDetails = useCallback(
    async (correlationId: string, version: number) => {
      const response = await api.get<DataSourceDetails | string>(`by-correlation-id/${correlationId}/version/${version}`);
      if (response.status === 200) return response.data as DataSourceDetails;
      throw new Error(response.data as string);
    },
    [api],
  );

  const createNewVersion = useCallback(
    async (correlationId: string) => {
      const response = await api.post<DataSourceDetails | string>(`by-correlation-id/${correlationId}/version`);
      if (response.status === 200) return response.data as DataSourceDetails;
      throw new Error(response.data as string);
    },
    [api],
  );

  const getVersions = useCallback(
    async (correlationId: string) => {
      const response = await api.get<DataSourceInfo[] | string>(`by-correlation-id/${correlationId}/version`);
      if (response.status === 200) return response.data as DataSourceInfo[];
      throw new Error(response.data as string);
    },
    [api],
  );

  const archiveAllVersionsOfDataSource = useCallback(
    async (correlationId: string) => {
      const response = await api.post<undefined | string>(`by-correlation-id/${correlationId}/archive`);
      if (response.status === 200) return undefined;
      throw new Error(response.data as string);
    },
    [api],
  );

  return {
    getLatestVersions,
    getDetails,
    update,
    publish,
    unpublish,
    createNew,
    getLatestVersionDetails,
    getVersionDetails,
    createNewVersion,
    getVersions,
    deleteVersion,
    archiveAllVersionsOfDataSource,
  };
};
