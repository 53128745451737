import styled from '@emotion/styled';
import React, { FC } from 'react';
import { theme } from '../../../../theme';
import { SidebarHeading } from '../SidebarHeading';
import { Help as HelpIcon } from '@mui/icons-material';
import { LatexHelp } from './LatexHelp';

const DrawerContent = styled.div`
  padding: ${theme.spacing(2)};
`;

export const Help: FC = () => {
  return (
    <DrawerContent>
      <SidebarHeading icon={<HelpIcon />}>Hjelp</SidebarHeading>
      <LatexHelp />
    </DrawerContent>
  );
};
