import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useCommentsContext } from './CommentsContext';
import { AccountCircle, CheckCircle } from '@mui/icons-material';

export const CommentsFilter: FC = () => {
  const { isCommentsEnabled, filter, filterComments } = useCommentsContext();

  if (!isCommentsEnabled) {
    return null;
  }
  return (
    <Stack direction="row" gap={2}>
      <div>
        <Stack direction="row" gap={1} alignItems="center">
          <FormControlLabel
            control={<Checkbox color="default" checked={filter.internal} onChange={(_, internal) => filterComments({ ...filter, internal })} />}
            label={
              <Stack direction="row" alignItems="center" gap={1}>
                <AccountCircle color="info" /> Interne
              </Stack>
            }
          />
          <FormControlLabel
            control={
              <Checkbox color="default" checked={filter.external} onChange={(_, hearing) => filterComments({ ...filter, external: hearing })} />
            }
            label={
              <Stack direction="row" alignItems="center" gap={1}>
                <AccountCircle color="warning" /> Eksterne
              </Stack>
            }
          />
          <FormControlLabel
            control={<Checkbox color="default" checked={filter.closed} onChange={(_, closed) => filterComments({ ...filter, closed })} />}
            label={
              <Stack direction="row" alignItems="center" gap={1}>
                <CheckCircle color="success" /> Lukkede
              </Stack>
            }
          />
        </Stack>
      </div>
    </Stack>
  );
};
