import { gql } from '../__generated__';

gql(/* GraphQL */ `
  fragment TaskItem on TaskModel {
    id
    title
    text
    type
    createdAt
    createdByName
    sourceDescription
    paragraphId
    standardId
    guides {
      guideRevisionId
      name
      title
      ownerId
      ownerName
      completed
      completedByName
      completedAt
      completedComment
    }
  }
`);

export const GET_ALL = gql(/* GraphQL */ `
  query GetTasks {
    tasks {
      ...TaskItem
    }
  }
`);

export const DELETE = gql(/* GraphQL */ `
  mutation TaskDelete($input: TaskDeleteInput!) {
    taskDelete(input: $input) {
      taskModel {
        id
      }
    }
  }
`);

export const UPDATE = gql(/* GraphQL */ `
  mutation TaskUpdate($input: TaskUpdateInput!) {
    taskUpdate(input: $input) {
      taskModel {
        id
        title
        text
      }
    }
  }
`);

export const COMPLETE = gql(/* GraphQL */ `
  mutation TaskComplete($input: TaskCompleteInput!) {
    taskComplete(input: $input) {
      taskModel {
        ...TaskItem
      }
    }
  }
`);

export const ADD_GUIDE_TASK = gql(/* GraphQL */ `
  mutation TaskAddToGuide($input: TaskAddToGuideInput!) {
    taskAddToGuide(input: $input) {
      taskModel {
        ...TaskItem
      }
    }
  }
`);

export const ADD_STANDARD_TASK = gql(/* GraphQL */ `
  mutation TaskAddToStandard($input: TaskAddToStandardInput!) {
    taskAddToStandard(input: $input) {
      taskModel {
        ...TaskItem
      }
    }
  }
`);

export const ADD_PARAGRAPH_TASK = gql(/* GraphQL */ `
  mutation TaskAddToParagraph($input: TaskAddToParagraphInput!) {
    taskAddToParagraph(input: $input) {
      taskModel {
        ...TaskItem
      }
    }
  }
`);
