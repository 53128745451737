import React, { useState } from 'react';
import { Typography, Tabs, Tab, Grid2 as Grid, FormControlLabel, Switch } from '@mui/material';
import { MultiSelect } from '../Components';
import { SummaryTable } from './SummaryTable';
import { ResourceTable } from './ResourceTable';
import { REVISIONS, USERS } from './resources.graphql';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../LoadingSpinner';
import { selectResourceFilter, setIgnoredRoles } from './filterSlice';
import { GetRevisionsForResourcesQuery } from '../__generated__/graphql';
import { Unpacked } from '../graphQLTypes/types';
import { useAuth } from 'oidc-react';
import { StyledPaper } from '../theme';

interface RoleDefinition {
  label: string;
  title: string;
}

export const roles = {
  PROJECT_LEAD: { label: 'PL', title: 'Prosjektleder' } as RoleDefinition,
  INTERAL_QUALITY_ASSURER: { label: 'IKS', title: 'Intern kvalitetssikrer' } as RoleDefinition,
  PEER_REVIEWER: { label: 'SK', title: 'Sidemannkontroll/sluttkontroll' } as RoleDefinition,
  AUTHOR: { label: 'FF', title: 'Forfatter' } as RoleDefinition,
  PROFESSION_QUALITY_ASSURER: { label: 'FKS', title: 'Faglig kvalitetssikrer' } as RoleDefinition,
  DAC: { label: 'DAK', title: 'Dac' } as RoleDefinition,
  PUBLISHING_EDITOR: { label: 'RED', title: 'Publishing Editor' } as RoleDefinition,
  OWNER: { label: 'FA', title: 'Fagansvarlig' } as RoleDefinition,
};

export interface Option {
  value: string;
  label: string;
}

export type Revision = Unpacked<GetRevisionsForResourcesQuery['revisions']>;

export function getRoles(
  revision: Revision,
): { role: string; userId?: string | null | undefined; name?: string | null | undefined; shortName?: string | null }[] {
  const list = Object.keys(roles).map((r) => {
    if (r === 'OWNER') {
      const owner = revision?.guide?.owner;
      return { role: r, userId: owner?.subjectId, name: owner?.name, shortName: getShortName(owner?.name) };
    }
    if (r === 'AUTHOR') {
      return { role: r, name: revision?.authors?.map((x) => x?.name).join(), shortName: revision?.authors?.map((x) => getShortName(x?.name)).join() };
    }
    if (r === 'PROFESSION_QUALITY_ASSURER') {
      return {
        role: r,
        name: revision?.professionQualityAssurers?.map((x) => x?.name).join(),
        shortName: revision?.professionQualityAssurers?.map((x) => getShortName(x?.name)).join(),
      };
    }

    const revisionRole = revision?.roles?.find((x) => x?.role === r);
    const user = revisionRole?.user;
    return { role: r, userId: user?.subjectId, name: user?.name, shortName: getShortName(user?.name) };
  });

  return list;
}

export function Overview() {
  const auth = useAuth();
  const userId = auth.userData?.profile.sub;
  const userName = getShortName(auth.userData?.profile.name ?? 'bruker') || 'bruker';
  const [searchParams] = useSearchParams();
  const showMe = searchParams.get('me') === '1';

  const [selectedUsers, setSelectedUsers] = useState<Option[]>(showMe && userId ? [{ label: userName, value: userId }] : []);

  const { data: userData } = useQuery(USERS);
  const users = userData?.users;
  const { data: revisionsData, loading } = useQuery(REVISIONS);
  const revisions = revisionsData?.revisions;

  return (
    <Grid container>
      <Grid size={{ xs: 12 }}>
        <StyledPaper>
          <Typography variant="h5">Anvisninger under revisjon</Typography>
          <MultiSelect
            placeholder="Ressurser"
            options={users ? users.map((u) => ({ value: u?.subjectId, label: getShortName(u?.name) })) : [{ label: 'Laster..', value: '' }]}
            value={selectedUsers}
            onChange={(values: Option[]) => (values ? setSelectedUsers(values) : setSelectedUsers([]))}
          />
          {loading && <LoadingSpinner />}
          {revisions && revisions.length === 0 && <Typography variant="subtitle1">Ingen anvisninger</Typography>}
          {revisions && revisions.length > 0 && <RevisionList revisions={revisions} selectedUsers={selectedUsers} />}
        </StyledPaper>
      </Grid>
    </Grid>
  );
}

function RevisionList({ revisions, selectedUsers }: { revisions: Revision[]; selectedUsers: Option[] }) {
  const [tab, setTab] = React.useState(0);
  const { ignoredRoles } = useSelector(selectResourceFilter);
  const userIds = selectedUsers.map((x) => x.value);

  const filteredRevisions =
    selectedUsers.length > 0
      ? revisions.filter((revision) => getRoles(revision).some((r) => !ignoredRoles.includes(r?.role ?? '') && userIds.includes(r?.userId ?? '')))
      : revisions;

  return (
    <>
      <Filters revisions={revisions} selectedUsers={selectedUsers} />
      <Tabs value={tab} onChange={(_, val) => setTab(val)} style={{ marginTop: '35px', marginLeft: '0px' }}>
        <Tab label="Oversikt" />
        <Tab label="Opptelling" />
      </Tabs>
      {tab === 0 && <ResourceTable revisions={filteredRevisions} selectedUsers={selectedUsers.map((x) => x.value)} />}
      {tab === 1 && <SummaryTable revisions={filteredRevisions} selectedUsers={selectedUsers} />}
    </>
  );
}

function Filters({ revisions, selectedUsers }: { revisions: Revision[]; selectedUsers: Option[] }) {
  const { ignoredRoles } = useSelector(selectResourceFilter);
  const dispatch = useDispatch();

  const userIds = selectedUsers.map((u) => u.value);
  const rolesWithUsers = revisions.flatMap((r) => r?.roles).filter((r) => userIds.includes(r?.user?.subjectId));
  let uniqueRoles = rolesWithUsers.map((x) => x?.role).filter((v, i, a) => a.indexOf(v) === i);

  const toggleRole = (role: string) => {
    if (ignoredRoles.includes(role)) {
      dispatch(setIgnoredRoles(ignoredRoles.filter((x) => x !== role)));
    } else {
      dispatch(setIgnoredRoles([...ignoredRoles, role]));
    }
  };

  if (uniqueRoles.length === 0) return null;

  return (
    <div>
      <ul style={{ listStyleType: 'none', paddingLeft: 12 }}>
        {uniqueRoles.map((role) => {
          if (!role) return null;
          return (
            <li key={role} style={{ float: 'left' as 'left' }}>
              <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', float: 'left', fontSize: 14 }}>
                <FormControlLabel
                  control={<Switch onChange={() => toggleRole(role)} checked={!ignoredRoles.includes(role)} />}
                  label={roles[role].label}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export const getShortName = (name: string | null | undefined) => {
  if (!name) {
    return null;
  }
  const names = name.split(',');
  const nameparts = names[0].split(' ');
  const firstName = nameparts.shift();
  const shortName = firstName + ' ' + nameparts.map((n) => n[0]).join(' ');
  return names.length === 1 ? shortName : shortName + ' m.fl.';
};
